import { Breadcrumb, Input } from 'antd'
import React, { useContext, useState } from 'react'
import { create_product_getProducts_Api } from '../../../apis'
import { organizationModel, productModel } from '../../../apis/model'
import { productSchema } from '../../../apis/schema'
import { SelectEntityItem } from '../../../components/SelectEntityItem'
import { emptyArray } from '../../../helpers/emptyObjects'
import useAsyncWithCache from '../../../modules/asyncCache/useAsyncWithCache'
import useTranslate from '../../../modules/local/useTranslate'
import { Product } from '../../../views/Market/Impcat/Impcat'
import { OrganizationContext } from '../OrganizationContext'

export function Products() {
  const {
    groups = emptyArray,
    organization
  } = useContext(OrganizationContext)
  const oId = organizationModel.getId(
    organization
  )
  useAsyncWithCache({
    cacheId: `create_product_getProducts_Api[organization,${oId}]`,
    apiInfo: create_product_getProducts_Api(),
    query: {
      ':type': 'organization',
      ':id': oId
    }
  })
  const t = useTranslate()
  const [filter, setFilter] = useState();


  return (
    <div className="p-3 space-y-6">
      <div className="flex flex-shrink-0">
        <Breadcrumb>
          <Breadcrumb.Item>
            {organizationModel.getTitle(
              organization
            )}
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {t('products')}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-y-3 md:gap-3">
        <section className="w-full grid-cols-1">

          <div
            className="w-full flex-col space-y-3 sticky top-0 md:shadow-none flex flex-1 md:flex-col flex-row overflow-auto rounded  bg-transparent  md:background-100 md:border border-gray-300 ">
            <Input.Search
              className="block py-3 md:p-3"
              placeholder={t(
                'type product name'
              )}
              allowClear
              // enterButton="Search"
              size="middle"
              onSearch={value => {
                setFilter(value)
              }}
            />
            <div
              className="p-0 md:px-3 md:background-100 text-gray-600 font-bold uppercase flex truncate flex-shrink-0 items-center font-semibold text-color-200">
              {t('product catalogs')}
            </div>
            <div className="py-3 md:p-3">
              {groups.map((o, i) => (
                <React.Fragment key={i}>
                  <a
                    id={o.title}
                    href={'#' + o.title}
                    className="py-1 font-bold mr-2 md:rounded-none border-gray-300 md:mr-0 flex-shrink-0 text-center rounded-lg border-none md:text-left cursor-pointer hover:background-200 md:border-b border-gray-300 flex items-center">
                    {o.title}
                  </a>
                  {o.products.map(
                    (p, i) => (
                      <a
                        id={p.idname}
                        key={
                          '#' + p.idname
                        }
                        href={
                          '#' + p.idname
                        }
                        className="py-1 mr-2 md:rounded-none border-gray-300 md:mr-0 flex-shrink-0 text-center rounded-lg md:border-none md:text-left cursor-pointer hover:background-200 md:border-b border-gray-300 flex items-center">
                        {' • '}
                        {p.title}
                      </a>
                    )
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </section>
        <section className="space-y-3 right col-span-3">
          <div className="py-3 font-medium text-xl border-b-2 border-green-500">
            {
              t('all products')
            }
          </div>
          {groups.map((o, i) => (
            <div
              key={i}
              className="space-y-3 flex flex-col space-y-3 relative">
              <a className="font-bold hover:underline text-xl font-bold">
                <div
                  className="absolute"
                  style={{
                    top: '-50px'
                  }}
                  id={o.title}></div>
                {o.title}
              </a>
              {o.products.map(
                product => {
                  const id = productModel.getId(
                    product
                  )
                  const visible =
                    !filter ||
                    (
                      product.title +
                      product.idname
                    ).includes(filter)
                  if (!visible)
                    return null
                  return (
                    <SelectEntityItem
                      key={id}
                      item={id}
                      schema={
                        productSchema
                      }>
                      {(
                        item = product
                      ) => (
                        <Product
                          {...{ item }}
                        />
                      )}
                    </SelectEntityItem>
                  )
                }
              )}
            </div>
          ))}
        </section>
      </div>
    </div>
  )
}
