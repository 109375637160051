import {Breadcrumb, Button, Form, Input, Modal, Select} from 'antd'
import _ from 'lodash'
import {useAppConfig} from 'modules/local'
import React, {useContext, useState} from 'react'
import {BsListUl} from "react-icons/bs"
import {Link} from 'react-router-dom'
import {datasets_getDatasetsByOwner_Api} from '../../../apis'
import {organizationModel} from '../../../apis/model'
import EmptyHolder from '../../../components/EmptyHolder'
import StickyFrame from "../../../components/StickyFrame"
import {emptyArray} from '../../../helpers/emptyObjects'
import logFunc from '../../../helpers/logFunc'
import {LazyPagination} from '../../../modules/asyncCache/components/LazyPagination'
import useTranslate from '../../../modules/local/useTranslate'
import {OrganizationContext} from '../OrganizationContext'
import {DatasetItem} from './DatasetItem'

const SelectCategory = ({
  onSelect,
                          hierarchy_categories
}) => {
  return (
    <div className="w-full mt-6 space-y-3">
      {hierarchy_categories.map(
        (e, i) => {
          return (
            <div
              key={i}
              className="border-l-2 border-transparent hover:border-primary">
              <div
                onClick={() =>
                  onSelect(e.idname, e)
                }
                key={e.id}
                className="cursor-pointer hover:background-200 py-2 pl-3 hover:text-primary font-bold flex items-center text-sm uppercase">
                {e.name}
              </div>
              <div className="block w-full ">
                <div className="w-full flex flex-col overflow-hidden ">
                  {_.get(
                    e,
                    'children',
                    emptyArray
                  ).map(ii => {
                    return (
                      <div
                        key={ii.idname}
                        className="border-l-4 border-transparent hover:border-primary">
                        <div
                          onClick={() =>
                            onSelect(
                              ii.idname,
                              ii
                            )
                          }
                          className="pl-3 py-1 hover:background-200 block hover:background-200 font-medium title-font cursor-pointer">
                          {ii.name}
                        </div>
                        {_.get(
                          ii,
                          'children',
                          emptyArray
                        ).map(iii => {
                          return (
                            <div
                              onClick={() =>
                                onSelect(
                                  iii.idname,
                                  iii
                                )
                              }
                              key={
                                iii.idname
                              }
                              className="pl-3 py-1 border-l-8 border-transparent hover:border-primary capitalize hover:background-200 cursor-pointer">
                              {iii.name}
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )
        }
      )}
    </div>
  )
}

export function Data() {
  const [
    { keyword, categories },
    setFilter
  ] = useState({
    categories: [],
    keyword: ''
  })
  const { organization } = useContext(
    OrganizationContext
  )
  const t = useTranslate()

  const appConfig = useAppConfig()
  const {
    hierarchy_categories = emptyArray,
  } = appConfig ||
    {
      hierarchy_categories: emptyArray
    }

  const [form] = Form.useForm()
  const [
    options,
    setOptions
  ] = useState([])
  return (
    <div className="p-3 space-y-6">
      <div className="flex flex-shrink-0">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link
              to={organizationModel.getLinkToDetail(
                organization
              )}>
              {organizationModel.getTitle(
                organization
              )}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {t('data')}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 md:gap-3 gap-y-3">
        <section
          style={{
            overflow: 'initial'
          }}
          className="h-full py-3">
          <StickyFrame>
            <div className="space-y-3">
              <div className="font-bold px-3 py-1 uppercase">
                {t('search')}
              </div>
              <div className="flex p-3 background-100 rounded">
                <Form
                  className="w-full"
                  form={form}
                  layout="vertical"
                  onFinish={logFunc(
                    setFilter
                  )}>
                  <div
                    style={{
                      minWidth: 120
                    }}
                    className="flex-1">
                    <Form.Item
                      className="flex-1"
                      name="keyword"
                      label={t('keyword')}>
                      <Input
                        allowClear
                        placeholder={t(
                          'keyword'
                        )}
                      />
                    </Form.Item>
                  </div>
                  <Form.Item
                    label={t('categories')}
                    name="categories">
                    <Select
                      allowClear
                      open={false}
                      options={options}
                      onFocus={() => {
                        const modal = Modal.info(
                          {
                            icon: null,
                            maskClosable: true,
                            title: t(
                              'categories'
                            ),
                            content: (
                              <SelectCategory
                                hierarchy_categories={
                                  hierarchy_categories
                                }
                                onSelect={(
                                  id,
                                  item
                                ) => {
                                  setOptions(
                                    [
                                      {
                                        value: id,
                                        label:
                                          item.name
                                      }
                                    ]
                                  )
                                  form.setFieldsValue(
                                    {
                                      categories: id
                                    }
                                  )
                                  modal.destroy()
                                }}
                              />
                            )
                          }
                        )
                      }}
                      placeholder={t(
                        'categories'
                      )}
                    />
                  </Form.Item>
                  <div className="flex space-x-3">
                    <div className="flex-1" />
                    <Button
                      htmlType="button"
                      onClick={() => {
                        form.resetFields()
                      }}>
                      {t('reset')}
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit">
                      {t('search')}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </StickyFrame>
        </section>
        <section className="space-y-3 col-span-2 py-3 w-full mx-auto">
          <div className="font-bold px-3 py-1 uppercase">
            {t('dataset')}
          </div>
          <LazyPagination
            key={JSON.stringify({
              api: datasets_getDatasetsByOwner_Api.path,
              keyword,
              categories
            })}
            renderEmpty={() => (
              <div className="items-center justify-center h-64">
                <EmptyHolder
                  icon={() => (
                    <div
                      style={{
                        fontSize:
                          '3rem',
                        color:
                          'rgb(170,176,184)'
                      }}>
                      <BsListUl />
                    </div>
                  )}
                  // title={null}
                  subTitle={null}
                />
              </div>
            )}
            apiInfo={{
              ...datasets_getDatasetsByOwner_Api,
              paramsFields: [
                'keyword',
                'categories'
              ]
            }}
            query={{
              ':type': 'organization',
              ':id': organization.idname
            }}
            values={{
              pinned: 0,
              keyword,
              categories
            }}
            renderLoading={() => (
              <>
                <div className="h-40 background-100 rounded p-3" />
                <div className="h-40 background-100 rounded p-3" />
              </>
            )}
            renderItem={item => (
              <div
                className="p-3 block cursor-pointer border border-gray-100 hover:border-gray-300  background-100  rounded-lg">
                <DatasetItem
                  item={item}
                />
              </div>
            )}
          />
        </section>
      </div>
    </div>
  )
}
