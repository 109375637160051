const env = 'PROD'
const ENV_LABEL = 'BETA';
const IS_CODOVA = false
const API_ROOT_URL =
  'https://feedin.me/api/v1'
const ROOT_URL =
  'https://feedin.me'

const STORAGE_URL =
  'https://feedin.me/storage'
const SOCKET_URL =
  'https://socket.feedin.me'
const STORAGE_GEO_VN_DATA_URL =
  'https://feedin.me/storage/geodata/json/vietnamLow.json'
const FB_ID = '201398911222813'
const GG_ID =
  '759535059502-ohstjkt8dbfitibjht4fduliea04kg8l.apps.googleusercontent.com'
const ANDROID_PLAY_LINK =
  'https://play.google.com/store/apps/details?id=feedin.app'
const APPLE_ITUNES_LINK =
  'https://itunes.apple.com/us/app/feedin-app/id1401245325'
const GOOGLE_API_KEY =
  'AIzaSyB3hLHkkmk60AHhjw7ybSzf0R0PC_R3LYs'
const FIREBASE_MESSAGING_SENDER_ID =
  '713485216987'

export {
  env,
  ENV_LABEL,
  IS_CODOVA,
  ROOT_URL,
  API_ROOT_URL,
  STORAGE_URL,
  SOCKET_URL,
  STORAGE_GEO_VN_DATA_URL,
  FB_ID,
  GG_ID,
  ANDROID_PLAY_LINK,
  APPLE_ITUNES_LINK,
  GOOGLE_API_KEY,
  FIREBASE_MESSAGING_SENDER_ID
}
