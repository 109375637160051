import Icon from '@ant-design/icons'
import {Select} from 'antd'
import filterOption from 'helpers/filterOption'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useMemo, useRef, useState} from 'react'
import {BsFolderFill} from "react-icons/bs";

export function SelectCategory({
                                 value: category_id,
                                 onChange,
                                 categoryList
                               }) {
  const t = useTranslate()
  const cacheCategoryListRef = useRef(
    categoryList
  )
  if (!_.isEmpty(categoryList)) {
    cacheCategoryListRef.current = categoryList
  }

  const options = useMemo(() => {
    const result = [
      {
        key: '',
        value: '',
        label: t('all categories'),
        customClassName: "text-sm font-medium mr-1 text-gray-600 capitalize pl-2 py-1",
        customlabel: (
          <div>
            <span className="text-sm font-medium mr-1 text-gray-600 capitalize pl-2 py-1">
              {t('category')}:{' '}
            </span>
            <span>{t('all categories')}</span>
          </div>
        )
      },
      ..._.flatMap(categoryList, ({id, name, children}) =>
        [
          {
            key: id,
            value: id,
            label: name,
            customClassName: "text-sm font-bold mr-1 text-gray-600 uppercase pl-2 py-1",
            customlabel: (
              <div className="text-sm font-bold mr-1 text-gray-600 uppercase pl-2 py-1">
                {name}
              </div>
            )
          },
          ..._.map(children, cat => ({
            key: id + '_' + cat.id,
            value: cat.id,
            label: cat.name,
            customClassName: "text-sm mr-1 text-gray-600 capitalize pl-3 py-1",
            customlabel: (
              <div className="text-sm mr-1 text-gray-600 capitalize pl-3 py-1">
                {cat.name}
              </div>
            )
          }))
        ]
      )
    ];

    return result;
  }, [categoryList, t])
  const [fosus, setfocus] = useState()
  const customfilterOption = filterOption
  return (
    <div className="w-full py-1  m-auto flex overflow-hidden rounded-lg space-x-2">
      <div
        style={
          fosus
            ? {
              width: 'calc(100Vw - 32px)'
            }
            : {
              width: 200,
              maxWidth: '25vw'
            }
        }
        className=" flex-shrink-0 background-200 sflex-shrink-0 sticky left-0 z-10 flex items-center rounded-full background-200">
        <Select
          showSearch
          onDropdownVisibleChange={
            setfocus
          }
          className="text-xs md:text-base block w-full"
          bordered={false}
          virtual={false}
          value={category_id}
          optionFilterProp="label"
          optionLabelProp={"label"}
          filterOption={
            customfilterOption
          }
          defaultValue={''}
          placeholder={t('categories')}
          suffixIcon={
            <Icon
              component={BsFolderFill}
            />
          }
          onChange={onChange}
          // options={options}
        >
          {options.map(item => {
            return (
              <Select.Option key={item.key}
                             value={item.value}
                             label={item.label}
                             className={item.customClassName}
                             children={item.customlabel}/>
            )
          })}
        </Select>
      </div>
    </div>
  )
}
