import { API_ROOT_URL } from 'envs/_current/config'
import { createAsyncAction } from 'modules/asyncCache'
import { schema } from 'normalizr'
import { baseFieldTypes } from './base/baseFieldTypes'
import {
  pollSchema,
  userSchema
} from './schema'
import { createListTransform } from './utils/createListTransform'
import { createTransform } from './utils/createTransform'

const fields = [
  'user_id', // nguoi tao
  'owner_id', // chủ sở hữu
  'owner_type', // chủ sở hữu
  'title', // câu hỏi
  'description', // mô tả thêm
  'status',
  'privacy', // public, limited, private
  'vote_on',
  'answers' // cho phep vote tren bao nhieu option
]

const fieldTypes = {
  user_id: baseFieldTypes.auto, // nguoi tao
  owner_id: baseFieldTypes.auto, // chủ sở hữu
  owner_type: baseFieldTypes.auto, // chủ sở hữu
  title: baseFieldTypes.string, // câu hỏi
  description: baseFieldTypes.string, // mô tả thêm
  status: baseFieldTypes.string,
  privacy: [
    {
      value: 'public',
      label: 'public'
    },
    {
      value: 'private',
      label: 'private'
    }
  ], // public, limited, private
  vote_on: baseFieldTypes.string, // cho phep vote tren bao nhieu option,
  answers: [
    {
      title: baseFieldTypes.string, // câu trả lời
      description:
        baseFieldTypes.string, // mô tả thêm, nếu có
      ref_type: baseFieldTypes.string, // câu trả lời tham chiếu đến 1 object trên Feedin
      ref_id: baseFieldTypes.string
    }
  ]
}
const transform = createTransform(
  new schema.Entity(
    'feeds',
    {
      content: new schema.Union(
        {
          poll_question: pollSchema
        },
        '_type'
      )
    },
    {
      idAttribute: 'id'
    }
  ),
  'data'
)

// Route::post('polls/poll-question/{prop}/{id}/add', 'PollController@addPollQuestion');
export const poll_add_Api = {
  backend: `// Route::post('polls/poll-question/{prop}/{id}/add', 'PollController@addPollQuestion');`,
  root: API_ROOT_URL,
  path:
    '/polls/poll-question/:prop/:id/add',
  method: 'POST',
  fields,
  fieldTypes,
  transform
}

// Route::post('polls/poll-question/{poll_question_id}/edit', 'PollController@updatePollQuestion');
// Route::post('polls/poll-question/{poll_question_id}/delete', 'PollController@deletePollQuestion');

// Route::post('polls/poll-answer/{poll_question_id}/add', 'PollController@addPollAnswer');
export const poll_addAnswer_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/polls/poll-answer/:poll_question_id/add',
  method: 'POST',
  fields,
  fieldTypes,
  transform: createTransform(
    pollSchema,
    'data'
  )
}
export const create_poll_addAnswer_Api_action = () => ({
  prefixStr = 'poll_addAnswer_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr + '@poll_addAnswer_Api',
    query,
    values,
    apiInfo: poll_addAnswer_Api,
    maxAge
  })

// Route::post('polls/poll-answer/{poll_answer_id}/edit', 'PollController@updatePollAnswer');
// Route::post('polls/poll-answer/{poll_answer_id}/delete', 'PollController@deletePollAnswer');
export const poll_delete_Api = {
  name: 'poll_delete',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/polls/poll-question/:poll_question_id/delete',
  method: 'POST',
  fields,
  fieldTypes,
  transform: createTransform(
    new schema.Entity(
      'polls',
      {
        isDeleted: true
      },
      {
        idAttribute: 'id'
      }
    ),
    'data'
  )
}
// Route::get('polls/poll-question/{poll_question_id}/get', 'PollController@get');
export const poll_get_Api = {
  backend: `// Route::get('polls/poll-question/{poll_question_id}/get', 'PollController@get');`,
  root: API_ROOT_URL,
  path:
    '/polls/poll-question/:poll_question_id/get',
  method: 'GET',
  fields,
  schema: pollSchema,
  fieldTypes,
  transform: createTransform(
    pollSchema,
    'data'
  )
}
export const create_poll_get_Api_action = () => ({
  prefixStr = '',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr + '@poll_get_Api',
    query,
    values,
    apiInfo: poll_get_Api,
    maxAge
  })

// Route::post('polls/poll-vote/{poll_question_id}/{poll_answer_id}/vote', 'PollController@voteOnPoll');
export const poll_vote_Api = {
  backend: `// Route::post('polls/poll-vote/{poll_question_id}/{poll_answer_id}/vote', 'PollController@voteOnPoll');`,
  root: API_ROOT_URL,
  path:
    '/polls/poll-vote/:poll_question_id/:poll_answer_id/vote',
  method: 'POST',
  fields,
  fieldTypes,
  transform: createTransform(
    pollSchema,
    'data'
  )
}
export const create_poll_vote_Api_action = () => ({
  prefixStr = '',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr + '@poll_vote_Api',
    query,
    values,
    apiInfo: poll_vote_Api,
    maxAge
  })

// Route::post('polls/poll-vote/{poll_question_id}/{poll_answer_id}/un-vote', 'PollController@unVoteOnPoll');
export const poll_unVoteOnPoll_Api = {
  backend: `// Route::post('polls/poll-vote/{poll_question_id}/{poll_answer_id}/un-vote', 'PollController@unVoteOnPoll');`,
  root: API_ROOT_URL,
  path:
    '/polls/poll-vote/:poll_question_id/:poll_answer_id/un-vote',
  method: 'POST',
  fields,
  fieldTypes,
  transform: createTransform(
    pollSchema,
    'data'
  )
}
export const create_poll_unVoteOnPoll_Api_action = () => ({
  prefixStr = '',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@poll_unVoteOnPoll_Api',
    query,
    values,
    apiInfo: poll_unVoteOnPoll_Api,
    maxAge
  })
// Route::get('polls/poll-answer/{poll_question_id}/{poll_answer_id}/get-voters', 'PollController@getVoters');
export const poll_getVoters_Api = {
  name: 'poll_',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/polls/poll-answer/:poll_question_id/:poll_answer_id/get-voters',
  method: 'GET',
  schema: userSchema,
  transform: createListTransform(
    userSchema
  )
}
