import logFunc from 'helpers/logFunc'

const TOKEN_KEY = '_wTokenKey'
const LANGUAGE_KEY = '_wLanguage'
const PRIMARY_CATEGORY_KEY =
  '_wPrimaryCategory'

export function getTokenKey() {
  return localStorage.getItem(TOKEN_KEY)
}
export function setTokenKey(token) {
  localStorage.setItem(TOKEN_KEY, token)
}
export const saveLanguageKey = logFunc(
  language => {
    localStorage.setItem(
      LANGUAGE_KEY,
      language
    )
  }
)
export function getLanguageKey() {
  return localStorage.getItem(
    LANGUAGE_KEY
  )
}

export function setPrimaryCategory(
  cate_id
) {
  localStorage.setItem(
    PRIMARY_CATEGORY_KEY,
    cate_id
  )
}

export function getPrimaryCategory() {
  return localStorage.getItem(
    PRIMARY_CATEGORY_KEY
  )
}
