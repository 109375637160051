import {HomeOutlined, ShopOutlined} from '@ant-design/icons'
import {Button, Form, Input, notification, Result, Select} from 'antd'
import {userModel} from 'apis/model'
import {API_ROOT_URL} from 'envs/_current/config'
import _ from 'lodash'
import {createAsyncAction} from 'modules/asyncCache'
import useDispatchAsyncActionWithNotify from 'modules/asyncCache/useDispatchAsyncActionWithNotify'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {Link, useParams} from 'react-router-dom'
import {getLogin} from 'redux/selectors'
import ServerContext from "../../Server/ServerContext";

const {Option} = Select

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8}
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16}
  }
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
}

const SuccessContactForm = ({email}) => {
  const t = useTranslate();

  return (
    <div className="w-full h-full flex items-center justify-center">
      <Result
        title={t(
          'Sent Successfully!'
        )}
        subTitle={t(
          'Notification of provider’s reply will be sent to {{email}}.'
        ).replace(
          '{{email}}',
          email
        )}
        extra={
          <div className="flex items-center justify-center space-x-3">
            <Link to={'/'}>
              <Button
                icon={
                  <HomeOutlined/>
                }>
                {t('home')}
              </Button>
            </Link>
            <Link
              to={{
                pathname: '/mart'
              }}>
              <Button
                type="primary"
                icon={
                  <ShopOutlined/>
                }>
                {t('product page')}
              </Button>
            </Link>
          </div>
        }
      />
    </div>
  )
}

const ContactForm = ({
                       errorMessages,
                       disabledNames,
                       content,
                       sender_name,
                       sender_email,
                       onFinish
                     }) => {
  const t = useTranslate()
  const formRef = useRef()
  const [form] = Form.useForm()
  const handleFinish = values => {
    onFinish(values)
    console.log(
      'Received values of form: ',
      values
    )
  }
  // useEffect(() => {
  //   formRef()
  // }, [])
  return (
    <Form prefixCls="w-full"
          ref={formRef}
          {...formItemLayout}
          form={form}
          name="contactWithProvider"
          onFinish={handleFinish}
          initialValues={{
            content,
            sender_name,
            sender_email,
          }}
          scrollToFirstError>

      <Form.Item
        name="sender_email"
        label={t('from')}
        rules={[
          {
            type: 'email',
            message: t(
              'The input is not valid E-mail!'
            )
          },
          {
            required: true,
            message: t('required!')
          }
        ]}>
        <Input/>
      </Form.Item>
      <Form.Item
        name="sender_name"
        rules={[
          {
            required: true,
            message: t('required!')
          }
        ]}
        label={t('name')}>
        <Input
          disabled={
            disabledNames['sender_name']
          }
        />
      </Form.Item>

      <Form.Item
        name="content"
        label={t('content')}
        rules={[
          {
            required: true,
            message: t('required!')
          }
        ]}>
        <Input.TextArea
          style={{
            width: '100%',
            minHeight: 120
          }}
        />
      </Form.Item>

      <Form.Item
        {...tailFormItemLayout}>
        <Button
          type="primary"
          htmlType="submit">
          {t('Send')}
        </Button>
      </Form.Item>
    </Form>
  )
}
const fixUglyrequest_type = (
  requestTypeArray = []
) => {
  return requestTypeArray
    .reduce((result, value) => {
      result[value - 1] = 1
      return result
    }, new Array(4).fill(0))
    .toString()
    .replaceAll(',', '')
}

function SimpleContactForm() {
  const t = useTranslate()
  const {item} = useContext(ServerContext)

  const login =
    useSelector(getLogin) || {}
  const initialValues = {
    content: '',
    sender_name: userModel.getTitle(
      login
    ),
    sender_email: login.email,
    sender_phone: login.phone_number,
    sender_company_name: login.company
  }
  const [
    {success, error, errorMessages},
    dispatch
  ] = useDispatchAsyncActionWithNotify({
    onError: () =>
      notification.error('error')
  })
  const [email, setEmail] = useState()
  const disabledNames = {
    sender_name: !_.isEmpty(
      userModel.getTitle(login)
    ),
    sender_email: !_.isEmpty(
      login.email
    ),
    sender_phone: !_.isEmpty(
      login.phone_number
    ),
    sender_company_name: !_.isEmpty(
      login.company
    )
  }
  return (
    <div className="w-full mx-auto flex flex-col space-y-3">
      <div className="font-bold text-2xl">
        {
          t('Send your message to this provider')
        }
      </div>

      <div className="flex grid grid-cols-1 md:grid-cols-2 gap-3">
        <main className="p-3 border col-span-1 rounded">
          {success ? (
            <SuccessContactForm email={email}/>
          ) : (
            <ContactForm
              disabledNames={
                disabledNames
              }
              key={login && login.id}
              errorMessages={
                errorMessages
              }
              onFinish={({
                           request_type,
                           ...values
                         }) => {
                setEmail(
                  values.sender_email
                )
                dispatch(
                  createAsyncAction({
                    apiInfo: {
                      root: API_ROOT_URL,
                      path: `/messages/organization/${_.get(
                        item,
                        'idname'
                      )}/send-request`,
                      method: 'POST'
                    },
                    values: {
                      ...values
                    }
                  })
                )
              }}
              {...initialValues}
            />
          )}
        </main>

        {
          Boolean(
            _.get(item, 'locations.length')
          ) &&
          Boolean(
            _.get(item, 'locations.0.coordinate.static_map_url')
          ) && (
            <div className="col-span-1">
              <div
                className="background-200 rounded h-full w-full overflow-hidden p-3 flex flex-col justify-start relative">
                <img
                  alt=""
                  className="inset-0 object-cover h-full"
                  src={_.get(
                    item,
                    'locations.0.coordinate.static_map_url',
                    '---- ---- ----'
                  )}
                />

              </div>
            </div>
          )
        }
      </div>

    </div>
  )
}

export default () => {
  const {id} = useParams()
  return (
    <>
      <SimpleContactForm id={id}/>
    </>
  )
}
