import _ from 'lodash'
import React, { useEffect } from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import { createAsyncAction } from '..'
import { getAsynCacheSelector } from '../selectors'
import CustomLazyPagination, {
  transformToRecordAction
} from './CustomLazyPagination'
export default function VisibleWhenHaveDataPagination({
  renderWrap,
  ...props
}) {
  const {
    id,
    apiInfo,
    query,
    values
  } = props
  const recordId = id || apiInfo.path
  const startAction = transformToRecordAction(
    recordId,
    createAsyncAction({
      asyncId: recordId,
      query,
      values,
      apiInfo
    })
  )
  const dispatch = useDispatch()
  const {
    success,
    response
  } = useSelector(state =>
    getAsynCacheSelector(
      state,
      startAction.asyncId
    )
  )

  useEffect(() => {
    if (!success) dispatch(startAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  if (!success) return null
  if (
    !_.get(response, 'data.data.length')
  )
    return null
  if (renderWrap) {
    return renderWrap(
      <CustomLazyPagination
        startAction={startAction}
        {...props}
      />,
      startAction
    )
  }
  return (
    <CustomLazyPagination
      startAction={startAction}
      {...props}
    />
  )
}
