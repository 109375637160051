import {paths} from './contains'
import News from './News.lazy'

export default {
  paths,
  path: [
    '/',
    paths.newPath,
    paths.followingPath,
    paths.followingCategoryPath,
    paths.categoryPath,
    paths.defaultCategoryPath,
  ],
  component: News,
  exact: true
}
