import { Button } from 'antd'
import { Formik } from 'formik'
import {
  emptyArray,
  emptyObject
} from 'helpers/emptyObjects'
import useTranslate from 'modules/local/useTranslate'
import React, { useState } from 'react'

export const StepsForm = ({
  children,
  routes = emptyArray,
  initialValues = emptyObject,
  renderBottom,
  onSubmit,
  isLoading,
  ...propsRest
}) => {
  const t = useTranslate()
  const [
    currentIndex,
    setCurrentIndex
  ] = useState(0)
  const handleOnBack = () =>
    setCurrentIndex(
      Math.max(0, currentIndex - 1)
    )
  const C =
    routes[currentIndex].component
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      {...propsRest}>
      {({
        handleChange,
        handleSubmit,
        values,
        ...rest
      }) => {
        const handleOnOk = () => {
          setCurrentIndex(
            Math.min(
              routes.length - 1,
              currentIndex + 1
            )
          )
          if (
            currentIndex ===
            routes.length - 1
          ) {
            handleSubmit()
          }
        }
        return (
          <div className="flex flex-col min-h-screen">
            {children &&
              children(currentIndex)}
            {routes[currentIndex]
              .name && (
              <h2 className="text-xl pr-5 font-bold pt-3 mb-6">
                {t(
                  routes[currentIndex]
                    .name
                )}
              </h2>
            )}
            <div
              key={currentIndex}
              className="animated fadeIn faster">
              <C
                {...{
                  currentIndex,
                  values: values,
                  onChange: handleChange,
                  onNext: handleOnOk,
                  onBack: handleOnBack,
                  ...propsRest,
                  ...rest
                }}
              />
            </div>
            <div className="flex-1" />
            {renderBottom ? (
              renderBottom({
                currentIndex,
                values: values,
                onChange: handleChange,
                onNext: handleOnOk,
                onBack: handleOnBack,
                ...rest
              })
            ) : (
              <div
                style={{
                  justifySelf: 'end'
                }}
                className="z-10 mt-8 p-3 sticky items-center bottom-0 flex justify-between background ">
                <div className="flex-1" />
                <Button
                  disabled={
                    currentIndex === 0
                  }
                  onClick={
                    handleOnBack
                  }>
                  {t('back')}
                </Button>
                <Button
                  loading={isLoading}
                  className="ml-2"
                  type="primary"
                  onClick={handleOnOk}>
                  {currentIndex ===
                  routes.length - 1
                    ? t('submit')
                    : t('next')}
                </Button>
              </div>
            )}
          </div>
        )
      }}
    </Formik>
  )
}
