import {
  ConfigProvider,
  Empty,
  Select
} from 'antd'
import Null from 'components/Null'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { useSelector } from 'react-redux'
import LoadingPage from 'views/LoadingPage'

const customizeRenderEmpty = () => (
  <Empty
    imageStyle={{
      height: 60
    }}
    description={
      <span>
        Customize{' '}
        <a href="#API">Description</a>
      </span>
    }></Empty>
)
export const LanguageSelect = ({
  defaultValue,
  onChange = Null
}) => {
  const t = useTranslate()
  const options = [
    { id: 'vi', name: 'vi' },
    { id: 'en', name: 'en' }
  ]

  const i18n_lang = useSelector(
    state => state.i18nState.lang
  )

  const isEmpty = _.isEmpty(options)

  return (
    <ConfigProvider
      renderEmpty={
        customizeRenderEmpty
      }>
      <div>
        <Select
          optionFilterProp="label"
          defaultValue={
            defaultValue || i18n_lang
          }
          notFoundContent={
            isEmpty ? (
              <LoadingPage />
            ) : null
          }
          loading={isEmpty}
          autoFocus={false}
          // mode="tags"
          placeholder={t(
            'please select'
          )}
          onChange={onChange}
          style={{ width: '100%' }}>
          {options.map(
            ({ id, name }) => (
              <Select.Option key={id}>
                {t(name)}
              </Select.Option>
            )
          )}
        </Select>
      </div>
    </ConfigProvider>
  )
}
