import Icon, { SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Modal, Select, Spin } from 'antd';
import Count from 'components/Feed/Count';
import ImageLoader from 'components/ImageLoader';
import { API_ROOT_URL } from 'envs/_current/config';
import preventDefaultEvent from 'helpers/preventDefaultEvent';
import _ from 'lodash';
import { createAsyncAction } from 'modules/asyncCache';
import useDispatchAsyncAction from 'modules/asyncCache/useDispatchAsyncAction';
import useTranslate from 'modules/local/useTranslate';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useToggle } from 'react-use';
import { children } from './AdditionalDetails';

export const SelectCategories = ({ type, selected: externalSelected = [], onChange, categoryOptions = [] }) => {
  const [selected, setselected] = useState(externalSelected)
  useEffect(() => {
    setselected(externalSelected)
  }, [externalSelected])
  const entitesRef = useRef({
    ...selected.reduce((result, value) => {
      result[value] = {
        id: value, name: value
      };
      return result;
    }, {}), ...categoryOptions.reduce((result, value) => {
      result[value.id] = value;
      return result;
    }, {})
  });
  const [keyword, setkeyword] = useState('');
  const t = useTranslate();
  const handleChange = (id) => {
    const findIndex = selected.findIndex(item => item === id);
    let newValues
    if (findIndex > -1) {
      newValues = (selected.map((item, i) => findIndex !== i && item).filter(Boolean));
      setselected(newValues)
      return onChange(newValues)
    }
    newValues = [...selected, id];
    setselected(newValues)
    return onChange(newValues)
  };
  const [isToggle, toggle] = useToggle();
  const [{ result = [], isLoading }, dispatch] = useDispatchAsyncAction();
  entitesRef.current = {
    ...entitesRef.current,
    ...result.reduce((result, value) => {
      result[value.id] = value;
      return result;
    }, {})
  };
  const options = selected.map(id => {
    const item = entitesRef.current[id];
    if (!item)
      return null;
    return ({ ...item, label: item.name, value: item.id });
  }).filter(Boolean);
  const categories = keyword.length ? result : categoryOptions
  const debouncedispatch = useMemo(() => {
    const handler = (keyword) => {
      setkeyword(keyword);
      dispatch(createAsyncAction({
        apiInfo: {
          root: API_ROOT_URL,
          path: '/categories/search?keyword=' + keyword + (type ? `&&type=${type}` : ""),
          method: 'GET',
        }
      }));
    };
    return _.debounce(handler);
  }, []);
  return <><Select
    options={options}
    open={false}
    onClick={toggle}
    {...{
      value: selected,
    }}
    mode="multiple"
    placeholder={t(
      'please select'
    )}
    onChange={value => {
      setselected(value)
      return onChange(value)
    }}
    style={{
      width: '100%'
    }}>
  </Select>
    <Modal bodyStyle={{ padding: 0 }} destroyOnClose onOk={toggle} footer={null} visible={isToggle} onCancel={toggle} title={t('select categories')}>
      <div style={{ height: 'calc(100vh - 155px)' }} className="space-y-3 overflow-y-scroll p-6 flex flex-col">
        <Input autoFocus placeholder={t(
          'please select'
        )} addonAfter={<SearchOutlined />} size="large" onChange={e => debouncedispatch(e.target.value)}></Input>
        <div className="flex flex-wrap  items-baseline ">
          {options.map(item => {
            return <div key={item.id}
              className="py-2 pl-3 mr-1 mb-1 leading-none cursor-pointer background-100 hover:background-200 border border-gray-300 rounded flex items-center"
            >
              <div className="flex flex-col space-y-1">
                <div className="text-xs font-bold">
                  {item.name}
                </div>
                <div>{children}</div>
              </div>
              <Count onClick={() => {
                handleChange(item.id);
              }}>
                <Icon component={MdClose} />
              </Count>
            </div>;
          })}
        </div>
        {isLoading && <Spin spinning>
          <div className="border background-100 rounded p-3 flex space-x-3 h-24">
          </div>
        </Spin>}
        {categories.map(
          item => {
            const {
              id, avatar,
              name, alt_idname, description
            } = item;
            const checked = selected.includes(id);
            return (
              <label key={id} onClick={(e) => {
                preventDefaultEvent(e);
                handleChange(id);
              }} className={(checked ? "border-primary " : "border-transparent") + "border background-100 hover:background-200 rounded p-3 flex space-x-3"}>
                <ImageLoader className="w-24 h-24" src={avatar}></ImageLoader>
                <div className="flex-1">
                  <div className="font-bold">{name}</div>
                  {!!alt_idname && <div>{alt_idname}</div>}
                  {!!description && <div className="font-bold text-gray-600">{description}</div>}
                </div>
                <div className="self-end">
                  <Checkbox checked={checked} value={id} id={id} name={id} />
                </div>
              </label>
            );
          }
        )}
        <div className="flex-1" />
        <div className="flex justify-end sticky bottom-0 background shadow-lg rounded p-3 border border-gray-300">
          <Button type="primary" onClick={toggle}>{t('ok')}</Button>
        </div>
      </div>
    </Modal>
  </>;
};
