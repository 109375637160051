import { emptyObject } from './emptyObjects';
import _ from 'lodash'

export default function getItemKeyId (
	item = emptyObject
) {
	switch (item._type) {
		default:
			return _.get(item, 'id');
	}
}
