import {API_ROOT_URL} from 'envs/_current/config'
import {schema} from 'normalizr'
import {chartSchema} from './chart'
import {baseItemSchema} from './schema'
import {createListTransform} from './utils/createListTransform'
import {createTransform} from './utils/createTransform'

export const datasetSchema = new schema.Entity(
  'datasets',
  {},
  {
    idAttribute: 'idname'
  }
)

export const datasetWithSampleChartsSchema = new schema.Entity(
  'datasets',
  {
    samples: [chartSchema]
  },
  {
    idAttribute: 'idname'
  }
)

// Route::get('datasets/dataset/{id}/get', 'DatasetController@getById');

export const dataset_getById_Api = {
  name: 'dataset_getById',
  backend: ``,
  root: API_ROOT_URL,
  path: '/datasets/dataset/:id/get',
  method: 'GET',
  schema: datasetSchema,
  transform: createTransform(
    datasetSchema,
    'data'
  )
}
// Route::post('datasets/dataset/add', 'DatasetController@store');
export const dataset_add_Api = {
  name: 'function_add',
  backend: ``,
  root: API_ROOT_URL,
  path: '/datasets/dataset/add',
  method: 'POST',
  fields: ['title']
}
// Route::post('datasets/dataset/{id}/edit', 'DatasetController@update');
export const dataset_edit_Api = {
  root: API_ROOT_URL,
  path: '/datasets/dataset/:id/edit',
  method: 'POST',
  schema: datasetSchema,
  transform: createTransform(
    datasetSchema,
    'data'
  )
}

// Route::post('datasets/dataset/{id}/delete', 'DatasetController@delete');
export const dataset_delete_Api = {
  root: API_ROOT_URL,
  path: '/datasets/dataset/:id/delete',
  method: 'POST'
}

// Route::get('datasets/{type}/{id}/gets', 'DatasetController@getDatasetsByProps');
export const dataset_getDatasetsByUser_Api = {
  name: 'dataset_getDatasetsByProps',
  backend: ``,
  root: API_ROOT_URL,
  path: '/datasets/user/:id/gets',
  method: 'GET'
}

// Route::get('datasets/dataset/{id}/charts/gets', 'ChartController@getChartsByDataset');
export const datasets_getChartsByDataset_Api = {
  name: 'datasets_getChartsByDataset',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/datasets/dataset/:id/charts/gets',
  method: 'GET',
  schema: chartSchema,
  transform: createTransform(
    [chartSchema],
    'data.data'
  )
}

// Route::get('datasets/dataset/{id}/charts/get-small', 'ChartController@getDataChartsByDataset');
export const datasets_getChartListByDataset_Api = {
  name:
    'datasets_getChartListByDataset_Api',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/datasets/dataset/:id/charts/get-small',
  method: 'GET',
  schema: chartSchema,
  transform: createTransform(
    [chartSchema],
    'data.data'
  )
}

export const datasets_getSuggestion_Api = {
  name: 'datasets_getChartsByDataset',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/auth/info/dataset/get-suggestion-by-auth',
  method: 'GET',
  schema: datasetSchema,
  transform: createListTransform(
    datasetSchema
  )
}

// Route::get('datasets/category/{id}/datasets/gets', 'DatasetController@getDatasetsByCategory');

export const datasets_getDatasetsByCategory_Api = {
  name: 'datasets_getChartsByDataset',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/datasets/category/:id/datasets/gets',
  method: 'GET',
  schema: datasetSchema,
  transform: createListTransform(
    datasetSchema
  )
}

// Route::get('datasets/{type}/{id}/gets', 'DatasetController@getDatasetByProps');
export const datasets_getDatasetsByOwner_Api = {
  name: 'getDatasetByProps',
  backend: ``,
  root: API_ROOT_URL,
  path: '/datasets/:type/:id/gets',
  method: 'GET',
  paramsFields: ['dataset_id'],
  fields: ['dataset_id'],
  schema: datasetSchema,
  transform: createListTransform(
    datasetSchema
  )
}

// Route::post('channels/channel/{channel_id}/user/{user_id}/set-subscription', 'ChannelSubscriptionController@manageChannelSubscriptionAdd');
export const dataset_manageChannelSubscriptionAdd_Api = {
  name:
    'dataset_manageChannelSubscriptionAdd',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/datasets/dataset/:dataset_id/user/:user_id/set-subscription',
  method: 'POST'
}

// Route::post('channels/channel-subscription/{channel_subscription_id}/edit', 'ChannelSubscriptionController@editChannelSubscription'); // expire, ngày hết hạn mới, phải lớn hơn ngày hiện tại
export const dataset_editDatasetSubscription_Api = {
  name:
    'dataset_editDatasetSubscription',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/datasets/dataset-subscription/:channel_subscription_id/edit',
  method: 'POST',
  transform: createTransform(
    baseItemSchema,
    'data'
  )
}

// Route::get('datasets/dataset/{id}/get-related-dataset-from-owner', 'DatasetController@getRelatedDatasetByCategories');
export const dataset_getRelatedDatasetFromOwnerByCategories_Api = {
  backend: `// Route::get('datasets/dataset/{id}/get-related-dataset-from-owner', 'DatasetController@getRelatedDatasetByCategories');`,
  root: API_ROOT_URL,
  path: '/datasets/dataset/:id/get-related-dataset-from-owner',
  method: 'GET',
  schema: datasetSchema,
  transform: createListTransform(
    datasetSchema
  )
}


// Route::get('datasets/dataset/{id}/get-related-dataset-from-others', 'DatasetController@getOtherDatasetDiffOwnerByCategories');
export const dataset_getRelatedDatasetDiffOwnerByCategories_Api = {
  backend: `// Route::get('datasets/dataset/{id}/get-related-dataset-from-others', 'DatasetController@getOtherDatasetDiffOwnerByCategories');`,
  root: API_ROOT_URL,
  path: '/datasets/dataset/:id/get-related-dataset-from-others',
  method: 'GET',
  schema: datasetSchema,
  transform: createListTransform(
    datasetSchema
  )
}