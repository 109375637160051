import _ from 'lodash'
import {emptyObject} from './emptyObjects'

export default function getLinkToDetail(
  item = emptyObject
) {
  const idname = _.get(item, 'idname')
  switch (item._type) {
    case 'user':
      return (
        '/user/' +
        (idname
          ? idname
          : _.get(item, 'username'))
      )
    case 'poll_question':
      return (
        '/news/poll_question/' +
        (idname
          ? idname
          : _.get(item, 'id'))
      )
    case 'post':
      return (
        '/news/post/' +
        (idname
          ? idname
          : _.get(item, 'id'))
      )
    case 'article':
      return (
        '/news/article/' +
        (idname
          ? idname
          : _.get(item, 'id'))
      )
    case 'channel':
      return (
        '/channel/' +
        (idname
          ? idname
          : _.get(item, 'idname'))
      )
    case 'organization':
      return (
        '/organization/' +
        (idname
          ? idname
          : _.get(item, 'idname'))
      )
    default:
      return ''
  }
}
