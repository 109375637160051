import React from 'react'
import {env} from "./_current/config";

export const ForDevelop = ({children}) => {
  // return null; // for test
  if (env !== 'PROD') {
    return children;
  }

  return null;
}

export const isDevelop = () => {
  // return false; // for test
  if (env !== 'PROD') {
    return true;
  }

  return false;
}

export default ForDevelop;