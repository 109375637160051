import Icon from '@ant-design/icons'
import {
  Input,
  message,
  Modal
} from 'antd'
import { linkfyPlugin } from 'components/RichEditor/plugins'
import useTranslate from 'modules/local/useTranslate'
import React, { useRef } from 'react'
import { ImEmbed2 } from 'react-icons/im'
import { string } from 'yup'
export default function EmbedButton({
  onClick,
  ...props
}) {
  const translate = useTranslate()
  const inputRef = useRef()
  const handleAddLink = imageUrl => {
    imageUrl &&
      props.setEditorState(
        linkfyPlugin.handleInsert(
          props.getEditorState(),
          { src: imageUrl }
        )
      )
  }
  const handleSubmit = async () => {
    const url =
      inputRef.current &&
      inputRef.current.state &&
      inputRef.current.state.value
    console.log(
      inputRef.current.state.value
    )
    const schema = string().url()
    const valid = await schema.isValid(
      url
    )
    if (valid) {
      handleAddLink(
        inputRef.current.state.value
      )
    } else {
      message.error('url is invalid!')
    }
  }
  return (
    <div className="buttonWrapper relative">
      <button
        style={{ fontSize: '24px' }}
        onClick={e => {
          Modal.confirm({
            icon: (
              <Icon
                component={
                  ImEmbed2
                }></Icon>
            ),
            title: translate(
              'Paste a link to embed content from another site (e.g. Twitter) and click Ok'
            ),
            content: (
              <form
                onSubmit={() => {
                  handleSubmit()
                  Modal.destroyAll()
                }}>
                <Input
                  ref={inputRef}
                  placeholder={translate(
                    'enter an url'
                  )}></Input>
                <input
                  hidden
                  type="submit"
                />
              </form>
            ),
            onOk: handleSubmit
          })
        }}
        className="button font-semibold px-2">
        <Icon
          component={ImEmbed2}></Icon>
      </button>
    </div>
  )
}
