import {Badge} from 'antd'
import React from 'react'
import classNames from "classnames";
import {FaCrown, FaDatabase} from "react-icons/fa";

export const DatasetIcon = ({
                              premium = 0,
                              className,
                              ...props
                            }) => {
  if (premium !== 1) {
    return (
      <div
        className={classNames(className, "items-center justify-center")}
        {...props}>
        <FaDatabase/>
      </div>
    );
  } else {
    return (
      <Badge offset={10} className={classNames(className, "items-center justify-center")}
             {...props}
             count={
               <FaCrown
                 style={{fontSize: '0.78rem', fill: '#ffb600'}}
               />
             }>
        <div>
          <FaDatabase/>
        </div>
      </Badge>
    )
  }
}
