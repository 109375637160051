import axios from 'axios'
import {
  getLanguageKey,
  getTokenKey
} from 'helpers/localStorage'

function validateJWT(response) {
  return response
}

export const callAPI = (
  method,
  api,
  data
) => {
  const AuthStr =
    'Bearer ' + getTokenKey()
  const headers = {
    Authorization: AuthStr,
    'Content-Type': 'application/json',
    'F-Lang': getLanguageKey()
  }
  switch (method) {
    case 'GET':
      return axios
        .get(api, {
          headers
        })
        .then(function(response) {
          return validateJWT(response)
        })
        .catch(function(error) {
          return error.response
        })
    case 'POST':
      return axios
        .post(api, data, {
          headers
        })
        .then(function(response) {
          return validateJWT(response)
        })
        .catch(function(error) {
          return error.response
        })
    case 'DELETE':
      return axios
        .delete(api, data, {
          headers
        })
        .then(function(response) {
          return validateJWT(response)
        })
        .catch(function(error) {
          return error.response
        })
    default:
      return null
  }
}
const Request = {
  get(api, data) {
    return callAPI('GET', api, data)
  },
  post(api, data) {
    return callAPI('POST', api, data)
  },
  delete(api, data) {
    return callAPI('DELETE', api, data)
  },
  put(api, data) {
    return callAPI('PUT', api, data)
  }
}
window &&
  (() => {
    window.feedInRequest = Request
  })()
export default Request
