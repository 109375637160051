export const baseFieldTypes = {
  string: '',
  object: {},
  image: 'image',
  auto: 'auto',
  description: '',
  status: [
    {
      value: 0,
      label: 'status_0'
    },
    {
      value: 1,
      label: 'status_1'
    }
  ],
  active: [
    {
      value: 0,
      label: 'inactive'
    },
    {
      value: 1,
      label: 'active'
    }
  ],
  certified: [
    {
      value: -1,
      label: 'no certified'
    },
    {
      value: 1,
      label: 'certified'
    }
  ],
  privacy: [
    {
      value: 1,
      label: 'public'
    },
    {
      value: 2,
      label: 'private'
    }
  ],
  secret: [
    {
      value: 1,
      label: 'secret'
    },
    {
      value: 0,
      label: 'regular'
    }
  ],
  public: [
    {
      value: 1,
      label: 'search allow'
    },
    {
      value: 0,
      label: 'no searchable'
    }
  ]
}
