import React from 'react'
import ReactDOM from 'react-dom'
import Loadable from 'react-loadable'
import App from './App'

function render() {
  const renderMethod = window.__SERVER_STATE__
    ? // $FlowIssue
      ReactDOM.hydrate
    : ReactDOM.render

  return renderMethod(
    <App />,
    document.getElementById('root')
  )
}

Loadable.preloadReady()
  .then(render)
  .catch(err => {
    console.error(err)
  })

window.addEventListener(
  'beforeinstallprompt',
  e => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault()
    // Stash the event so it can be triggered later.
    console.log(
      0,
      'START to beforeinstallprompt'
    )
    window.deferredInstallPrompt = e
  }
)
window.addEventListener(
  'appinstalled',
  evt => {
    console.log(0, 'a2hs installed')
  }
)
