import React, {useEffect, useState} from 'react'
import {FaYoutube} from 'react-icons/fa'
import ReactPlayer from 'react-player'
import useToggle from 'react-use/lib/useToggle'
import ImageLoader from '../ImageLoader'
import {FB_ID} from "../../envs/_current/config";

let currentPlayerRefs = {
  current: {
    setPlaying: undefined
  }
}

const MediaPlayer = ({ url }) => {
  const [
    playing,
    setPlaying
  ] = useState()
  useEffect(() => {
    if (
      currentPlayerRefs.current
        .setPlaying
    ) {
      currentPlayerRefs.current.setPlaying(
        false
      )
    }
    currentPlayerRefs.current.setPlaying = setPlaying
    setPlaying(true)
    return () => {
      currentPlayerRefs.current.setPlaying = undefined
    }
  }, [setPlaying])
  return (
    <ReactPlayer
      style={{
        fontSize: '1em'
      }}
      width="100%"
      height="100%"
      url={playing ? url : null}
      config={{
        youtube: {
          playerVars: {
            controls: 1,
            autoplay: 1,
            showinfo: 1
          }
        },
        facebook: {
          appId: FB_ID
        }
      }}
    />
  )
}
export function VideoEmbed({
  title,
  subTitle,
  url,
  description,
  image,
  categories = []
}) {
  const [
    showPlayer,
    toggleShowPlayer
  ] = useToggle()
  return (
    <div
      style={{
        borderRadius: '0.3em'
      }}
      className="border  background-200 border-gray-300 p-3 verticalList">
      <div
        className="relative overflow-hidden"
        style={{
          paddingTop:
            'calc(360/640 * 100%)',
          width: '100%'
        }}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0
          }}>
          {!showPlayer ? (
            <div
              onClick={() =>
                toggleShowPlayer()
              }
              className="w-full">
              <ImageLoader
                style={{
                  width: '100%'
                }}
                src={
                  image
                }></ImageLoader>
              <div
                className="flex items-center justify-center"
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0
                }}>
                <FaYoutube
                  style={{
                    fontSize: '5em',
                    color: '#000000e0'
                  }}
                />
              </div>
            </div>
          ) : (
            <MediaPlayer url={url} />
          )}
        </div>
      </div>
      <div className="break-words flex-1 mb-4 mr-2 flex flex-col overflow-hidden verticalList__small">
        {subTitle && (
          <div className="flex inline">
            <img
              style={{
                verticalAlign: 'sub'
              }}
              className="inline pr-1 object-cover"
              alt={title}
              src={`https://www.google.com/s2/favicons?domain=${subTitle}`}
            />
            <a
              href={'//' + subTitle}
              target="_blank"
              rel="noopener noreferrer nofollow"
              className="truncate text-gray-600 hover:underline leading-none">
              {subTitle}
            </a>
          </div>
        )}
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer nofollow"
          className="font-semibold hover:underline color-primary text-lg leading-6">
          <h3>{title}</h3>
        </a>
        <div>{description}</div>
        <div className="text-xs">
          {categories.map(e => {
            return (
              <span
                key={e.id}
                className="text-gray-600">
                {'•'}
                <span className="px-2">
                  {e.name}
                </span>
              </span>
            )
          })}
        </div>
      </div>
    </div>
  )
}
