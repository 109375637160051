import { Avatar } from 'antd'
import classnames from 'classnames'
import getUpperFirstChar from 'helpers/getUpperFirstChar'
import _ from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import { ChannelIcon } from '../icons/ChannelIcon'
import VerifiedIcon from '../icons/VerifiedIcon'
import ImageLoader from '../ImageLoader'
import LazyComponent from '../LazyComponent'
import './BaseItem.scss'

export const BaseItem = React.memo(
  ({
    to = null,
    totals,
    avatar,
    cover,
    type,
    premium,
    title,
    description,
    verified,
    id,
    className,
    horizontal,
    style,
    children
  }) => {
    return (
      <div
        style={style}
        className={classnames(
          'BaseItemWrap',
          className,
          { horizontal }
        )}>
        {cover && (
          <LazyComponent>
            <ImageLoader
              className="BaseItemCover"
              src={cover}
              alt=""
            />
          </LazyComponent>
        )}
        <div className="BaseItemCoverBackground" />
        <div className="BaseItemContent">
          <div className="pb-3 flex items-end">
            {type === 'channel' ? (
              <div
                className="flex items-center justify-center"
                style={{
                  width: '40px'
                }}>
                <ChannelIcon
                  className="mr-2 text-gray-600 text-3xl w-8 h-8"
                  premium={premium}
                />
              </div>
            ) : (
              <Avatar
                size="large"
                className="BaseItemAvatar"
                src={avatar}
                shape={
                  type === 'user'
                    ? 'circle'
                    : 'square'
                }>
                {getUpperFirstChar(
                  title
                )}
              </Avatar>
            )}
          </div>
          <div className="pb-3 flex items-center">
            {!!verified && (
              <span>
                <VerifiedIcon />
              </span>
            )}
            {!_.isEmpty(to) ? (
              <Link
                to={to}
                style={{
                  textOverflow:
                    'ellipsis'
                }}
                title={title}
                className="font-bold whitespace-no-wrap hover:underline cursor-pointer truncate">
                {title}
              </Link>
            ) : (
              <span
                style={{
                  textOverflow:
                    'ellipsis'
                }}
                title={title}
                className="font-bold whitespace-no-wrap hover:underline cursor-pointer truncate">
                {title}
              </span>
            )}
          </div>
          <div className="mb-3 BaseItemDescription">
            <span>{description}</span>
          </div>
          {children}
        </div>
      </div>
    )
  }
)
