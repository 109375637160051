import { Input, Radio, Select, Space } from 'antd'
import { dataset_edit_Api } from 'apis'
import ApiInfoForm from 'components/form/ApiInfoForm'
import FieldDecorator from 'components/form/FieldDecorator'
import { ToggleField } from 'components/form/fields/ToggleField'
import FormActionBar from 'components/form/FormActionBar'
import { createValue } from 'components/form/utils'
import { ROOT_URL } from 'envs/_current/config'
import { FormikConsumer } from 'formik'
import _ from 'lodash'
import { Pure } from 'modules/asyncCache/components/Pure'
import { getCacheSelector } from 'modules/asyncCache/selectors'
import { useAppConfig } from 'modules/local'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { compose, mapProps, pure, withProps } from 'recompose'
import { SelectCategories } from 'views/Market/Seller/SelectCategories'

const alias = {
  region_id: 'region.id',
  categories: 'categories.id'
}

const labels = {
  title: 'title', // => làm Như Channel
  description: 'description', // => làm Như Channel
  active: 'active', // => Làm switch box (1: true, 0:false)
  idname: 'idname', // => Show như phần URL (của channel)
  privacy: 'privacy', // => Làm như Channel.
  database_id: 'database', // => string, disabled
  database_code: 'database code', // => string, disabled
  region_id: 'region', // => select box =>
  newest_at: 'newest', // => Date, disabled
  oldest_at: 'oldest', // => Date, disabled
  data_frequency: 'data frequency', // => Làm layout như privacy, value t('1D') = Daily, 1M, 3M, 6M, 1Y
  delivery_frequency:
    'delivery frequency', // => Làm layout như privacy, value t('1D') = Daily, 1M, 3M, 6M, 1Y
  index_type: 'index type',
  is_system_defined:
    'is system defined', // => Làm switch box (1: true, 0:false), disabled
  is_3rd_source: 'is 3rd source', // => Làm switch box (1: true, 0:false), disabled
  api_url: 'api url', // => Làm text box, disabled
  datasheet_url: 'datasheet url', //: làm textbox
  categories: 'categories' //: làm textbox
}
const show = {
  title: true, // => làm Như Channel
  description: true, // => làm Như Channel
  active: true, // => Làm switch box (1: true, 0:false)
  idname: true, // => Show như phần URL (của channel)
  privacy: true, // => Làm như Channel.
  categories: true, //: làm selectbox
  database_id: true, // => string, disabled
  database_code: true, // => string, disabled
  region_id: true, // => select box =>
  newest_at: true, // => Date, disabled
  oldest_at: true, // => Date, disabled
  data_frequency: true, // => Làm layout như privacy, value t('1D') = Daily, 1M, 3M, 6M, 1Y
  delivery_frequency: true, // => Làm layout như privacy, value t('1D') = Daily, 1M, 3M, 6M, 1Y
  index_type: true, // => select box =>
  is_system_defined: true, // => Làm switch box (1: true, 0:false), disabled
  is_3rd_source: true, // => Làm switch box (1: true, 0:false), disabled
  api_url: true // => Làm text box, disabled
  // datasheet_url: false, //: làm textbox
}
const disabled = {
  database_id: true,
  database_code: true,
  newest_at: true,
  oldest_at: true,
  is_system_defined: true,
  is_3rd_source: true,
  api_url: true
}

const UpdateDataset = ({
  item,
  onSuccess
}) => {
  const [
    resetNumber,
    setResetNumber
  ] = useState()
  let categoryOptions = []
  let initialValues = {}
  Object.keys(show).map(key => {
    if (key === 'categories') {
      categoryOptions = _.get(
        item,
        'categories',
        []
      )
      initialValues[key] = _.get(
        item,
        'categories',
        []
      ).map(({ id }) => id)
    } else {
      initialValues[key] = _.get(
        item,
        alias[key] || key
      )
    }
    return key
  })
  if (!item) return null
  return (
    <ApiInfoForm
      onReset={() =>
        setResetNumber(Date.now())
      }
      onSuccess={onSuccess}
      initialValues={initialValues}
      {...{
        apiInfo: dataset_edit_Api,
        query: {
          ':id': item.id
        }
      }}>
      <FormikConsumer>
        {({
          values,
          errors,
          handleChange
        }) => (
          <Pure key={resetNumber}>
            <div className="container max-w-lg mx-auto p-3">
              <Space
                className="container w-full mx-auto"
                size="large"
                direction="vertical">
                {Object.keys(
                  initialValues
                ).map(key => {
                  const InputField =
                    components[key] ||
                    components.default
                  if (disabled[key])
                    return null
                  return (
                    <FieldDecorator
                      key={key}
                      errors={
                        errors[key]
                      }
                      label={
                        labels[key] ||
                        key
                      }>
                      <InputField
                        disabled={
                          disabled[key]
                        }
                        defaultValue={
                          values[key]
                        }
                        categoryOptions={categoryOptions}
                        name={key}
                        onChange={value =>
                          handleChange(
                            createValue(
                              key,
                              value
                            )
                          )
                        }
                        placeholder={
                          key
                        }
                      />
                    </FieldDecorator>
                  )
                })}
              </Space>
              <FormActionBar />
            </div>
          </Pure>
        )}
      </FormikConsumer>
    </ApiInfoForm>
  )
}
const components = {
  default: compose(
    withProps(({ onChange }) => ({
      onChange: e =>
        onChange(e.target.value),
      size: 'large'
    })),
    pure
  )(Input),
  description: compose(
    withTranslate,
    withProps(({ onChange }) => ({
      onChange: e =>
        onChange(e.target.value),
      rows: 5,
      name: 'description',
      type: 'text',
      size: 'large'
      // placeholder: translate(
      //   'Short description about your dataset!'
      // ),
    })),
    pure
  )(Input.TextArea),
  categories: compose(
    connect(state => { return { categories: getCacheSelector(state, 'appConfig', {}).dataset_categories } }),
    mapProps(
      ({
        onChange,
        categories,
        defaultValue, categoryOptions,
      }) => ({
        value: defaultValue,
        type: 'dataset',
        categoryOptions:
          [...categoryOptions, ...categories]
        ,
        selected: defaultValue, onChange: (onChange)
      })
    ),
    pure
  )(SelectCategories),
  idname: compose(
    withProps(
      ({ onChange, value }) => ({
        onChange: e =>
          onChange(e.target.value),
        addonBefore:
          'https://.../data/',
        placeholder:
          'please input name!',
        type: 'text',
        trigger: ['focus'],
        name: 'idname',
        title:
          ROOT_URL + '/data/' + value,
        placement: 'topLeft',
        disabled: true
      })
    ),
    pure
  )(Input),
  active: compose(
    withProps(
      ({ onChange, defaultValue }) => ({
        onChange: value =>
          onChange(value ? 1 : 0),
        defaultValue: Boolean(
          defaultValue
        ),
        title: 'active',
        description:
          'The state of the data set. If "On", the dataset is ready to use'
      })
    )
  )(ToggleField),
  privacy: compose(
    withProps(({ name, onChange }) => ({
      onChange: e =>
        onChange(e.target.value),
      buttonStyle: 'solid',
      options: [
        {
          value: 1,
          title: 'public',
          description:
            'Data content is viewable by everyone, anyone is free to join and can post articles and comment freely on this'
        },
        {
          value: 2,
          title: 'private',
          description:
            'Only members can view data content, post articles or comment on this'
        }
      ]
    })),
    pure
  )(({ options, ...props }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const t = useTranslate()
    return (
      <div className="verticalList__small">
        <Radio.Group {...props}>
          {options.map(
            ({
              value,
              title: label
            }) => (
              <React.Fragment
                key={value}>
                <Radio.Button
                  value={value}>
                  {t(label)}
                </Radio.Button>
              </React.Fragment>
            )
          )}
        </Radio.Group>
        <div className="rounded border border-dashed border-blue-400 p-3 verticalList__small">
          {options.map(
            ({
              title,
              description,
              value
            }) => (
              <div key={value}>
                <div className="font-bold ">
                  {t(title)}
                </div>
                <div>
                  {t(description)}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    )
  }),
  region_id: compose(
    withTranslate,
    withProps(
      ({ onChange, translate }) => ({
        onChange,
        translate
      })
    ),
    pure
  )(
    ({
      title = 'region',
      ...props
    }) => {

      const { regions } = useAppConfig()
      const options = regions
      if (!options) return null
      return (
        <Select
          className="block"
          showSearch
          optionFilterProp="children"
          filterOption={(
            input,
            option
          ) =>
            option.children
              .toLowerCase()
              .indexOf(
                input.toLowerCase()
              ) >= 0
          }
          {...props}>
          {options.map(o => (
            <Select.Option
              key={o.id}
              value={o.id}>
              {o.label}
            </Select.Option>
          ))}
        </Select>
      )
    }
  ),
  index_type: compose(
    withTranslate,
    withProps(({ onChange }) => ({
      onChange
    })),
    pure
  )(
    ({
      title = 'index type',
      ...props
    }) => {

      const { index_types } = useAppConfig()
      const options = index_types
      if (!options) return null
      return (
        <Select
          className="block"
          showSearch
          optionFilterProp="children"
          filterOption={(
            input,
            option
          ) =>
            option.children
              .toLowerCase()
              .indexOf(
                input.toLowerCase()
              ) >= 0
          }
          {...props}>
          {options.map(o => (
            <Select.Option
              key={o.id}
              value={o.id}>
              {o.label}
            </Select.Option>
          ))}
        </Select>
      )
    }
  ),
  data_frequency: compose(
    withTranslate,
    withProps(
      ({ onChange, translate }) => ({
        options: [
          '1D',
          '1M',
          '3M',
          '6M',
          '1Y'
        ].map(value => ({
          value,
          label: translate(value)
        })),
        onChange,
        t: translate
      })
    ),
    pure
  )(
    ({
      title = 'data frequency',
      options,
      t,
      ...props
    }) => {
      if (!options) return null
      return (
        <Select
          className="block"
          showSearch
          optionFilterProp="children"
          filterOption={(
            input,
            option
          ) =>
            option.children
              .toLowerCase()
              .indexOf(
                input.toLowerCase()
              ) >= 0
          }
          {...props}>
          {options.map(o => (
            <Select.Option
              key={o.value}
              value={o.value}>
              {o.label}
            </Select.Option>
          ))}
        </Select>
      )
    }
  ),
  delivery_frequency: compose(
    withTranslate,
    withProps(
      ({ onChange, translate }) => ({
        options: [
          '1D',
          '1M',
          '3M',
          '6M',
          '1Y'
        ].map(value => ({
          value,
          label: translate(value)
        })),
        onChange,
        t: translate
      })
    ),
    pure
  )(
    ({
      title = 'delivery frequency',
      t,
      options,
      ...props
    }) => {
      if (!options) return null
      return (
        <Select
          className="block"
          showSearch
          optionFilterProp="children"
          filterOption={(
            input,
            option
          ) =>
            option.children
              .toLowerCase()
              .indexOf(
                input.toLowerCase()
              ) >= 0
          }
          {...props}>
          {options.map(o => (
            <Select.Option
              key={o.value}
              value={o.value}>
              {o.label}
            </Select.Option>
          ))}
        </Select>
      )
    }
  )
}

export default UpdateDataset
