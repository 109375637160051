import { IS_CODOVA } from 'envs/_current/config'

export const detectDevice = () => {
  if (IS_CODOVA) return 'Mobile-Device'
  let mob = undefined
  switch (true) {
    case navigator.userAgent.match(
      /Android/i
    ) !== null:
      mob = 'Android'
      break
    case navigator.userAgent.match(
      /webOS/i
    ) !== null:
      mob = 'webOS'
      break
    case navigator.userAgent.match(
      /iPhone/i
    ) !== null:
      mob = 'iPhone'
      break
    case navigator.userAgent.match(
      /iPad/i
    ) !== null:
      mob = 'iPad'
      break
    case navigator.userAgent.match(
      /iPod/i
    ) !== null:
      mob = 'iPod'
      break
    case navigator.userAgent.match(
      /BlackBerry/i
    ) !== null:
      mob = 'BlackBerry'
      break
    case navigator.userAgent.match(
      /Windows Phone/i
    ) !== null:
      mob = 'Windows Phone'
      break
    default:
      break
  }
  return mob
}
