import _ from 'lodash'
import { emptyObject } from './emptyObjects'

export default function getTitle(
  item = emptyObject
) {
  let title = _.get(item, 'title')
  if (title) return title
  return _.get(item, 'name', '')
}
