import {
  LeftOutlined,
  RightOutlined
} from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'
import useToggle from 'react-use/lib/useToggle'
import ImageLoader from '../ImageLoader'
import ImagesLightBox from '../ImagesLightBox/ImagesLightBox'
import { ImageEmbed } from './ImageEmbed'

export function GalleryEmbed({
  images
}) {
  const [open, toggle] = useToggle()
  if (!images || images.length === 0)
    return null
  if (images.length === 1)
    return (
      <ImageEmbed image={images[0]} />
    )
  return (
    <>
      <div
        onClick={toggle}
        style={{
          // paddingTop: '70%'
          maxHeight: '70vh',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex'
        }}
        className="relative  w-full background-200 rounded overflow-hidden">
        <ImageLoader
          style={{
            // position: 'absolute',
            // top: 0,
            // left: 0,
            height: 'auto',
            // height: '100%',
            width: '100%',
            objectFit: 'cover'
          }}
          className=" w-full "
          src={images[0]}
        />
        <Button.Group
          size="small"
          className="z-10 absolute bottom-0 right-0 p-4">
          <Button
            type="primary"
            style={{
              border: 'none',
              background:
                'rgba(0, 0, 0, 0.53)'
            }}
            icon={<LeftOutlined />}
          />
          <Button
            type="primary"
            style={{
              border: 'none',
              background:
                'rgba(0, 0, 0, 0.53)'
            }}>
            <span
              style={{
                fontWeight: 500
              }}>
              1-{images.length} images
            </span>
          </Button>
          <Button
            type="primary"
            style={{
              border: 'none',
              background:
                'rgba(0, 0, 0, 0.53)'
            }}
            icon={<RightOutlined />}
          />
        </Button.Group>
      </div>
      {open && (
        <ImagesLightBox
          images={images}
          onCloseRequest={toggle}
        />
      )}
    </>
  )
}
