import Count from 'components/Feed/Count'
import { KeywordInput } from 'components/KeywordInput'
import preventParentEvent from 'helpers/preventParentEvent'
import useQueryString from 'hooks/useQueryString'
import useTranslate from 'modules/local/useTranslate'
import React, { useContext, useEffect, useState } from 'react'
import { FcShop } from 'react-icons/fc'
import { Link } from 'react-router-dom'
import { PageContext } from 'views/Market/PageContext'
import productImageHolder from 'views/Market/productImageHolder'


const Banner = () => {
  const { routeEntities } = useContext(PageContext)
  const t = useTranslate()
  const [
    { keyword = '', ...rest } = {},
    setQuery
  ] = useQueryString({
    pathname: routeEntities.search.path
  })
  const [value, setValue] = useState(
    keyword
  )
  useEffect(() => setValue(keyword), [
    keyword
  ])
  return (
    <div
      style={{
        backgroundColor: 'var(--background-200)',
        backgroundImage: `url("${productImageHolder}")`
      }}
      className="w-full pb-6 lg:pb-12 relative">
      <Link
        to={routeEntities.home.path}
        className="truncate flex items-center order-first">
        <Count
          style={{
            height:
              'var(--header-height)'
          }}
          className="order-first">
          <FcShop size={'30px'} />
        </Count>
        <h1
          style={{
            filter:
              'drop-shadow(rgba(0, 0, 0, 0.8) 0px 1px 1px)'
          }}
          className="font-bold uppercase text-xs">
          FeedIn Mart
        </h1>
      </Link>
      <form
        onSubmit={e => {
          preventParentEvent(e)
          setQuery({
            ...rest,
            keyword: value
          })
        }}
        className="px-3 max-w-3xl mx-auto text-center space-y-4">
        <span
          style={{
            filter: `drop-shadow(rgba(0, 0, 0, 0.8) 0px 1px 1px)`
          }}
          className="text-2xl md:text-3xl lg:text-4xl">
          {t(
            'What are you looking for?'
          )}
        </span>
        <KeywordInput
          {...{
            value,
            onClear: () => {
              setQuery({
                ...rest,
                keyword: ''
              })
            },
            onSearchClick: () => {
              setQuery({
                ...rest,
                keyword: value
              })
            },
            onChange: e =>
              setValue(e.target.value),
            placeholder: t(
              'Enter products / services search'
            )
          }}
        />

      </form>
    </div>
  )
}

export default Banner
