import Icon, {LoadingOutlined} from '@ant-design/icons'
import {referral_deleteReferral_Api, referral_getReferrals_Api} from 'apis'
import classnames from 'classnames'
import EmptyHolder from 'components/EmptyHolder'
import Timestamp from 'components/Feed/Timestamp'
import Modal from 'components/Modal/Modal'
import AsyncAction from 'modules/asyncCache/components/AsyncAction'
import {LazyPagination} from 'modules/asyncCache/components/LazyPagination'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import React from 'react'
import {MdClose} from 'react-icons/md'
import useToggle from 'react-use/lib/useToggle'
import {HeaderButton} from 'views/Organization/components/PageHeader'
import Invite from './Invite'
import copyToClipboard from "copy-to-clipboard";
import {ROOT_URL} from "../../../envs/_current/config";
import _ from "lodash";

const InviteRow = ({
  className,
  inviter,
  invite_code,
  uses,
  actions,
  expires
}) => (
  <div
    className={classnames(
      'flex items-center',
      className
    )}>
    <div
      style={{ flex: '3 1 0px' }}
      className="hidden md:block">
      {inviter}
    </div>
    <div style={{ flex: '3 1 0px' }}>
      {invite_code}
    </div>
    <div style={{ flex: '1 1 0px' }}>
      {uses}
    </div>
    <div style={{ flex: '3 1 0px' }}>
      {expires}
    </div>
    {
      <div style={{ flex: '1 1 0px' }}>
        {actions}
      </div>
    }
  </div>
)
const InviteRowHeader = withTranslate(
  ({ translate }) => (
    <InviteRow
      {...{
        inviter: (
          <div className="text-xs uppercase text-gray-600 font-semibold truncate hidden md:block">
            {translate('inviter')}
          </div>
        ),
        invite_code: (
          <div className="text-xs uppercase text-gray-600 font-semibold truncate">
            {translate('invite code')}
          </div>
        ),
        uses: (
          <div className="text-xs text-right uppercase text-gray-600 font-semibold truncate">
            {translate('uses')}
          </div>
        ),
        expires: (
          <div className="text-xs text-right uppercase text-gray-600 font-semibold truncate">
            {translate('expires')}
          </div>
        )
      }}
    />
  )
)

function InviteManager({ item }) {
  const t = useTranslate()
  const containerType = item._type
  const containerId = item.id
  const [isToggle, toggle] = useToggle()
  const [
    listKey,
    setListKey
  ] = useToggle()
  return (
    <div className="container max-w-6xl mx-auto p-3 verticalList">
      <div className="text-xs font-semibold">
        {t(
          'Here is a list of all invite links. You can revoke any one.'
        )}{' '}
        <span
          onClick={() => toggle()}
          className="color-primary font-bold cursor-pointer">
          {t('create one')}
        </span>
        <Modal
          destroyOnClose
          footer={null}
          title={t('invite')}
          maskClosable
          visible={isToggle}
          onCancel={() => {
            setListKey(
              new Date().getTime()
            )
            toggle()
          }}>
          <Invite item={item} />
        </Modal>
      </div>
      <div className="border-b border-gray-300">
        <div />
      </div>
      <div>
        <InviteRowHeader />
        <div className="verticalList__small">
          <LazyPagination
            key={listKey}
            query={{
              ':prop': containerType,
              ':id': containerId
            }}
            renderEmpty={() => (
              <EmptyHolder
                subTitle={t(
                  'no invites yet'
                )}
              />
            )}
            apiInfo={
              referral_getReferrals_Api
            }
            renderItem={item =>
              item && (
                <InviteRow
                  className="h-16 hover:background-100 rounded border border-transparent hover:border-gray-300 py-3 px-3"
                  {...{
                    inviter:
                      item.creator
                        .name || 'NaN',
                    invite_code: (
                      <div onClick={() => {
                        copyToClipboard(
                          ROOT_URL +
                          '/invite/organization/' +
                          item.referral_code
                        )
                      }}
                           className="text-orange-500 cursor-pointer">
                        {
                          item.referral_code
                        }
                        <span className="text-gray-500 px-1">({t('copy')})</span>
                      </div>
                    ),
                    uses: (
                      <div className="text-right">
                        {item.total_accepts ||
                          0}
                      </div>
                    ),
                    expires: (
                      <div className="text-right">
                        {
                          _.isEmpty(item.expired) ? "" : (
                            <Timestamp
                              timestamp={
                                item.expired
                              }
                            />
                          )
                        }
                      </div>
                    ),
                    actions: (
                      <AsyncAction
                        apiInfo={
                          referral_deleteReferral_Api
                        }
                        query={{
                          ':referral_id':
                            item.id
                        }}>
                        {({
                          handleAsyncAction,
                          isLoading,
                          success
                        }) =>
                          success ? (
                            <div className="text-red-600 text-right ">
                              {t(
                                'deleted'
                              )}
                            </div>
                          ) : (
                            <HeaderButton
                              onClick={() =>
                                handleAsyncAction()
                              }
                              className="text-red-600 ml-auto">
                              {isLoading ? (
                                <LoadingOutlined />
                              ) : (
                                <Icon
                                  component={
                                    MdClose
                                  }
                                />
                              )}
                            </HeaderButton>
                          )
                        }
                      </AsyncAction>
                    )
                  }}
                />
              )
            }
          />
        </div>
      </div>
    </div>
  )
}

export default InviteManager
