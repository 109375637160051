import _ from 'lodash';
import { emptyArray } from './emptyObjects';
export const createAliasTransform = alias => (
	item,
	input = emptyArray
) => {
	return input.reduce((result, key) => {
		let value;
		_.find(
			_.flattenDeep([alias[key]], 1),
			key => {
				value = _.get(item, key);
				return value;
			}
		);
		result.push(value);
		return result;
	}, []);
};
