import {
  LOGIN_FAILURE,
  LOGIN_SUCCESS
} from './types'

const INITIAL_STATE = {}
export default function (
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return action.payload
    case LOGIN_FAILURE:
      return {
        ...state,
        failure: true,
      }
    default:
      return state
  }
}
