import { CameraOutlined } from '@ant-design/icons'
import { message, Upload } from 'antd'
import { image_upload_Api } from 'apis'
import { imagePlugin } from 'components/RichEditor/plugins'
import preventParentEvent from 'helpers/preventParentEvent'
import _ from 'lodash'
import { callApi } from 'modules/asyncCache/callApi'
import React from 'react'
export default function AddImage({
  onClick,
  ...props
}) {
  const handleAddImage = (
    imageUrl,
    imageId
  ) => {
    props.setEditorState(
      imagePlugin.handleInsert(
        props.getEditorState(),
        {
          src: imageUrl,
          imageId
        }
      )
    )
  }
  return (
    <div
      onMouseDown={preventParentEvent}
      className="buttonWrapper relative  leading-none ">
      <Upload
        fileList={[]}
        className="inline"
        customRequest={({
          onSuccess,
          ...args
        }) =>
          callApi({
            apiInfo: image_upload_Api,
            values: {
              'photos[]': args.file
            }
          }).then(res => {
            if (res.status === 200) {
              const imageUrl = _.get(
                res,
                'data.urls.0'
              )
              if (imageUrl) {
                const imageId = _.get(
                  res,
                  'data.ids.0'
                )
                handleAddImage(
                  imageUrl,
                  imageId
                )
              }
            } else {
              message.error(
                _.get(
                  res,
                  'data.error.photos[0]'
                )
              )
            }
          })
        }>
        <button
          style={{ fontSize: '24px' }}
          className="button font-semibold px-2  flex items-center justify-center">
          <CameraOutlined
            width={24}
            height={24}
          />
        </button>
      </Upload>
    </div>
  )
}
