import {
  LikeFilled,
  LikeOutlined
} from '@ant-design/icons'
import React from 'react'
import Count from './Count'
export const Like = React.memo(
  ({
    vote_status,
    total_votes = 0,
    handleVote
  }) => {
    return (
      <Count
        active={!!vote_status}
        defaultNumber={total_votes}
        color="text-blue-600"
        onClick={handleVote}
        {...(!!vote_status
          ? {
              component: LikeFilled
            }
          : {
              component: LikeOutlined
            })}
      />
    )
  }
)
