import React, {useEffect, useState} from 'react'
import {PhotoPlaceholderFeedIn} from "../conts/imageBase64";

const _loaded = {}
const imageSources = {}

function ImageLoader({
                       loadingClassName = '', style, alt, holder = PhotoPlaceholderFeedIn,
                       onClick,
                       className,
                       loadedClassName = 'fadeIn animated', src
                     }) {
  const [visible, setvisible] = useState(() => {
    if (!imageSources[src]) {
      const image = new Image()
      image.src = src
      imageSources[src] = image
    }
    return imageSources[src]
  })
  const [error, setError] = useState()
  const _className = `${className} ${visible
    ? loadedClassName
    : loadingClassName
  }`
  useEffect(() => {
    if (imageSources[src]) {
      let handler = () => {
        _loaded[src] = true
        setvisible(true)
      }
      imageSources[src].addEventListener('load', handler)
      return () => imageSources[src].removeEventListener('load', handler)
    } else {

    }
  }, [src])
  return (
    <img
      alt={alt || ''}
      style={style}
      onError={() => setError(true)}
      src={!error && visible ? src : holder}
      onClick={onClick}
      className={_className}
    />
  )
}

export default React.memo(ImageLoader)
