import { schema } from 'normalizr'

export const productSchema = new schema.Entity(
  'products',
  {},
  {
    idAttribute: 'idname',
    processStrategy: e => {
      e.requireStrings = e.alerts
        ? Object.values(e.alerts)
        : []
      return e
    }
  }
)
