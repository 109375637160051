import Icon from '@ant-design/icons'
import Count from 'components/Feed/Count'
import ForDevelop from 'envs/ForDevelop'
import {emptyArray} from 'helpers/emptyObjects'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext} from 'react'
import {BsChat, BsSearch} from 'react-icons/bs'
import {FcShop} from 'react-icons/fc'
import {Link, useHistory} from 'react-router-dom'
import {useLocation} from 'react-use'
import {PageContext} from 'views/Market/PageContext'
import {defaultCategories} from 'views/NewFeed/defaultCategories'
import {footer} from './components/Layout/footer'
import PageLink from './components/PageLink'
import {useAppConfig} from "../../modules/local";

export const Layout = ({
  children,
  extra,
  breadcrumbs
}) => {
  const t = useTranslate()
  const { routeEntities } = useContext(PageContext)
  const history = useHistory()
  const location = useLocation();
  const appConfig = useAppConfig();
  return (
    <div className="flex flex-col w-full">
      <div className="w-full min-h-screen flex flex-col flex-1">
        <div className="PageHeader stickyTop ">
          <div className=" flex background  items-center ">
            <div className="w-full flex flex-wrap items-center justify-between px-3">
              <Link
                to={
                  routeEntities.home.path
                }
                className="truncate flex items-center order-first ">
                <Count
                  style={{
                    height:
                      'var(--header-height)'
                  }}
                  className="order-first">
                  <FcShop size={'30px'} />
                </Count>
                <h1 className="font-normal uppercase text-xs">
                  <span className="font-bold">
                    FeedIn Mart
                </span>
                </h1>
              </Link>
              <div
                style={{
                  top: '100%',
                  left: 0,
                  right: 0
                }}
                className="hidden lg:flex lg:flex-1 h-64 lg:h-full  w-full lg:w-0 justify-center px-2 background order-last lg:order-first relative">
                <div
                  key={
                    location.search
                  }
                  className="flex justify-start flex-1 container mx-auto flex-col lg:flex-row relative ">
                  {// new Array(6)
                    _.get(
                      appConfig,
                      'hierarchy_product_categories',
                      defaultCategories
                    ).map(i => {
                      return (
                        <div
                          tabIndex={0}
                          key={i.id}
                          className="withHover flex rounded-t-lg cursor-pointer  hover:shadow-2xl hover:background-100 border  border-transparent hover:border-gray-300 focus::border-gray-300">
                          <div
                            style={{
                              background:
                                'inherit'
                            }}
                            className="rounded-t-lg z-30 hover:w-1/3 p-3  h-full flex items-center   text-sm truncate">
                            {i.name}
                          </div>
                          <div
                            className="visible transition-opacity duration-200 ease-linear border border-gray-300 rounded-b-lg -mt-px top-full shadow-2xl block background-100 right-0  h-full lg:mt-10 lg:h-auto  top-0 w-2/3 absolute lg:w-full ">
                            <div className="p-4 w-full">
                              <div className="flex flex-wrap -m-3 p-2">
                                {// new Array(8)
                                  _.get(
                                    i,
                                    'children',
                                    emptyArray
                                  ).map(
                                    ii => {
                                      return (
                                        <div
                                          key={
                                            ii.id
                                          }
                                          className="p-2 lg:w-1/4 sm:w-1/2 w-full">
                                          <h2
                                            href="#"
                                            className="border-l-4 border-primary pl-4 font-bold text-xs hover:underline lg:text-base title-font tracking-widest text-gray-600 mb-4 text-center sm:text-left">
                                            <PageLink
                                              to={`/impcat?categories=${ii.idname}`}>
                                              {
                                                ii.name
                                              }
                                            </PageLink>
                                          </h2>
                                          <nav
                                            className="border-l-4 border-transparent pl-4 text-xs lg:text-base flex flex-col sm:items-start sm:text-left text-left items-left -mb-1">
                                            {//  Array(
                                              //   3
                                              // )
                                              _.get(
                                                ii,
                                                'children',
                                                emptyArray
                                              ).map(
                                                iii => {
                                                  return (
                                                    <PageLink
                                                      to={
                                                        '/impcat?categories=' +
                                                        iii.id
                                                      }
                                                      key={
                                                        iii.id
                                                      }
                                                      className="mb-2 capitalize">
                                                      {
                                                        iii.name
                                                      }
                                                    </PageLink>
                                                  )
                                                }
                                              )}
                                            <PageLink
                                              to={
                                                '/impcat?categories=' +
                                                ii.id
                                              }
                                              className="mb-2 underline">
                                              {t(
                                                'See all'
                                              )}
                                            </PageLink>
                                          </nav>
                                        </div>
                                      )
                                    }
                                  )}
                                <div className="p-2 w-full flex justify-end  sticky bottom-0 ">
                                  <PageLink
                                    className="text-xs lg:text-base"
                                    to={`/impcat?categories=${i.idname}`}>
                                    <Count>
                                      {t(
                                        'view all'
                                      )}
                                    </Count>
                                  </PageLink>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
              <div className="flex mr-1 justify-center items-center space-x-3">
                <ForDevelop>
                  <Link
                    to={`/messages`}
                    className="flex items-center">
                    <Count>
                      <Icon
                        className="text-2xl"
                        component={BsChat}
                      />
                    </Count>
                  </Link>
                </ForDevelop>
                <PageLink
                  to={{
                    pathname:
                      '/search',
                    state: {
                      isModal: true
                    }
                  }}
                  className="flex items-center">
                  <Count>
                    <Icon
                      className="text-2xl"
                      component={BsSearch}
                    />
                  </Count>
                </PageLink>
              </div>
            </div>
          </div>
        </div>
        <div
          className="background-100 justify-center items-start mx-auto w-full flex-1 flex flex-col lg:flex-row md:p-3 space-y-3 lg:space-y-0 lg:space-x-6">
          <div className="w-full background lg:container z-10 relative flex-1 flex flex-col lg:min-h-full ">
            <div className="w-full mx-auto p-3 md:p-6 rounded flex-1 pb-6 min-h-full">
              {children}
            </div>
          </div>
          {extra}
        </div>
      </div>
      {footer}
    </div>
  )
}
