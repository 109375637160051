import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {ValidateComponent} from './routesValidate'

export default function RouteWithSubRoutes({
  routes = [],
  component: C,
  render,
  meta,
  ...route
}) {
  return (
    <Route
      path={route.path}
      {...route}
      render={props => {
        if (render) return render(props)
        return (
          // pass the sub-routes down to keep nesting
          <ValidateComponent
            meta={meta}>
            <C
              {...props}
              children={
                <Switch
                  location={
                    props.location
                  }>
                  {routes.map(
                    (route, i) => {
                      return (
                        <RouteWithSubRoutes key={i}
                                            {...route}
                        />
                      )
                    }
                  )}
                  {/* <Route
										component={
											NoFoundPage
										}
									/> */}
                </Switch>
              }
            />
          </ValidateComponent>
        )
      }}
    />
  )
}
