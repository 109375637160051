import logParams from 'helpers/logParams'
import _ from 'lodash'
import { useMemo, useRef } from 'react'
export const Pure = ({
  input = [],
  debug,
  children
}) => {
  const renderTrackRef = useRef()
  const result = useMemo(() => {
    if (debug) {
      if (!renderTrackRef.current)
        renderTrackRef.current = []
      renderTrackRef.current.push(input)
      logParams(renderTrackRef.current)
    }
    return _.isFunction(children)
      ? children(input)
      : children
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, input)
  return result || null
}
