import { Modal } from 'antd'
import { createAliasTransform } from 'helpers/createAliasTransform'
import { emptyObject } from 'helpers/emptyObjects'
import Translate from 'modules/local/Translate'
import React from 'react'
import {
  DetailFeedContent,
  FeedContent,
  FeedContentLayout,
  FeedContentMeta,
  FeedFooter,
  FeedWrapper,
  withFeedContext
} from './FeedCard'
import FeedContainerStateless from './FeedContainerStateless'
import { FeedProvider } from './FeedProvider'

const alias = {
  creatorId: [
    'creator.username',
    'creator.idname',
    'creator.id'
  ],
  photos: 'photos',
  embed_data: 'embed_data',
  created: 'created',
  creatorType: 'creator._type',
  creatorName: 'creator.name',
  title: ['title', 'name'],
  description: 'description',
  cover: 'cover_photo',
  id: 'id',
  type: '_type'
}

export const transformAlias = createAliasTransform(
  alias
)
export const allActions = {
  to: true,
  pin: true,
  unpin: true,
  bookmark: true,
  vote: true,
  message: true,
  view: true,
  share: true,
  copy_link: true,
  edit: true,
  delete: true
}
const Container = withFeedContext(
  FeedContainerStateless
)

export const defaultComponents = {
  Container: Container,
  Wrapper: FeedWrapper,
  ContentLayout: FeedContentLayout,
  ContentMeta: FeedContentMeta,
  Content: FeedContent,
  Footer: FeedFooter
}

export const defaultDetailComponents = {
  Container: Container,
  Wrapper: FeedWrapper,
  ContentLayout: FeedContentLayout,
  ContentMeta: FeedContentMeta,
  Content: DetailFeedContent,
  Footer: FeedFooter
}
export const showConfirm = ({
  title = 'Are you sure delete this task?',
  content,
  okText = 'Yes',
  okType = 'danger',
  cancelText = 'No',
  onOk = () => {
    console.log('OK')
  },
  onCancel = () => {
    console.log('Cancel')
  }
} = {}) =>
  Modal.confirm({
    title,
    content,
    okText,
    okType,
    cancelText,
    onOk,
    onCancel
  })
export const createTimeLineFeed = (
  customComponents = emptyObject,
  defaultActions = allActions
) => {
  const components = {
    ...defaultComponents,
    ...customComponents
  }
  const {
    Wrapper,
    ContentLayout,
    ContentMeta,
    Content,
    Footer
  } = components

  return function TimelineFeed({
    item,
    detail = false,
    children
  }) {
    if (!item) return null

    return (
      <FeedProvider
        item={item}
        detail={detail}
        defaultActions={defaultActions}>
        {children ? (
          children
        ) : item.isDeleted ||
          item.deleted ? (
          <Translate>
            {t => (
              <Wrapper>
                {/*<Container />*/}
                <ContentLayout>
                  <div className="p-3 text-gray-600">
                    {t('deleted')}
                  </div>
                </ContentLayout>
              </Wrapper>
            )}
          </Translate>
        ) : (
          <Wrapper>
            {/* <Container /> */}
            <ContentLayout>
              <ContentMeta />
              <Content />
              <Footer />
            </ContentLayout>
          </Wrapper>
        )}
      </FeedProvider>
    )
  }
}

const TimelineFeed = createTimeLineFeed()

export default TimelineFeed
