import produce from 'immer'
import _ from 'lodash'
function customizer(
  objValue,
  srcValue
) {
  if (
    _.isArray(objValue) &&
    _.isArray(srcValue)
  ) {
    return srcValue
  }
}
const INITIAL_STATE = {}

export default function (
  state = INITIAL_STATE,
  action
) {
  if (action.entities) {
    const newState = produce(
      state,
      draftState => {
        _.mergeWith(
          draftState,
          action.entities,
          customizer
        )
      }
    )
    return newState
  }
  return state
}
