import {Form, Select} from 'antd'
import {location_getStaticCountries_Api} from 'apis'
import {emptyArray} from 'helpers/emptyObjects'
import _ from 'lodash'
import {AsyncWithCahce} from 'modules/asyncCache/components/Async'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'

export const CountrySelect = ({
                                name,
                                form,
                                defaultValue,
                                disabled,
                                onChange,
                                rules,
                                ...rest
                              }) => {
  const t = useTranslate();

  return (
    <AsyncWithCahce
      once
      apiInfo={
        location_getStaticCountries_Api
      }
      cacheId="countryList">
      {({
          response,
          isLoading,
          success
        }) => {
        const countryData = _.get(
          response,
          'data.data',
          emptyArray
        );

        return (
          <Form.Item
            className="flex-1"
            name={name}
            hasFeedback
            noStyle
            rules={rules}>
            <Select
              optionFilterProp={"label"}
              value={defaultValue}
              disabled={disabled}
              loading={isLoading}
              showSearch
              style={{
                minWidth: '160px',
                width: '100%'
              }}
              onChange={(value) => {
                onChange({value})
              }}
              placeholder={t(
                'country'
              )}>
              {countryData.map(
                ({
                   country_code,
                   id,
                   common_name,
                   telephone_code
                 }) => (
                  <Select.Option
                    data-country_code={
                      country_code
                    }
                    key={id}
                    label={common_name}
                    value={id}>
                    {common_name} (
                    {country_code}) +
                    {telephone_code}
                  </Select.Option>
                )
              )}
            </Select>
          </Form.Item>
        )
      }}
    </AsyncWithCahce>
  )
}
