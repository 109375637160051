import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { WithDynamicState } from './WithDynamicState';

const isSelected = (
  state,
  id
) => {
  return state.selections[id];
};
const handleOnSingleSelect = (
  state,
  updateState,
  id
) => {
  return updateState({
    selections: { [id]: true }
  });
};
const handleOnMultipleSelect = (
  state,
  updateState,
  id
) => {
  return updateState({
    selections: {
      ...state.selections,
      [id]: true
    }
  });
};
class MultiToggle extends React.PureComponent {
  static modeTyles = {
    SINGLE: 'SINGLE',
    MULTIPLE: 'MULTIPLE'
  };
  static propTypes = {
    // SINGLE|multiple
    mode: PropTypes.oneOf([
      MultiToggle.modeTyles
        .SINGLE,
      MultiToggle.modeTyles
        .MULTIPLE
    ]),
    options:
      PropTypes.arrayOf(
        PropTypes.string
      ).isRequired ||
      PropTypes.arrayOf(
        PropTypes.object
      ).isRequired,
    defaultSelections: PropTypes.arrayOf(
      PropTypes.string
    )
  };
  static defaultProps = {
    mode:
      MultiToggle.modeTyles
        .SINGLE,
    options: [],
    defaultSelections: []
  };
  render() {
    const options = this.props.options.map(
      option => {
        if (
          _.isString(option)
        ) {
          return {
            id: option
          };
        }
        return option;
      }
    );

    return (
      <WithDynamicState
        initialState={{
          options,
          selections: this.props.defaultSelections.reduce(
            (result, id) => {
              return {
                ...result,
                [id]: true
              };
            },
            {}
          )
        }}
      >
        {(
          state,
          updateState
        ) => {
          if (
            this.props
              .mode ===
            MultiToggle
              .modeTyles
              .SINGLE
          ) {
            return this.props.children(
              state.options,
              isSelected.bind(
                null,
                state
              ),
              handleOnSingleSelect.bind(
                null,
                state,
                updateState
              )
            );
          }
          if (
            this.props
              .mode ===
            MultiToggle
              .modeTyles
              .SINGLE
          ) {
            return this.props.children(
              state.options,
              isSelected.bind(
                null,
                state
              ),
              handleOnMultipleSelect.bind(
                null,
                state,
                updateState
              )
            );
          }
          return null;
        }}
      </WithDynamicState>
    );
  }
}
export default MultiToggle;
