import {Alert, Badge} from 'antd'
import {getChannelsByProps_Api} from 'apis'
import {channelModel} from 'apis/model'
import {organizationSchema} from 'apis/schema'
import {ChannelIcon} from 'components/icons/ChannelIcon'
import Toggle from 'components/Toggle'
import {LazyPagination} from 'modules/asyncCache/components/LazyPagination'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext} from 'react'
import {FaAngleDown, FaAngleUp} from 'react-icons/fa'
import {FiPlus} from 'react-icons/fi'
import {Link} from 'react-router-dom'
import {HeaderButton} from 'views/Organization/components/PageHeader'
import {routes} from 'views/Organization/routes'
import ServerContainer from 'views/Server/ServerContainer'
import ServerContext from 'views/Server/ServerContext'

const OrgChannelsC = () => {
  const t = useTranslate()
  const {
    item,
    setCurrent
  } = useContext(ServerContext)

  if (!item) return null

  return (
    <div className="verticalList__small">
      <Toggle defaultValue={true}>
        {(isToggle, toggle) => (
          <>
            <div className="flex items-center h-6 mt-6 mb-3">
              <div className="px-2 flex-1 uppercase font-bold text-sm text-gray-600">
                {t(
                  "provider's channels"
                )}
              </div>
              <div
                onClick={() => {
                  setCurrent(
                    'createChannel'
                  )
                }}
                className="items-center justify-center">
                <HeaderButton>
                  <FiPlus />
                </HeaderButton>
              </div>
              <HeaderButton
                onClick={() =>
                  toggle()
                }>
                {isToggle ? (
                  <FaAngleUp />
                ) : (
                  <FaAngleDown />
                )}
              </HeaderButton>
            </div>
            {isToggle && (
              <div className="verticalList__small background-100">
                <LazyPagination
                  cacheId={
                    'my organization' +
                    item.id
                  }
                  {...{
                    apiInfo: getChannelsByProps_Api,
                    query: {
                      ':type':
                        'organization',
                      ':id': item.idname
                    }
                  }}
                  renderItem={o => (
                    <Link
                      to={channelModel.getLinkToDetail(
                        o
                      )}
                      className="flex hover:background-100 items-center justify-center py-1 px-3">
                      <ChannelIcon
                        className="mr-2 text-gray-600 text-3xl w-8 h-8"
                        premium={
                          o.premium
                        }
                      />
                      <div className="truncate font-semibold items-center justify-center flex-1">
                        {channelModel.getTitle(
                          o
                        )}
                      </div>
                      {o.has_unread && (
                        <Badge
                          dot
                          color="green"
                        />
                      )}
                    </Link>
                  )}
                  renderEmpty={() => (
                    <Alert
                      type="info"
                      closable
                      showIcon={false}
                      banner
                      className="text-center background-100"
                      message={
                        <div className="py-4">
                          <div className="text-sm m-auto flex flex-1">
                            <span className="whitespace-no-wrap pt-3">
                              {t('Tap')}
                            </span>
                            <div
                              onClick={() => {
                                setCurrent(
                                  'createChannel'
                                )
                              }}
                              className="items-center justify-center">
                              <HeaderButton>
                                <FiPlus />
                              </HeaderButton>
                            </div>
                            <span className="flex pt-3">
                              {t(
                                'to create new channel on your company'
                              )}
                            </span>
                          </div>
                        </div>
                      }
                    />
                  )}
                />
              </div>
            )}
          </>
        )}
      </Toggle>
    </div>
  )
}

export const OrgChannels = ({
  primaryOrg
}) => {
  if (!primaryOrg) return null

  return (
    <ServerContainer
      {...{
        item: primaryOrg,
        schema: organizationSchema,
        id: primaryOrg.id,
        type: primaryOrg._type,
        routes: routes
      }}>
      <OrgChannelsC />
    </ServerContainer>
  )
}
