import { Button, Table } from 'antd'
import {
  auth_unSubscribeDataset_Api,
  billling_getDatasetSubscriptionsByLogin_Api
} from 'apis'
import { baseItemSchema } from 'apis/schema'
import { handleconfirm } from 'components/Confirm'
import {
  emptyArray,
  emptyObject
} from 'helpers/emptyObjects'
import logParams from 'helpers/logParams'
import _ from 'lodash'
import { createAsyncAction } from 'modules/asyncCache'
import { AsyncByActionWithNotify } from 'modules/asyncCache/components/Async'
import useDispatchAsyncAction from 'modules/asyncCache/useDispatchAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React, {
  useEffect,
  useState
} from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectEntities } from 'redux/selectors'
import CurrencyAmount from './CurrencyAmount'

const BillHistoryTableContext = React.createContext(
  {
    dataSource: emptyArray
  }
)
const BillHistoryTable = () => {
  const t = useTranslate()
  const columns = [
    {
      title: t('dataset'),
      dataIndex: 'dataset',
      key: 'dataset',
      width: 16,
      render: item => (
        <Link
          className="font-bold"
          to={`/data/${item.idname}`}>
          {item.title}
        </Link>
      )
    },
    {
      title: t('fee'),
      dataIndex: 'price',
      key: 'price',
      width: 10,
      render: number => (
        <CurrencyAmount>
          {number}
        </CurrencyAmount>
      )
    },
    {
      title: t('status'),
      key: 'status',
      dataIndex: 'status',
      width: 10
    },
    {
      title: t('renew / expires on'),
      key: 'to',
      dataIndex: 'to',
      width: 16
    },
    {
      title: t('manage'),
      key: 'manage',
      dataIndex: 'dataset',
      width: 12,
      fixed: 'right',
      render: (
        dataset = emptyObject
      ) => (
        <AsyncByActionWithNotify>
          {(data, dispath) => (
            <Button
              type="link"
              onClick={() => {
                handleconfirm({
                  title: t(
                    'do you want to cancel?'
                  ),
                  onOk: () => {
                    dispath(
                      createAsyncAction(
                        {
                          apiInfo: auth_unSubscribeDataset_Api,
                          query: {
                            ':id':
                              dataset.idname
                          }
                        }
                      )
                    )
                  }
                })
              }}
              loading={data.isLoading}
              block
              className="p-0"
              size="large">
              {t('cancel')}
            </Button>
          )}
        </AsyncByActionWithNotify>
      )
    }
  ]
  const [
    current,
    setCurrent
  ] = useState(1)
  const [
    {
      response,
      result = emptyArray,
      isLoading,
      success
    },
    handleDispatchAsyncAction
  ] = useDispatchAsyncAction()
  useEffect(() => {
    handleDispatchAsyncAction(
      createAsyncAction({
        apiInfo: billling_getDatasetSubscriptionsByLogin_Api,
        values: {
          page: current
        }
      })
    )
  }, [
    current,
    handleDispatchAsyncAction
  ])
  const handleTableChange = pagination => {
    setCurrent(pagination.current)
  }
  const mapPagination = (
    response,
    current
  ) => ({
    pageSize:
      _.get(
        response,
        'data.meta.pagination.per_page'
      ) || 10,
    current,
    total: _.get(
      response,
      'data.meta.pagination.total'
    )
  })
  const data = useSelector(state =>
    selectEntities(
      state,
      result,
      [baseItemSchema],
      emptyArray
    )
  )
  const dataSource = data.map(item => ({
    ...item,
    price: _.get(item, 'dataset.price'),
    dataset: _.get(item, 'dataset'),
    status: _.get(item, 'status_text'),
    started: moment(
      _.get(item, 'started')
    ).format('LL'),
    from: moment(
      _.get(item, 'from')
    ).format('LL'),
    to: moment(
      _.get(item, 'to')
    ).format('LL')
  }))
  return (
    <BillHistoryTableContext.Provider
      dataSource={logParams(
        dataSource
      )}>
      <Table
        scroll={{ x: 800 }}
        loading={isLoading}
        columns={columns}
        pagination={mapPagination(
          response,
          current
        )}
        dataSource={dataSource}
        onChange={handleTableChange}
      />
    </BillHistoryTableContext.Provider>
  )
}

export const ManageSubscriptionDatasets = () => {
  return (
    <div className="-mx-2 flex flex-col verticalList__large">
      <div className="p-2">
        <BillHistoryTable />
      </div>
    </div>
  )
}
export default ManageSubscriptionDatasets
