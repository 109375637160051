import { API_ROOT_URL } from 'envs/_current/config'
import { schema } from 'normalizr'
import { createTransform } from './utils/createTransform'

export const chartSchema = new schema.Entity(
  'charts',
  {},
  {
    idAttribute: 'idname'
  }
)

// Route::get('datasets/chart/{id}/get', 'ChartController@getById');
export const datasets_chart_getById_Api = {
  name: 'datasets_chart_getById_Api',
  backend: ``,
  root: API_ROOT_URL,
  path: '/datasets/chart/:id/get',
  method: 'GET',
  fields: ['id'],
  schema: chartSchema,
  transform: createTransform(
    chartSchema,
    'data'
  )
}
// Route::post('datasets/dataset/{dataset_id}/charts/add', 'ChartController@store');
export const datasets_chart_add_Api = {
  name: 'datasets_chart_add_Api',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/datasets/dataset/:id/charts/add',
  method: 'POST',
  fields: ['title'],
  schema: chartSchema,
  transform: createTransform(
    chartSchema,
    'data'
  )
}
// Route::post('datasets/charts/{id}/edit', 'ChartController@update');
export const datasets_chart_edit_Api = {
  name: 'datasets_chart_add_Api',
  root: API_ROOT_URL,
  path: '/datasets/charts/:id/edit',
  method: 'POST',
  schema: chartSchema,
  transform: createTransform(
    chartSchema,
    'data'
  )
}

// Route::post('datasets/charts/{id}/delete', 'ChartController@delete');
export const datasets_chart_delete_Api = {
  name: 'datasets_chart_delete_Api',
  root: API_ROOT_URL,
  path: '/datasets/charts/:id/delete',
  method: 'POST'
}

// Route::get('datasets/organization/{id}/get-typical-charts', 'ChartController@getChartsByOrganization');
export const getChartsByOrg_Api = {
  backend: `Route::get('datasets/organization/{id}/get-typical-charts', 'ChartController@getChartsByOrganization');`,
  root: API_ROOT_URL,
  path:
    '/datasets/organization/:id/get-typical-charts',
  method: 'GET',
  schema: chartSchema,
  transform: createTransform(
    [chartSchema],
    'data.data'
  )
}
