import { Button, Input, message, Modal, Spin } from 'antd'
import {
  article_delete_Api,
  bookmarks_mark_Api,
  bookmarks_unMark_Api,
  pin_mark_Api,
  pin_unMark_Api,
  poll_delete_Api,
  post_deletePost_Api
} from 'apis'
import { baseItemSchema } from 'apis/schema'
import { createTransform } from 'apis/utils/createTransform'
import ApiInfoForm from 'components/form/ApiInfoForm'
import { RegAsModal } from 'components/Modal/Modal'
import Null from 'components/Null'
import { SelectEntityItem } from 'components/SelectEntityItem'
import UseState from 'components/UseState'
import copyToClipboard from 'copy-to-clipboard'
import { API_ROOT_URL, ROOT_URL } from 'envs/_current/config'
import _ from 'lodash'
import { createAsyncAction } from 'modules/asyncCache'
import Async from 'modules/asyncCache/components/Async'
import { useDispatchAsyncOnlyActionWithNotify } from 'modules/asyncCache/useDispatchAsyncActionWithNotify'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import PermissionProvider from 'modules/permissions/PermissionProvider'
import React, { useContext, useState } from 'react'
import Loadable from 'react-loadable'
import { collectionsSchema } from 'views/Bookmark/collectionsSchema'
import { EditArticle } from 'views/EditArticle/EditArticle'
import { useVote } from 'views/Server/ServerContainer'
import ServerContext from 'views/Server/ServerContext'
import { useRequiredLogin } from 'views/Server/useRequiredLogin'
import { LoginContext } from '../LoginContext'
import Count from './Count'
import { FeedContext } from './FeedCard'
import { showConfirm, transformAlias } from './TimeLineFeed'

const FeedSetting = Loadable({
  loader: () =>
    import(
      'views/Feed/FeedSetting.dumb'
    ),
  loading: Null
})

export const FeedProvider = ({
  item,
  detail,
  defaultActions,
  children
}) => {
  const t = useTranslate()
  const [hide, setHide] = useState(
    false
  )
  const {
    item: container
  } = useContext(ServerContext)
  const [modal, setmodal] = useState()
  const login = useContext(LoginContext)
  const history = useHistory()
  const [
    creatorId,
    created,
    creatorType,
    creatorName,
    title,
    description,
    cover,
    id,
    type,
    photos,
    embed_data
  ] = transformAlias(item, [
    'creatorId',
    'created',
    'creatorType',
    'creatorName',
    'title',
    'description',
    'cover',
    'id',
    'type',
    'photos',
    'embed_data'
  ])
  const dispatch = useDispatchAsyncOnlyActionWithNotify()
  const vote_status = _.get(
    item,
    'vote_status'
  )
  const bookmark_status = _.get(
    item,
    'bookmark_status'
  )
  const total_votes = _.get(
    item,
    'total_votes'
  )
  const handleVote = useVote({
    vote_status,
    total_votes,
    owner_type: type,
    owner_id: id
  })
  const translate = useTranslate()
  const handleRequiredLogin = useRequiredLogin()
  const url = `/news/${type}/${id}`
  const shareUrl = `${ROOT_URL}${url}`
  function handleClick(key) {
    console.log('click', key)
    switch (key) {
      case 'to':
        history.push({
          pathname: `/news/${type}/${id}`
          // state: { isModal: true },
        })
        break
      case 'edit':
        if (type === 'article') {
          setmodal(
            <EditArticle
              id={id}
              onCancel={() =>
                setmodal(null)
              }
            />
          )
        }
        if (type === 'post') {
          setmodal(
            <FeedSetting
              id={id}
              onCancel={() =>
                setmodal(null)
              }
            />
          )
        }
        break
      case 'delete':
        switch (type) {
          case 'poll_question':
            showConfirm({
              title: translate(
                'Are you sure delete this poll?'
              ),
              onOk: () => {
                dispatch(
                  createAsyncAction({
                    apiInfo: poll_delete_Api,
                    query: {
                      ':poll_question_id':
                        item.id
                    }
                  })
                )
              }
            })
            break
          case 'article':
            showConfirm({
              title: translate(
                'Are you sure delete this article?'
              ),
              onOk: () => {
                dispatch(
                  createAsyncAction({
                    apiInfo: article_delete_Api,
                    query: {
                      ':article_id':
                        item.id
                    }
                  })
                )
              }
            })

            break

          default:
            showConfirm({
              title: translate(
                'Are you sure delete this article?'
              ),
              onOk: () => {
                dispatch(
                  createAsyncAction({
                    apiInfo: post_deletePost_Api,
                    query: {
                      ':post_id':
                        item.id
                    }
                  })
                )
              }
            })

            break
        }

        break
      case 'vote':
        handleVote()

        break
      case 'pin':
        dispatch(
          createAsyncAction({
            apiInfo: pin_mark_Api,
            query: {
              ':container': _.get(
                item,
                'container._type'
              ),
              ':container_id': _.get(
                item,
                'container.id'
              ),
              ':type': type,
              ':id': id
            }
          })
        )

        break
      case 'unpin':
        dispatch(
          createAsyncAction({
            apiInfo: pin_unMark_Api,
            query: {
              ':container': _.get(
                item,
                'container._type'
              ),
              ':container_id': _.get(
                item,
                'container.id'
              ),
              ':type': _.get(
                item,
                '_type'
              ),
              ':id': _.get(item, 'id')
            },
            onSuccess: () =>
              setHide(true)
          })
        )
        break
      case 'copy_link':
        copyToClipboard(
          `${ROOT_URL}/news/${type}/${id}`
        )
        message.info(
          translate('copied')
        )
        break
      case 'rebookmark':
      case 'bookmark':
        handleRequiredLogin(() =>
          setmodal(
            <Modal
              destroyOnClose
              title={t('favorites')}
              footer={null}
              visible={true}
              onOk={null}
              onCancel={() =>
                setmodal(null)
              }>
              <RegAsModal></RegAsModal>
              <div className="col-span-12 md:col-span-3  space-y-3 ">
                <Async
                  apiInfo={{
                    root: API_ROOT_URL,
                    path:
                      '/folders/folder/gets',
                    method: 'GET',
                    transform: createTransform(
                      collectionsSchema,
                      'data'
                    )
                  }}>
                  {({ isLoading }) => {
                    return (
                      <div
                        className={
                          'space-y-3  h-1/2 '
                        }>
                        <div className="flex items-center pt-3 flex-wrap">
                          <div className="uppercase  text-sm font-semibold text-gray-600 flex-1">
                            {t(
                              'folders'
                            )}
                          </div>

                          <UseState
                            initalValue={
                              0
                            }>
                            {([
                              count,
                              setCount
                            ]) => {
                              const isToggle =
                                count %
                                2
                              const toggle = () =>
                                setCount(
                                  count +
                                  1
                                )
                              return (
                                <>
                                  {!isToggle && (
                                    <Spin
                                      spinning={
                                        isLoading
                                      }>
                                      {' '}
                                      <Count
                                        onClick={
                                          toggle
                                        }>
                                        <span className="text-xs  uppercase text-right">
                                          +{' '}
                                          {t(
                                            'new folder'
                                          )}
                                        </span>
                                      </Count>
                                    </Spin>
                                  )}
                                  {!!isToggle && (
                                    <ApiInfoForm
                                      onSuccess={() => {
                                        toggle()
                                      }}
                                      apiInfo={{
                                        root: API_ROOT_URL,
                                        path:
                                          '/folders/folder/add',
                                        method:
                                          'POST'
                                      }}
                                      initialValues={{
                                        title:
                                          ''
                                      }}>
                                      {({
                                        values,
                                        handleChange,
                                        handleSubmit,
                                        isSubmitting
                                      }) => {
                                        return (
                                          <div className="w-full flex flex-wrap justify-end space-y-2 space-x-2">
                                            <Input
                                              name="title"
                                              defaultValue={
                                                values.title
                                              }
                                              className="block w-full animated zoomIn faster"
                                              autoFocus
                                              onChange={
                                                handleChange
                                              }
                                              onBlur={e => {
                                                if (
                                                  !e
                                                    .target
                                                    .value
                                                    .length
                                                ) {
                                                  toggle()
                                                }
                                              }}></Input>
                                            <Button
                                              onClick={
                                                toggle
                                              }>
                                              {t(
                                                'cancel'
                                              )}
                                            </Button>
                                            <Button
                                              loading={
                                                isSubmitting
                                              }
                                              onClick={() =>
                                                handleSubmit()
                                              }
                                              type="primary">
                                              {t(
                                                'create'
                                              )}
                                            </Button>
                                          </div>
                                        )
                                      }}
                                    </ApiInfoForm>
                                  )}
                                </>
                              )
                            }}
                          </UseState>
                        </div>
                        <nav className="w-full py-2 rounded-lg background-100  flex-1  font-semibold">
                          <div
                            onClick={() => {
                              dispatch(
                                createAsyncAction(
                                  {
                                    apiInfo: bookmarks_mark_Api,
                                    query: {
                                      ':container':
                                        'user',
                                      ':container_id': _.get(
                                        login,
                                        'username'
                                      ),
                                      ':type': type,
                                      ':id': id
                                    }
                                  }
                                )
                              )
                              setmodal(
                                null
                              )
                            }}
                            className="p-3 flex-shrink-0 cursor-pointer hover:background-200">
                            {t('all')}
                          </div>
                          <SelectEntityItem
                            item={
                              'bookmarkByUser'
                            }
                            schema={
                              baseItemSchema
                            }>
                            {bookmarkByUser => {
                              return (
                                ((bookmarkByUser &&
                                  bookmarkByUser.data) || null) &&
                                bookmarkByUser.data.map(
                                  (
                                    item,
                                    i
                                  ) => {
                                    return (
                                      <SelectEntityItem
                                        key={
                                          item
                                        }
                                        item={
                                          item
                                        }>
                                        {item =>
                                          (item ||
                                            null) && (
                                            <div
                                              onClick={() => {
                                                dispatch(
                                                  createAsyncAction(
                                                    {
                                                      apiInfo: bookmarks_mark_Api,
                                                      query: {
                                                        ':container':
                                                          'folder',
                                                        ':container_id':
                                                          item.id,
                                                        ':type': type,
                                                        ':id': id
                                                      }
                                                    }
                                                  )
                                                )
                                                setmodal(
                                                  null
                                                )
                                              }}
                                              style={{
                                                minWidth: 100
                                              }}
                                              className="p-3 rounded-none  cursor-pointer hover:background-200 border-t border-gray-300 ">
                                              {
                                                item.title
                                              }
                                            </div>
                                          )
                                        }
                                      </SelectEntityItem>
                                    )
                                  }
                                )
                              )
                            }}
                          </SelectEntityItem>
                        </nav>
                      </div>
                    )
                  }}
                </Async>
              </div>
            </Modal>
          )
        )

        break
      case 'unbookmark':
        handleRequiredLogin(() =>
          dispatch(
            createAsyncAction({
              apiInfo: bookmarks_unMark_Api,
              query: {
                ':container': 'user',
                ':container_id': _.get(
                  login,
                  'username'
                ),
                ':type': type,
                ':id': id
              },
              onSuccess: () =>
                setHide(true)
            })
          )
        )

        break
      case 'message':
        break
      default:
        break
    }
  }
  const allowActions = item.edit
    ? {
      ...defaultActions,
      pin:
        true &&
        container &&
        container.edit,
      unpin:
        true &&
        container &&
        container.edit,
      edit:
        item.edit &&
        item._type !==
        'poll_question',
      delete: true
    }
    : {
      ...defaultActions,
      pin:
        true &&
        container &&
        container.edit,
      unpin:
        true &&
        container &&
        container.edit,
      edit:
        item.edit &&
        item._type !==
        'poll_question',
      delete: false
    }
  const actions = Object.keys(
    defaultActions
  )
    .filter(
      key =>
        defaultActions[key] &&
        allowActions[key]
    )
    .filter(
      key =>
        !(
          key === 'vote' &&
          item._type === 'poll_question'
        )
    )
    .reduce((result, key) => {
      result[key] = true
      return result
    }, {})
  return (
    <FeedContext.Provider
      value={{
        handleClick,
        item,
        detail,
        actions,
        creatorId,
        created,
        creatorType,
        creatorName,
        title,
        photos,
        poll:
          type === 'poll_question' &&
          item,
        article:
          type === 'article' && item,
        embed_data,
        description,
        cover,
        type,
        id,
        url,
        shareUrl,
        vote_status,
        bookmark_status,
        total_votes,
        mentions: item.mentions
      }}>
      <PermissionProvider
        permissions={{
          ...actions,
          ...item.available_actions
        }}>
        {children}
        {!!modal && modal}
      </PermissionProvider>
    </FeedContext.Provider>
  )
}
