import { emptyArray } from 'helpers/emptyObjects'
import useMap from 'react-use/lib/useMap'

const useAsyncList = (
  actions = emptyArray
) => {
  const [map, { set, reset }] = useMap(
    actions
  )

  return [map, { set, reset }]
}
export default useAsyncList
