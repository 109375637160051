import { API_ROOT_URL } from 'envs/_current/config'
import _ from 'lodash'
import { createAsyncAction } from 'modules/asyncCache'
import { schema } from 'normalizr'
import { baseFieldTypes } from './base/baseFieldTypes'
import {
  articleSchema,
  feedSchema
} from './schema'
import { createTransform } from './utils/createTransform'
const fields = [
  'description',
  'title',
  'content',
  'embed_data',
  'categories',
  'cover_photo',
  'privacy',
  'user_id',
  'action',
  'photos',
  'container_type',
  'container_id',
  'owner_type',
  'owner_id'
]

const fieldTypes = {
  photos: baseFieldTypes.image,
  description: '',
  title: '',
  cover_photo: baseFieldTypes.string,
  embed_data: baseFieldTypes.object,
  categories: [],
  privacy: '',
  content: baseFieldTypes.string,
  user_id: baseFieldTypes.auto,
  action: baseFieldTypes.auto,
  container_type: '',
  container_id: '',
  owner_type: '',
  owner_id: ''
}
// // articles
// Route::post('articles/article/{prop}/{id}/create', 'ArticleController@createArticle');
export const function_article_createArticle_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/articles/article/:prop/:id/create',
  method: 'POST',
  fields,
  schema: articleSchema,
  fieldTypes,
  transform: createTransform(
    feedSchema,
    'data'
  )
}
export const create_function_article_createArticle_Api_action = () => ({
  prefixStr = 'function_article_createArticle_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@function_article_createArticle_Api',
    query,
    values,
    apiInfo: function_article_createArticle_Api,
    maxAge
  })
// Route::post('articles/article/{prop}/{id}/{article_id}/delete', 'PostController@deletePost');
export const article_delete_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/articles/article/:article_id/delete',
  method: 'POST',
  fields,
  fieldTypes,
  transform: (response, { query }) => {
    return {
      response,
      entities: {
        articles: {
          [query[':article_id']]: {
            isDeleted: true,
            update: Date.now()
          }
        }
      }
    }
  }
}
export const create_article_delete_Api_action = () => ({
  prefixStr = 'article_delete_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr + '@article_delete_Api',
    query,
    values,
    apiInfo: article_delete_Api,
    maxAge
  })

// Route::post('articles/article/{prop}/{id}/{article_id}/update', 'PostController@updatePost');
export const article_update_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/articles/article/:prop/:id/:article_id/update',
  method: 'POST',
  fields,
  fieldTypes,
  transform: createTransform(
    articleSchema,
    'data'
  )
}
export const create_article_update_Api_action = () => ({
  prefixStr = 'article_update_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr + '@article_update_Api',
    query,
    values,
    apiInfo: article_update_Api,
    maxAge
  })

// Route::post('articles/article/{article_id}/change-tags', 'PostController@updatePostTags');

// Route::get('articles/gets', 'ArticleController@getArticles')
export const article_getAticles_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/articles/gets',
  method: 'GET',
  fields,
  fieldTypes,
  schema: articleSchema,
  transform: createTransform([
    articleSchema
  ])
}
export const create_article_getAticles_Api_action = () => ({
  prefixStr = 'article_getAticles_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@article_getAticles_Api',
    query,
    values,
    apiInfo: article_getAticles_Api,
    maxAge
  })

//Route::get('articles/article/{article_id}/get', 'ArticleController@getById');
export const article_getById_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/articles/article/:article_id/get',
  method: 'GET',
  fields,
  schema: articleSchema,
  fieldTypes,
  transform: createTransform(
    articleSchema,
    'data'
  )
}
export const create_article_getById_Api_action = () => ({
  prefixStr = 'article_getById_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@article_getById_Api',
    query,
    values,
    apiInfo: article_getById_Api,
    maxAge
  })

// Route::get('articles/latest-articles', 'ArticleController@getArticleGroups');
const articleGroups_CategoriesSchema = new schema.Entity(
  'articleGroups_Categories',
  {
    articles: [articleSchema]
  },
  {
    idAttribute: 'id'
  }
)
const articleGroupSchema = new schema.Entity(
  'articleGroups',
  {
    categories: [
      articleGroups_CategoriesSchema
    ],
    latest_articles: [articleSchema]
  },
  {
    idAttribute: 'id',
    processStrategy: entity => {
      entity.id = _.uniqueId(
        'articleGroup_'
      )
      return entity
    }
  }
)
export const article_getArticleGroups_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/articles/latest-articles',
  method: 'GET',
  fields,
  fieldTypes,
  schema: articleGroupSchema,
  transform: createTransform(
    articleGroupSchema
  )
}
export const create_article_getArticleGroups_Api_action = () => ({
  prefixStr = 'article_getArticleGroups_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@article_getArticleGroups_Api',
    query,
    values,
    apiInfo: article_getArticleGroups_Api,
    maxAge
  })

// Route::get('articles/article/{prop}/{id}/get-articles', 'ArticleController@getDraftArticles');
export const article_getDraftArticles_Api = {
  name: 'article_getDraftArticles',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/articles/article/:prop/:id/get-articles',
  method: 'GET'
}
// Route::post('articles/article/{prop}/{id}/create-draft', 'ArticleController@createDraftArticle');
export const article_createDraftArticle_Api = {
  name: 'article_createDraftArticle',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/articles/article/:prop/:id/create-draft',
  method: 'POST'
}
// Route::post('articles/article/{prop}/{id}/{article_id}/delete-draft', 'ArticleController@deleteDraftArticle');
export const article_deleteDraftArticle_Api = {
  name: 'article_deleteDraftArticle',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/articles/article/:prop/:id/:article_id/delete-draft',
  schema: articleSchema,
  method: 'POST',
  transform: createTransform(
    articleSchema,
    'data'
  )
}
// Route::post('articles/article/{prop}/{id}/{article_id}/update-draft', 'ArticleController@updateDraftArticle');
export const article_updateDraftArticle_Api = {
  name: 'article_updateDraftArticle',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/articles/article/:prop/:id/:article_id/update-draft',
  schema: articleSchema,
  method: 'POST',
  transform: createTransform(
    articleSchema,
    'data'
  )
}
// Route::post('articles/article/{prop}/{id}/{article_id}/publish', 'ArticleController@publishArticle');
export const article_publishArticle_Api = {
  name: 'article_publishArticle',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/articles/article/:prop/:id/:article_id/publish',
  method: 'POST',
  transform: createTransform(
    feedSchema,
    'data'
  )
}
