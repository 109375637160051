import React from 'react';

export const ShortFeedinSvg = () => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 296.3 445.8">
		<defs>
			<style
				dangerouslySetInnerHTML={{
					__html:
						'.fdfe57d3-ec5d-459d-887e-9da8bea9fb0a{fill:#97c33d;}.\\34 8963b9c-254c-4b2c-8ed1-1e0ee4127cc8{fill:#459dd6;}.a643b9fd-580c-495e-9a10-2958f62889f7{fill:#00a551;}'
				}}
			/>
		</defs>
		<title>feedin-small</title>
		<path
			className="fdfe57d3-ec5d-459d-887e-9da8bea9fb0a"
			d="M388.4,147.2c.1-.2-.3-.5-.6-1.1-22,10.3-47.5,30.8-35.6,53.5,10.6,20.1,27,40.4,14.5,62.3,22.5-16.8,21.8-35,12.6-54.2,16.4,18.6,9,36.7-6.2,54.9,4.6-3.1,11.7-6.1,15.6-9.4,20.8-18,19.1-37,3.8-56.2C379.5,180.7,378.6,164.4,388.4,147.2Z"
			transform="translate(-107.8 -33.1)"
		/>
		<path
			className="48963b9c-254c-4b2c-8ed1-1e0ee4127cc8"
			d="M397.3,301.4c0-8.1-2.6-14.9-7.6-20.3a24.9,24.9,0,0,0-19.2-8.4,25.3,25.3,0,0,0-19.4,8.6c-3.7,4.3-6,9.6-6.9,16.7l.3,152.1q0,12.1,7.5,20.4a25.1,25.1,0,0,0,19.1,8.4h.1a25.3,25.3,0,0,0,19.4-8.6c3.8-4.3,6-9.6,6.9-16.7Z"
			transform="translate(-107.8 -33.1)"
		/>
		<path
			className="a643b9fd-580c-495e-9a10-2958f62889f7"
			d="M379.7,107.6l-.8-1.7c-26.3-44.7-75-72.6-127.5-72.8h-3.8l-5.7.3c-3,.2-6,.6-9.1,1l-1.1.2a102.8,102.8,0,0,0-10.5,1.9l-1.2.2A147.2,147.2,0,0,0,107.9,151.1V450.8a26.6,26.6,0,0,0,8.1,19.7,26.9,26.9,0,0,0,19.1,8h.2a27.5,27.5,0,0,0,19.6-8.4,27.2,27.2,0,0,0,8-16.5,24.3,24.3,0,0,0,.3-3.8V256.6H310.8a27.6,27.6,0,0,0,20.3-8.2,27,27,0,0,0,5.4-7.9,31.4,31.4,0,0,0,1-22.6,26.3,26.3,0,0,0-6.5-10.3,28.5,28.5,0,0,0-6.7-4.7,29.8,29.8,0,0,0-13.5-3.2H163.1v-34c.6-3.5,1.3-6.7,2.2-10.1,3.9-17.2,12.4-31.4,25.8-43.6l1.4-1.4h.1c17-14.7,37.5-22.1,61.1-22.1h1.9c19.5.5,36,5.9,50.4,16.4,1.1.7,2.1,1.6,3.9,3s3.3,2.5,5.1,4a78.9,78.9,0,0,1,8.6,8.7A85.7,85.7,0,0,1,334,135.2l1.4,1.6a23.2,23.2,0,0,0,6.4,4.3,26.4,26.4,0,0,0,10.7,2.3,28.3,28.3,0,0,0,10.9-2.2,26.9,26.9,0,0,0,15.4-14.7C381.3,120.7,381.7,114.6,379.7,107.6Z"
			transform="translate(-107.8 -33.1)"
		/>
	</svg>
);
