import classnames from 'classnames'
import Modal from 'components/Modal/Modal'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import './SettingsModal.css'

export const SettingsModal = ({
  name = 'settings',
  full,
  className,
  children,
  onCancel,
  ...props
}) => {
  const t = useTranslate()

  return (
    <Modal
      maskClosable={false}
      style={{ top: 0 }}
      className={classnames(
        'SettingsLayout',
        className,
        full
      )}
      bodyStyle={{
        padding: 0
      }}
      title={
        typeof name === 'string'
          ? t(name)
          : name
      }
      header={null}
      footer={null}
      visible={true}
      onCancel={onCancel}
      {...props}>
      {children}
    </Modal>
  )
}
