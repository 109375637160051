import {Button, notification} from 'antd'
import _ from 'lodash'
import React, {useCallback, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {updateApp} from 'redux/actions'
import * as serviceWorker from '../serviceWorker'
import buildInfo from '../_buildId.json'

const version = buildInfo.lastedVersion
const fetchversion = () => {
  var myHeaders = new Headers()
  myHeaders.append('pragma', 'no-cache')
  myHeaders.append(
    'cache-control',
    'no-cache'
  )
  var myInit = {
    method: 'GET',
    headers: myHeaders
  }
  return fetch('/_buildId.json', myInit)
    .then(res => res.json())
    .then(({ lastedVersion } = {}) => {
      return lastedVersion
    })
}
const ServiceWorker = () => {
  const lastedVersion = useSelector(
    state =>
      _.get(state, 'app.lastedVersion')
  )
  const dispatch = useDispatch()
  const handleUpdate = useCallback(
    lastedVersion => {
      console.log({
        lastedVersion,
        version,
        buildInfo
      })
      dispatch(
        updateApp({
          lastedVersion
        })
      )
    },
    [dispatch]
  )
  useEffect(() => {
    console.log({
      lastedVersion,
      version
    })
    if (lastedVersion && version) {
      if (
        '' + lastedVersion !==
        '' + version
      ) {
        notification.open({
          duration: 0,
          message: 'New version available',
          description:
            'There is a newer version of FeedIn. Update Now.',
          btn: (
            <Button
              type="primary"
              size="small"
              onClick={() =>
                window.location.reload()
              }>
              Update and Restart
            </Button>
          ),
          key: 'update',
          onClose: () => {}
        })
      }
    }
  }, [lastedVersion])
  useEffect(() => {
    function handllocalStorageChange(
      event
    ) {
      if (
        event.storageArea ===
        localStorage
      ) {
        const ver = localStorage.getItem(
          'version'
        )
        if ('' + ver !== '' + version) {
          handleUpdate(ver)
        }
      }
    }
    window.addEventListener(
      'storage',
      handllocalStorageChange,
      false
    )
    return () => {
      window.removeEventListener(
        'storage',
        handllocalStorageChange
      )
    }
  }, [handleUpdate])
  useEffect(() => {
    serviceWorker.register({
      onUpdate: registration => {
        if (
          registration &&
          registration.waiting
        ) {
          registration.waiting.addEventListener(
            'statechange',
            event => {
              if (
                event.target.state ===
                'activated'
              ) {
                fetchversion().then(
                  version => {
                    localStorage.setItem(
                      'lastedVersion',
                      version
                    )
                    dispatch(
                      updateApp({
                        lastedVersion: version
                      })
                    )
                  }
                )
              }
            }
          )
          registration.waiting.postMessage(
            { type: 'SKIP_WAITING' }
          )
        }
      },
      onSuccess: () => {
        console.log(
          'update successful !'
        )
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return null
}

export default ServiceWorker
