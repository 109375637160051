import React, { Component } from 'react'
import ReactDOM from 'react-dom'

const getDisplayName = WrappedComponent => {
  const component =
    WrappedComponent.WrappedComponent ||
    WrappedComponent
  return (
    component.displayName ||
    component.name ||
    'Component'
  )
}

export default ({
  store
}) => WrappedComponent =>
  class BlockAlignmentDecorator extends Component {
    static displayName = `Alignment(${getDisplayName(
      WrappedComponent
    )})`
    static WrappedComponent =
      WrappedComponent.WrappedComponent ||
      WrappedComponent

    componentDidUpdate = () => {
      if (
        this.props.blockProps
          .isFocused &&
        this.props.blockProps
          .isCollapsedSelection
      ) {
        // TODO figure out if and how to achieve this without fetching the DOM node
        // eslint-disable-next-line react/no-find-dom-node
        const blockNode = ReactDOM.findDOMNode(
          this
        )
        const boundingRect = blockNode.getBoundingClientRect()
        store.updateItem(
          'setAlignment',
          this.props.blockProps
            .setAlignment
        )
        store.updateItem(
          'alignment',
          this.props.blockProps
            .alignment
        )
        store.updateItem(
          'boundingRect',
          boundingRect
        )
        store.updateItem(
          'visibleBlock',
          this.props.block.getKey()
        )
        // Only set visibleBlock to null in case it's the current one. This is important
        // in case the focus directly switches from one block to the other. Then the
        // Alignment tool should not be hidden, but just moved.
      } else if (
        store.getItem(
          'visibleBlock'
        ) === this.props.block.getKey()
      ) {
        store.updateItem(
          'visibleBlock',
          null
        )
      }
    }

    componentWillUnmount() {
      // Set visibleBlock to null if the block is deleted
      store.updateItem(
        'visibleBlock',
        null
      )
    }

    render() {
      const {
        blockProps,
        style,
        // using destructuring to make sure unused props are not passed down to the block
        ...elementProps
      } = this.props
      const alignment =
        blockProps.alignment || 'center'
      let newStyle = style || {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block'
      }
      if (alignment === 'left') {
        newStyle = {
          ...style,
          width: '50%',
          marginRight: '1.4em',
          marginBottom: '1.4em',
          float: 'left'
        }
      } else if (
        alignment === 'center'
      ) {
        newStyle = {
          ...style,
          width: '100%',
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'block'
        }
      } else if (
        alignment === 'right'
      ) {
        newStyle = {
          ...style,
          width: '50%',
          marginLeft: '1.4em',
          marginBottom: '1.4em',
          float: 'right'
        }
      }
      return (
        <WrappedComponent
          {...elementProps}
          blockProps={blockProps}
          style={newStyle}
        />
      )
    }
  }
