import { emptyArray } from 'helpers/emptyObjects'
import {
  useContext,
  useMemo
} from 'react'
import PermissionContext from './PermissionContext'
const useCheckPermissions = (
  permissions = emptyArray
) => {
  const pers =
    useContext(PermissionContext) ||
    emptyArray
  return useMemo(() => {
    return permissions.map(
      key => pers[key]
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...permissions, pers])
}
export default useCheckPermissions
