import { PlusOutlined } from '@ant-design/icons'
import preventParentEvent from 'helpers/preventParentEvent'
import React, { useRef } from 'react'
import {
  useClickAway,
  useToggle
} from 'react-use'

const onMouseDown = clickEvent => {
  clickEvent.preventDefault()
  clickEvent.stopPropagation()
}
export default function BlockTypeSelect(
  props
) {
  const [open, toggle] = useToggle()
  const {
    getEditorState,
    setEditorState
  } = props
  const ref = useRef(null)
  useClickAway(ref, () => {
    toggle(false)
  })
  return (
    <div
      onMouseDown={onMouseDown}
      onClick={preventParentEvent}
      ref={ref}
      className={
        'flex space-x-3 -mx-3 buttonScalableGroup pointer-events-none' +
        (open ? ' is-scaled' : '')
      }>
      <button
        onClick={toggle}
        className="w-10 h-10 opacity-75 button--inlineTooltipControl pointer-events-auto border boder-gray-600 flex justify-center items-center background-100 hover:background-200 shadow-2xl z-10 rounded-full">
        <PlusOutlined />
      </button>
      {/*
        The spacer is needed so the popup doesn't go away when moving from the
        blockType div to the popup.
      */}
      <div className="spacer " />
      <div
        onClick={toggle}
        className={
          'space-x-2 flex ' +
          (open
            ? 'pointer-events-auto'
            : ' pointer-events-none')
        }>
        {props
          .childNodes({
            getEditorState,
            setEditorState,
            theme: {}
          })
          .map((e, i) => (
            <div
              key={i}
              className="sidebuttonwrap button--scale u-transitionSeries w-10 h-10 opacity-75 border boder-gray-600 flex justify-center items-center background-100 hover:background-200 shadow-2xl z-10 rounded-full">
              {e}
            </div>
          ))}
      </div>
    </div>
  )
}
