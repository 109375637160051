import {
  createBlockRendererFn,
  createInsertAtomicBlock
} from '../../utils'
import { type } from './constains'
import Linkfy from './Linkfy'
export default function creatLinkfyPlugin({
  entityType = type,
  component = Linkfy,
  decorator
} = {}) {
  let EnchancedLinkfy = component
  if (decorator) {
    EnchancedLinkfy = decorator(
      component
    )
  }
  const handleInsert = createInsertAtomicBlock(
    entityType
  )
  return {
    blockRendererFn: createBlockRendererFn(
      entityType,
      EnchancedLinkfy
    ),
    handleInsert
  }
}
