import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, {PureComponent, useContext} from 'react'
import {LayoutContext} from "./layouts/Default/LayoutContext";
import {branch, compose, mapProps} from "recompose";

class StickyFrame extends PureComponent {
  state = {
    offsetTop: 0,
    height: 16,
    customStyle: {
      top: 0,
      paddingBottom: 0,
      paddingTop: 0
    }
  }

  constructor(props, ...rest) {
    super(props, ...rest);
    this.containerRef = React.createRef();
  }

  handleScroll = () => {
    const { offset = 5 } = this.props
    if (this.containerRef.current) {
      if (
        this.containerRef.current.getBoundingClientRect()
          .height +
          offset >
        window.innerHeight
      ) {
        if (
          window.innerHeight +
            window.scrollY >
          this.containerRef.current.getBoundingClientRect()
            .height +
            this.state.offsetTop
        ) {
          // set at bottom
          this.setState({
            customStyle: {
              top:
                this.state.height +
                offset,
              paddingBottom: 16
            }
          })
        } else if (
          this.containerRef.current
            .offsetTop > window.scrollY
        ) {
          // set at top
          this.setState({
            customStyle: {
              bottom:
                this.state.height +
                offset
              // paddingTop: (offset)
            }
          })
        } else {
          this.containerRef.current.top = null
          this.containerRef.current.bottom = null
        }
      } else {
        if (
          window.scrollY >
          this.state.offsetTop
        ) {
          this.setState({
            customStyle: {
              top: offset
            }
          })
        }
      }
    }
  }

  setHeight = () => {
    if (this.containerRef.current) {
      this.setState({
        height:
          window.innerHeight -
          // - this.containerRef.current.offsetTop
          (this.props.offset || 5) -
          this.containerRef.current.getBoundingClientRect()
            .height
      })
    }
  }

  componentDidMount = () => {
    window.addEventListener(
      'scroll',
      this.handleScroll
    )
    if (this.containerRef.current) {
      if (
        this.containerRef.current.getBoundingClientRect()
          .height > 0
      ) {
        this.setState({
          offsetTop: this.containerRef
            .current.offsetTop
        })
        this.setHeight()
      }
    }
  }

  componentDidUpdate = () => {
    this.setHeight()
  }

  componentWillUnmount = () => {
    window.removeEventListener(
      'scroll',
      this.handleScroll
    )
  }

  render() {
    const {
      children,
      className = ''
    } = this.props;

    return (
      <div
        className={classNames(
          'sticky-container',
          className
        )}
        style={this.state.customStyle}
        ref={this.containerRef}>
        {children}
      </div>
    )
  }
}

StickyFrame.propTypes = {
  className: PropTypes.string
}

const C = ({children}) => (
  <React.Fragment>
    {children}
  </React.Fragment>
)

export default compose(
  mapProps(props => {
    const {isSm} = useContext(LayoutContext);
    return {
      ...props,
      isSm: isSm
    }
  }),
  branch(
    ({isSm}) => isSm === 'sm',
    () => C
  )
)(StickyFrame)
