import {Button, notification} from 'antd'
import {create_message_sendRequestToOrg_Api} from 'apis'
import logFunc from '../../../helpers/logFunc'
import _ from 'lodash'
import {createAsyncAction} from '../../../modules/asyncCache'
import useDispatchAsyncAction from '../../../modules/asyncCache/useDispatchAsyncAction'
import useTranslate from '../../../modules/local/useTranslate'
import React, {useContext} from 'react'
import {FaDirections} from 'react-icons/fa'
import {useHistory} from 'react-router-dom'
import ServerContext from '../../../views/Server/ServerContext'
import {useRequiredLogin} from '../../../views/Server/useRequiredLogin'
import {QuickContactBox} from "./QuickContactBox";


export const Contact = React.memo(
  () => {
    const t = useTranslate()
    const {item} = useContext(
      ServerContext
    )
    const history = useHistory()
    const [
      {response, isLoading},
      asyncCreateConversation
    ] = useDispatchAsyncAction({
      onSuccess: logFunc((__, res) => {
        const node = document.querySelector(
          '#messages'
        )
        node.value = ''
        const id = _.get(
          res,
          'response.data.id'
        )
        notification.success({
          message: t(
            'Your message save been sent'
          ),
          description: (
            <a
              className="float-right"
              href={`/messages?conversationId=${id}`}
              target="_blank">
              <Button>
                {'Go to conversation'}
              </Button>
            </a>
          )
        })
      }),
      onError: (...args) => {
        const [
          error,
          errorObj = {
            error_code: 'System error!',
            error: '-1'
          }
        ] = args
        notification.error({
          message:
            typeof errorObj.error_code ===
            'string'
              ? errorObj.error_code
              : JSON.stringify(
              errorObj.error_code
              ),
          description:
            typeof errorObj.error ===
            'string'
              ? errorObj.error
              : JSON.stringify(
              errorObj.error
              ),
          duration: 3,
          placement: 'topRight'
        })
      }
    })
    const requireLogin = useRequiredLogin()
    const handleCreateConversation = () => {
      const node = document.querySelector(
        '#messages'
      )
      if (node) {
        requireLogin(() =>
          asyncCreateConversation(
            createAsyncAction({
              apiInfo: create_message_sendRequestToOrg_Api(),
              query: {
                ':organization_id':
                item.idname
              },
              values: {
                message: node.value
              }
            })
          )
        )
      }
    }
    return (
      Boolean(
        _.get(item, 'locations.length')
      ) && (
        <div className="p-3 md:p-6 mt-12 background-100">
          <div className="flex flex-col text-center w-full mb-3 lg:mb-6">
            <h2 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-600">
              {t(
                'Get in touch with us'
              )}
            </h2>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              {t(
                'For inquiring about product quotes, sending product information, or contacting cooperation, please contact us'
              )}
            </p>
          </div>
          <div className="mx-auto flex sm:flex-no-wrap flex-wrap">
            <div
              className="lg:w-2/3 md:w-1/2 background-200 rounded-lg overflow-hidden sm:mr-10 p-2 flex flex-col md:flex-row md:items-end justify-start relative">
              <img
                alt=""
                className="md:absolute inset-0 object-cover w-full h-full"
                src={_.get(
                  item,
                  'locations.0.coordinate.static_map_url',
                  '---- ---- ----'
                )}
              />
              <div className="background relative flex-wrap py-3 shadow space-y-3">
                <div className="w-full px-3">
                  <h3 className="title-font font-sm text-gray-600 tracking-widest text-sm leading-none">
                    {t('address')}
                  </h3>
                  <p className="leading-relaxed">
                    {_.get(
                      item,
                      'locations.0.address',
                      '---- ---- ----'
                    )}{' '}
                    <a
                      target="_blank"
                      href={`https://www.google.com/maps/dir//${_.get(
                        item,
                        'locations.0.coordinate.latitude',
                        '---- ---- ----'
                      )},${_.get(
                        item,
                        'locations.0.coordinate.longitude',
                        '---- ---- ----'
                      )}/@${_.get(
                        item,
                        'locations.0.coordinate.latitude',
                        '---- ---- ----'
                      )},${_.get(
                        item,
                        'locations.0.coordinate.longitude',
                        '---- ---- ----'
                      )}`}
                      className="block underline text-primary">
                      <FaDirections className="text-lg"/>{' '}
                      {t(
                        'Get Direction'
                      )}
                    </a>
                  </p>
                </div>
                <div className="w-full px-3">
                  <h3 className="title-font font-sm text-gray-600 tracking-widest text-sm leading-none">
                    {t('email')}
                  </h3>
                  <p className="text-primary leading-relaxed">
                    {_.get(
                      item,
                      'contacts.0.email',
                      '---- ---- ----'
                    )}
                  </p>
                </div>
                <div className="w-full px-3">
                  <h3 className="title-font font-medismum text-gray-600 tracking-widest text-sm mt-4 leading-none">
                    {t('phone')}
                  </h3>
                  <p className="leading-relaxed">
                    {_.get(
                      item,
                      'contacts.0.phone',
                      '---- ---- ----'
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:w-1/3 md:w-1/2  flex flex-col md:ml-auto w-full">
              <QuickContactBox prefixCls="p-3"/>
            </div>
          </div>
        </div>
      )
    )
  }
)
