import {Button, message} from 'antd'
import {organization_Edit_Api} from 'apis'
import RichEditor from 'components/RichEditor'
import {parseRawContent} from 'components/RichEditor/parseRawContent'
import {convertToRaw} from 'draft-js'
import {createAsyncAction} from 'modules/asyncCache'
import {getAsynCacheSelector} from 'modules/asyncCache/selectors'
import useTranslate from 'modules/local/useTranslate'
import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {OrganizationSettingContext} from './OrganizationSettingContext'
import _ from "lodash";

function useAsyncAction() {
  const dispatch = useDispatch()
  const [
    asyncId,
    setAsyncId
  ] = useState()
  const data = useSelector(state =>
    getAsynCacheSelector(state, asyncId)
  )
  const handleAction = useCallback(
    ({
      prefixStr,
      values,
      apiInfo,
      query
    }) => {
      const action = createAsyncAction({
        prefixStr,
        values,
        apiInfo,
        query
      })
      setAsyncId(action.asyncId)
      dispatch(action)
    },
    [dispatch]
  )

  return { data, handleAction }
}

export function About({ className }) {
  const t = useTranslate()
  const { item } = useContext(
    OrganizationSettingContext
  )
  const [
    editorState,
    setEditorState
  ] = useState(() =>
    parseRawContent(item.about)
  )
  const handleOnChange = useCallback(
    editorState => {
      setEditorState(editorState)
    },
    []
  )

  const {
    data,
    handleAction
  } = useAsyncAction();

  const imageSrcList = [
    ...Object.values(
      convertToRaw(
        editorState.getCurrentContent()
      ).entityMap
    )
      .filter(
        ({type}) => type === 'IMAGE'
      )
      .map(item => _.get(item, 'data'))
  ];

  const handleSubmit = value => {
    handleAction({
      prefixStr: 'update_about',
      values: {
        about: value,
        photo_ids_string: _.join(_.map(imageSrcList, 'imageId'), ',')
      },
      apiInfo: organization_Edit_Api,
      query: {
        ':id': item.id
      }
    })
  }

  useEffect(() => {
    if (data.success) {
      message.success(
        t('update about success')
      )
    }
  }, [data.success, t])
  return (
    <div className="relative">
      <div
        className="flex flex-col"
        style={{
          width: '100%'
        }}>
        <div />
        <div className="p-3 flex-1 overflow-hidden">
          <RichEditor
            editorState={editorState}
            onChange={handleOnChange}
          />
        </div>
        <div className="flex justify-end sticky bottom-0 p-3 background rounded z-10">
          <Button
            style={{
              marginLeft: '1em'
            }}
            key="submit"
            type="primary"
            loading={data.isLoading}
            onClick={() =>
              handleSubmit(
                JSON.stringify(
                  convertToRaw(
                    editorState.getCurrentContent()
                  )
                )
              )
            }>
            {t('save')}
          </Button>
        </div>
      </div>
    </div>
  )
}
