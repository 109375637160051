import { billing_validateSelectedSubscription_Api } from 'apis'
import { emptyObject } from 'helpers/emptyObjects'
import _ from 'lodash'
import useAsync from 'modules/asyncCache/useAsync'
import React from 'react'
import {
  Redirect,
  useParams
} from 'react-router-dom'
import { links } from '../routes'
import ConfirmPlanAmount from './ConfirmPlanAmount'
function ChoosePlanConfirm() {
  const { planId } = useParams()
  const {
    response,
    success
  } = useAsync({
    apiInfo: billing_validateSelectedSubscription_Api,
    query: {
      ':code': planId
    }
  })
  let to
  const {
    authority_class,
    trial_enabled,
    downgrade,
    deposit_required,
    deposit_amount,
    upgrade
  } = _.get(
    response,
    'data',
    emptyObject
  )
  switch (true) {
    case downgrade:
      to = links.downgradePlan.replace(
        ':planId',
        authority_class
      )
      return <Redirect to={to} />
    default:
      break
  }
  if (!success) return null
  return (
    <ConfirmPlanAmount
      planId={planId}
      amount={deposit_amount}
      canTrail={trial_enabled}
    />
  )
}

export default ChoosePlanConfirm
