import { API_ROOT_URL } from 'envs/_current/config'
import {
  emptyArray,
  emptyObject
} from 'helpers/emptyObjects'
import _ from 'lodash'
import { createAsyncAction } from 'modules/asyncCache'
import { normalize } from 'normalizr'
import Request from './middleware/request'
import { categorySchema } from './schema'
import { createTransform } from './utils/createTransform'

export function getCategoriesApi() {
  return Request.get(
    `${API_ROOT_URL}/categories/gets`
  )
}

// Route::post('categories/{id}/edit', 'TagController@updateCategory');
// Route::get('categories/gets', 'TagController@getCategories');

const transformArray = createTransform([
  categorySchema
])
export const category_getCategories_Api = {
  backend: `Route::get('categories/gets', 'TagController@getCategories')`,
  root: API_ROOT_URL,
  path: '/categories/gets',
  method: 'GET',
  fields: emptyArray,
  fieldTypes: emptyObject,
  schema: [categorySchema],
  transform: transformArray
}
export const create_category_getCategories_Api_action = () => ({
  prefixStr = '',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@category_getCategories_Api',
    query,
    values,
    apiInfo: category_getCategories_Api,
    maxAge
  })

// Route::get('categories/get-parents', 'TagController@getParentCategories');
export const category_getparent_Api = {
  name: 'category_getparent',
  backend: ``,
  root: API_ROOT_URL,
  path: '/categories/get-parents',
  method: 'GET',
  transform: response => {
    const { status } = response
    const data = _.get(
      response,
      'data.data'
    )
    if (data) {
      data.products = []
      const {
        entities,
        result
      } = normalize(data, [
        categorySchema
      ])
      response.entities = entities
      response.result = result
    }
    return {
      ...response,
      ...(status !== 200
        ? {
            error: true,
            errorMessages: response.data
          }
        : {
            success: true
          }),
      status,
      entities: response.entities,
      result: response.result
    }
  }
}

// Route::get('categories/category/{id}/get', 'TagController@getCategoryById');
export const categories_getCategoryById_Api = {
  name: 'categories_getCategoryById',
  backend: `Route::get('categories/category/{id}/get', 'TagController@getCategoryById');`,
  root: API_ROOT_URL,
  path: '/categories/category/:id/get',
  method: 'GET',
  transform: createTransform(
    categorySchema,
    'data'
  )
}

// Route::get('categories/shortcuts/charts/{id}/get', 'ChartController@getShortcutChartListByChartId');
export const categories_getShortcutChartsByChartId_Api = {
  name:
    'categories_getShortcutChartsByChartId_Api',
  backend: `Route::get('categories/shortcuts/charts/{id}/get', 'ChartController@getShortcutChartListByChartId');`,
  root: API_ROOT_URL,
  path:
    '/categories/shortcuts/charts/:id/get',
  method: 'GET',
  transform: createTransform(
    [categorySchema],
    'data.data'
  )
}

// Route::post('categories/shortcuts/{cate_id}/charts/{id}/position/{position}/add', 'ChartController@addShortcutChartToCategory');
export const categories_addShortcutChartToCategory_Api = {
  name:
    'categories_addShortcutChartToCategory_Api',
  backend: `Route::post('categories/shortcuts/{cate_id}/charts/{id}/position/{position}/add', 'ChartController@addShortcutChartToCategory');`,
  root: API_ROOT_URL,
  path:
    '/categories/shortcuts/:cate_id/charts/:id/position/:position/add',
  method: 'POST',
  fields: ['cate_id', 'id', 'position'],
  transform: createTransform(
    categorySchema,
    'data'
  )
}

// Route::post('categories/shortcuts/{cate_id}/position/{position}/remove', 'ChartController@removeShortcutChartToCategory');
export const categories_removeShortcutChartToCategory_Api = {
  name:
    'categories_removeShortcutChartToCategory_Api',
  backend: `Route::post('categories/shortcuts/{cate_id}/position/{position}/remove', 'ChartController@removeShortcutChartToCategory');`,
  root: API_ROOT_URL,
  path:
    '/categories/shortcuts/:cate_id/position/:position/remove',
  method: 'POST',
  fields: ['cate_id', 'position'],
  transform: createTransform(
    categorySchema,
    'data'
  )
}
