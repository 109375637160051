import { API_ROOT_URL } from 'envs/_current/config'
import _ from 'lodash'
import { createAsyncAction } from 'modules/asyncCache'
import { schema } from 'normalizr'
import { createTransform } from './utils/createTransform'
export const commentContainerSchema = new schema.Entity(
  'commentContainers',
  {},
  {
    mergeStrategy: (entityA, entityB) =>
      _.merge({}, entityA, entityB)
  }
)

const customCommentSchemaProcessStrategy = entity => {
  return {
    ...entity,
    commentContainer: {
      id: `${entity.container_type}_${entity.container_id}`,
      sortByDate: {
        [new Date(
          entity.created
        ).getTime()]: entity.id
      }
    }
  }
}
export const customCommentSchema = new schema.Entity(
  'comments',
  {
    commentContainer: commentContainerSchema
  },
  {
    processStrategy: customCommentSchemaProcessStrategy
  }
)
// Route::get('comments/{prop}/{id}/get-comments', 'CommentController@getComments');
export const comment_getComments_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/comments/:prop/:id/get-comments',
  method: 'GET',
  fields: [],
  fieldTypes: {},
  schema: customCommentSchema,
  transform: createTransform([
    customCommentSchema
  ])
}
export const create_comment_getComments_Api_action = () => ({
  prefixStr = 'comment_getComments_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@comment_getComments_Api',
    query,
    values,
    apiInfo: comment_getComments_Api,
    maxAge
  })
// Route::get('comments/{prop}/{id}/comment/{comment_id}/get-replies'
// Route::post('comments/comment/{prop}/{id}/add-comment', 'CommentController@addComment');
export const comment_addComment_Api = {
  name: 'comment_addComment',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/comments/comment/:prop/:id/add-comment',
  method: 'POST',
  transform: createTransform(
    new schema.Entity(
      'comments',
      {
        commentContainer: commentContainerSchema
      },
      {
        processStrategy: entity => {
          return {
            ...entity,
            isNew: true,
            commentContainer: {
              id: `${entity.container_type}_${entity.container_id}`,
              sortByDate: {
                [new Date(
                  entity.created
                ).getTime()]: entity.id
              }
            }
          }
        }
      }
    ),
    'data'
  )
}
// Route::post('comments/comment/{prop}/{id}/edit-comment/{comment_id}', 'CommentController@editComment');
export const comment_editComment_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/comments/comment/:prop/:id/edit-comment/:comment_id',
  method: 'POST',
  fields: ['message'],
  transform: createTransform(
    customCommentSchema,
    'data'
  )
}
export const create_comment_editComment_Api_action = () => ({
  prefixStr = '',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@comment_editComment_Api',
    query,
    values,
    apiInfo: comment_editComment_Api,
    maxAge
  })
// Route::post('comments/comment/{prop}/{id}/remove-comment/{comment_id}', 'CommentController@removeComment');
export const comment_removeComment_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/comments/comment/:prop/:id/remove-comment/:comment_id',
  method: 'POST',
  transform: (response, { query }) => {
    return {
      response,
      entities: {
        comments: {
          [query[':comment_id']]: {
            isDeleted: true,
            update: Date.now()
          }
        }
      }
    }
  }
}
export const create_comment_removeComment_Api_action = () => ({
  prefixStr = 'comment_removeComment_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@comment_removeComment_Api',
    query,
    values,
    apiInfo: comment_removeComment_Api,
    maxAge
  })
// Route::post('comments/reply/{prop}/{id}/comment/{comment_id}/add-reply', 'CommentController@addReply');
export const comment_addReply_Api = {
  root: API_ROOT_URL,
  path: `/comments/reply/:type/:id/comment/:comment_id/add-reply`,
  method: 'POST',
  fiels: ['message'],
  transform: createTransform(
    new schema.Entity(
      'comments',
      {
        commentContainer: commentContainerSchema
      },
      {
        processStrategy: entity => {
          return {
            ...entity,
            isNew: true,
            commentContainer: {
              id: `${entity.container_type}_${entity.container_id}_${entity.parent_id}`,
              sortByDate: {
                [new Date(
                  entity.created
                ).getTime()]: entity.id
              }
            }
          }
        }
      }
    ),
    'data'
  )
}

// Route::post('comments/reply/{prop}/{id}/edit-reply/{comment_id}', 'CommentController@editReply');
// Route::post('comments/reply/{prop}/{id}/remove-reply/{comment_id}', 'CommentController@removeReply');

export const comment_getReples_Api = {
  root: API_ROOT_URL,
  path: `/comments/:type/:id/comment/:comment_id/get-replies`,
  method: 'GET',
  transform: createTransform([
    new schema.Entity(
      'comments',
      {
        commentContainer: commentContainerSchema
      },
      {
        processStrategy: entity => {
          return {
            ...entity,
            commentContainer: {
              id: `${entity.container_type}_${entity.container_id}_${entity.parent_id}`,
              sortByDate: {
                [new Date(
                  entity.created
                ).getTime()]: entity.id
              }
            }
          }
        }
      }
    )
  ])
}
