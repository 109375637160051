import { userSchema } from 'apis/schema'
import { emptyArray } from 'helpers/emptyObjects'
import _ from 'lodash'
import { selectEntities } from '../entities/selectors'

export const getLang = state => {
  return state.i18nState
    ? state.i18nState.lang
    : 'vi'
}
export const getLogin = state => {
  return state.auth && state.auth.login
    ? selectEntities(
        state,
        state.auth.login,
        userSchema,
        state.auth.user
      ) || state.auth.user
    : undefined
}
export const getLoginBoolean = state =>
  !!getLogin(state)

export const getAllPackages = (
  login = {}
) => {
  return login['packages']
}
export const getCurrentPackageIndex = (
  login = {}
) => {
  return login['current_package_id']
}
export const selectCurrentPackages = state => {
  const login = getLogin(state)
  const allPackages = getAllPackages(
    login
  )
  const current = getCurrentPackageIndex(
    login
  )
  return _.get(allPackages, current)
}
export const getAvailableCategories = state => {
  const login = getLogin(state)
  return _.get(
    login,
    'categories',
    emptyArray
  )
}
