import { Field } from 'formik'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { defaultInputProps } from './defaultInputProps'
import FieldDecorator from './FieldDecorator'

const defaultFormSchema = [
  {
    title: 'email',
    children: {
      type: 'email',
      ...defaultInputProps.email,
      name: 'email'
    }
  },
  {
    title: 'fullname',
    children: {
      type: 'fullname',
      ...defaultInputProps.fullname,
      name: 'fullname'
    }
  },
  {
    title: 'personal profile',
    children: {
      type: 'text',
      ...defaultInputProps.textArea,
      placeholder:
        'brief introduction to yourself',
      name: 'personal_profile'
    }
  },
  {
    title: 'country',
    children: {
      type: 'text',
      ...defaultInputProps.country,
      name: 'country'
    }
  },
  {
    title: 'address',
    children: [
      {
        type: 'province',
        ...defaultInputProps.fullname,
        name: 'province'
      },
      {
        type: 'district',
        ...defaultInputProps.fullname,
        name: 'district'
      }
    ]
  },
  {
    title: 'phone',
    children: [
      {
        ...defaultInputProps.prefixPhoneNumber,
        name: 'prefixPhoneNumber'
      },
      {
        ...defaultInputProps.phone,
        name: 'phone_number'
      }
    ]
  }
]

const renderChildren = (
  {
    component: C,
    render,
    className,
    label,
    errors,
    isRequired,
    withProps = {},
    name,
    ...props
  },
  translate
) => {
  return (
    <Field
      name={name}
      render={({
        field,
        form: {
          touched = {},
          errors = {},
          ...rest
        } = {
          touched: {},
          errors: {}
        }
      }) => (
        <FieldDecorator
          key={props.name}
          {...{
            label: translate(label),
            errors: errors[name],
            isRequired
          }}>
          {render ? (
            render({
              ...field,
              errors,
              touched,
              form: rest
            })
          ) : (
            <C
              {...field}
              {...withProps}
              {...props}
              form={rest}
            />
          )}
        </FieldDecorator>
      )}
    />
  )
}
const renderChildrens = (
  children,
  translate
) => {
  return (
    <div
      style={{
        marginRight: '-1em'
      }}
      className="flex flex-wrap">
      {children.map((child, i) => (
        <div
          className="flex-1"
          style={{
            marginRight: '1em'
          }}
          key={i}>
          {renderChildren(
            child,
            translate
          )}
        </div>
      ))}
    </div>
  )
}
const FieldsFactory = ({
  formSchema = defaultFormSchema
}) => {
  const translate = useTranslate()
  return (
    <React.Fragment>
      {formSchema.map(
        ({ title, children }, i) => {
          const render = _.isArray(
            children
          )
            ? renderChildrens
            : renderChildren
          return (
            <div
              className="w-full"
              key={i}>
              <div className="pb-1 font-semibold capitalize">
                {translate(title)}
              </div>
              {render(
                children,
                translate
              )}
            </div>
          )
        }
      )}
    </React.Fragment>
  )
}
export default FieldsFactory
