import { emptyArray } from 'helpers/emptyObjects'
import _ from 'lodash'
import { useMemo } from 'react'
export default function Pure({
  values = emptyArray,
  children = null
}) {
  const result = useMemo(
    () => {
      if (_.isFunction(children)) {
        return children(values)
      }
      return children
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    values
  )
  return result
}
