import Null from 'components/Null'
import * as React from 'react'

class ErrorBoundary extends React.PureComponent {
  state = { error: null }
  componentDidCatch = (
    error,
    errorInfo
  ) => {
    console.error({ error, errorInfo })
    // window.Raven && window.Raven.captureException(error, { extra: errorInfo });
    this.setState({ error })
  }
  render() {
    const { error } = this.state
    const {
      fallbackComponent: FallbackComponent = Null,
      children
    } = this.props

    if (error) {
      if (
        this.props.fallbackComponent
      ) {
        // $FlowFixMe
        return <FallbackComponent />
      }

      if (
        !this.props.fallbackComponent
      ) {
        return null
      }
    }

    return children
  }
}

export default ErrorBoundary
