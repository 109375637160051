import React from 'react'

export const FlagENSvg = props => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      data-name="Layer 1"
      viewBox="0 0 128 128">
      <path
        fill="#0b55b1"
        d="M12 27.751h104v72.497H12z"></path>
      <path
        fill="#f0f0f0"
        d="M116 27.751h-14.864L64 53.638 26.864 27.751H12v10.362L49.136 64 12 89.887v10.362h14.864L64 74.362l37.136 25.887H116V89.887L78.864 64 116 38.113z"></path>
      <path
        fill="#d60a2e"
        d="M116 27.751h-.429L64 63.701l-51.571-35.95H12v4.706l48.41 33.746L12 99.95v.299h6.751L64 68.706l45.249 31.543H116v-.299L67.59 66.203 116 32.457z"></path>
      <path
        fill="#f0f0f0"
        d="M116 54.667H73.333V27.751H54.667v26.916H12v18.666h42.667v26.916h18.666V73.333H116z"></path>
      <path
        fill="#d60a2e"
        d="M59.667 95.249V68.333H17v-8.666h42.667V32.751h8.666v26.916H111v8.666H68.333v26.916z"></path>
    </svg>
  )
}
