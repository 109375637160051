import Icon, {PaperClipOutlined} from '@ant-design/icons'
import {Button, Col, Modal, Row, Skeleton} from 'antd'
import {baseModel, productModel} from 'apis/model'
import {createBaseItemSchema, productSchema} from 'apis/schema'
import EmptyHolder from 'components/EmptyHolder'
import Count from 'components/Feed/Count'
import Description from 'components/Feed/Description'
import Toggle from 'components/Toggle'
import {UseHook} from 'components/UseHook'
import {API_ROOT_URL} from 'envs/_current/config'
import {emptyArray, emptyObject} from 'helpers/emptyObjects'
import useQueryString from 'hooks/useQueryString'
import _ from 'lodash'
import CustomLazyPagination from 'modules/asyncCache/components/CustomLazyPagination'
import {DidOnChange} from 'modules/asyncCache/components/DidOnChange'
import {getAsynCacheSelector} from 'modules/asyncCache/selectors'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {AiOutlineFileSearch} from 'react-icons/ai'
import {BsChevronCompactRight} from 'react-icons/bs'
import {FaYoutube} from 'react-icons/fa'
import {FcBinoculars} from "react-icons/fc"
import ReactPlayer from 'react-player'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-use'
import {useSelectEntities} from 'redux/entities/useSelectEntities'
import formatCurrency from 'views/Billing/components/formatCurrency'
import {Selector} from 'views/Discovery/Recommendation'
import {FB_ID} from "../../../envs/_current/config"
import {GetQuoteButton} from '../components/GetQuoteButton'
import {Layout} from '../Layout'
import {SelectCategory} from "../MarketSearch/SelectCategory"
import {SelectLocation} from '../MarketSearch/SelectLocation'
import productImageHolder from '../productImageHolder'
import {useAppConfig} from "../../../modules/local";
import queryString from 'query-string'

export const Product = ({item}) => {
  const t = useTranslate()
  return (
    <div className="p-2 md:rounded background-100 rounded-lg">
      {item && (
        <div className="relative flex space-x-3">
          <div className="w-1/4 ">
            <div
              style={{
                paddingTop: '100%'
              }}
              className="w-full relative background-200 rounded-lg overflow-hidden">
              <div className="absolute w-full h-full top-0 left-0">
                <img
                  className="object-cover w-full h-full"
                  src={
                    _.get(
                      item,
                      'thumbnail',
                      productImageHolder
                    ) ||
                    productImageHolder
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className=" flex-1 ">
            <div
              style={{
                height: '100%'
              }}
              className=" w-full flex flex-col">
              <div className="w-full ">
                <div
                  className="absolute"
                  style={{
                    top: '-50px'
                  }}></div>
                <h2 className="m-0 md:text-xl font-bold">
                  <Link
                    className="text-primary"
                    to={productModel.getLinkToDetail(
                      item
                    )}>
                    {item.title}
                  </Link>
                </h2>
                <div className="italic text-sm ">
                  {t('provided by')}{' '}
                  <b>
                    {_.get(
                      item,
                      'owner.title'
                    )}
                  </b>
                </div>
                <div className="text-sm">
                  {item.price > 0 ? (
                    <span className="mr-6">
                      <b>
                        {formatCurrency(
                          item.price
                        )}
                      </b>
                      /{item.unit_name}
                    </span>
                  ) : (
                    <span className="mr-6 text-gray-600">
                      {t('no price')}
                    </span>
                  )}
                  <GetQuoteButton
                    item={item}
                    button={
                      <button className="hover:underline text-primary inline-block ">
                        {t(
                          'Get Best Price'
                        )}
                      </button>
                    }></GetQuoteButton>
                </div>
              </div>
              <div className="w-full py-2 flex-1">
                <div
                  direction="vertical"
                  className="w-full space-y-3">
                  <div className="w-full flex flex-col">
                    {[
                      ...productModel.get(
                        item,
                        'specifications',
                        emptyArray
                      ),
                      ...productModel.get(
                        item,
                        'attributes',
                        emptyArray
                      )
                    ]
                      .filter(
                        (item, i) =>
                          i < 4
                      )
                      .map(
                        (attr, i) => (
                          <div
                            key={i}
                            className="list-inside">
                            <Row className="border-b border-gray-300">
                              <Col
                                className="text-gray-600"
                                xs={24}
                                md={8}>
                                {baseModel.get(
                                  attr,
                                  'attribute_name'
                                )}
                              </Col>
                              <Col
                                className="font-semibold"
                                xs={24}
                                md={16}>
                                {baseModel.get(
                                  attr,
                                  'attribute_value'
                                )}
                              </Col>
                            </Row>
                          </div>
                        )
                      )}
                  </div>
                  {item.description && (
                    <Description className="hidden md:block">
                      {item.description}
                    </Description>
                  )}
                  <div className="space-x-3 flex items-center flex-wrap">
                    {item.video_url && (
                      <Toggle>
                        {(
                          isToggle,
                          toggle
                        ) => (
                          <>
                            <button
                              onClick={() =>
                                toggle()
                              }
                              data-effect="ripple"
                              className="px-2  rounded border capitalize border-gray-300 space-x-2 flex items-center">
                              <Icon
                                className="text-lg"
                                style={{
                                  color:
                                    '#FF0000'
                                }}
                                component={
                                  FaYoutube
                                }
                              />
                              <span>
                                {t(
                                  'video'
                                )}
                              </span>
                            </button>
                            <Modal
                              destroyOnClose
                              maskClosable
                              onCancel={
                                toggle
                              }
                              closeIcon={
                                null
                              }
                              bodyStyle={{
                                padding: 0
                              }}
                              header={
                                null
                              }
                              footer={
                                null
                              }
                              visible={
                                isToggle
                              }>
                              <ReactPlayer
                                style={{
                                  fontSize:
                                    '1em'
                                }}
                                width="100%"
                                height={
                                  400
                                }
                                url={
                                  item.video_url
                                }
                                config={{
                                  youtube: {
                                    playerVars: {
                                      controls: 1,
                                      // autoplay: 1,
                                      showinfo: 1
                                    }
                                  },
                                  facebook: {
                                    appId:
                                    FB_ID
                                  }
                                }}
                              />
                            </Modal>
                          </>
                        )}
                      </Toggle>
                    )}
                    {item.pdf_path && (
                      <a
                        download
                        href={
                          item.pdf_path
                        }
                        target="_blank"
                        data-effect="ripple"
                        className="px-2 rounded border capitalize border-gray-300 space-x-2 flex items-center">
                        <Icon
                          className="text-lg"
                          style={{
                            color:
                              'var(--color-primary)'
                          }}
                          component={
                            PaperClipOutlined
                          }
                        />
                        <span>PDF</span>
                      </a>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex justify-end ">
                <GetQuoteButton
                  {...{item}}
                  button={
                    <Button
                      type="primary"
                      className="rounded-lg">
                      {t(
                        'contact provider'
                      )}
                    </Button>
                  }/>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
const enhance = (data, map = []) =>
  data.reduce((result, value) => {
    result[value.id] = value
    result[value.id] = {
      ...result[value.id]
    }
    result[value.id].map = map
    if (
      value.children &&
      value.children.length
    ) {
      result = {
        ...result,
        ...enhance(value.children, [
          ...map,
          value.id
        ])
      }
    }
    return result
  }, {})

export function Result({} = {}) {
  const t = useTranslate()
  const location = useLocation()
  const path =
    '/search/filter-data/product' +
    location.search
  const schema = createBaseItemSchema({
    idAttribute: 'owner_id',
    schema: {
      owner: productSchema
    }
  })
  const apiInfo = {
    backend: ``,
    root: API_ROOT_URL,
    path,
    method: 'GET',
    fields: [],
    paramsFields: [],
    fieldTypes: {},
    schema
  }
  const dispatch = useDispatch()

  return (
    <div className="space-y-2 md:space-y-6 w-full">
      <CustomLazyPagination
        cacheId={apiInfo.path}
        key={apiInfo.path}
        {...{
          apiInfo: apiInfo
        }}
        renderItem={({
                       owner: item
                     }) => {
          return (
            <Product
              key={item.id}
              {...{item}}
            />
          )
        }}
        renderLoading={() => (
          <div className="pb-8 pt-6 px-4 m-3 flex flex-col items-center m-auto max-w-2xl text-center rounded-full">
            <div className="m-3">
              <div
                style={{
                  fontSize: '3rem',
                  color:
                    'rgba(226, 232, 240, 1)'
                }}>
                <AiOutlineFileSearch/>
              </div>
            </div>

            <div className="text-2xl text-color mb-4">
              <Skeleton.Input
                style={{width: 150}}
                active="true"
                size={'small'}
              />
            </div>
            <div className="text-color-100">
              <Skeleton.Input
                style={{width: 200}}
                active="true"
                size={'small'}
              />
            </div>
          </div>
        )}
        renderNoMore={() => (
          <EmptyHolder
            icon={() => (
              <div
                style={{
                  opacity: 0.5,
                  fontSize: '3rem',
                  color: 'rgba(226, 232, 240, 1)'
                }}>
                <FcBinoculars/>
              </div>
            )}
            subTitle={null}
            title={null}
          />
        )}>
        {(render, record) => {
          const {asyncId} = record

          return (
            <>
              {asyncId && (
                <Selector
                  selector={state =>
                    getAsynCacheSelector(
                      state,
                      asyncId
                    )
                  }>
                  {item => (
                    <DidOnChange
                      handler={() => {
                        dispatch({
                          type:
                            'update',
                          entities: {
                            baseItems: {
                              ['[market.search]' +
                              path]: baseModel.get(
                                item,
                                'response.data',
                                emptyObject
                              )
                            }
                          }
                        })
                      }}
                      input={[
                        item
                      ]}></DidOnChange>
                  )}
                </Selector>
              )}

              {render()}
            </>
          )
        }}
      </CustomLazyPagination>
    </div>
  )
}

export function RelatedCategories({
                                    setQuery
                                  } = {}) {
  const t = useTranslate()
  const location = useLocation()
  const item = useSelectEntities(
    '[market.search]/search/filter-data/product' +
    location.search
  )
  const related_categories = enhance(
    _.get(
      item,
      'filters.related_categories.options',
      []
    )
  )

  return (
    <div className="space-y-3  h-1/2">
      <nav
        className="w-full md:text-sm md:shadow-none flex flex-1 md:flex-col flex-row overflow-auto rounded  bg-transparent  ">
        <div className="flex truncate flex-shrink-0 items-center p-2 text-sm font-semibold font-semibold text-gray-600">
          {t('related categories')}
        </div>
        {Object.keys(
          related_categories
        ).map(key => {
          const {
            id,
            label,
            map
          } = related_categories[key]
          return (
            <div
              title={label}
              key={id}
              onClick={() =>
                setQuery({
                  categories: id
                })
              }
              className={
                item.children
                  ? 'p-2 py-1 mr-2 md:rounded-none md:mr-0 flex-shrink-0 text-center rounded-lg border md:border-none md:text-left cursor-pointer md:border-b border-gray-300 flex items-center font-semibold'
                  : 'p-2 py-1 mr-2 md:rounded-none md:mr-0 flex-shrink-0 text-center rounded-lg border md:border-none md:text-left cursor-pointer md:border-b border-gray-300 flex items-center'
              }>
              <span className="truncate hover:underline w-full">
                {Boolean(map.length) &&
                new Array(
                  map.length
                ).fill('• ')}
                {label}
              </span>
            </div>
          )
        })}
      </nav>
    </div>
  )
}

export default function Impcat() {
  const location = useLocation();
  const appConfig = useAppConfig();
  const item = useSelectEntities(
    '[market.search]/search/filter-data/product' +
    location.search
  )
  const related_provinces = _.get(
    item,
    'filters.related_provinces.options',
    []
  )
    .flatMap(({id, label}) => {
      return {
        id,
        label,
        value: id
      }
    })
    .filter(item => !_.isEmpty(item));

  const searchParams = queryString.parse(location.search);

  return (
    <UseHook
      hook={useQueryString}
      deps={[]}>
      {([query, setQuery]) => {
        const data = _.get(
          appConfig,
          'hierarchy_product_categories',
          []
        )

        const enhancedData = enhance(
          data
        )
        const category =
          enhancedData[
            query.categories
            ]
        let categoryMap =
          category &&
          category.map.map(
            id => enhancedData[id]
          );

        if (_.isEmpty(categoryMap)) {
          categoryMap = [category];
        }


        return (
          <Translate>
            {t => (
              <Layout>
                <div className=" mx-auto space-y-3">
                  <div className="flex items-center space-x-3">
                    <div className="w-full">
                      <div className="-mx-2 py-3 text-sm flex overflow-x-auto w-full items-center">
                        <Link
                          to={`/mart/c`}>
                          <Count className="truncate">
                            {t(
                              'category'
                            )}
                          </Count>
                        </Link>

                        {Boolean(
                          categoryMap &&
                          categoryMap.length
                        ) &&
                        categoryMap.map(
                          cate => (
                            <React.Fragment
                              key={
                                cate.id
                              }>
                              <Icon
                                className="text-lg text-gray-500 opacity-50"
                                component={
                                  BsChevronCompactRight
                                }
                              />
                              <Link
                                key={
                                  cate.id
                                }
                                to={`/mart/impcat?categories=${cate.id}`}>
                                <Count className="truncate">
                                  {
                                    cate.name
                                  }
                                </Count>
                              </Link>
                            </React.Fragment>
                          )
                        )}
                      </div>
                      {category && (
                        <h1 className="p-3 text-center background-100 rounded-lg">
                          <Link
                            className="block text-2xl font-bold"
                            to={`/mart/impcat?categories=${category.id}`}>
                            {
                              category.name
                            }
                          </Link>
                        </h1>
                      )}
                    </div>
                  </div>
                  <div className="space-y-3">

                    <div className="flex space-x-3 overflow-y-hidden overflow-x-auto">
                      {
                        category && (
                          <div className="block md:hidden">
                            <SelectCategory
                              categoryList={
                                categoryMap
                              }
                              value={
                                category.id
                              }
                              onChange={value => {
                                setQuery({
                                  ...searchParams,
                                  categories: value
                                })
                              }}
                            />
                          </div>
                        )
                      }

                      <SelectLocation
                        related_provinces={
                          related_provinces
                        }
                        value={
                          query.province
                        }
                        onChange={value => {
                          setQuery({
                            ...searchParams,
                            province: value
                          })
                        }}
                      />
                    </div>

                    <div className=" m-auto block grid grid-cols-12 w-full gap-2 md:gap-6 ">
                      <div className="col-span-12 md:col-span-3 space-y-3">
                        <section
                          className="h-full md:block hidden"
                          style={{
                            overflow:
                              'initial'
                          }}>
                          <RelatedCategories
                            {...{
                              categories:
                              query.categories,
                              query,
                              setQuery
                            }}
                          />
                        </section>
                      </div>
                      <div className="col-span-12  md:col-span-9 ">
                        <Result
                          categories={
                            query.categories
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Layout>
            )}
          </Translate>
        )
      }}
    </UseHook>
  )
}
