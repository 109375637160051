import Null from 'components/Null'
import {
  emptyArray,
  emptyObject
} from 'helpers/emptyObjects'
import React from 'react'

const ServerContext = React.createContext(
  {
    onFollow: Null,
    item: emptyObject,
    routes: emptyArray,
    current: '',
    setCurrent: Null
  }
)
export default ServerContext
