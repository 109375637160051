import React from 'react'
import { FlagENSvg } from '../Svgs/FlagENSvg'
import { FlagVISvg } from '../Svgs/FlagVISvg'

export const FlagIcon = ({
  type,
  ...props
}) => {
  switch (type) {
    case 'vi':
      return <FlagVISvg {...props} />
    case 'en':
      return <FlagENSvg {...props} />
    default:
      return <FlagVISvg {...props} />
  }
}
