import {LeftOutlined} from '@ant-design/icons';
import Count from 'components/Feed/Count';
import {createLazyComponent} from 'helpers/createLazyComponent';
import _ from 'lodash';
import Translate from 'modules/local/Translate';
import {HistoryContext} from 'modules/navigation/NavigationProvider';
import React from 'react';
import MarketCategory from './Category.lazy';
import {MarketLayout} from './components/Layout/MarketLayout';
import Home from './Home.lazy';
import {Layout} from './Layout';
import MarketSearch from './MarketSearch/MarketSearch.lazy';
import {PageContext} from './PageContext';
import ProductPage from './ProductPage.lazy';
import {LocalContext} from 'modules/local/LocalContext'

const Impcat = createLazyComponent(
  () => import('./Impcat'),
  'Impcat'
);

const createMarketRoutes = ({ pageRoot = '/mart' } = {}) => {
  const routeEntities = {
    home: {
      name: 'home',
      path: pageRoot,
      breadcrumbName: 'home',
      exact: true,
      render: () => (
        <MarketLayout>
          <Home />
        </MarketLayout>
      )
    },
    search: {
      name: 'search',
      path: pageRoot + '/s',
      render: () => (
        <MarketLayout>
          <MarketSearch />
        </MarketLayout>
      )
    },
    category: {
      name: 'category',
      path: pageRoot + '/c',
      render: () => (
        <LocalContext.Consumer>
          {({appConfig}) => {

            const data = _.get(appConfig, 'hierarchy_product_categories', []);

            return (
              <Translate>
                {t => (
                  <Layout>
                    <div className=" mx-auto space-y-6">
                      <div className="flex items-center space-x-3">
                        <HistoryContext.Consumer>
                          {history => (
                            <Count
                              onClick={() => history.push(
                                routeEntities
                                  .home
                                  .path
                              )}
                              component={LeftOutlined}/>
                          )}
                        </HistoryContext.Consumer>

                        <h2 className="text-2xl font-bold ">
                          {t('category')}
                        </h2>
                      </div>
                      <MarketCategory
                        data={data} />
                    </div>
                  </Layout>
                )}
              </Translate>
            );
          }}
        </LocalContext.Consumer>
      )
    },
    impcat: {
      name: 'impcat',
      path: pageRoot + '/impcat',
      component: Impcat
    },
    productPage: {
      name: 'product',
      path: pageRoot + '/product/:id',
      component: ProductPage
    },
    contactSeller: {
      name: 'contactSeller',
      path: pageRoot + '/contactSeller/:id',
      breadcrumbName: 'contactSeller',
      component: createLazyComponent(() => import('./ContactSeller')
      )
    },
    seller: {
      name: 'contactSeller',
      path: pageRoot + '/seller',
      breadcrumbName: 'seller',
      component: createLazyComponent(() => import('./Seller')
      )
    }
  };
  const marketRoutes = [
    routeEntities.impcat,
    routeEntities.category,
    routeEntities.search,
    routeEntities.productPage,
    routeEntities.contactSeller,
    routeEntities.seller,
    routeEntities.home,
  ];
  const PageProvider = PageContext.Provider
  const routes = marketRoutes.map(({ render, component, ...rest }) => {
    const result = { ...rest }
    if (render) {
      result.render = (...args) => {
        return <PageProvider value={{ pageRoot, routeEntities, routes: marketRoutes }}>
          {render(args)}
        </PageProvider>;
      };
    }
    if (component) {
      const C = component;
      result.component = (props) => {
        return <PageProvider value={{ pageRoot, routeEntities, routes: marketRoutes }}>
          <C {...props} />
        </PageProvider>;
      };
    }
    return result
  });
  return {
    entities: routeEntities,
    routes
  };
};
export default createMarketRoutes
