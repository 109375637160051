import { createStore } from 'components/RichEditor/utils'
import React from 'react'
import Toolbar from './components/Toolbar'
export default (config = {}) => {
  const defaultPostion = 'left'
  const store = createStore({
    isVisible: false
  })

  const {
    position = defaultPostion
  } = config

  const SideToolbar = props => {
    return (
      <Toolbar
        {...props}
        store={store}
        position={position}
      />
    )
  }

  return {
    initialize: ({
      setEditorState,
      getEditorState,
      getEditorRef
    }) => {
      store.updateItem(
        'getEditorState',
        getEditorState
      )
      store.updateItem(
        'setEditorState',
        setEditorState
      )
      store.updateItem(
        'getEditorRef',
        getEditorRef
      )
    },
    // Re-Render the toolbar on every change
    onChange: editorState => {
      store.updateItem(
        'editorState',
        editorState
      )
      return editorState
    },
    SideToolbar
  }
}
