import {
  message,
  notification
} from 'antd'
import Null from 'components/Null'
import { createOnError } from 'helpers'
import { compose } from 'redux'
import useDispatchAsyncAction from './useDispatchAsyncAction'

function useDispatchAsyncActionWithNotify({
  onSuccess = Null,
  onError = Null,
  disableNotify = false
} = {}) {
  const [
    data,
    handleDispatchAsyncAction
  ] = useDispatchAsyncAction({
    onSuccess: compose(
      onSuccess,
      (...args) => {
        !disableNotify &&
          message.success('success')
        return args
      }
    ),
    onError: compose(
      onError,
      createOnError()
    )
  })
  return [
    data,
    handleDispatchAsyncAction
  ]
}
export function useDispatchAsyncOnlyActionWithNotify({
  onSuccess = Null,
  onError = Null,
  disableNotify = false
} = {}) {
  const [
    data,
    handleDispatchAsyncAction
  ] = useDispatchAsyncAction({
    onSuccess: compose(
      onSuccess,
      (...args) => {
        !disableNotify &&
          message.success('success')
        return args
      }
    ),
    onError: compose(
      onError,
      (...args) => {
        if (!disableNotify) {
          const [
            error,
            errorObj = {
              error_code:
                'System error!',
              error: '-1'
            }
          ] = args
          notification.error({
            message:
              typeof errorObj.error_code ===
              'string'
                ? errorObj.error_code
                : JSON.stringify(
                    errorObj.error_code
                  ),
            description:
              typeof errorObj.error ===
              'string'
                ? errorObj.error
                : JSON.stringify(
                    errorObj.error
                  ),
            duration: 3,
            placement: 'topRight'
          })
        }

        return args
      }
    )
  })
  return handleDispatchAsyncAction
}
export default useDispatchAsyncActionWithNotify
