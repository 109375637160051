import React from 'react'


export default function GoogleLoginBtn({
  onSuccess
}) {
  const responseGoogleViaMob = () => {
    window.plugins.googleplus.login(
      {
        // scopes:
        //   'profile email openid', // optional, space-separated list of scopes, If not included or empty, defaults to `profile` and `email`.
        // webClientId: GG_ID,
        // offline: true // optional, but requires the webClientId - if set to true the plugin will also return a serverAuthCode, which can be used to grant offline access to a non-Google server
      },
      obj => {
        // alert(JSON.stringify(obj)) // do something useful instead of alerting
        if (obj['accessToken']) {
          onSuccess({
            token: obj.accessToken,
            provider: 'google'
          })
        }
      },
      msg => {
        alert('error: ' + msg)
      }
    )

  }
  return (
    <button
      className="loginBtn loginBtn--google m-3"
      onClick={
        responseGoogleViaMob
      }
    >
      Google
    </button>
  )
}