import { Modal } from 'antd'
import useTranslate from 'modules/local/useTranslate'
import { NavigationContext } from 'modules/navigation/NavigationContext'
import React from 'react'
import { connect } from 'react-redux'
import { branch } from 'recompose'
import { compose } from 'redux'
import { getLogin } from 'redux/selectors'
import Login from 'views/Auth/Login'

export const ModalLayout = ({
  name,
  onCancel,
  children
}) => (
  <NavigationContext.Consumer>
    {({ handleGoBack }) => (
      <Modal
        style={{ top: 0 }}
        title={name}
        header={null}
        footer={null}
        visible={true}
        onCancel={
          onCancel || handleGoBack
        }>
        <div className="h-full md:h-auto">
          {children}
        </div>
      </Modal>
    )}
  </NavigationContext.Consumer>
)
export const ModalLogin = props => {
  const translate = useTranslate()
  return (
    <ModalLayout
      name={translate(
        'you need login first'
      )}
      {...props}>
      <Login />
    </ModalLayout>
  )
}

export const createRequiredLogin = (
  FallBackComponent = ModalLogin
) =>
  compose(
    connect(state => ({
      login: !!getLogin(state)
    })),
    branch(
      props => !props.login,
      props => props => (
        <FallBackComponent {...props} />
      )
    )
  )
export const RequiredLogin = createRequiredLogin()(
  ({ children }) => children
)
