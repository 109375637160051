import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { FeedContext } from './FeedCard'
import { Like } from './Like'

export const Vote = ({
  component: C = Like,
  context = FeedContext
}) => {
  const {
    handleClick,
    vote_status,
    type: owner_type,
    id: owner_id,
    total_votes
  } = useContext(context)
  const timeOut = useRef()
  const [active, setActive] = useState(
    Boolean(vote_status)
  )
  useEffect(() => {
    if (timeOut.current) {
      clearTimeout(timeOut.current)
    }
    setActive(Boolean(vote_status))
    return () => {
      if (timeOut.current) {
        clearTimeout(timeOut.current)
      }
    }
  }, [vote_status])
  return useMemo(
    () => (
      <C
        {...{
          vote_status: active,
          owner_type,
          owner_id,
          total_votes,
          handleVote: () => {
            setActive(!active)
            timeOut.current = setTimeout(
              () => {
                setActive(active)
              },
              1000
            )
            handleClick('vote')
          }
        }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [active, total_votes]
  )
}
