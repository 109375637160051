import Null from 'components/Null'
import { emptyObject } from 'helpers/emptyObjects'
import React from 'react'

export const NavigationContext = React.createContext(
  {
    canBack: false,
    history: emptyObject,
    location: emptyObject,
    mainLocation: emptyObject,
    routesConfig: emptyObject,
    modalRoutesConfig: emptyObject,
    handleGoBack: Null,
    isModal: false
  }
)
