import classNames from 'classnames'
import { emptyArray } from 'helpers/emptyObjects'
import _ from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { Mention } from './Mention'

const testUrl = text =>
  Yup.string()
    .url()
    .isValidSync(text)
const tranformDescriptionText = (
  str = ''
) => {
  const words = str
    .split(' ')
    .reduce((result, word, i) => {
      let start
      let end
      switch (true) {
        case word &&
          word.length > 0 &&
          word[0] === '@':
          start = str.indexOf(word)
          end = start + word.length
          result.push({
            range: [start, end],
            type: 'mention',
            prefix: '@',
            text: _.trim(word, '@')
          })
          break
        case word &&
          word.length > 0 &&
          word[0] === '#':
          start = str.indexOf(word)
          end = start + word.length
          result.push({
            range: [start, end],
            type: 'tag',
            text: word
          })
          break
        case word &&
          word.length > 0 &&
          testUrl(word):
          start = str.indexOf(word)
          end = start + word.length
          result.push({
            range: [start, end],
            type: 'url',
            text: word
          })
          break
        default:
          if (word && word.length > 0) {
            start = str.indexOf(word)
            end = start + word.length
            result.push({
              range: [start, end],
              type: 'text',
              text: word
            })
          }
          break
      }
      return result
    }, [])
  return words
}

export const mapKeywords = (
  children = '',
  keywords = [],
  setKeywords
) => {
  if (!children || children === null)
    return null

  const escapeRegExp = string => {
    return string.replace(
      /[.*+?^${}()|[\]\\]/g,
      '\\$&'
    )
  }

  const text = keywords.reduce(
    (res, keyword) => {
      const arr = _.get(
        keyword,
        'keyword_alt',
        ''
      ).split(',')
      const logRes = res
      for (
        var i = 0;
        i < arr.length;
        i++
      ) {
        const regex = new RegExp(
          ' ' +
            escapeRegExp(
              arr[i].trim() + ' '
            ),
          'i'
        )
        res = res.replace(
          regex,
          ` @keyword:${
            keyword.idname
          }:${encodeURI(
            arr[i].trim()
          )} `
        )
        if (res !== logRes) {
          if (setKeywords) {
            _.pullAt(arr, i)
            keywords = [
              ...keywords.filter(
                e =>
                  e.idname !==
                  keyword.idname
              ),
              {
                ...keyword,
                keyword_alt: arr.join(
                  ', '
                )
              }
            ]
            setKeywords(keywords)
          }
          break
        }
      }

      return res
    },
    children
  )

  return text
}

const Description = ({
  className,
  placeholder = null,
  children = '',
  type = 'channel',
  mentions = emptyArray,
  keywords = emptyArray
}) => {
  if (
    !children ||
    children === null ||
    _.isEmpty(children)
  )
    return placeholder
  const escapeRegExp = string => {
    return string.replace(
      /[.*+?^${}()|[\]\\]/g,
      '\\$&'
    )
  }

  const text = keywords.reduce(
    (res, keyword) => {
      const arr = _.get(
        keyword,
        'keyword_alt',
        ''
      ).split(',')
      const logRes = res
      for (
        var i = 0;
        i < arr.length;
        i++
      ) {
        const regex = new RegExp(
          escapeRegExp(arr[i].trim()),
          'i'
        )
        res = res.replace(
          regex,
          ` @keyword:${
            keyword.idname
          }:${encodeURI(
            arr[i].trim()
          )} `
        )
        if (res !== logRes) {
          break
        }
      }

      return res
    },
    children
  )
  const entities = tranformDescriptionText(
    text
  )

  // let className2 = "";
  // if (formatHeadline && text) {
  //   if (text.toUpperCase() === text) {
  //     className2 = "font-bold";
  //   } else if (text.trim().indexOf(" ") === 1) {
  //     className2 = "";
  //   } else if (text.length < 160
  //     && (text.endsWith("?") || text.endsWith(":"))) {
  //     className2 = "font-bold";
  //   } else if (text.length < 64) {
  //     className2 = "font-semibold";
  //   }
  // }

  return (
    <div
      className={classNames(
        className,
        'overflow-x-hidden whitespace-pre-line'
      )}>
      {entities.map((entity, i) => {
        return (
          <React.Fragment key={i}>
            {(() => {
              switch (true) {
                case entity &&
                  entity.type ===
                    'text':
                  return entity.text
                case entity &&
                  entity.type ===
                    'mention':
                  return (
                    <Mention
                      prefix={
                        entity.prefix
                      }
                      mentions={
                        mentions
                      }>
                      {entity.text}
                    </Mention>
                  )
                case entity &&
                  entity.type === 'tag':
                  return (
                    <Link
                      className="italic hover:underline color-primary"
                      to={`/discovery?type=${type}&keyword=${entity.text.substring(
                        1
                      )}`}>
                      {entity.text}
                    </Link>
                  )
                case entity &&
                  entity.type === 'url':
                  return (
                    <a
                      className="italic hover:underline color-primary"
                      target="_blank"
                      href={
                        entity.text
                      }>
                      {entity.text}
                    </a>
                  )
                default:
                  return JSON.stringify(
                    entity
                  )
              }
            })()}
            {i < entities.length - 1 &&
              ' '}
          </React.Fragment>
        )
      })}
    </div>
  )
}
export default Description
