import React from 'react';
import {Block} from './Block';
import Null from "../../../components/Null";

export const EditAbleBlockHolder = ({
                                      icon = Null,
                                      title,
                                      description,
                                      onClick
                                    }) => (
  <Block className="border border-dashed border-blue-300 hover:border-blue-600 cursor-pointer ">
    <div
      onClick={onClick}
      className="flex w-full flex flex-col px-4 py-5 items-center opacity-75 ">
      {!!icon && icon()}
      <div className="font-bold text-xl">
        {title}
      </div>
      <div className="pt-3 font-semibold">
        {description}
      </div>
    </div>
  </Block>
);
