import { Breadcrumb, Button, Space } from 'antd'
import _ from 'lodash'
import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { create_product_getById_Api } from '../../../apis'
import { baseModel, organizationModel, productModel } from '../../../apis/model'
import { productSchema } from '../../../apis/schema'
import { emptyArray } from '../../../helpers/emptyObjects'
import { AsyncWithCahce } from '../../../modules/asyncCache/components/Async'
import useTranslate from '../../../modules/local/useTranslate'
import { useSelectEntities } from '../../../redux/entities/useSelectEntities'
import formatCurrency from '../../../views/Billing/components/formatCurrency'
import { OrganizationContext } from '../OrganizationContext'

export function Product() {
  const { productId } = useParams()
  const t = useTranslate()
  const {
    groups = emptyArray,
    organization
  } = useContext(OrganizationContext)
  const product = useSelectEntities(
    productId,
    productSchema
  )
  return (
    <>
      <AsyncWithCahce
        cacheId={productId}
        apiInfo={create_product_getById_Api()}
        query={{
          ':id': productId
        }}></AsyncWithCahce>
      <div className="grid grid-cols-12 gap-3 p-3">
        <div className="col-span-12">
          <Breadcrumb>
            <Breadcrumb.Item>
              {organizationModel.getTitle(
                organization
              )}
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {t('products')}
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {productId}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div
          style={{
            marginTop: '-50px',
            paddingTop: '50px'
          }}
          className="col-span-2 hidden lg:block sticky top-0 self-start">
          <nav className=" border  border-gray-300 rounded text-sm ">
            <div className="px-2 background-200 py-1">
              {t('product catalogs')}
            </div>
            {groups.map((o, i) => (
              <div
                key={i}
                className="space-y-1 p-2 flex flex-col">
                <a
                  href={'#' + o.title}
                  className="font-bold hover:underline">
                  {o.title}
                </a>
                {o.products.map(
                  (p, i) => (
                    <a
                      key={
                        '#' + p.idname
                      }
                      href={
                        '#' + p.idname
                      }
                      className=" hover:underline">
                      {p.title}
                    </a>
                  )
                )}
              </div>
            ))}
          </nav>
        </div>
        <div className="col-span-12 lg:col-span-10 space-y-4">
          {product && (
            <div
              key={productModel.getId(
                product
              )}
              className="col-span-12">
              <div className="relative grid grid-cols-12 gap-1 sm:gap-3 lg:gap-4">
                <div className="col-span-12 sm:col-span-6 lg:col-span-5">
                  <div
                    style={{
                      paddingTop: '100%'
                    }}
                    className="w-full relative border border-gray-300">
                    <div className="absolute w-full h-full top-0 left-0 flex flex-col">
                      <img
                        className="object-cover w-full h-full flex-1"
                        src={_.get(
                          product,
                          'photos.0.path'
                        )}
                      />
                      <div className="w-full">
                        {new Array(6).fill(<div className="w-1/6 background-200" style={{ paddingTop: '100%' }}></div>)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-12 sm:col-span-6 lg:col-span-7">
                  <div
                    style={{
                      height: '100%'
                    }}
                    className=" w-full">
                    <div className="w-full">
                      <h2 className="m-0 text-3xl font-bold">
                        {product.title}
                      </h2>
                      <div>
                        <b className="text-lg">
                          {formatCurrency(
                            product.price
                          )}
                        </b>
                        /
                        {
                          product.unit_name
                        }{' '}
                        <Button
                          href="#"
                          type="link"
                          className="bold text-blue-600 underline font-bold">
                          {t(
                            'Get best price'
                          )}
                        </Button>
                      </div>
                    </div>
                    <div className="w-full py-2 ">
                      <Space
                        direction="vertical"
                        className="w-full">
                        <div className="w-full flex flex-col">
                          {productModel
                            .get(
                              product,
                              'attributes',
                              emptyArray
                            )
                            .filter(
                              (
                                item,
                                i
                              ) => i < 4
                            )
                            .map(
                              (
                                attr,
                                i
                              ) => (
                                <div
                                  key={
                                    i
                                  }
                                  className="grid grid-cols-12 gap-4 border-t border-gray-300">
                                  <div className="col-span-6 text-gray-600">
                                    {baseModel.get(
                                      attr,
                                      'attribute_name'
                                    )}
                                  </div>
                                  <div className="col-span-6 ">
                                    {baseModel.get(
                                      attr,
                                      'attribute_value'
                                    )}
                                  </div>
                                </div>
                              )
                            )}
                        </div>
                      </Space>
                    </div>
                    <a
                      href="#detail"
                      className="color-primary ">
                      {t(
                        'View complete detail'
                      )}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
