import moment from 'moment'
import React from 'react'
const momentCalendar = timestamp =>
  moment(timestamp).calendar()
const Timestamp = React.memo(
  ({
    timestamp,
    children,
    className
  }) => {
    return (
      <time
        className={className}
        dateTime={timestamp}>
        {momentCalendar(
          timestamp || children
        )}
      </time>
    )
  }
)
export default Timestamp
