import {
  Button,
  Radio,
  Spin
} from 'antd'
import {
  billing_classSubscriptionViaGateway_Api,
  billing_getStaticFeaturesOfClass_Api,
  billing_selectSubscription_Api,
  billing_subscribeTrialClass_Api
} from 'apis'
import classnames from 'classnames'
import { emptyArray } from 'helpers/emptyObjects'
import _ from 'lodash'
import { createAsyncAction } from 'modules/asyncCache'
import useAsync from 'modules/asyncCache/useAsync'
import useDispatchAsyncActionWithNotify from 'modules/asyncCache/useDispatchAsyncActionWithNotify'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { links } from '../routes'
import CurrencyAmount from './CurrencyAmount'

const modes = {
  TRAIL: 'TRAIL',
  CHANGE_CLASS: 'CHANGE_CLASS',
  CHANGE_CLASS_DEPOSIT_REQUIRED:
    'CHANGE_CLASS_DEPOSIT_REQUIRED'
}

const createTrailAction = code =>
  createAsyncAction({
    apiInfo: billing_subscribeTrialClass_Api,
    query: {
      ':code': code
    }
  })
const createSubscriptionAction = code =>
  createAsyncAction({
    apiInfo: billing_selectSubscription_Api,
    query: {
      ':code': code
    }
  })
const createPayAndSubscriptionAction = (
  code,
  amount
) =>
  createAsyncAction({
    apiInfo: billing_classSubscriptionViaGateway_Api,
    query: {
      ':code': code
    },
    values: {
      amount: amount
    }
  })

export const ConfirmPlanAmount = ({
  planId,
  amount,
  canTrail
}) => {
  const [select, setSelect] = useState(
    amount
      ? modes.CHANGE_CLASS_DEPOSIT_REQUIRED
      : modes.CHANGE_CLASS
  )
  const {
    response,
    isLoading
  } = useAsync({
    apiInfo: billing_getStaticFeaturesOfClass_Api
  })
  const plans = _.get(
    response,
    'data.data',
    emptyArray
  )
  const plan =
    plans.find(
      item => item.id == planId
    ) || {}
  const history = useHistory()

  const [
    data,
    dispath
  ] = useDispatchAsyncActionWithNotify({
    onSuccess: (...args) => {
      switch (select) {
        case modes.TRAIL:
          history.push(
            links.manageSubscriptions
          )
          break

        case modes.CHANGE_CLASS:
          history.push(
            links.manageSubscriptions
          )
          break
        case modes.CHANGE_CLASS_DEPOSIT_REQUIRED:
          console.log(args)
          const gateway_checkout_url = _.get(
            args,
            '[0][1].response.data.checkout_url'
          )
          if (gateway_checkout_url) {
            window.location.href = gateway_checkout_url
          }
          break
        default:
          break
      }
    }
  })
  const handSubmitaction = () => {
    switch (select) {
      case modes.TRAIL:
        dispath(
          createTrailAction(planId)
        )
        break
      case modes.CHANGE_CLASS_DEPOSIT_REQUIRED:
        dispath(
          createPayAndSubscriptionAction(
            planId,
            amount
          )
        )
        break
      case modes.CHANGE_CLASS:
        dispath(
          createSubscriptionAction(
            planId
          )
        )
        break
      default:
        break
    }
  }
  const t = useTranslate()
  return (
    <Spin spinning={isLoading}>
      <div
        style={{ fontWeight: 500 }}
        className="w-full text-gray-800 verticalList__large max-w-md mx-auto ">
        <h2 className="text-4xl text-center block font-bold">
          {t('upgrade to {name}', {
            name: t(plan.name)
          })}
        </h2>
        <Radio.Group
          onChange={e => {
            setSelect(e.target.value)
          }}
          value={select}>
          <div className="verticalList">
            {!!amount && (
              <label
                htmlFor={
                  modes.CHANGE_CLASS_DEPOSIT_REQUIRED
                }
                className={classnames(
                  'cursor-pointer transition-colors duration-300 flex p-4 hover:shadow rounded-lg border-2 border-transparent background-100',
                  1 === select
                    ? 'border-green-300'
                    : 'border-transparent'
                )}>
                <Radio
                  id={
                    modes.CHANGE_CLASS_DEPOSIT_REQUIRED
                  }
                  value={
                    modes.CHANGE_CLASS_DEPOSIT_REQUIRED
                  }
                />
                <div className="verticalList__small ml-3">
                  <div className="">
                    <div className="">
                      {t('pay')}{' '}
                      <span className="color-primary font-bold">
                        <CurrencyAmount>
                          {amount}
                        </CurrencyAmount>
                      </span>{' '}
                      {t(
                        'to upgrade. This subscription cost you'
                      )}{' '}
                      <span className="color-primary font-bold">
                        <CurrencyAmount>
                          {plan.price}
                        </CurrencyAmount>
                      </span>{' '}
                      {t(
                        plan.default_billing_period
                      )}
                    </div>
                  </div>
                </div>
              </label>
            )}
            {!amount && (
              <label
                htmlFor={
                  modes.CHANGE_CLASS
                }
                className={classnames(
                  'cursor-pointer transition-colors duration-300 flex p-4 hover:shadow rounded-lg border-2 border-transparent background-100',
                  1 === select
                    ? 'border-green-300'
                    : 'border-transparent'
                )}>
                <Radio
                  id={
                    modes.CHANGE_CLASS
                  }
                  value={
                    modes.CHANGE_CLASS
                  }
                />
                <div className="verticalList__small ml-3">
                  <div className="">
                    <div className="">
                      {t(
                        'upgrade now. This subscription cost you'
                      )}{' '}
                      <span className="color-primary font-bold">
                        <CurrencyAmount>
                          {plan.price}
                        </CurrencyAmount>
                      </span>{' '}
                      {t(
                        plan.default_billing_period
                      )}
                    </div>
                  </div>
                </div>
              </label>
            )}
            {canTrail && (
              <label
                htmlFor={modes.TRAIL}
                className={classnames(
                  'cursor-pointer transition-colors duration-300 flex p-4 hover:shadow rounded-lg border-2 border-transparent background-100',
                  0 === select
                    ? 'border-green-300'
                    : 'border-transparent'
                )}>
                <Radio
                  id={modes.TRAIL}
                  value={modes.TRAIL}
                />
                <div className="verticalList__small ml-3">
                  <div className="">
                    <div className="font-bold">
                      {t(
                        'start your no-cost {subscription} trial today',
                        {
                          subscription: t(
                            plan.name
                          )
                        }
                      )}
                    </div>
                    <div className="text-gray-600">
                      {t(
                        "we will upgrade you to {subscription} immediately, you won't have to pay anything until your trial ends",
                        {
                          subscription: t(
                            plan.name
                          )
                        }
                      )}
                    </div>
                  </div>
                  <div>
                    <span className="font-bold">
                      {t(
                        'after your trial ends'
                      )}
                    </span>{' '}
                    <span className="text-gray-600">
                      {t(
                        'add payment to keep using {subscription} or change back to free',
                        {
                          subscription: t(
                            plan.name
                          )
                        }
                      )}
                      .
                    </span>
                  </div>
                </div>
              </label>
            )}
          </div>
        </Radio.Group>
        <div className="text-gray-600 text-sm">
          {t(
            'By clicking Submit, you agree to the policy of FeedIn {term1} and {term2}.',
            {
              term1: (
                <a
                  className="hover:underline color-primary"
                  href="#">
                  {t(
                    'terms of service'
                  )}
                </a>
              ),
              term2: (
                <a
                  className="hover:underline color-primary"
                  href="#">
                  {t('privacy policy')}
                </a>
              )
            }
          )}
        </div>
        <div className="flex justify-end">
          <Link to={links.changePlan}>
            <Button
              type="ghost"
              className="mr-3">
              {t('back')}
            </Button>
          </Link>
          <Button
            loading={data.isLoading}
            onClick={() => {
              handSubmitaction()
            }}
            type="primary">
            {t('submit')}
          </Button>
        </div>
      </div>
    </Spin>
  )
}

export default ConfirmPlanAmount
