import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined'
import classNames from 'classnames'
import copyToClipboard from 'copy-to-clipboard'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, { useContext, useState } from 'react'
import { FaAngleDoubleRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { paths } from 'views/News/contains'
import ImageLoader from '../ImageLoader'
import Description, { mapKeywords } from './Description'
import { FeedContext } from './FeedCard'

export function LinkfyEmbed({
  title,
  url,
  subTitle,
  description,
  categories = [],
  image,
  style = {},
  className = ''
}) {
  return (
    <div
      style={{
        borderRadius: '0.3em',
        ...style
      }}
      className={classNames(
        'background-100 cursor-pointer p-2',
        className
      )}>
      <div className="flex overflow-hidden w-full flex-wrap">
        <div
          style={{ minWidth: '200px' }}
          className="break-words flex-1 mb-4 flex-col w-full">
          {subTitle && (
            <div className="inline-flex items-start w-full">
              <img
                style={{
                  verticalAlign: 'sub',
                  margin: 0,
                  marginTop: '0.2em'
                }}
                className="inline pr-1 object-contain m-0 flex-shrink-0"
                alt={title}
                src={`https://www.google.com/s2/favicons?domain=${subTitle}`}
              />
              <span className="truncate w-0 flex-1 text-gray-600 hover:underline leading-tight">
                {subTitle}
              </span>
            </div>
          )}
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer nofollow"
            className="font-semibold hover:underline color-primary">
            {title}
          </a>
          <div className="leading-snug">
            {description}
          </div>
          <div className="text-xs text-color-200 leading-none">
            {categories.map(e => (
              <Link
                key={e.id}
                className="leading-none"
                to={
                  paths.categoryPath
                    .replace(
                      ':category',
                      e.id
                    )
                    .replace(
                      ':refcategory',
                      e.alt_idname
                    ) +
                  '?lang=' +
                  e.language
                }>
                {' '}
                • {e.name}
              </Link>
            ))}
          </div>
        </div>
        {!!image && (
          <ImageLoader
            style={{
              minWidth: '160px',
              objectFit: 'cover',
              flex: 1,
              // height: '100%',
              margin: '0 0 6px 6px',
              float: 'right'
            }}
            src={image}
          />
        )}
      </div>
    </div>
  )
}

export function BaseEmbed({
  title,
  description,
  keywords = [],
  categories = [],
  image
}) {
  const { url: linkToUrl } = useContext(
    FeedContext
  )

  return (
    <div
      style={{
        borderRadius: '0.3em'
      }}
      className="background-100 p-2">
      <div className="flex overflow-hidden">
        <div className="break-words flex-1 mb-4 flex-col verticalList__small w-full">
          {!!image && (
            <Link
              to={linkToUrl}
              style={{
                minWidth: '100px',
                maxWidth: '30%',
                maxHeight: '20vw',
                minHeight: '70px',
                objectFit: 'cover',
                flexShrink: '0',
                margin: '0 0 6px 6px',
                float: 'right'
              }}
              className="w-40 rounded background-100 overflow-hidden h-32 flex-shrink-0 mt-2">
              <ImageLoader
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
                alt={title}
                src={image}
              />
            </Link>
          )}
          <h3 className="mt-0">
            <Link
              to={linkToUrl}
              className="font-semibold hover:underline color-primary text-lg leading-6">
              {title}
            </Link>
          </h3>

          {/*<div className="leading-snug">            */}
          {/*{description}*/}
          {/*</div>*/}
          <Description
            className="leading-snug"
            ghostbav>
            {mapKeywords(
              description,
              keywords
            )}
          </Description>
          <div className="text-xs leading-none">
            {categories.map(e => (
              <Link
                key={e.id}
                className="leading-none"
                to={
                  paths.categoryPath
                    .replace(
                      ':category',
                      e.id
                    )
                    .replace(
                      ':refcategory',
                      e.alt_idname
                    ) +
                  '?lang=' +
                  e.language
                }>
                {' '}
                • {e.name}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export function DetailEmbed({
  id,
  title,
  url,
  subTitle,
  description,
  categories = [],
  image,
  content,
  keywords = [],
  others = []
}) {
  const t = useTranslate()
  const [
    toggleLinkView,
    setToggleLinkView
  ] = useState(false)
  const _renderContentItem = (
    it,
    i
  ) => {
    switch (_.get(it, 'type', 'p')) {
      case 'img':
        return !_.has(
          it,
          'url'
        ) ? null : (
            <div
              className="py-4 w-full"
              key={i}>
              <img
                className="w-full object-cover bg-no-repeat"
                src={_.get(it, 'url')}
                alt={_.get(it, 'alt', '')}
              />
              {_.has(it, 'value') && (
                <div className="flex text-gray-600 text-base italic">
                  {_.get(it, 'value')}
                </div>
              )}
            </div>
          )
      case 'html':
        return !_.has(
          it,
          'value'
        ) ? null : (
            <div
              className="py-2 w-full flex"
              key={i}>
              <div
                className="flex-1 fix-table w-0 overflow-auto"
                dangerouslySetInnerHTML={{
                  __html: _.get(
                    it,
                    'value'
                  )
                }}
                key={i}></div>
            </div>
          )
      case 'h':
        return !_.has(
          it,
          'value'
        ) ? null : (
            <Description
              className="py-2 font-semibold"
              ghostbav
              key={i}>
              {mapKeywords(
                _.get(it, 'value', ''),
                keywords
              )}
            </Description>
          )
      default:
        return !_.has(
          it,
          'value'
        ) ? null : (
            <Description
              className="py-2"
              ghostbav
              key={i}>
              {mapKeywords(
                _.get(it, 'value', ''),
                keywords
              )}
            </Description>
          )
    }
  }

  return (
    <div>
      <div className="md:border md:background-200 md:border-gray-300 rounded-md md:p-3">
        <div className="flex overflow-hidden">
          <div className="break-words flex-1 flex-col verticalList__small">
            <h1 className="font-semibold color-primary md:text-lg text-2xl leading-snug">
              {title}
            </h1>
            <div className="leading-snug md:text-base text-xl my-3">
              {description}
            </div>
            <div className="text-xs leading-none">
              {categories.map(e => (
                <Link
                  key={e.id}
                  className="leading-none"
                  to={
                    paths.categoryPath
                      .replace(
                        ':category',
                        e.id
                      )
                      .replace(
                        ':refcategory',
                        e.alt_idname
                      ) +
                    '?lang=' +
                    e.language
                  }>
                  {' '}
                  • {e.name}
                </Link>
              ))}
            </div>
            {!!image && (
              <div
                style={{
                  width: '100%',
                  overflow: 'hidden',
                  margin: 'auto',
                  position: 'relative',
                  display:
                    'inline-block'
                }}>
                <ImageLoader
                  alt={
                    (
                      keywords || []
                    ).reduce(function (
                      r,
                      e
                    ) {
                      r +=
                        e.keyword + ', '
                      return r
                    },
                      '') + 'by FeedIn'
                  }
                  style={{
                    verticalAlign:
                      'middle',
                    maxWidth: '100%'
                  }}
                  src={image}
                />
              </div>
            )}
          </div>
        </div>
        <React.Fragment>
          {others
            .slice(0, 2)
            .map((e, i) => (
              <h3 key={i}>
                <Link
                  key={i}
                  to={{
                    pathname: `/news/${e._type}/${e.id}`
                  }}
                  className="px-2 pt-2 flex items-start justify-start text-gray-600 background-100 leading-5 md:text-base text-lg">
                  <span className="px-2">
                    <FaAngleDoubleRight />
                  </span>
                  {_.truncate(
                    _.get(
                      e,
                      'overview.title'
                    ),
                    {
                      length: 160
                    }
                  )}
                </Link>
              </h3>
            ))}
        </React.Fragment>

        {!_.isEmpty(content) && (
          // (!toggleContentView ? (
          //   <React.Fragment>
          //     <div className="whitespace-pre-wrap p-3 background-100 leading-7 md:text-base text-xl w-full">
          //       {content
          //         .slice(0, 3)
          //         .map((it, i) => {
          //           return _renderContentItem(
          //             it,
          //             i
          //           )
          //         })}
          //     </div>
          //     <div className="relative">
          //       <div
          //         style={{
          //           backgroundImage:
          //             'linear-gradient(to bottom, rgba(255, 255, 255, 0) 20%, var(--background) 100%)'
          //         }}
          //         className="absolute bottom-0 p-3 left-0 w-full h-32 flex items-end justify-center">
          //         <Count
          //           onClick={() => {
          //             setCookie(
          //               'is-all-content-' +
          //                 id,
          //               true
          //             )
          //             setTimeout(
          //               setToggleContentView(
          //                 !toggleContentView
          //               ),
          //               300
          //             )
          //           }}>
          //           <div className="font-bold uppercase text-xl underline text-green-600">
          //             {t('read more')}
          //           </div>
          //         </Count>
          //       </div>
          //     </div>
          //   </React.Fragment>
          // ) : (
          //   <div>
          //     <div className="whitespace-pre-wrap p-3 background-100 leading-7 md:text-base text-xl w-full">
          //       {content.map(
          //         (it, i) => {
          //           return _renderContentItem(
          //             it,
          //             i
          //           )
          //         }
          //       )}
          //     </div>
          //   </div>
          // ))
          <div>
            <div className="whitespace-pre-wrap p-3 background-100 leading-7 md:text-base text-xl w-full">
              {content.map((it, i) => {
                return _renderContentItem(
                  it,
                  i
                )
              })}
            </div>
          </div>
        )}
      </div>

      {/*{!!toggleContentView && (*/}
      <div className="flex my-3">
        <div className="flex-1" />
        <div className="flex mx-2 background-200 border rounded-full justify-center items-center ">
          <div className="italic text-gray-600 px-2">
            {t('source')}:
          </div>
          <div className="font-bold pr-2">
            {subTitle}
          </div>
          {!toggleLinkView && (
            <div
              onClick={() => {
                copyToClipboard(url)
                setToggleLinkView(true)
              }}
              className="px-2 cursor-pointer italic text-sm">
              <QuestionCircleOutlined />
            </div>
          )}
        </div>
      </div>
      {/*)}*/}

      {toggleLinkView && (
        <div className="whitespace-normal my-3 text-gray-600 text-sm italic">
          {t('source')}
          {': '}
          <span className="hover:underline">
            {url}
          </span>
        </div>
      )}
    </div>
  )
}

export const Keywords = ({
  keywords = null
}) => {
  const t = useTranslate()
  return !!keywords &&
    !!keywords.length ? (
      <div id="keywords">
        <span className="text-gray-600 font-bold">
          {t('keywords')}:{' '}
        </span>
        {keywords.map((k, i) => (
          <React.Fragment key={i}>
            <Link
              className="font-bold color-primary hover:underline"
              to={`/t/${k.idname}`}>
              {k.keyword}
            </Link>
            {i < keywords.length - 1
              ? ', '
              : ''}
          </React.Fragment>
        ))}
      </div>
    ) : null
}
