import {
  BlockquoteButton,
  CodeBlockButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  OrderedListButton,
  UnorderedListButton
} from 'draft-js-buttons'
// @ts-ignore
import DraftOffsetKey from 'draft-js/lib/DraftOffsetKey'
import React, {
  useEffect,
  useMemo,
  useState
} from 'react'
import BlockTypeSelect from '../BlockTypeSelect'

export default function Toolbar(props) {
  const {
    position,
    store,
    children = externalProps => [
      <HeadlineOneButton
        {...externalProps}
      />,
      <HeadlineTwoButton
        {...externalProps}
      />,
      <BlockquoteButton
        {...externalProps}
      />,
      <CodeBlockButton
        {...externalProps}
      />,
      <UnorderedListButton
        {...externalProps}
      />,
      <OrderedListButton
        {...externalProps}
      />
    ]
  } = props

  const [
    offsetKey,
    setoffsetKey
  ] = useState()
  const [show, setShow] = useState(
    false
  )
  const [top, setTop] = useState()
  const [
    editorRoot,
    seteditorRoot
  ] = useState()
  const left = useMemo(() => {
    if (!editorRoot) return 0
    return position === 'right'
      ? editorRoot.offsetLeft +
          editorRoot.offsetWidth +
          80
      : editorRoot.offsetLeft - 40
  }, [editorRoot, position])
  useEffect(() => {
    const onEditorStateChange = editorState => {
      if (!editorState) return
      const selection = editorState.getSelection()
      if (!selection.getHasFocus()) {
        setShow(false)
      }

      const currentContent = editorState.getCurrentContent()
      const currentBlock = currentContent.getBlockForKey(
        selection.getStartKey()
      )
      if (
        currentBlock &&
        currentBlock.getType() ===
          'unstyled' &&
        currentBlock.getText() === ''
      ) {
        setShow(true)
      } else {
        setShow(false)
      }
      setTimeout(() => {
        const offsetKey = DraftOffsetKey.encode(
          currentBlock.getKey(),
          0,
          0
        )
        setoffsetKey(offsetKey)
        const editorRef = store.getItem(
          'getEditorRef'
        )()

        if (!editorRef) return null
        let editorRoot =
          editorRef.refs &&
          editorRef.refs.editor
            ? editorRef.refs.editor
            : editorRef.editor
        while (
          editorRoot.className.indexOf(
            'DraftEditor-root'
          ) === -1
        ) {
          editorRoot =
            editorRoot.parentNode
        }
        seteditorRoot(editorRoot)
      }, 0)
    }
    store.subscribeToItem(
      'editorState',
      onEditorStateChange
    )
    return () => {
      store.unsubscribeFromItem(
        'editorState',
        onEditorStateChange
      )
    }
  }, [position, store])
  useEffect(() => {
    if (!editorRoot) return
    if (!show) return
    let timeout = setTimeout(() => {
      const node = document.querySelectorAll(
        `[data-offset-key="${offsetKey}"]`
      )[0]
      if (!node) return
      const newtop =
        node.offsetTop +
        editorRoot.offsetTop
      if (newtop !== top) {
        setTop(newtop)
      }
      timeout = setTimeout(() => {
        const node = document.querySelectorAll(
          `[data-offset-key="${offsetKey}"]`
        )[0]
        if (!node) return
        const newtop =
          node.offsetTop +
          editorRoot.offsetTop
        setTop(newtop)
      }, 300)
    }, 0)
    return () => {
      timeout && clearTimeout(timeout)
    }
  }, [editorRoot, offsetKey, show, top])
  return useMemo(
    () => (
      <div
        className="wrapper animated"
        style={{
          display: show
            ? 'flex'
            : 'none',
          transform: show
            ? 'scale(1)'
            : 'scale(0)',
          left: left,
          top: top
        }}>
        <BlockTypeSelect
          getEditorState={store.getItem(
            'getEditorState'
          )}
          setEditorState={store.getItem(
            'setEditorState'
          )}
          childNodes={children}
        />
      </div>
    ),
    [children, left, show, store, top]
  )
}
