import { API_ROOT_URL } from 'envs/_current/config'
import createPath from 'helpers/createPath'
import { createBaseItemSchema, productSchema } from './schema'
import { createListTransform } from './utils/createListTransform'
import { createTransform } from './utils/createTransform'

// Route::post('products/product/add', 'ProductController@store')
export const create_product_create_Api = props => ({
  backend: ``,
  root: API_ROOT_URL,
  path: '/products/product/add',
  method: 'POST',
  fields: [],
  paramsFields: [],
  fieldTypes: {},
  transform: createTransform(
    productSchema,
    'data'
  ),
  ...props
})

// Route::post('products/product/{id}/edit', 'ProductController@update');
// PARAMS:
// title:Gạo 504 (Bổ sung)
// description:Gạo vụ hè thu 2020
// categories:rice-and-by-products-adb84dc1-14bb-11ea-9a48-b64aaf48aa3c
// photos:
// price:25000
// price_unit: VND
// unit: kg
// trademark: VN504
// status:1
// weight: 1000
// active:1
// no_sales:0

export const create_product_update_Api = props => ({
  backend: ``,
  root: API_ROOT_URL,
  path: '/products/product/:id/edit',
  method: 'POST',
  transform: createTransform(
    productSchema,
    'data'
  ),
  ...props
})
export const create_product_updatePrice_Api = props => ({
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/products/product/:id/update-price',
  method: 'POST',
  transform: createTransform(
    productSchema,
    'data'
  ),
  ...props
})
// Route::post('products/product/{id}/delete', 'ProductController@destroy');
export const create_product_delete_Api = props => ({
  backend: ``,
  root: API_ROOT_URL,
  path: '/products/product/:id/delete',
  method: 'POST',
  fields: [],
  paramsFields: [],
  fieldTypes: {},
  transform: createTransform(
    productSchema,
    'data'
  ),
  ...props
})

// Route::get('products/product/{id}/get', 'ProductController@getById');
export const create_product_getById_Api = props => ({
  backend: ``,
  root: API_ROOT_URL,
  path: '/products/product/:id/get',
  method: 'GET',
  fields: [],
  paramsFields: [],
  fieldTypes: {},
  transform: createTransform(
    productSchema,
    'data'
  ),
  ...props
})

// Route::get('products/{type}/{id}/get-products', 'ProductController@getProducts');
export const create_product_getProducts_Api = ({
  query,
  ...props
} = {}) => ({
  backend: ``,
  root: API_ROOT_URL,
  path: createPath({
    path:
      '/products/:type/:id/get-products',
    query
  }),
  method: 'GET',
  fields: [],
  paramsFields: [
    'title',
    'product_catalog_id',
    'categories',
    'active'
  ],
  fieldTypes: {},
  schema: productSchema,
  transform: createListTransform(
    productSchema
  ),
  ...props
})

// Route::get('products/{type}/{id}/get-products-with-price', 'ProductController@getProductsWithPrice');
export const create_product_getProductsWithPrice_Api = ({
                                                          query,
                                                          ...props
                                                        } = {}) => ({
  backend: ``,
  root: API_ROOT_URL,
  path: createPath({
    path:
      '/products/:type/:id/get-products-with-price',
    query
  }),
  method: 'GET',
  fields: [],
  paramsFields: [
    'title',
    'product_catalog_id',
    'categories',
    'active'
  ],
  fieldTypes: {},
  schema: productSchema,
  transform: createListTransform(
    productSchema
  ),
  ...props
})

// Route::post('products/product/{id}/publish', 'ProductController@publish');
export const create_product_publish_Api = ({
  query,
  ...props
} = {}) => ({
  backend: ``,
  root: API_ROOT_URL,
  path: createPath({
    path:
      '/products/product/:id/publish',
    query
  }),
  method: 'POST',
  fields: [],
  fieldTypes: {},
  schema: productSchema,
  transform: createTransform(
    productSchema,
    'data'
  ),
  ...props
})

// Sản phẩm liên quan theo category:
// Route::get('products/product/{id}/get-other-products-by-category', 'ProductController@getOtherProductsByCategory');
export const create_product_getOtherProductsByCategory_Api = ({
  query,
  ...props
} = {}) => ({
  backend: ``,
  root: API_ROOT_URL,
  path: createPath({
    path:
      '/products/product/:id/get-other-products-by-category',
    query
  }),
  method: 'GET',
  schema: productSchema,
  ...props
})

// Sản phẩm liên quan cùng nhà cung cấp
// Route::get('products/product/{id}/get-other-products-by-owner', 'ProductController@getOtherProductsByOwner');
export const create_product_getOtherProductsByOwner_Api = ({
  query,
  ...props
} = {}) => ({
  backend: ``,
  root: API_ROOT_URL,
  path: createPath({
    path:
      '/products/product/:id/get-other-products-by-owner',
    query
  }),
  method: 'GET',
  schema: productSchema,
  ...props
})


// Route::get('products/mart/get-related-products', 'MartController@getRelatedProducts');
export const product_getRelatedProducts_Api = {
  backend: `Route::get('products/mart/get-related-products', 'MartController@getRelatedProducts');`,
  root: API_ROOT_URL,
  path: '/products/mart/get-related-products',
  method: 'GET',
  schema: productSchema,
  transform: createListTransform(
    productSchema,
    'data.data'
  ),
}


export const product_get_default_specifications_Api = {
  root: API_ROOT_URL,
  path:
    '/products/get-default-specifications',
  method: 'GET',
  transform: createListTransform(
    createBaseItemSchema({
      type: 'specifications'
    })
  )
}
