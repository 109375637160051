export const createValue = (
	name,
	value
) => {
	return {
		target: {
			name,
			value
		}
	};
};
