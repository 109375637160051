import { reducerKey } from './const'
import reducer from './reducer'

const createHashFromString = (
  string
) => {
  var hash = 0,
    i,
    chr
  if (string.length === 0) {
    return hash
  }
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

export const createAsyncAction = ({
  asyncId,
  cacheId,
  query,
  values,
  apiInfo,
}) => {
  const start = new Date().getTime()
  const _asyncId =
    asyncId ||
    `${
      apiInfo.path
    }/${createHashFromString(
      `${JSON.stringify({
        start,
        query,
        values,
        path: apiInfo.path,
        method: apiInfo.method,
      })}`
    )}`
  const type = _asyncId
  return {
    type,
    cacheId,
    asyncId: _asyncId,
    start,
    apiInfo,
    query,
    values,
  }
}

const reducers = {
  [reducerKey]: reducer,
}
export { reducers }
