import { message } from 'antd'
import { bookmarks_mark_Api, bookmarks_unMark_Api } from 'apis'
import { chartSchema, datasets_chart_getById_Api } from 'apis/chart'
import { createTransform } from 'apis/utils/createTransform'
import FeedInSvg from 'assets/svgs/feedin.svg'
import ImagesLightBox from 'components/ImagesLightBox/ImagesLightBox'
import { LoginContext } from 'components/LoginContext'
import copyToClipboard from 'copy-to-clipboard'
import { API_ROOT_URL, ROOT_URL } from 'envs/_current/config'
import { createAliasTransform } from 'helpers/createAliasTransform'
import logParams from 'helpers/logParams'
import _ from 'lodash'
import { createAsyncAction } from 'modules/asyncCache'
import { AsyncByAction } from 'modules/asyncCache/components/Async'
import useDispatchAsyncActionWithNotify from 'modules/asyncCache/useDispatchAsyncActionWithNotify'
import useTranslate from 'modules/local/useTranslate'
import { permissionCodes } from 'modules/permissions/contants'
import PermissionProvider from 'modules/permissions/PermissionProvider'
import React, { useContext, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { selectEntities } from 'redux/entities/selectors'
import FullChartModal from 'views/ChartsPlayground/FullChartModal'
import { useVote } from '../Server/ServerContainer'
import { useRequiredLogin } from '../Server/useRequiredLogin'
import { GetChartComponent } from './Charts'

export const ChartContext = React.createContext(
  {}
)
export default ChartContext

const alias = {
  title: ['title', 'name'],
  description: 'description',
  idname: 'idname',
  id: 'id',
  type: '_type'
}

const transformAlias = createAliasTransform(
  alias
)
export const TableChartProvider = props => (
  <ChartProvider
    {...props}
    defaultActions={{
      to: true,
      bookmark: true,
      vote: true,
      view: true,
      share: true,
      copy_link: true,
      capture: false
    }}
  />
)
export const PremiumChartProvider = props => (
  <ChartProvider
    {...props}
    defaultActions={{
      to: true,
      bookmark: true,
      vote: true,
      view: true,
      share: true,
      copy_link: true,
      capture: false
    }}
  />
)
export const ChartProvider = React.memo(
  ({
    item,
    defaultActions = {
      to: true,
      bookmark: true,
      vote: true,
      view: true,
      share: true,
      copy_link: true,
      capture: true,
      upload_avatar: false
    },
    children
  }) => {
    const dispatch2 = useDispatch()
    const t = useTranslate()
    const [hide, setHide] = useState(
      false
    )
    const [
      extend,
      setExtend
    ] = useState(false)
    const login = useContext(
      LoginContext
    )
    const history = useHistory()
    const [
      title,
      description,
      id,
      idname,
      type
    ] = transformAlias(item, [
      'title',
      'description',
      'id',
      'idname',
      'type'
    ])

    const copyright = _.get(
      item,
      'dataset.copyright',
      null
    )

    const [
      ,
      dispatch
    ] = useDispatchAsyncActionWithNotify()

    const vote_status = _.get(
      item,
      'vote_status'
    )
    const bookmark_status = _.get(
      item,
      'bookmark_status'
    )
    const total_votes = _.get(
      item,
      'total_votes'
    )
    const handleVote = useVote({
      vote_status,
      total_votes,
      owner_type: type,
      owner_id: id
    })
    const translate = useTranslate()

    const url = `/chart/${idname}`
    const shareUrl = `${ROOT_URL}${url}`
    const handleRequiredLogin = useRequiredLogin()
    const [modal, setModal] = useState()
    const [
      chartWrap,
      setChartWrap
    ] = useState()

    const dataEdit = _.get(
      item,
      'dataset.edit'
    )
    const dataPremium = _.get(
      item,
      'dataset.premium'
    )
    const actions = !item
      ? { ...defaultActions }
      : !!item &&
        (item.edit ?? dataEdit)
        ? {
          ...defaultActions,
          ...item.available_actions,
          edit: item.edit ?? dataEdit,
          [permissionCodes.canEdit]: dataEdit,
          [permissionCodes.canSubscribe]:
            !dataEdit && !!dataPremium,
          delete: true
        }
        : {
          ...defaultActions,
          ...item.available_actions,
          delete: false
        }
    function handleClick(key) {
      console.log('click', key)
      switch (key) {
        case 'extend':
          setExtend(true)
          break
        case 'to':
          history.push({
            pathname: `/chart/${idname}`,
            state: { isModal: true }
          })
          break
        case 'vote':
          handleVote()

          break
        case 'copy_link':
          copyToClipboard(
            `${ROOT_URL}/chart/${idname}`
          )
          message.info(
            translate('copied')
          )
          break
        case 'rebookmark':
        case 'bookmark':
          handleRequiredLogin(() =>
            dispatch(
              createAsyncAction({
                apiInfo: bookmarks_mark_Api,
                query: {
                  ':container': 'user',
                  ':container_id': _.get(
                    login,
                    'username'
                  ),
                  ':type': type,
                  ':id': id
                }
              })
            )
          )

          break
        case 'unbookmark':
          handleRequiredLogin(() =>
            dispatch(
              createAsyncAction({
                apiInfo: bookmarks_unMark_Api,
                query: {
                  ':container': 'user',
                  ':container_id': _.get(
                    login,
                    'username'
                  ),
                  ':type': type,
                  ':id': id
                },
                onSuccess: () =>
                  setHide(true)
              })
            )
          )

          break
        case 'message':
          break
        case 'capture':
          if (
            Boolean(chartWrap.toDataURL)
          ) {
            const imageUrl = chartWrap.toDataURL()
            if (
              actions.upload_avatar &&
              chartWrap.toBlob
            ) {
              chartWrap.toBlob(blob => {
                dispatch2(
                  createAsyncAction({
                    apiInfo: {
                      // Route::post('datasets/charts/{id}/upload-avatar', 'ChartController@uploadChartPhoto');
                      root: API_ROOT_URL,
                      method: 'POST',
                      path: `/datasets/charts/${id}/upload-avatar`,
                      transform: createTransform(
                        chartSchema,
                        'data'
                      )
                    },
                    values: {
                      avatar: blob
                    }
                  })
                )
              })
            }
            setModal(
              <ImagesLightBox
                images={[imageUrl]}
                onCloseRequest={() =>
                  setModal(undefined)
                }
              />
            )
            return
          }
          if (
            chartWrap &&
            logParams(chartWrap)
          ) {
            switch (chartWrap.type) {
              case 'amchart':
                const amchart = chartWrap.getChart();
                let options = amchart.exporting.getFormatOptions("jpg");
                options.quality = 0.8;
                amchart.exporting.setFormatOptions("jpg", options);

                amchart.exporting
                  .getImage('jpg')
                  .then(function (
                    imgData
                  ) {

                    setModal(
                      <ImagesLightBox
                        images={[
                          imgData
                        ]}
                        onCloseRequest={() =>
                          setModal(
                            undefined
                          )
                        }
                      />
                    );

                    actions.upload_avatar &&
                      fetch(imgData)
                        .then(res =>
                          res.blob()
                        )
                        .then(blob => {
                          setTimeout(
                            () => {
                              dispatch2(
                                createAsyncAction(
                                  {
                                    apiInfo: {
                                      // Route::post('datasets/charts/{id}/upload-avatar', 'ChartController@uploadChartPhoto');
                                      root: API_ROOT_URL,
                                      method:
                                        'POST',
                                      path: `/datasets/charts/${id}/upload-avatar`,
                                      transform: createTransform(
                                        chartSchema,
                                        'data'
                                      )
                                    },
                                    values: {
                                      avatar: blob
                                    }
                                  }
                                )
                              )
                            }
                          )
                        });

                  })

                break
              default:
                let chart = chartWrap.getChart()
                if (!chart) break
                // console.log({ chartWrap, chart })
                let imageURI = chart.getImageURI()
                // console.log({imageURI})
                const img = new window.Image()
                const logo = new window.Image()
                const imgCanvas = document.createElement(
                  'canvas'
                )
                const ctx = imgCanvas.getContext(
                  '2d'
                )
                const source =
                  t('source') +
                  ': ' +
                  copyright

                logo.addEventListener(
                  'load',
                  function () {
                    ctx.globalAlpha = 0.45
                    ctx.drawImage(
                      logo,
                      img.width - 190,
                      img.height - 40,
                      173.876,
                      50
                    )
                    ctx.save()
                  }
                )
                img.addEventListener(
                  'load',
                  function () {
                    imgCanvas.width =
                      img.width
                    imgCanvas.height =
                      img.height + 32
                    ctx.fillStyle =
                      'white'
                    ctx.fillRect(
                      0,
                      0,
                      imgCanvas.width,
                      img.height + 32
                    )
                    ctx.font =
                      '12px Verdana'
                    ctx.fillStyle =
                      'black'
                    ctx.fillText(
                      title,
                      3,
                      14
                    )
                    ctx.fillText(
                      source,
                      3,
                      28
                    )
                    ctx.drawImage(
                      img,
                      0,
                      32
                    )
                    logo.setAttribute(
                      'src',
                      FeedInSvg
                    )
                  }
                )
                img.setAttribute(
                  'src',
                  imageURI
                )

                setTimeout(() => {
                  const image = imgCanvas.toDataURL()
                  actions.upload_avatar &&
                    imgCanvas.toBlob(
                      blob => {
                        dispatch2(
                          createAsyncAction(
                            {
                              apiInfo: {
                                // Route::post('datasets/charts/{id}/upload-avatar', 'ChartController@uploadChartPhoto');
                                root: API_ROOT_URL,
                                method:
                                  'POST',
                                path: `/datasets/charts/${id}/upload-avatar`,
                                transform: createTransform(
                                  chartSchema,
                                  'data'
                                )
                              },
                              values: {
                                avatar: blob
                              }
                            }
                          )
                        )
                      }
                    )
                  setModal(
                    <ImagesLightBox
                      images={[image]}
                      onCloseRequest={() =>
                        setModal(
                          undefined
                        )
                      }
                    />
                  )
                }, 500)
                /// end
                break
            }
          }

          break
        default:
          break
      }
    }

    return useMemo(
      () => {
        return hide ? null : (
          <ChartContext.Provider
            value={{
              handleClick,
              setChartWrap,
              item,
              actions,
              title,
              description,
              type,
              id,
              url,
              shareUrl,
              vote_status,
              bookmark_status,
              total_votes
            }}>
            <PermissionProvider
              permissions={actions}>
              <>
                {children}
                {modal}
                {extend && (
                  <FullChartModal
                    onClose={() =>
                      setExtend(false)
                    }>
                    {({
                      width,
                      height
                    }) => (
                      <GetChartComponent
                        item={item}
                        {...{
                          width,
                          height
                        }}></GetChartComponent>
                    )}
                  </FullChartModal>
                )}
              </>
            </PermissionProvider>
          </ChartContext.Provider>
        )
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        // eslint-disable-next-line react-hooks/exhaustive-deps
        !!item && item,
        extend,
        chartWrap,
        hide,
        modal
      ]
    )
  }
)

export const ChartContainer = ({
  id,
  children
}) => {
  const t = useTranslate()
  const item = useSelector(state =>
    selectEntities(
      state,
      id,
      chartSchema
    )
  )
  const { search } = useLocation()
  return (
    <ChartProvider
      key={id + search}
      item={item}>
      <React.Fragment>
        <AsyncByAction
          action={createAsyncAction(({
            apiInfo: {
              ...datasets_chart_getById_Api,
              path:
                datasets_chart_getById_Api.path +
                search
            }, query: {
              ':id': id
            }
          }))}
        >

        </AsyncByAction>
        {item && children}
      </React.Fragment>
    </ChartProvider>
  )
}
