import { Result } from 'antd'
import { organizationModel } from 'apis/model'
import { createLazyComponent } from 'helpers/createLazyComponent'
import { emptyArray } from 'helpers/emptyObjects'
import PermissionContext from 'modules/permissions/PermissionContext'
import React from 'react'
import { branch, compose, fromRenderProps, nest } from 'recompose'
import { SellerContext } from 'views/Market/Seller/SellerContext'
import Members from 'views/MemberSetting/Members.lazy'
import WaitingAcceptMembers from 'views/MemberSetting/WaitingAcceptMembers.lazy'
import Translate from "../../../modules/local/Translate"
import { About } from './About'
import ChannelSettings from './ChannelSettings.lazy'
import Contacts from "./Contacts/Contacts.lazy"
import DatasetSettings from './DatasetSettings.lazy'
import Delete from './Delete/Delete.lazy'
import InviteManager from './InviteManager'
import Locations from "./Locations/Locations.lazy"
import { OrganizationSettingContext } from './OrganizationSettingContext'
import Overview from './Overview.lazy'
import Photo from "./Photo/Photo.lazy"
import Profile from "./Profile/Profile.lazy"

const createSellerComponent = C =>
  nest(
    fromRenderProps(
      OrganizationSettingContext.Consumer,
      ({ item }) => ({
        value: {
          organization: item,
          organizationId: organizationModel.getId(
            item
          ),
          rootPath: `/organization/${organizationModel.getId(
            item
          )}/setting`
        }
      })
    )(SellerContext.Provider),
    C
  )
export const createCheckPermissions = (
  checkPermissions = emptyArray,
  FallBackComponent = () => (
    <Translate>
      {
        t => (
          <Result
            status="403"
            title={
              t('permission denied')
            }
          />
        )
      }
    </Translate>
  )
) =>
  compose(
    fromRenderProps(
      PermissionContext.Consumer,
      permissions => {
        return {
          denied: checkPermissions.find(
            key =>
              !permissions ||
              !permissions[key]
          )
        }
      }
    ),
    branch(
      ({ denied }) => denied,
      () => FallBackComponent
    )
  )
export const routeEntities = {
  overview: {
    path: '',
    name: 'overview',
    component: compose(
      fromRenderProps(
        OrganizationSettingContext.Consumer,
        props => props
      ),
      createCheckPermissions(['edit'])
    )(Overview),
    exact: true
  },
  about: {
    path: 'about',
    name: 'about',
    component: compose(
      fromRenderProps(
        OrganizationSettingContext.Consumer,
        props => props
      ),
      createCheckPermissions(['edit'])
    )(About),
    exact: true
  },
  locations: {
    path: 'locations',
    name: 'locations',
    component: compose(
      fromRenderProps(
        OrganizationSettingContext.Consumer,
        props => props
      ),
      createCheckPermissions(['edit'])
    )(Locations),
    exact: true
  },
  contacts: {
    path: 'contacts',
    name: 'contacts',
    component: compose(
      fromRenderProps(
        OrganizationSettingContext.Consumer,
        props => props
      ),
      createCheckPermissions(['edit'])
    )(Contacts),
    exact: true
  },
  profiles: {
    path: 'profiles',
    name: 'provider profiles',
    component: compose(
      fromRenderProps(
        OrganizationSettingContext.Consumer,
        props => props
      ),
      createCheckPermissions(['edit'])
    )(Profile),
    exact: true
  },
  photos: {
    path: 'photos',
    name: 'photos',
    component: compose(
      fromRenderProps(
        OrganizationSettingContext.Consumer,
        props => props
      ),
      createCheckPermissions(['edit'])
    )(Photo),
    exact: true
  },
  members: {
    path: 'members',
    name: 'members',
    component: compose(
      fromRenderProps(
        OrganizationSettingContext.Consumer,
        props => props
      ),
      createCheckPermissions(['edit'])
    )(Members),
    exact: true
  },
  waitingMembers: {
    path: 'waiting-members',
    name: 'waiting members list',
    component: compose(
      fromRenderProps(
        OrganizationSettingContext.Consumer,
        props => props
      ),
      createCheckPermissions(['edit'])
    )(WaitingAcceptMembers),
    exact: true
  },
  delete: {
    path: 'delete',
    name: 'delete',
    component: compose(
      fromRenderProps(
        OrganizationSettingContext.Consumer,
        props => props
      ),
      createCheckPermissions(['edit'])
    )(Delete),
    exact: true
  },
  manageDataset: {
    path: 'dataset-manage',
    name: 'dataset management',
    component: compose(
      fromRenderProps(
        OrganizationSettingContext.Consumer,
        props => props
      ),
      createCheckPermissions([
        'dataset_privilege'
      ])
    )(({ item }) => {
      return (
        <DatasetSettings
          {...{
            type: organizationModel.getType(
              item
            ),
            id: organizationModel.getId(
              item
            )
          }}
        />
      )
    })
  },
  managechannel: {
    path: 'channel-manage',
    name: 'channel management',
    component: compose(
      fromRenderProps(
        OrganizationSettingContext.Consumer,
        props => props
      ),
      createCheckPermissions([
        'channel-allowable'
      ])
    )(({ item }) => {
      return (
        <ChannelSettings
          {...{
            type: organizationModel.getType(
              item
            ),
            id: organizationModel.getId(
              item
            )
          }}
        />
      )
    })
  },
  summary: {
    name: 'Verify sales information',
    path: 'summary',
    breadcrumbName: 'Verify sales information',
    exact: true,
    component: createSellerComponent(
      createLazyComponent(() =>
        import(
          'views/Market/Seller/DashBoard'
        )
      )
    )
  },
  manageProducts: {
    name: 'products',
    path: 'manage-products',
    breadcrumbName: 'products',
    exact: true,
    component: createSellerComponent(
      createLazyComponent(() =>
        import(
          'views/Market/Seller/ManageProducts'
        )
      )
    )
  },
  addProduct: {
    name: 'create product',
    path: 'add-product',
    breadcrumbName: 'create product',
    exact: true,
    component: createSellerComponent(
      createLazyComponent(() =>
        import(
          'views/Market/Seller/AddProduct'
        )
      )
    )
  },
  product: {
    name: 'product',
    path: 'product/:id',
    breadcrumbName: 'product',
    component: createSellerComponent(
      createLazyComponent(() =>
        import(
          'views/Market/Seller/Product'
        )
      )
    )
  },
  preview: {
    name: 'preview',
    path: 'preview',
    breadcrumbName: 'preview',
    exact: true,
    component: createSellerComponent(
      createLazyComponent(() =>
        import(
          'views/Market/Seller/Preview'
        )
      )
    )
  },

  businessProfile: {
    name: 'sales information',
    path: 'business-profiles',
    exact: true,
    breadcrumbName: 'sales information',
    component: createSellerComponent(
      createLazyComponent(() =>
        import(
          'views/Market/components/BusinessProfile'
        )
      )
    )
  },

  incomingRequests: {
    name: 'incoming requests',
    path: 'incoming-requests',
    breadcrumbName: 'incoming requests',
    exact: true,
    component: createSellerComponent(
      createLazyComponent(() =>
        import(
          'views/Workspace/RequestsManager'
        )
      )
    )
  },

  messages: {
    name: 'messages',
    path: 'messages',
    breadcrumbName: 'contact messages',
    exact: true,
    component: createSellerComponent(
      createLazyComponent(() =>
        import(
          'views/Market/Seller/Messages'
        )
      )
    )
  },
  invite: {
    path: 'invite',
    name: 'invite',
    component: compose(
      fromRenderProps(
        OrganizationSettingContext.Consumer,
        props => props
      ),
      createCheckPermissions([
        'edit'
      ])
    )(InviteManager),
    exact: true
  }
}
export const routes = [
  routeEntities.overview,
  routeEntities.about,
  routeEntities.contacts,
  routeEntities.locations,
  routeEntities.profiles,
  routeEntities.photos,
  routeEntities.members,
  routeEntities.waitingMembers,
  routeEntities.invite,
  routeEntities.delete,
  routeEntities.manageDataset,
  routeEntities.managechannel,
  routeEntities.summary,
  routeEntities.addProduct,
  routeEntities.manageProducts,
  routeEntities.product,
  routeEntities.preview,
  routeEntities.businessProfile,
  routeEntities.incomingRequests,
  routeEntities.messages
]
