import {
  convertFromRaw,
  EditorState
} from 'draft-js'
import { defaultRawContent } from './RichEditor'
const coverToEditorStateFromRaw = raw => {
  try {
    return EditorState.createWithContent(
      convertFromRaw(raw)
    )
  } catch (error) {
    console.error(error)
    return EditorState.createWithContent(
      convertFromRaw(defaultRawContent)
    )
  }
}
export default coverToEditorStateFromRaw
