import {
  emptyArray,
  emptyObject
} from 'helpers/emptyObjects'
import {
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import { createAsyncAction } from '.'
import { getAsynCacheSelector } from './selectors'
const asynEqual = (pre, curr) =>
  pre.isLoading === curr.isLoading
function useAsync({
  query = emptyObject,
  values,
  deps = emptyArray,
  apiInfo
}) {
  const [
    action = emptyObject,
    setAction
  ] = useState(() => {
    const action = createAsyncAction({
      query,
      apiInfo
    })
    return action
  })

  const selector = useMemo(() => {
    return state =>
      getAsynCacheSelector(
        state,
        action.asyncId
      )
  }, [action.asyncId])
  const {
    isLoading = true,
    success,
    errorMessages,
    result,
    time,
    error,
    ...rest
  } = useSelector(selector, asynEqual)
  const dispatch = useDispatch()
  const handleAsyncAction = useCallback(
    (requiredValues = {}) => {
      const action = createAsyncAction({
        query,
        apiInfo,
        values,
        ...requiredValues
      })
      setAction(action)
      dispatch(action)
      return action
    },
    [apiInfo, dispatch, query, values]
  )
  useEffect(() => {
    if (action && !success) {
      handleAsyncAction()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  const asyncApi = useMemo(
    () => ({
      action,
      isLoading,
      success,
      errorMessages,
      result,
      error,
      time,
      ...rest,
      handleAsyncAction
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, handleAsyncAction]
  )

  return asyncApi
}
export default useAsync
