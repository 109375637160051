import { API_ROOT_URL } from 'envs/_current/config'
import { emptyObject } from 'helpers/emptyObjects'
import _ from 'lodash'
import { createAsyncAction } from 'modules/asyncCache'
import {
  normalize,
  schema
} from 'normalizr'
import { baseFieldTypes } from './base/baseFieldTypes'
import {
  baseItemSchema,
  channelSchema,
  userSchema
} from './schema'
import { createTransform } from './utils/createTransform'

const transform = createTransform(
  channelSchema
)

const transformArray = createTransform([
  channelSchema
])

const fields = [
  'user_id', // auto fill = creator
  'owner_id', // user or organization
  'owner_type', // user or organization
  'categories', // user or organization
  'title', // required|max:100
  'description', //max:10000
  'about', // max:65000
  'idname', // unique:channels,idname
  'avatar', // avatar
  'status', // -1, 1, 9
  'active', // 0 / 1
  'privacy', // 1 / 2
  'public', // 0 / 1
  'exclude_promos' // 0 / 1
]

const fieldTypes = {
  user_id: baseFieldTypes.auto,
  owner_id: baseFieldTypes.string,
  owner_type: baseFieldTypes.string,
  avatar: baseFieldTypes.image,
  thumbnail: baseFieldTypes.auto,
  idname: baseFieldTypes.string
  // status: baseFieldTypes.status,
  // active: baseFieldTypes.active,
  // certified: baseFieldTypes.certified,
  // privacy: baseFieldTypes.privacy,
  // public: baseFieldTypes.public
}

// Route::post('channels/add', 'ChannelController@store');
export const channel_add_Api = {
  backend: `Route::post('channels/add', 'ChannelController@store')`,
  root: API_ROOT_URL,
  path: '/channels/add',
  method: 'POST',
  fields: ['owner_id', 'owner_type'],
  fieldTypes,
  transform: (
    response,
    { values = emptyObject }
  ) => {
    let error,
      errorMessages,
      entities,
      result,
      success
    const { status } = response || {}
    const data = _.get(response, 'data')
    const {
      owner_id,
      owner_type
    } = values
    switch (status) {
      case 200:
        success = true
        const {
          entities: e,
          result: r
        } = normalize(
          data,
          owner_type === 'organization'
            ? new schema.Entity(
              'organizations',
              {
                channels: [
                  channelSchema
                ]
              },
              {
                idAttribute: () =>
                  owner_id,
                processStrategy: entities => {
                  return {
                    idname: owner_id,
                    channels: [data]
                  }
                }
              }
            )
            : new schema.Entity(
              'users',
              {
                channels: [
                  channelSchema
                ]
              },
              {
                idAttribute: () =>
                  owner_id,
                processStrategy: entities => {
                  return {
                    username: owner_id,
                    channels: [data]
                  }
                }
              }
            )
        )
        entities = e
        result = r
        break

      default:
        error = true
        errorMessages = response.data
        break
    }

    return {
      ...response,
      response,
      error,
      errorMessages,
      success,
      status,
      entities,
      result
    }
  }
}

// Route::post('channels/{id}/edit', 'ChannelController@update');
export const channel_edit_Api = {
  backend: `Route::post('channels/{id}/edit', 'ChannelController@update')`,
  root: API_ROOT_URL,
  path: '/channels/:id/edit',
  method: 'POST',
  fields,
  fieldTypes,
  transform: createTransform(
    channelSchema,
    'data'
  )
}
// Route::post('channels/{id}/delete', 'ChannelController@deletePollQuestion');
export const channel_delete_Api = {
  backend: ` Route::post('channels/{id}/delete', 'ChannelController@deletePollQuestion')`,
  root: API_ROOT_URL,
  path: '/channels/:id/delete',
  method: 'POST',
  fields,
  fieldTypes,
  transform
}
// Route::get('channels/get-typical-organizations', 'ChannelController@getTypicalChannels');
export const channel_getTypicalChannels_Api = {
  backend: `Route::get('channels/get-typical-channels', 'ChannelController@getTypicalChannels');`,
  root: API_ROOT_URL,
  path:
    '/channels/get-typical-channels',
  method: 'GET',
  fields,
  fieldTypes,
  transform
}
export const create_channel_getTypicalChannels_Api_action = () => ({
  prefixStr = 'channel_getTypicalChannels_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@channel_getTypicalChannels_Api',
    query,
    values,
    apiInfo: channel_getTypicalChannels_Api,
    maxAge
  })
// Route::get('channels/{id}/get-following-users', 'ChannelController@getFollowingUsers');
export const channel_getFollowingUsers_Api = {
  backend: `Route::get('channels/{id}/get-following-users', 'ChannelController@getFollowingUsers');`,
  root: API_ROOT_URL,
  path:
    '/channels/:id/get-following-users',
  method: 'GET',
  fields,
  schema: userSchema,
  fieldTypes,
  transform: createTransform([
    userSchema
  ])
}
export const create_channel_getFollowingUsers_Api_action = () => ({
  prefixStr = 'channel_getFollowingUsers_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@channel_getFollowingUsers_Api',
    query,
    values,
    apiInfo: channel_getFollowingUsers_Api,
    maxAge
  })
// Route::get('channels/{id}/get', 'ChannelController@getById');
export const channel_getById_Api = {
  backend: `Route::get('channels/{id}/get', 'ChannelController@getById');`,
  root: API_ROOT_URL,
  path: '/channels/:id/get',
  method: 'GET',
  fields,
  fieldTypes,
  transform: createTransform(
    channelSchema,
    'data'
  )
}
// Route::get('channels/{id}/show', 'ChannelController@show');

//  Route::get('channels/{type}/{id}/gets', 'ChannelController@getChannelsByProps')
export const getChannelsByProps_Api = {
  backend: `Route::get('channels/{type}/{id}/gets', 'ChannelController@getChannelsByProps')`,
  root: API_ROOT_URL,
  path: '/channels/:type/:id/gets',
  method: 'GET',
  fields,
  fieldTypes,
  transform: transformArray,
  schema: channelSchema
}
export const getChannelsByOrganization_Api = {
  backend: `Route::get('channels/organization/{id}/gets', 'ChannelController@getChannelsByProps')`,
  root: API_ROOT_URL,
  path:
    '/channels/organization/:id/gets',
  method: 'GET',
  fields,
  fieldTypes,
  transform: (response, { query }) => {
    let error,
      errorMessages,
      entities,
      result,
      success
    const { status } = response || {}
    const data = _.get(
      response,
      'data.data'
    )
    switch (status) {
      case 200:
        success = true
        const {
          entities: e,
          result: r
        } = normalize(
          data,
          new schema.Entity(
            'organizations',
            {
              channels: [channelSchema]
            },
            {
              idAttribute: () =>
                query[':id'],
              processStrategy: entities => {
                return {
                  idname: query[':id'],
                  channels: data
                }
              }
            }
          )
        )
        entities = e
        result = r
        break

      default:
        error = true
        errorMessages = response.data
        break
    }

    return {
      ...response,
      response,
      error,
      errorMessages,
      success,
      status,
      entities,
      result
    }
  }
}
// Route::get('channels/category/{category_id}/gets', 'ChannelController@getChannelsByCategory');
export const channel_getChannelsByCategory_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/channels/category/:category_id/gets',
  method: 'GET',
  paramsFields: ['exclude_promos'],
  transform: transformArray,
  schema: channelSchema
}

//  Route::get('channels/{id}/get-content-update-timestamp', 'ChannelController@getContentUpdatedTimestamp');
export const channel_getContentUpdatedTimestamp_Api = {
  name:
    'channel_getContentUpdatedTimestamp',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/channels/:id/get-content-update-timestamp',
  method: 'GET'
}

// Route::post('channels/{id}/mark-as-read', 'ChannelController@markAsRead');
export const channel_markAsRead_Api = {
  name: 'channel_markAsRead',
  backend: ``,
  root: API_ROOT_URL,
  path: '/channels/:id/mark-as-read',
  method: 'POST',
  transform: createTransform(
    channelSchema,
    'data'
  )
}

// Route::post('channels/channel/{channel_id}/user/{user_id}/set-subscription', 'ChannelSubscriptionController@manageChannelSubscriptionAdd');
export const channel_manageChannelSubscriptionAdd_Api = {
  name:
    'channel_manageChannelSubscriptionAdd',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/channels/channel/:channel_id/user/:user_id/set-subscription',
  method: 'POST'
}

// Route::post('channels/channel-subscription/{channel_subscription_id}/edit', 'ChannelSubscriptionController@editChannelSubscription'); // expire, ngày hết hạn mới, phải lớn hơn ngày hiện tại
export const channel_editSubscription_Api = {
  name: 'channel_editSubscription',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/channels/channel-subscription/:channel_subscription_id/edit',
  method: 'POST',
  transform: createTransform(
    baseItemSchema,
    'data'
  )
}

// Route::get('channels/sponsored/gets', 'NewsController@getSponsoredChannels');
export const feed_fetchSponsoredChannels_Api = {
  backend: `Route::get('channels/sponsored/gets', 'NewsController@getSponsoredChannels');`,
  root: API_ROOT_URL,
  path: '/channels/sponsored/gets',
  method: 'GET',
  schema: channelSchema,
  transform: createTransform([
    channelSchema
  ])
}

// Route::get('channels/auth/get-channels-by-owner', 'ChannelController@getChannelsByYourOwner'); 
// type:user,organization & id:{id của user, hoặc organization}
export const channel_getChannelsByYourOwner_Api = {
  backend: `Route::get('channels/auth/get-channels-by-owner', 'ChannelController@getChannelsByYourOwner')`,
  root: API_ROOT_URL,
  path: '/channels/auth/get-channels-by-owner',
  method: 'GET',
  schema: channelSchema,
  paramsFields: ['type', 'id'],
  transform: createTransform([
    channelSchema
  ])
}