import { Divider, message } from 'antd'
import { auth_login_Api } from 'apis'
import MultiToggle from 'components/MultiToggle'
import Null from 'components/Null'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useToggle } from 'react-use'
import { loginSuccess } from 'redux/actions'
import FacebookLoginBtn from './FacebookLoginBtn/index'
import GoogleLoginBtn from './GoogleLoginBtn/index'
import './Login.css'
import LoginForm from './LoginForm'
import Register from './RegisterForm'
const Login = ({
  onSuccess = Null
}) => {
  const [isToggle, toggle] = useToggle(
    false
  )
  const t = useTranslate()
  const dispatch = useDispatch()
  const {
    isLoading,
    handleAsyncAction
  } = useAsyncAction({
    apiInfo: auth_login_Api,
    onSuccess: (...args) => {
      dispatch(
        loginSuccess(
          _.get(
            args,
            '1.response.data'
          )
        )
      )
      onSuccess()
    },
    onError: (...args) =>
      message.error(
        _.get(args, '1.message')
      )
  })

  return (
    <div className="py-4">
      <div>
        {/*<div className="text-center font-semibold text-lg pt-1">*/}
        {/*  {t('sign in with')}*/}
        {/*</div>*/}
        {/*<div className="text-center font-bold text-sm my-0 color-primary">*/}
        {/*  ({t('automatically register with Google, Facebook... account')})*/}
        {/*</div>*/}
      </div>
      <section className="flex flex-col justify-center verticalList__small">
        <FacebookLoginBtn
          onSuccess={handleAsyncAction}
        />
        <GoogleLoginBtn
          onSuccess={handleAsyncAction}
        />
      </section>
      <Divider
        style={{ marginBottom: 0 }}>
        {t('or')}
      </Divider>
      <div className="text-center font-light mb-2">
        {t('with account')} FeedIn
      </div>
      {isToggle ? (
        <section className="py-3">
          <MultiToggle
            mode={
              MultiToggle.modeTyles
                .SINGLE
            }
            options={[
              'login',
              'register'
            ]}
            defaultSelections={[
              'login'
            ]}>
            {(
              options,
              isSelected,
              onSelect
            ) => {
              return (
                <div className="verticalList">
                  <div className="flex text-center">
                    <div
                      className={`flex-1 text-right px-4 font-light uppercase cursor-pointer${isSelected(
                        'login'
                      )
                        ? ' color-primary font-bold'
                        : ''
                        }`}
                      onClick={onSelect.bind(
                        null,
                        'login'
                      )}>
                      {t('sign in')}
                    </div>
                    <div
                      className={`flex-1 px-4 text-left font-light uppercase cursor-pointer${isSelected(
                        'register'
                      )
                        ? ' color-primary font-bold'
                        : ''
                        }`}
                      onClick={onSelect.bind(
                        null,
                        'register'
                      )}>
                      {t('sign up')}
                    </div>
                  </div>
                  <div className="pt-2">
                    {isSelected(
                      'login'
                    ) && <LoginForm />}
                    {isSelected(
                      'register'
                    ) && <Register />}
                  </div>
                </div>
              )
            }}
          </MultiToggle>
        </section>
      ) : (
        <section className="flex flex-col justify-center verticalList__small">
          <div
            className="flex items-center justify-center rounded background-200 shadow-outline m-3 py-2 text-lg text-primary cursor-pointer"
            onClick={() => {
              toggle()
            }}>
            {t('login with account')}
          </div>
        </section>
      )}

      <div className="mt-2 font-semibold">
        {t(
          'by using FeedIn, you agree to our'
        )}{' '}
        <Link
          to={
            '/document/privacy-policy'
          }
          className="color-primary">
          {t('privacy policy')}
        </Link>{' '}
        {t('and')}{' '}
        <Link
          to={
            '/document/terms-of-service'
          }
          className="color-primary">
          {t('terms of service')}
        </Link>
        .
      </div>
    </div>
  )
}
export default Login
