import {
  Button,
  Input,
  message,
  Modal
} from 'antd'
import { dataset_delete_Api } from 'apis'
import FieldDecorator from 'components/form/FieldDecorator'
import { emptyObject } from 'helpers/emptyObjects'
import AsyncAction from 'modules/asyncCache/components/AsyncAction'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, { useState } from 'react'

function Delete({ item, onSuccess }) {
  const history = useHistory()
  const [error, setError] = useState()
  const t = useTranslate()
  return (
    <div className="p-3 flex flex-col items-center text-center verticalList__medium">
      <div>
        <h2 className="text-2xl font-bold">
          {t('delete')}
        </h2>
        <div>
          {t(
            'Deleted dataset are gone forever. Are you sure?'
          )}
        </div>
        <br />
        <div className="text-left">
          <FieldDecorator
            errors={[error]}
            label={t(
              'enter dataset name'
            )}>
            <Input id="delete_input" />
          </FieldDecorator>
        </div>
      </div>
      <div>
        <AsyncAction
          onSuccess={
            onSuccess
              ? onSuccess
              : () => {
                  Modal.success({
                    content: t(
                      'the dataset has been deleted'
                    )
                  })
                  history.replace('/')
                }
          }
          onError={() =>
            message.error('failure')
          }
          apiInfo={dataset_delete_Api}
          query={{
            ':id': item.id
          }}>
          {({
            handleAsyncAction,
            isLoading
          }) => {
            return (
              <Button
                onClick={() => {
                  const input =
                    document.querySelector(
                      '#delete_input'
                    ) || emptyObject
                  const value =
                    input.value
                  if (
                    value !== item.title
                  ) {
                    setError(
                      t(
                        "you didn't enter the channel name correctly"
                      )
                    )
                  } else {
                    handleAsyncAction()
                  }
                }}
                loading={isLoading}
                type="danger"
                ghost>
                {t('delete')}
              </Button>
            )
          }}
        </AsyncAction>
      </div>
    </div>
  )
}

export default Delete
