import { dataset_getById_Api } from 'apis'
import logFunc from 'helpers/logFunc'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import React, { useState } from 'react'
import UpdateDataset from './UpdateDataset'

export const routeUrls = {
  list: '/',
  create: '/create',
  update: '/update/:id',
  delete: '/delete/:id'
}

export const UpdateData = ({
  id,
  onSuccess
}) => {
  const [
    resetNumber,
    setResetNumber
    // eslint-disable-next-line react-hooks/rules-of-hooks
  ] = useState(Date.now())
  return (
    <>
      <Async
        apiInfo={dataset_getById_Api}
        query={{ ':id': id }}>
        {({ response, success }) => {
          const item = logFunc(_.get)(
            response,
            'data',
            []
          )
          if (!item) return null
          return (
            <UpdateDataset
              onSuccess={onSuccess}
              item={item}
            />
          )
        }}
      </Async>
    </>
  )
}
