import { emptyArray } from 'helpers/emptyObjects'
import _ from 'lodash'
import {
  AUTHENTICATED,
  COMMENT_RES,
  CONNECT,
  JOINED_ROOM,
  RECEIVE_COMMENT
} from './types'
const INITIAL_STATE = {}

const socket = function (
  state = INITIAL_STATE,
  { type, payload }
) {
  const newState = { ...state }
  switch (type) {
    case CONNECT:
      newState[CONNECT] = true
      break
    case AUTHENTICATED:
      newState[AUTHENTICATED] = true
      break
    case JOINED_ROOM:
      newState.roomType =
        payload.roomType
      newState.roomId = payload.roomId
      break
    case RECEIVE_COMMENT:
      const newComments = [
        payload,
        ...(newState.newComments ||
          emptyArray)
      ]
      newState.newComments = newComments
      break

    case COMMENT_RES:
      newState.reqId = _.uniqueId()
      break
    default:
      break
  }
  return newState
}
export default function socketReducer(
  state = INITIAL_STATE,
  action
) {
  if (action.socket) {
    return socket(state, action.socket)
  }
  return state
}
