import { SearchOutlined } from '@ant-design/icons'
import { Input, Tooltip } from 'antd'
import useTranslate from 'modules/local/useTranslate'
import React, { useMemo } from 'react'
import { MdClose } from 'react-icons/md'
import { createValue } from './form/utils'
import './KeywordInput.scss'
import Null from './Null'

const style = {
  fontSize: '1.2em',
  fontWeight: 'bold',
  height: '44px'
}
export const KeywordInput = ({
  value,
  name,
  onChange,
  onClear,
  onSearchClick = Null,
  title,
  tooltip,
  placeholder = '',
}) => {
  const translate = useTranslate()
  const isEmpty = !(
    value && value.length > 0
  )
  const suffix = useMemo(() => {
    return (
      <div className="flex">
        {!isEmpty && (
          <button
            data-effect="ripple"
            className="KeywordInputButton">
            <MdClose
              onClick={() => {
                onClear && onClear()
                onChange(
                  createValue(name, '')
                )
              }
              }
            />
          </button>
        )}
        <button
          data-effect="ripple"
          className="KeywordInputButton">
          <SearchOutlined
            onClick={onSearchClick}
          />
        </button>
      </div>
    )
  }, [
    isEmpty,
    name,
    onChange,
    onSearchClick
  ])

  const result = useMemo(() => {
    return (
      <div className="flex flex-col items-center ">
        {title && (
          <div className="font-bold text-2xl pb-3">
            {typeof title === 'string'
              ? translate(title)
              : title}
          </div>
        )}
        {tooltip ? (
          <Tooltip
            trigger={['focus']}
            title={translate(
              'press enter to search'
            )}
            placement="bottomLeft">
            <Input
              prefixCls="KeywordInput"
              name={name}
              size="large"
              value={value}
              autoComplete="off"
              onChange={onChange}
              style={style}
              suffix={suffix}
              placeholder={translate(
                placeholder
              )}
              className="w-full"
            />
          </Tooltip>
        ) : (
          <Input
            prefixCls="KeywordInput"
            name={name}
            size="large"
            value={value}
            autoComplete="off"
            onChange={onChange}
            style={style}
            suffix={suffix}
            placeholder={translate(
              placeholder
            )}
            className="w-full"
          />
        )}
      </div>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])
  return result
}
