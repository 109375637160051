import { emptyObject } from 'helpers/emptyObjects'
import React, {
  useContext,
  useMemo
} from 'react'
import PermissionContext from './PermissionContext'
const PermissionProvider = ({
  permissions = emptyObject,
  children
}) => {
  const pers =
    useContext(PermissionContext) ||
    emptyObject
  const endpers = useMemo(
    () => ({
      ...pers,
      ...permissions
    }),
    [permissions, pers]
  )
  return (
    <PermissionContext.Provider
      value={endpers}>
      {children}
    </PermissionContext.Provider>
  )
}

export default PermissionProvider
