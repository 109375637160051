import { Button } from 'antd'
import { create_loadMore_Api_action } from 'apis'
import { createTransform } from 'apis/utils/createTransform'
import Count from 'components/Feed/Count'
import LazyComponent from 'components/LazyComponent'
import Null from 'components/Null'
import { SelectEntityItem } from 'components/SelectEntityItem'
import {
  emptyArray,
  emptyObject
} from 'helpers/emptyObjects'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import { schema } from 'normalizr'
import React, {
  Fragment,
  useEffect,
  useMemo,
  useState
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import LoadingPage from 'views/LoadingPage'
import { createAsyncAction } from '..'
import { getAsynCacheSelector } from '../selectors'
import { DidOnMount } from './DidOnMount'
import { LazyList } from './LazyList'

export const transformToRecordAction = (
  recordId,
  { apiInfo, ...rest }
) => {
  const recordSchema = new schema.Entity(
    'records',
    {
      responses: {
        [rest.asyncId]: {
          data: [apiInfo.schema]
        }
      }
    },
    {
      idAttribute: () => recordId,
      processStrategy: e => {
        const pagination = _.get(
          e,
          'meta.pagination',
          {}
        )
        const {
          links = {}
        } = pagination
        const { next: nextUrl } = links
        const done = !nextUrl
        // const {
        //   result,
        //   entites
        // } = normalize(
        //   e.data || emptyArray,
        //   [apiInfo.schema]
        // )
        return {
          id: recordId,
          ...pagination,
          update: Date.now(),
          asyncId: rest.asyncId,
          // entites,
          responses: {
            [rest.asyncId]: {
              id: rest.asyncId,
              asyncId: rest.asyncId,
              ...e,
              // result,
              success: true,
              nextUrl
            }
          },
          nextUrl,
          done
        }
      }
    }
  )
  return {
    ...rest,
    apiInfo: {
      ...apiInfo,
      transform: createTransform(
        recordSchema,
        'data'
      )
    }
  }
}

const CustomLazyPagination = ({
  startAction,
  id,
  renderEmpty = Null,
  renderNoMore,
  renderLoading = () => <LoadingPage />,
  query,
  children,
  renderLoadMore = null,
  renderItem = Null,
  apiInfo,
  auto = true,
  values,
  sample = 0
}) => {
  const t = useTranslate()
  const recordId = id || apiInfo.path
  const firstAction = useMemo(
    () =>
      startAction ||
      transformToRecordAction(
        recordId,
        createAsyncAction({
          prefixStr: recordId,
          query,
          values,
          apiInfo
        })
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [recordId]
  )
  const record = useSelector(state => {
    const records = _.get(
      state,
      `entities.records`,
      emptyObject
    )
    return (
      records[recordId] || emptyObject
    )
  })
  const {
    news = emptyArray,
    responses = emptyArray,
    nextUrl,
    asyncId,
    done
  } = record
  const dispatch = useDispatch()
  const nextAction = useMemo(() => {
    return (
      nextUrl &&
      transformToRecordAction(
        recordId,
        create_loadMore_Api_action({
          prefixStr: recordId,
          path: nextUrl,
          apiInfo
        })
      )
    )
  }, [apiInfo, nextUrl, recordId])
  useEffect(() => {
    if (
      !startAction &&
      (!record ||
        record === emptyObject)
    )
      dispatch(firstAction)
  }, [
    dispatch,
    firstAction,
    record,
    startAction
  ])
  const [
    currentAsyncId,
    setCuurentAsyncId
  ] = useState(firstAction.asyncId)
  const reachEnd =
    asyncId === currentAsyncId
  const asyncData = useSelector(state =>
    getAsynCacheSelector(
      state,
      (nextAction || firstAction)
        .asyncId
    )
  )

  const render = () => (
    <>
      {news.map(item => (
        <SelectEntityItem
          key={item}
          item={item}
          schema={apiInfo.schema}>
          {renderItem}
        </SelectEntityItem>
      ))}
      <LazyList>
        {Object.values(responses).map(
          (item = emptyObject, i) => {
            const {
              success,
              asyncId,
              data = emptyArray,
              nextUrl: url
            } = item
            return (
              <Fragment key={asyncId}>
                {i === 0 &&
                  renderEmpty &&
                  _.isEmpty(data) &&
                  renderEmpty()}
                {(sample > 0
                  ? data.slice(
                      0,
                      sample
                    )
                  : data
                ).map(id => (
                  <SelectEntityItem
                    key={
                      typeof id ===
                      'string'
                        ? id
                        : id.id
                    }
                    item={id}
                    schema={
                      apiInfo.schema
                    }>
                    {item =>
                      renderItem(item)
                    }
                  </SelectEntityItem>
                ))}
                <LazyComponent>
                  <DidOnMount
                    onMount={() => {
                      setCuurentAsyncId(
                        asyncId
                      )
                    }}>
                    <div />
                  </DidOnMount>
                </LazyComponent>
              </Fragment>
            )
          }
        )}
      </LazyList>
      {renderLoadMore &&
        renderLoadMore(
          () => dispatch(nextAction),
          reachEnd,
          asyncData
        )}
      {done
        ? renderNoMore && renderNoMore()
        : sample === 0 && (
            <>
              {!renderLoadMore &&
                (auto ? (
                  reachEnd && (
                    <LazyComponent>
                      <DidOnMount
                        onMount={() =>
                          dispatch(
                            nextAction
                          )
                        }>
                        <div />
                      </DidOnMount>
                    </LazyComponent>
                  )
                ) : (
                  <div className="flex justify-center p-3">
                    <Count>
                      {asyncData.isLoading ? (
                        <Button
                          ghost
                          className="border-none"
                          loading={
                            asyncData.isLoading
                          }></Button>
                      ) : (
                        <Button
                          ghost
                          className="border-none"
                          onClick={() =>
                            dispatch(
                              nextAction
                            )
                          }>
                          {t(
                            'show more'
                          )}
                        </Button>
                      )}
                    </Count>
                  </div>
                ))}
              {auto
                ? !renderLoadMore &&
                  renderLoading()
                : renderNoMore &&
                  renderNoMore()}
            </>
          )}
    </>
  )
  if (children) {
    return children(render, record)
  }
  return render()
}
export default CustomLazyPagination
