import Icon, { EditOutlined, SettingOutlined } from '@ant-design/icons'
import { Breadcrumb, Button, Carousel, Image, Modal } from 'antd'
import { channel_add_Api, getChannelsByOrganization_Api } from 'apis'
import { channelModel, organizationModel } from 'apis/model'
import ApiInfoForm from 'components/form/ApiInfoForm'
import Toggle from 'components/Toggle'
import { API_ROOT_URL } from 'envs/_current/config'
import { emptyArray } from 'helpers/emptyObjects'
import _ from 'lodash'
import { AsyncWithCahce } from 'modules/asyncCache/components/Async'
import { LazyPagination } from 'modules/asyncCache/components/LazyPagination'
import { getAsynCacheSelector } from 'modules/asyncCache/selectors'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, { useContext } from 'react'
import { Provider, ReactReduxContext, useSelector } from 'react-redux'
import { Link, Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import CreateChannelForm from 'views/CreateChannelForm'
import { DelayRender } from 'views/Discovery/DelayRender'
import OrganizationProvider from 'views/Organization/OrganizationProvider'
import ServerContext from 'views/Server/ServerContext'
import PhotoSvg from "../../components/Svgs/PhotoSvg"
import { Photo } from "../../conts/imageBase64"
import { AboutUs } from './AboutUs/AboutUs'
import { News } from './Channel/News'
import { Contact } from './Contact/Contact'
import { ContactDetail } from "./Contact/ContactDetail"
import SimpleContactForm from "./Contact/SimpleContactForm"
import { Data } from './Dataset/Data'
import { validateServiceType } from "./functions/validateServiceType"
import { Header } from './Header'
import { OrganizationContext } from './OrganizationContext'
import { OrganizationLayout } from './OrganizationLayout'
import { Product } from './Product/Product'
import { Products } from './Product/Products'
import { OurDatas } from './samples/OurDatas'
import { OurNews } from './samples/OurNews'
import { Slider } from './Slider'

const OrganizationHome = () => {
  const { item } = useContext(
    ServerContext
  )
  const t = useTranslate()

  return (
    <div className="w-full space-y-6 md:py-0">
      {
        _.get(item, 'photos.length') > 0 && (
          <section className="flex flex-col w-full pb-3 overflow-hidden border-0"
            style={{ height: 'min(35Vw, 35Vh)' }}>
            <div className="flex">
              <div className="flex-1 w-0">
                <Carousel dotPosition={"top"} autoplay>
                  {
                    _.get(item, 'photos', []).map(e => (
                      <div key={e} className="w-full background-100"
                        style={{ height: 'min(30Vw, 25Vh)' }}
                      >
                        <img key={e} className="background-100 object-cover w-full border-0"
                          src={e}
                          alt=""
                        />
                      </div>
                    ))
                  }
                </Carousel>
              </div>
            </div>
          </section>
        )
      }
      {
        validateServiceType(_.get(item, 'service_type'), "product") && (
          <section className=" body-font relative pt-6 space-y-3 p-3">
            <div className="flex justify-center items-center font-bold text-xl uppercase">
              {
                t('products')
              }
            </div>
            <Slider />
          </section>
        )
      }
      {
        validateServiceType(_.get(item, 'service_type'), "dataset") && (
          <section className="p-3">
            <OurDatas />
          </section>
        )
      }
      {
        validateServiceType(_.get(item, 'service_type'), "channel") && (
          <section className="p-3">
            <OurNews />
          </section>
        )
      }
      {(item.edit ||
        _.get(item, 'about')) && (
          <section className="space-y-3 p-3">
            <h2 className="flex justify-center items-center font-bold text-xl uppercase">
              {
                t('provider profile')
              }
            </h2>
            <AboutUs />
          </section>
        )}
      <section className=" body-font relative p-3">
        <div
          className="absolute"
          style={{
            top: '-50px'
          }}
          id="contact"></div>
        <Contact />
      </section>
    </div>
  )
}

export const OrganizationBanner = () => {
  const { item } = useContext(
    ServerContext
  )
  const t = useTranslate()

  return (
    <div className="w-full h-full space-y-6 md:py-0">
      <div className="flex p-3 w-full h-full flex-col justify-center items-center">
        <div className="table border rounded-lg">
          <Image
            width={160}
            height={160}
            src={organizationModel.getFullAvatarUrl(
              item
            ) || "#"}
            className="object-contain w-full h-full rounded-lg border-white table"
            placeholder={<PhotoSvg />}
            fallback={Photo}
          />
        </div>

        <div className="flex flex-col px-3 justify-center items-center space-y-3">
          <Link
            title={
              item.title
            }
            to={organizationModel.getLinkToDetail(
              item
            )}
            className="">
            <h2 className="p-3 title-font text-base md:text-lg font-bold">
              {item.title}
            </h2>
          </Link>
          <div className="text-base">
            {item.description}
          </div>
        </div>
      </div>
    </div>
  )
}

export const OrganizationStateless = ({
  id,
  groupsCacheid,
  children
}) => {
  let { url } = useRouteMatch()
  const t = useTranslate()
  const {
    item: organization
  } = useContext(ServerContext)
  const {
    result: groups = emptyArray
  } = useSelector(
    state => {
      return getAsynCacheSelector(
        state,
        groupsCacheid
      )
    },
    (left, right) =>
      left.updated === right.updated
  )
  const renderHomeLayout = children => (
    <OrganizationLayout
      admin={
        organization &&
        _.get(
          organization,
          'edit',
          false
        ) && (
          <div className="p-2 px-3 background-100 font-bold flex space-x-2 items-center">
            <div>{t('admin')}</div>
            <div className="flex-1" />
            <Link
              to={{
                pathname: `/organization/${organizationModel.getId(
                  organization
                )}/setting`,
                state: {
                  isModal: true
                }
              }}>
              <Button
                icon={
                  <SettingOutlined />
                }>
                {t('setting')}
              </Button>
            </Link>
          </div>
        )
      }
      header={<Header url={url} />}>
      <DelayRender time={300}>
        {children}
      </DelayRender>
    </OrganizationLayout>
  )

  return (
    <OrganizationContext.Provider
      value={{
        id,
        organization,
        url: url,
        groups: groups.filter(
          group =>
            group.products &&
            group.products.length
        ),
        allProducts: groups
          .flatMap(group => {
            const {
              title,
              products = emptyArray
            } = group
            return products.map(p => ({
              ...p,
              groupName: title,
              group
            }))
          })
          .map((p, i) => ({
            ...p,
            label: p.title,
            value: i
          }))
      }}>
      {organization && (
        <Switch>
          <Route
            path={url + '/news'}
            render={() =>
              renderHomeLayout(
                <div>
                  <News />
                  <Contact />
                </div>
              )
            }
          />
          <Route
            path={url + '/data'}
            render={() =>
              renderHomeLayout(
                <div>
                  <Data />
                  <Contact />
                </div>
              )
            }
          />
          <Route
            path={url + '/products'}
            render={() =>
              renderHomeLayout(
                <>
                  <Products />
                  <Contact />
                </>
              )
            }
          />
          <Route
            path={
              url +
              '/products/:productId'
            }
            render={() =>
              renderHomeLayout(
                <Product />
              )
            }
          />
          <Route
            path={url + '/about-us'}
            render={() =>
              renderHomeLayout(
                <div className="p-3 space-y-6">
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <Link
                        to={url}></Link>
                      {organizationModel.getTitle(
                        organization
                      )}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      {t('about us')}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                  <AboutUs />
                </div>
              )
            }
          />
          <Route
            path={url + '/contact'}
            render={() =>
              renderHomeLayout(
                <div className="p-3 space-y-6">
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <Link
                        to={url}></Link>
                      {organizationModel.getTitle(
                        organization
                      )}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      {t('contact')}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                  <ContactDetail />

                  <SimpleContactForm />

                  <div className="mb-16" />
                </div>
              )
            }
          />
          <Route
            path={url}
            render={() =>
              renderHomeLayout(
                <OrganizationHome />
              )
            }
          />
        </Switch>
      )}
    </OrganizationContext.Provider>
  )
}
const FloatButton = ({ type, id }) => {
  const history = useHistory()
  const t = useTranslate()
  return <div
    style={{ padding: '1em' }}
    className="fixed z-50 bottom-0 right-0 pointer-events-none">
    <Toggle>
      {(isToggle, toggle) => (
        <>
          <ReactReduxContext.Consumer>
            {({ store }) => (
              <button
                onClick={() => {
                  Modal.info({
                    closable: true,
                    maskClosable: true,
                    icon: false,
                    title: t(
                      'Choose the channel for posting'
                    ),
                    content: (
                      <div className="space-y-3">
                        <Button
                          onClick={() =>
                            toggle()
                          }
                          type="dashed"
                          block>
                          {t(
                            'Create new channel'
                          )}
                        </Button>
                        <Provider
                          store={
                            store
                          }>
                          <LazyPagination
                            apiInfo={
                              getChannelsByOrganization_Api
                            }
                            query={{
                              ':id': id
                            }}
                            renderItem={item => {
                              return (
                                <div
                                  data-effect="ripple"
                                  onClick={() => {
                                    Modal.destroyAll()
                                    history.push(
                                      channelModel.getLinkToDetail(
                                        item
                                      ) +
                                      `?initialCurrent=posting`
                                    )
                                  }}
                                  className="verticalList__small ">
                                  <div className="p-3 block cursor-pointer border border-gray-100 hover:border-gray-300  background-100  rounded-lg">
                                    <div className="flex items-center mb-2">
                                      <a className="font-semibold flex-1 text-lg">
                                        {channelModel.getTitle(
                                          item
                                        )}
                                      </a>
                                    </div>
                                    {item.description && (
                                      <div className="">
                                        {
                                          item.description
                                        }
                                      </div>
                                    )}
                                    <div className="mt-3">
                                      <span className="mr-3">
                                        <span className="font-semibold mr-1">
                                          {
                                            item.total_members
                                          }
                                        </span>
                                        {t(
                                          'members'
                                        )}
                                      </span>
                                      <span className="mr-3">
                                        <span className="font-semibold mr-1">
                                          {
                                            item.total_articles
                                          }
                                        </span>
                                        {t(
                                          'articles'
                                        )}
                                      </span>
                                    </div>
                                    <div className="flex flex-wrap text-sm">
                                      {item.categories.map(
                                        (
                                          e,
                                          i
                                        ) => {
                                          return (
                                            <span
                                              key={
                                                i
                                              }
                                              className="mr-2 border border-gray-300 mt-2 px-2 background rounded-lg">
                                              {
                                                e.name
                                              }
                                            </span>
                                          )
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )
                            }}></LazyPagination>
                        </Provider>
                      </div>
                    )
                  })
                }}
                data-effect="ripple"
                style={{
                  marginBottom:
                    'var(--header-height)'
                }}
                className="w-12 flex items-center justify-center bg-primary text-white shadow-xl h-12 rounded-full pointer-events-auto border border-white">
                <Icon
                  component={
                    EditOutlined
                  }
                />
              </button>
            )}
          </ReactReduxContext.Consumer>
          <Modal
            onCancel={toggle}
            visible={isToggle}
            destroyOnClose
            footer={null}>
            <ApiInfoForm
              onSuccess={([
                __,
                data
              ]) => {
                Modal.destroyAll()
                history.push(
                  channelModel.getLinkToDetail(
                    data.response
                      .data
                  ) +
                  `?initialCurrent=posting`
                )
              }}
              apiInfo={
                channel_add_Api
              }
              onPreSubmit={values => {
                return {
                  ...values,
                  privacy:
                    values.privacy ===
                      '1'
                      ? 2
                      : 1
                }
              }}
              initialValues={{
                owner_id: id,
                owner_type:
                  type
              }}>
              {({ ...props }) => {
                return (
                  <CreateChannelForm
                    {...props}
                  />
                )
              }}
            </ApiInfoForm>
          </Modal>
        </>
      )}
    </Toggle>
  </div>
}
export default () => {
  const { id } = useParams()
  const groupsCacheid = `${id}/get-product-catalog-with-products`
  return (
    <>
      <React.Fragment key={id}>
        <AsyncWithCahce
          cacheId={groupsCacheid}
          apiInfo={{
            root: API_ROOT_URL,
            path: `/products/organization/${id}/get-product-catalog-with-products`,
            method: 'GET'
          }}
        />
      </React.Fragment>
      <OrganizationProvider {...{ id }}>
        {React.useMemo(
          () => (
            <OrganizationStateless
              {...{
                id,
                groupsCacheid
              }}
            />
          ),
          [groupsCacheid, id]
        )}
      </OrganizationProvider>
      <FloatButton {...{ type: 'organization', id }} />
    </>
  )
}
