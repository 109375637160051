import { schema } from 'normalizr'
import { organizationSchema } from './organization'
import { userSchema } from './user'

export const pollSchema = new schema.Entity(
  'polls',
  {
    creator: new schema.Union(
      {
        user: userSchema,
        organization: organizationSchema
      },
      '_type'
    )
  },
  {
    idAttribute: 'id'
  }
)
