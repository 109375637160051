import _ from 'lodash'
import React from 'react'
import {Link} from 'react-router-dom'
import {WithDetailPopup} from '../WithDetailPopup'

export const Mention = ({
  prefix,
  children,
  mentions,
}) => {
  const [
    type,
    id,
    name,
  ] = children.split(':')
  const mention = _.find(
    mentions,
    (item) =>
      item.owner_type === type &&
      (item.owner_id === id ||
        item.idname === id ||
        item.owner.username === id),
    {}
  )
  if (type === 'keyword')
    return (
      <Link
        className="color-primary underline"
        to={`/t/${id}`}>
        {decodeURI(name)}
      </Link>
    )
  if (!mention) {
    return children
  }
  const title =
    mention.owner.title ||
    mention.owner.name
  return (
    <WithDetailPopup
      item={{
        ...mention.owner,
        avatar: mention.owner.thumbnail,
        _type: type,
        title,
        id,
      }}>
      <span className="color-primary cursor-pointer hover:underline">
        {prefix}
        {title}
      </span>
    </WithDetailPopup>
  )
}
