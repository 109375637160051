import {auth_updateLang_Api} from 'apis'
import {createTransform} from 'apis/utils/createTransform'
import {LoginContext} from 'components/LoginContext'
import {API_ROOT_URL} from 'envs/_current/config'
import {emptyArray} from 'helpers/emptyObjects'
import {saveLanguageKey} from 'helpers/localStorage'
import logParams from 'helpers/logParams'
import translations from 'locales'
import getMomentLocale from 'locales/getMomentLocale'
import _ from 'lodash'
import {AsyncWithCahce} from 'modules/asyncCache/components/Async'
import {getCacheSelector} from 'modules/asyncCache/selectors'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useSearchParam} from 'react-use'
import I18n, {setLanguage} from 'redux-i18n'
import {getLang, getLogin} from 'redux/selectors'
import {DelayRender} from 'views/Discovery/DelayRender'
import {createAsyncAction} from '../asyncCache'
import {LocalContext} from './LocalContext'

const LOCALES = {
  en_US: {
    bcp47: 'en-US',
    displayName: 'English (US)\u200e',
    englishName: 'English (US)',
    rtl: false
  },
  en: {
    bcp47: 'en-US',
    displayName: 'English (US)\u200e',
    englishName: 'English (US)',
    rtl: false
  },
  vi: {
    bcp47: 'vi',
    displayName: 'Tiếng Việt\u200e',
    englishName: 'Vietnamese (VN)',
    rtl: false
  }
}
const LocalProvider = (
  { children },
  { t: translate }
) => {
  const lang = useSelector(getLang)
  const isLogin = !!useContext(
    LoginContext
  )
  const dispatch = useDispatch()
  const changeLang = useCallback(
    value => {
      dispatch(setLanguage(value))
      saveLanguageKey(value)
      !!isLogin &&
        dispatch(
          createAsyncAction({
            apiInfo: auth_updateLang_Api,
            values: {
              language: value
            }
          })
        )
    },
    [dispatch, isLogin]
  )
  const changeContentLang = useCallback(
    value => {
      isLogin &&
        dispatch(
          createAsyncAction({
            apiInfo: auth_updateLang_Api,
            values: {
              content_languages: value
            }
          })
        )
    },
    [dispatch, isLogin]
  )
  useEffect(() => {
    moment.updateLocale(
      lang,
      getMomentLocale(lang)
    )
    const html = document.getElementsByTagName(
      'html'
    )[0]
    if (html != null) {
      html.lang = LOCALES[lang].bcp47
    }
  }, [lang])
  const appConfig = useSelector(state => getCacheSelector(state, 'appConfig', {}))
  const { configsVersion: currentconfigsVersion, lang: currentLang } = appConfig
  return (
    <LocalContext.Provider
      value={{
        lang: lang,
        changeLang,
        changeContentLang,
        appConfig,
        translate: translate
      }}>
      <>
        {useMemo(
          () =>
            (lang) && (
              <DelayRender lazy time={3000}>
                <AsyncWithCahce key={lang}
                  cacheId={
                    'configs-version'}

                  apiInfo={{
                    root: API_ROOT_URL,
                    path:
                      '/apps/get-configs-version',
                    method: 'GET',
                  }}>{
                    ({ response, success }) => {
                      if (!success) return null
                      const configsVersion = _.get(logParams(response), 'data.configs-version')
                      if ('' + logParams(currentconfigsVersion) === '' + logParams(configsVersion) && lang === currentLang) {
                        return null
                      }
                      return <AsyncWithCahce key={lang}
                        cacheId={
                          'appConfig'
                        }
                        apiInfo={{
                          root: API_ROOT_URL,
                          path:
                            '/apps/get-configs',
                          method: 'GET',
                          transform: (response) => {
                            const result = createTransform(null, 'data')(response)
                            return {
                              ...result,
                              result: ((entity => {
                                const {
                                  hierarchy_categories
                                } = entity
                                entity.lang = lang
                                entity.configsVersion = configsVersion
                                  entity.categoryEntities = hierarchy_categories
                                  .flatMap(
                                    cate => {
                                      cate.breadcrumbs = emptyArray
                                      return [
                                        cate,
                                        ...cate.children.flatMap(
                                          cate2 => {
                                            cate2.breadcrumbs = [
                                              cate.id
                                            ]
                                            return [
                                              cate2,
                                              ...cate2.children.map(
                                                cate3 => {
                                                  cate3.breadcrumbs = [
                                                    cate.id,
                                                    cate2.id
                                                  ]
                                                  return cate3
                                                }
                                              )
                                            ]
                                          }
                                        )
                                      ]
                                    }
                                  )
                                  .reduce(
                                    (
                                      re,
                                      cate
                                    ) => {
                                      re[
                                        cate.idname
                                      ] = cate
                                      return re
                                    },
                                    {}
                                  )
                                return entity
                              }
                              ))(result.result)
                            }
                          }
                        }
                        }/>
                    }
                  }</AsyncWithCahce>
              </DelayRender>
            ),
          [lang]
        )}
        {children}
      </>
    </LocalContext.Provider >
  )
}

LocalProvider.contextTypes = {
  t: PropTypes.func
}
const selectUserLang = state => {
  const login = getLogin(state)
  const lang = login
    ? login.language
    : 'vi'
  return lang
}
export const useAppConfig = () => {
  const { appConfig } = useContext(LocalContext)
  return appConfig
}
export default ({ children }) => {
  const searchParamLang = useSearchParam(
    'lang'
  )
  const userlang = useSelector(
    selectUserLang
  )
  const lang =
    searchParamLang || userlang
  const [
    initialLang,
    setInitialLang
  ] = useState()
  useEffect(() => {
    saveLanguageKey(lang)
    setInitialLang(lang)
  }, [lang])
  return (
    <I18n
      key={initialLang}
      translations={translations}
      initialLang={initialLang}
      fallbackLang={'vi'}>
      {(initialLang || null) && (
        <LocalProvider>
          {children}
        </LocalProvider>
      )}
    </I18n>
  )
}
