import {API_ROOT_URL} from 'envs/_current/config'
import {getFormDataFromJson} from 'helpers'
import {createAsyncAction} from 'modules/asyncCache'
import {schema} from 'normalizr'
import {baseFieldTypes} from './base/baseFieldTypes'
import {datasetSchema} from './dataset'
import Request from './middleware/request'
import {baseItemSchema, channelSchema, organizationSchema, userSchema} from './schema'
import {search_getDataWithType_ApiS_schema} from './search'
import {createListTransform} from './utils/createListTransform'
import {createTransform} from './utils/createTransform'

const ROOT_SUB = 'auth'

const auth_getDetail_userSchema = new schema.Entity(
  'users',
  {
    channels: [channelSchema],
    organizations: [organizationSchema],
    primary_org: organizationSchema
  },
  {
    idAttribute: 'username'
  }
)

export function logoutApi() {
  return Request.get(
    `${API_ROOT_URL}/${ROOT_SUB}/logout`
  )
}

export function loginApi(data) {
  return Request.post(
    `${API_ROOT_URL}/${ROOT_SUB}/login`,
    data
  )
}

export const auth_register_Api = {
  name: 'auth_register',
  backend: ``,
  root: API_ROOT_URL,
  path: `/${ROOT_SUB}/register`,
  method: 'POST',
  fields: [
    'email',
    'name',
    'password',
    'confirm_password'
  ]
}

export function registerApi(data) {
  return Request.post(
    `${API_ROOT_URL}/${ROOT_SUB}/register`,
    data
  )
}

export function loginSocialApi(data) {
  return Request.post(
    `${API_ROOT_URL}/${ROOT_SUB}/login`,
    data
  )
}

export function refreshTokenApi() {
  return Request.get(
    `${API_ROOT_URL}/token/refresh`
  )
}

export const fetchUserInfoApiObj = {
  getPath: () =>
    `${API_ROOT_URL}/${ROOT_SUB}/info`,
  method: 'GET',
  schema: new schema.Entity(
    'users',
    {
      channels: [channelSchema],
      organizations: [
        organizationSchema
      ],
      primary_org: organizationSchema
    },
    {
      idAttribute: 'username'
    }
  )
}

export function updateFollowCategoriesApi({
  cate_ids
}) {
  return Request.post(
    `${API_ROOT_URL}/${ROOT_SUB}/follow-categories/update`,
    getFormDataFromJson({
      cate_ids
    })
  )
}

export function updateUserApi(
  requiredFields
) {
  return Request.post(
    `${API_ROOT_URL}/${ROOT_SUB}/edit`,
    getFormDataFromJson(requiredFields)
  )
}

const transform = createTransform(
  userSchema
)
const fields = [
  'avatar',
  'name',
  'cover_photo'
]

const fieldTypes = {
  avatar: baseFieldTypes.image,
  cover_photo: baseFieldTypes.image,
  name: baseFieldTypes.string
}
// Route::post('user/push-notify-register-topic/{token}/{topic}', 'AuthenticateController@subscribeTokenToTopic');

// Route::get('auth/info', 'AuthenticateController@getAuthenticatedUser');
export const auth_getAuthenticatedUser_Api = {
  backend: `Route::get('auth/info', 'AuthenticateController@getAuthenticatedUser');`,
  root: API_ROOT_URL,
  path: '/auth/info',
  method: 'GET'
}
// Route::get('auth/info/get-count', 'UserController@getCountNotifications');
// Route::get('auth/info/{props}', 'UserController@getProps');
export const auth_getProps_Api = {
  backend: `// Route::get('auth/info/{props}', 'UserController@getProps');`,
  root: API_ROOT_URL,
  path: '/auth/info/:props/:params',
  method: 'GET',
  fields,
  paramsFields: ['category_id'],
  fieldTypes
}
export const auth_getMyFollowingChannels_Api = {
  backend: `// Route::get('auth/info/{props}', 'UserController@getProps');`,
  root: API_ROOT_URL,
  path:
    '/auth/info/channel?filter=follow',
  method: 'GET',
  schema: channelSchema,
  transform: createListTransform(
    channelSchema
  )
}
export const auth_getMyFollowingOrganization_Api = {
  backend: `// Route::get('auth/info/{props}', 'UserController@getProps');`,
  root: API_ROOT_URL,
  path:
    '/auth/info/organization?filter=follow',
  method: 'GET',
  schema: organizationSchema,
  transform: createListTransform(
    organizationSchema
  )
}
export const auth_getMyFollowingArticle_Api = {
  backend: `// Route::get('auth/info/{props}', 'UserController@getProps');`,
  root: API_ROOT_URL,
  path:
    '/auth/info/article?filter=follow',
  method: 'GET',
  schema: organizationSchema,
  transform: createListTransform(
    organizationSchema
  )
}
export const auth_getMyJoinedOrganization_Api = {
  backend: `// Route::get('auth/info/{props}', 'UserController@getProps');`,
  root: API_ROOT_URL,
  path:
    '/auth/info/organization?filter=member',
  method: 'GET',
  schema: organizationSchema,
  transform: createListTransform(
    organizationSchema
  )
}
export const auth_getMyFollowingOUser_Api = {
  backend: `// Route::get('auth/info/{props}', 'UserController@getProps');`,
  root: API_ROOT_URL,
  path: '/auth/info/user?filter=follow',
  method: 'GET',
  schema: userSchema,
  transform: createListTransform(
    userSchema
  )
}
// Route::get('auth/info/channel/get-joined-channels', 'UserController@getJoinedChannels');
export const auth_getJoinedChannels_Api = {
  backend: `// Route::get('auth/info/channel/get-joined-channels', 'UserController@getJoinedChannels');`,
  root: API_ROOT_URL,
  path:
    '/auth/info/channel/get-joined-channels',
  method: 'GET',
  schema: channelSchema,
  transform: createListTransform(
    channelSchema
  )
}

// Route::post('auth/edit', 'UserController@updateInfo');
export const auth_updateInfo_Api = {
  backend: `Route::post('auth/edit', 'UserController@updateInfo')`,
  root: API_ROOT_URL,
  path: '/auth/edit',
  method: 'POST',
  fields,
  fieldTypes,
  transform: createTransform(
    auth_getDetail_userSchema,
    'data'
  )
}
export const create_auth_updateInfo_Api_action = () => ({
  prefixStr = 'auth_updateInfo_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@auth_updateInfo_Api',
    query,
    values,
    apiInfo: auth_updateInfo_Api,
    maxAge
  })

// Route::post('auth/edit-language', 'UserController@updateLang');
export const auth_updateLang_Api = {
  backend: `Route::post('auth/edit-language', 'UserController@updateLang');`,
  root: API_ROOT_URL,
  path: '/auth/edit-language',
  method: 'POST',
  fields: ['language'],
  fieldTypes: {
    language: baseFieldTypes.string
  },
  schema: auth_getDetail_userSchema,
  transform: createTransform(
    auth_getDetail_userSchema,
    'data'
  )
}
export const create_auth_updateLang_Api_action = () => ({
  prefixStr = '',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@auth_updateLang_Api',
    query,
    values,
    apiInfo: auth_updateLang_Api,
    maxAge
  })

// Route::post('auth/edit-primary-org/{id}', 'UserController@updateOrg'); // id: organization_id
export const auth_updateOrg_Api = {
  backend: `Route::post('auth/edit-primary-org', 'UserController@updateOrg');`,
  root: API_ROOT_URL,
  path: '/auth/edit-primary-org',
  method: 'POST',
  fields: ['organization_id'],
  fieldTypes: {
    organization_id:
      baseFieldTypes.string
  },
  schema: auth_getDetail_userSchema,
  transform: createTransform(
    auth_getDetail_userSchema,
    'data'
  )
}
export const create_auth_updateOrg_Api_action = () => ({
  prefixStr = '',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr + '@auth_updateOrg_Api',
    query,
    values,
    apiInfo: auth_updateOrg_Api,
    maxAge
  })

// Route::post('auth/accept-as-hosted', 'UserController@acceptAsHosted');
// Route::post('auth/undo-accept-as-hosted', 'UserController@undoAcceptAsHosted');
// Route::post('auth/reject-as-hosted', 'UserController@rejectAsHosted');
// tags for filtering
// Route::get('auth/follow-tags', 'UserTagsController@listTags');
// Route::post('auth/follow-tags/add', 'UserTagsController@store');
// Route::post('auth/follow-tags/delete', 'UserTagsController@delete');
// Route::post('auth/follow-tags/update', 'UserTagsController@update');

// categories for filtering
// Route::get('auth/follow-categories', 'UserCategoriesController@listCategories');
// Route::post('auth/follow-categories/add', 'UserCategoriesController@store');
// Route::post('auth/follow-categories/delete', 'UserCategoriesController@delete');
// Route::post('auth/follow-categories/update', 'UserCategoriesController@update');
export const auth_updateFollowedCategories_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/auth/follow-categories/update',
  method: 'POST',
  fields: ['cate_ids'],
  fieldTypes: {
    cate_ids: baseFieldTypes.string
  },
  schema: auth_getDetail_userSchema,
  transform: createTransform(
    auth_getDetail_userSchema,
    'data'
  )
}
export const create_auth_Followed_updateCategories_Api_action = () => ({
  prefixStr = '',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@auth_updateFollowedCategories_Api',
    query,
    values,
    apiInfo: auth_updateFollowedCategories_Api,
    maxAge
  })

// Route::post('auth/edit-password', 'UserController@updatePassword');
// 'password' new password, 'confirm_password' new password, 'old_password' old password (neu $user->isset_password = 1) thì bo qua old_password
export const auth_updatePassword_Api = {
  backend: `Route::post('auth/edit-password', 'UserController@updatePassword');`,
  root: API_ROOT_URL,
  path: '/auth/edit-password',
  method: 'POST',
  fields: [
    'password',
    'confirm_password',
    'old_password'
  ],
  fieldTypes,
  transform
}
export const create_auth_updatePassword_Api_action = () => ({
  prefixStr = '',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@auth_updatePassword_Api',
    query,
    values,
    apiInfo: auth_updatePassword_Api,
    maxAge
  })
export const auth_login_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/auth/login',
  method: 'POST',
  fields: ['email', 'password'],
  fieldTypes
}
export const create_auth_login_Api_action = () => ({
  prefixStr = 'auth_login_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr + '@auth_login_Api',
    query,
    values,
    apiInfo: auth_login_Api,
    maxAge
  })
// Route::post('auth/request-reset-email', 'UserController@requestResetEmail'); // params: email
export const auth_requestResetEmail_Api = {
  backend: `Route::post('auth/request-reset-email', 'UserController@requestResetEmail'); // params: email`,
  root: API_ROOT_URL,
  path: '/auth/request-reset-email',
  method: 'POST',
  fields: ['email'],
  fieldTypes
}
export const create_auth_requestResetEmail_Api_action = () => ({
  prefixStr = '',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@auth_requestResetEmail_Api',
    query,
    values,
    apiInfo: auth_requestResetEmail_Api,
    maxAge
  })

// Route::post('auth/request-reset-password', 'UserController@requestResetPassword'); // params: email
export const auth_requestResetPassword_Api = {
  backend: `Route::post('auth/request-reset-password', 'UserController@requestResetPassword'); // params: email`,
  root: API_ROOT_URL,
  path: '/auth/request-reset-password',
  method: 'POST',
  fields: ['email'],
  fieldTypes,
  transform
}
export const create_auth_requestResetPassword_Api_action = () => ({
  prefixStr = '',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@auth_requestResetPassword_Api',
    query,
    values,
    apiInfo: auth_requestResetPassword_Api,
    maxAge
  })
// Route::post('auth/reset-password-confirm', 'UserController@resetPasswordConfirm');
// params: pwd_reset_code (code sinh ra tu lan yeu cau request tren), password: new password
export const auth_resetPasswordConfirm_Api = {
  backend: `Route::post('auth/reset-password-confirm', 'UserController@resetPasswordConfirm')`,
  root: API_ROOT_URL,
  path: '/auth/reset-password-confirm',
  method: 'POST',
  fields: ['password', 'pwd_reset_code']
}

export const auth_refreshToken_Api = {
  name: '/token/refresh',
  backend: ``,
  root: API_ROOT_URL,
  path: '/token/refresh',
  method: 'GET'
}

//Route::get('auth/info/organization', 'UserController@getPropsByUserLogin');
// props: organization,channel
// filter: owner, admin, member (default)
export const auth_getMyOrganizations_Api = {
  name: 'auth_getMyOrganizations_Api',
  backend: `get('auth/info/organization', 'UserController@getPropsByUserLogin')`,
  root: API_ROOT_URL,
  path: '/auth/info/organization',
  method: 'GET',
  schema: organizationSchema,
  paramsFields: ['filter'],
  transform: createListTransform(
    organizationSchema
  )
}
export const auth_getMyChannels_Api = {
  name: 'auth_getMyChannels_Api',
  backend: `get('auth/info/organization', 'UserController@getPropsByUserLogin')`,
  root: API_ROOT_URL,
  path: '/auth/info/channel',
  method: 'GET',
  paramsFields: ['filter'],
  schema: channelSchema,
  transform: createListTransform(
    channelSchema
  )
}
export const auth_getMydatas_Api = {
  name: 'auth_getMyChannels_Api',
  backend: `get('auth/info/organization', 'UserController@getPropsByUserLogin')`,
  root: API_ROOT_URL,
  path: '/auth/info/dataset',
  method: 'GET',
  paramsFields: ['filter'],
  schema: datasetSchema,
  transform: createListTransform(
    datasetSchema
  )
}
// Route::get('auth/info/{type}/get-suggestion-by-auth', 'UserSuggestionController@fetchSuggestionByAuthCategories');;
export const auth_fetchSuggestionDatasetByAuthCategories_Api = {
  name:
    'auth_fetchSuggestionDatasetByAuthCategories_Api',
  backend: `Route::get('auth/info/{type}/get-suggestion-by-auth', 'UserSuggestionController@fetchSuggestionByAuthCategories');`,
  root: API_ROOT_URL,
  path:
    '/auth/info/dataset/get-suggestion-by-auth',
  method: 'GET',
  paramsFields: ['category_id'],
  schema: datasetSchema,
  transform: createListTransform(
    datasetSchema
  )
}

// Route::get('datasets/dataset/get-promote-dataset-for-everyone', 'DatasetController@getPromoteDatasetForEveryone');
export const dataset_getPromoteDatasetForEveryone_Api = {
  name:
    'dataset_getPromoteDatasetForEveryone_Api',
  backend: `Route::get('datasets/dataset/get-promote-dataset-for-everyone', 'DatasetController@getPromoteDatasetForEveryone');`,
  root: API_ROOT_URL,
  path:
    '/datasets/dataset/get-promote-dataset-for-everyone',
  method: 'GET',
  paramsFields: ['category_id', 'ref_category_id'],
  schema: datasetSchema,
  transform: createListTransform(
    datasetSchema
  )
}

export const auth_fetchSuggestionChannelsByAuthCategories_Api = {
  name:
    'auth_fetchSuggestionChannelsByAuthCategories_Api',
  backend: `Route::get('auth/info/{type}/get-suggestion-by-auth', 'UserSuggestionController@fetchSuggestionByAuthCategories');`,
  root: API_ROOT_URL,
  path:
    '/auth/info/channel/get-suggestion-by-auth',
  method: 'GET',
  paramsFields: ['category_id'],
  schema: channelSchema,
  transform: createListTransform(
    channelSchema
  )
}

// Route::get('channels/channel/get-promote-channels-for-everyone', 'ChannelController@getPromoteChannelsForEveryone');
export const channel_getPromoteChannelsForEveryone_Api = {
  name:
    'channel_getPromoteChannelsForEveryone_Api',
  backend: `Route::get('channels/channel/get-promote-channels-for-everyone', 'ChannelController@getPromoteChannelsForEveryone');`,
  root: API_ROOT_URL,
  path:
    '/channels/channel/get-promote-channels-for-everyone',
  method: 'GET',
  paramsFields: ['category_id', 'ref_category_id'],
  schema: channelSchema,
  transform: createListTransform(
    channelSchema
  )
}

export const auth_fetchSuggestionArticlesByAuthCategories_Api = {
  name:
    'auth_fetchSuggestionChannelsByAuthCategories_Api',
  backend: `Route::get('auth/info/{type}/get-suggestion-by-auth', 'UserSuggestionController@fetchSuggestionByAuthCategories');`,
  root: API_ROOT_URL,
  path:
    '/auth/info/article/get-suggestion-by-auth',
  method: 'GET',
  paramsFields: ['category_id'],
  schema: search_getDataWithType_ApiS_schema,
  transform: createListTransform(
    search_getDataWithType_ApiS_schema
  )
}
export const auth_fetchSuggestionOrganizationsByAuthCategories_Api = {
  name:
    'auth_fetchSuggestionOrganizationsByAuthCategories_Api',
  backend: `Route::get('auth/info/{type}/get-suggestion-by-auth', 'UserSuggestionController@fetchSuggestionByAuthCategories');`,
  root: API_ROOT_URL,
  path:
    '/auth/info/organization/get-suggestion-by-auth',
  method: 'GET',
  paramsFields: ['category_id'],
  schema: organizationSchema,
  transform: createListTransform(
    organizationSchema
  )
}

export const auth_fetchSuggestionUsersByAuthCategories_Api = {
  name:
    'auth_fetchSuggestionUsersByAuthCategories_Api',
  backend: `Route::get('auth/info/{type}/get-suggestion-by-auth', 'UserSuggestionController@fetchSuggestionByAuthCategories');`,
  root: API_ROOT_URL,
  path:
    '/auth/info/user/get-suggestion-by-auth',
  method: 'GET',
  paramsFields: ['category_id'],
  schema: userSchema,
  transform: createListTransform(
    userSchema
  )
}
// Route::post('/auth/push-notify-register-topic', 'AuthenticateController@subscribeTopic');
export const auth_subscribe_Api = {
  name: 'auth_subscribe',
  backend: `Route::post('/auth/push-notify-register-topic', 'AuthenticateController@subscribeTopic');`,
  root: API_ROOT_URL,
  path:
    '/auth/push-notify-register-topic',
  method: 'POST',
  fields: ['token', 'topic']
}
// test-log/send-push-notifications
export const auth_send_push_notifications_Api = {
  name: 'auth_send-push-notifications',
  root: API_ROOT_URL,
  path: '/test-log/try',
  method: 'POST'
}

// Route::post('test-log/try-2/{username}', 'TestController@try2')
export const auth_try2_Api = {
  name: 'auth_send-push-notifications',
  root: API_ROOT_URL,
  path: '/test-log/try-2/:username',
  method: 'POST',
  fields: ['payload']
}
// Route::get('auth/info/{type}', 'UserController@getProps');
export const auth_followers_Api = {
  name: 'auth_followers',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/auth/info/user?filter=follower',
  method: 'GET',
  transform: createListTransform(
    userSchema
  )
}

// Route::post('auth/activate-account/{username}/{activation_reset_code}',
export const auth_activate_Api = {
  name: 'auth_activate',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/auth/activate-account/:username/:activation_reset_code',
  method: 'POST'
}

// Route::get('datasets/{id}/dataset-subscriptions/gets', 'DatasetController@getMembersByDatasetSubs'); // nothing
export const auth_getMembersByDatasetSubs_Api = {
  name: 'getMembersByDatasetSubs',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/datasets/:id/dataset-subscriptions/gets',
  method: 'GET',
  transform: createListTransform(
    baseItemSchema
  )
}

// Route::post('auth/validate-dataset-subscription/{dataset_id}', 'DatasetSubscriptionController@validateSelectedChannel'); // channel_id
export const auth_validateDataset_Api = {
  name: 'auth_validateDataset_Api',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/auth/validate-dataset-subscription/:id',
  method: 'POST'
}
// Route::post('auth/set-subscribe-dataset/{dataset_id}', 'DatasetSubscriptionController@subscribeChannel'); // channel_id
export const auth_subscribeDataset_Api = {
  name: 'auth_subscribeDataset_Api',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/auth/set-subscribe-dataset/:id',
  method: 'POST',
  transform: createTransform(
    datasetSchema,
    'data'
  )
}
// Route::post('auth/set-subscribe-trial-dataset/{dataset_id}', 'DatasetSubscriptionController@subscribeTrialChannel'); // channel_id
export const auth_subscribeTrialDataset_Api = {
  name:
    'auth_subscribeTrialDataset_Api',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/auth/set-subscribe-trial-dataset/:id',
  method: 'POST',
  transform: createTransform(
    datasetSchema,
    'data'
  )
}
// Route::post('auth/set-unsubscribe-dataset/{dataset_id}', 'DatasetSubscriptionController@unsubscribeChannel'); // nothing
export const auth_unSubscribeDataset_Api = {
  name: 'auth_usSubscribeDataset_Api',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/auth/set-unsubscribe-dataset/:id',
  method: 'POST',
  transform: createTransform(
    datasetSchema,
    'data'
  )
}

// Route::post('datasets/dataset/{channel_id}/member/{member_id}/set-subscription', 'DatasetSubscriptionController@grantChannelSubscription'); // nothing
// Route::post('datasets/dataset-subscription/{dataset_subscription_id}/edit', 'DatasetSubscriptionController@editChannelSubscription'); // expire
// Route::post('payments/vnpay/deposit/datasets/{id}/subscribe', 'VNPayController@depositDatasetSubscriptionWithVNPay'); // description, amount

export const auth_depositDatasetSubscriptionWithVNPayt_Api = {
  name:
    'depositDatasetSubscriptionWithVNPay',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/payments/vnpay/deposit/datasets/:id/subscribe',
  method: 'POST',
  transform: createTransform(
    datasetSchema,
    'data'
  ),
  fields: ['description', 'amount']
}
