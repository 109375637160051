import Icon from '@ant-design/icons'
import {Badge} from 'antd'
import classNames from 'classnames'
import {isDevelop} from 'envs/ForDevelop'
import useTranslate from 'modules/local/useTranslate'
import {ThemeContext} from 'modules/theme/ThemeContext'
import React, {useContext, useEffect} from 'react'
import {
  BsBag,
  BsBagFill,
  BsBell,
  BsBellFill,
  BsHouse,
  BsHouseFill,
  BsPerson,
  BsPersonFill,
  BsSearch
} from 'react-icons/bs'
import {FaSearch} from 'react-icons/fa'
import {connect} from 'react-redux'
import {NavLink, Route} from 'react-router-dom'
import {withProps} from 'recompose'
import {getLogin} from 'redux/selectors'
import './BottomBar.css'
import _ from 'lodash'

export const options = [
  {
    title: 'home',
    key: 'home',
    to: {
      pathname: '/'
    },
    Icon: withProps({
      component: BsHouse
    })(Icon),
    ActiveIcon: withProps({
      component: BsHouseFill
    })(Icon)
  },
  isDevelop() && {
    title: 'mart',
    key: 'mart',
    to: {
      pathname: '/mart'
    },
    Icon: withProps({
      component: BsBag
    })(Icon),
    ActiveIcon: withProps({
      component: BsBagFill
    })(Icon)
  },
  {
    title: 'discovery',
    key: 'discovery',
    to: {
      pathname: '/discovery'
    },
    Icon: withProps({
      component: BsSearch
    })(Icon),
    ActiveIcon: withProps({
      component: FaSearch
    })(Icon)
  },

  {
    title: 'notification',
    key: 'notification',
    Icon: connect(state => ({
      count: (getLogin(state) || {})
        .count_notifications
    }))(({count, className}) => (
      <Badge
        className="relative"
        count={count}>
        <Icon
          component={BsBell}
          className={className}
        />
      </Badge>
    )),
    ActiveIcon: connect(state => ({
      count: (getLogin(state) || {})
        .count_notifications
    }))(({count, className}) => (
      <Badge
        className="relative"
        count={count}>
        <Icon
          component={BsBellFill}
          className={classNames(
            className,
            'text-primary'
          )}
        />
      </Badge>
    )),
    to: {
      pathname: '/notifications'
    }
  },
  {
    title: 'personal',
    key: 'menu',
    to: {
      pathname: '/menu'
    },
    Icon: withProps({
      component: BsPerson
    })(Icon),
    ActiveIcon: withProps({
      component: BsPersonFill
    })(Icon)
  }
]
export const BottomBar = React.memo(
  () => {
    const t = useTranslate()
    const {
      changeStickyBottom
    } = useContext(ThemeContext)
    useEffect(() => {
      changeStickyBottom('50px')
      return () =>
        changeStickyBottom('0px')
    }, [changeStickyBottom])
    return (
      <header className="BottomBar">
        <div
          style={{
            height:
              'var(--header-height)'
          }}
        />
        <nav className="w-full  background items-stretch flex  border-t border-gray-300">
          {options
            .filter(Boolean)
            .map((item, i) => {
              const {
                to,
                title,
                Icon,
                ActiveIcon
              } = item
              return (
                <NavLink
                  to={to}
                  exact
                  className="BottomBarItem"
                  activeClassName="BottomBarItem__active"
                  key={i}>
                  <Route
                    path={to}
                    children={({
                                 match,
                                 location
                               }) => {
                      if (
                        _.get(match, 'path.pathname') === '/' || _.get(match, 'path.pathname') === ''
                      ) {
                        return (
                          _.get(location, 'pathname', '') === _.get(match, 'path.pathname')
                          || _.get(location, 'pathname', '').startsWith(
                            '/news'
                          )
                        )
                          ? (
                            <ActiveIcon className="BottomBarItem_icon"/>
                          )
                          : (
                            <Icon className="BottomBarItem_icon"/>
                          )
                      }

                      return _.get(location, 'pathname', '').includes(
                        _.get(match, 'path.pathname')
                      ) ? (
                        <ActiveIcon className="BottomBarItem_icon"/>
                      ) : (
                        <Icon className="BottomBarItem_icon"/>
                      )
                    }}
                  />
                  <div className="whitespace-no-wrap text-center BottomBarItem_label font-bold mt-1 uppercase ">
                    {t(title)}
                  </div>
                </NavLink>
              )
            })}
        </nav>
      </header>
    )
  }
)
