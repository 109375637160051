import en from './en/en_US'
import fr from './fr/fr_FR'
import vi from './vi/vi_VN'
export const transToCorectCode = code => {
  switch (code) {
    case 'vi':
      return 'vi-VN'
    case 'fr':
      return 'fr-FR'
    default:
      return 'en-US'
  }
}
const translations = {
  vi: vi,
  en: en,
  fr: fr
}
export default translations
