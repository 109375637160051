import Icon from '@ant-design/icons'
import {Button, Input, Menu, message, Upload} from 'antd'
import {article_delete_Api, article_getById_Api, article_update_Api, image_upload_Api} from 'apis'
import {articleSchema, channelSchema, createBaseItemSchema, organizationSchema, userSchema} from 'apis/schema'
import {widgetSchema} from 'apis/schema/widget'
import {createTransform} from 'apis/utils/createTransform'
import ContentContainer from 'components/ContentContainer'
import ApiInfoForm from 'components/form/ApiInfoForm'
import {CategoriesSelect} from 'components/form/fields/CategoriesSelect'
import {LanguageSelect} from 'components/form/fields/LanguageSelect'
import FieldsFactory from 'components/form/FieldsFactory'
import {createValue} from 'components/form/utils'
import ImageLoader from 'components/ImageLoader'
import {FullSideModalLayout} from 'components/Modal/SideModalLayout'
import Null from 'components/Null'
import Pure from 'components/Pure'
import coverToEditorStateFromRaw from 'components/RichEditor/coverToEditorStateFromRaw'
import RichEditor from 'components/RichEditor/RichEditor'
import Toggle from 'components/Toggle'
import {convertToRaw} from 'draft-js'
import {emptyArray} from 'helpers/emptyObjects'
import logParams from 'helpers/logParams'
import _ from 'lodash'
import {createAsyncAction} from 'modules/asyncCache'
import {callApi} from 'modules/asyncCache/callApi'
import AsyncAction from 'modules/asyncCache/components/AsyncAction'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import useRouter from 'modules/navigation/useRouter'
import {schema} from 'normalizr'
import React, {useEffect, useMemo, useState} from 'react'
import {MdClose} from 'react-icons/md'
import {useDispatch, useSelector} from 'react-redux'
import {Route} from 'react-router'
import {compose, mapProps, pure} from 'recompose'
import {selectEntities} from 'redux/selectors'
import LoadingPage from 'views/LoadingPage'
import * as Yup from 'yup'
import {HeaderButton} from '../Organization/components/PageHeader'
import SettingsLayout from '../Organization/Settings/SettingsLayout'

const EditContent = ({ item }) => {
  const t = useTranslate()
  const initialValues = useMemo(() => {
    return {
      editorState: coverToEditorStateFromRaw(
        JSON.parse(item.content)
      )
    }
  }, [item.content])
  return (
    <ApiInfoForm
      {...{
        apiInfo: article_update_Api,
        query: {
          ':prop': _.get(
            item,
            'container._type'
          ),
          ':id':
            _.get(
              item,
              'container.username'
            ) ||
            _.get(item, 'container.id'),
          ':article_id': item.id
        }
      }}
      initialValues={initialValues}
      onPreSubmit={values => {
        let raw = convertToRaw(
          values.editorState.getCurrentContent()
        )
        let _values = {
          content: JSON.stringify(raw)
        }
        const imageSrcList = Object.values(
          raw.entityMap
        )
          .filter(
            ({ type }) =>
              type === 'IMAGE'
          )
          .map(item =>
            _.get(item, 'data')
          )
        if (
          imageSrcList &&
          imageSrcList.length > 0
        ) {
          _values[
            'photos'
          ] = imageSrcList.map(
            ({ imageId }) => imageId
          )
        }
        return _values
      }}>
      {({
        isValid,
        handleChange,
        handleSubmit,
        values
      }) => {
        return (
          <ContentContainer>
            <div className="p-3 w-full mx-auto md:text-lg lg:text-xl">
              <Pure>
                <RichEditor
                  onChange={value => {
                    handleChange(
                      createValue(
                        'editorState',
                        value
                      )
                    )
                  }}
                  editorState={
                    values.editorState
                  }
                />
              </Pure>
            </div>
            {isValid && (
              <div
                style={{
                  height:
                    'var(--header-height)'
                }}
                className="sticky bottom-0 z-10 px-3 background flex justify-end items-center">
                <Button
                  onClick={() =>
                    handleSubmit()
                  }
                  type="primary">
                  {t('save')}
                </Button>
              </div>
            )}
          </ContentContainer>
        )
      }}
    </ApiInfoForm>
  )
}

const formSchema = [
  {
    children: {
      type: 'title',
      name: 'title',
      label: 'preview title',
      component: compose(
        withTranslate,
        mapProps(
          ({
            onChange,
            value,
            translate
          }) => ({
            type: 'text',
            size: 'large',
            placeholder: translate(
              'title'
            ),
            value: value,
            onChange: e =>
              onChange(
                createValue(
                  'title',
                  e.target.value
                )
              )
          })
        )
      )(Input)
    }
  },
  {
    children: {
      type: 'description',
      name: 'description',
      label: 'preview description',
      component: compose(
        withTranslate,
        mapProps(
          ({
            onChange,
            value,
            translate,
            form
          }) => ({
            autosize: {
              minRows: 4,
              maxRows: 8
            },
            type: 'text',
            placeholder: translate(
              'short description'
            ),

            value: value,
            onChange: e =>
              onChange(
                createValue(
                  'description',
                  e.target.value
                )
              )
          })
        )
      )(Input.TextArea)
    }
  },
  {
    children: {
      label: 'categories',

      name: 'categories',
      component: compose(
        mapProps(
          ({
            onChange,
            value = emptyArray,
            form
          }) => ({
            value,
            options:
              form.values
                .categoriesOptions,
            onChange: value =>
              onChange(
                createValue(
                  'categories',
                  value
                )
              )
          })
        ),
        pure
      )(CategoriesSelect)
    }
  },
  {
    children: {
      label: 'language',
      name: 'language',
      component: compose(
        mapProps(
          ({ onChange, value }) => ({
            defaultValue: value,
            onChange: value =>
              onChange(
                createValue(
                  'language',
                  value
                )
              )
          })
        ),
        pure
      )(LanguageSelect)
    }
  }
]
function getBase64(file) {
  return new Promise(
    (resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () =>
        resolve(reader.result)
      reader.onerror = error =>
        reject(error)
    }
  )
}

const PicturesWall = props => {
  return (
    <Upload
      customRequest={({
        onSuccess,
        ...args
      }) =>
        callApi({
          apiInfo: image_upload_Api,
          values: {
            'photos[]': args.file
          }
        }).then(res => {
          if (res.status === 200) {
            const imageUrl = _.get(
              res,
              'data.urls.0'
            )
            if (imageUrl) {
              const imageId = _.get(
                res,
                'data.ids.0'
              )
              props.onChange(
                imageUrl,
                imageId
              )
            }
          } else {
            message.error(
              _.get(
                res,
                'data.error.photos[0]'
              )
            )
          }
        })
      }
      {...props}></Upload>
  )
}
const OtherSettings = ({ item }) => {
  const t = useTranslate()
  const {
    description,
    title,
    content,
    cover_photo,
    language,
    categories = emptyArray
  } = item
  const editorState = coverToEditorStateFromRaw(
    JSON.parse(item.content)
  )
  const imageSrcList = [
    ...Object.values(
      convertToRaw(
        editorState.getCurrentContent()
      ).entityMap
    )
      .filter(
        ({ type }) => type === 'IMAGE'
      )
      .map(item => _.get(item, 'data')),
    ...(cover_photo
      ? [
          {
            src: cover_photo,
            imageId: cover_photo
          }
        ]
      : [])
  ]

  const initialValues = useMemo(
    () => ({
      categoriesOptions: categories,
      editorState,
      description,
      categories: categories.map(
        ({ id }) => id
      ),
      title,
      content,
      cover_photo,
      language
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  return (
    <ApiInfoForm
      {...{
        apiInfo: {
          ...article_update_Api,
          transform: createTransform(
            new schema.Entity(
              'articles',
              {
                creator: new schema.Union(
                  {
                    user: userSchema,
                    organization: organizationSchema
                  },
                  '_type'
                ),
                container: new schema.Union(
                  {
                    channel: channelSchema
                  },
                  '_type'
                ),
                widgets: [widgetSchema],
                fixLinkUpdate: new schema.Entity(
                  'fixLinkUpdates'
                )
              },
              {
                idAttribute: 'id',
                processStrategy: e => {
                  e.update = Date.now()
                  e.fixLinkUpdate = {
                    id: item.id,
                    newId: e.id
                  }
                  return e
                }
              }
            ),
            'data'
          )
        },
        validationSchema: Yup.object().shape(
          {
            title: Yup.string().required(
              'required field'
            ),
            description: Yup.string().required(
              'required field'
            )
          }
        ),
        query: {
          ':prop': _.get(
            item,
            'container._type'
          ),
          ':id':
            _.get(
              item,
              'container.username'
            ) ||
            _.get(item, 'container.id'),
          ':article_id': item.id
        }
      }}
      initialValues={initialValues}
      onPreSubmit={({
        editorState,
        ...rest
      }) => {
        return {
          ...rest,
          content: JSON.stringify(
            convertToRaw(
              editorState.getCurrentContent()
            )
          )
        }
      }}>
      {({
        isLoading,
        isValid,
        handleChange,
        handleSubmit,
        setValues,
        values
      }) => {
        return (
          <Toggle
            defaultValue={
              !item.cover_photo
            }>
            {(isToggle, toggle) => (
              <div className="p-4 max-w-md mx-auto verticalList">
                <div className="flex flex-wrap justify-center w-full">
                  {isToggle && (
                    <div className="flex w-full flex-wrap justify-center background-200">
                      <div className="leading-relaxed font-bold w-full bg-gray-300 p-1">
                        {t(
                          'select preview photo'
                        )}
                      </div>
                      {logParams(
                        imageSrcList
                      ).map(
                        ({
                          src,
                          imageId
                        }) => (
                          <div
                            key={src}
                            style={{
                              padding:
                                '0.5em',
                              width:
                                '100px',
                              height:
                                '100px',
                              textAlign:
                                'center'
                            }}>
                            {
                              <img
                                onClick={() => {
                                  setValues(
                                    {
                                      ...values,
                                      cover_photo: src,
                                      photos: imageId
                                    }
                                  )
                                  toggle()
                                }}
                                alt={
                                  src
                                }
                                className="background-200 w-full h-full object-fill hover:shadow"
                                src={
                                  src
                                }
                              />
                            }
                          </div>
                        )
                      )}
                      <PicturesWall
                        onChange={(
                          imageUrl,
                          imageId
                        ) => {
                          setValues({
                            ...values,
                            cover_photo: imageUrl,
                            photos: imageId
                          })
                          toggle()
                        }}>
                        <div
                          style={{
                            padding:
                              '0.5em',
                            width:
                              '100px',
                            height:
                              '100px',
                            textAlign:
                              'center'
                          }}>
                          <Button className="background-200 w-full h-full flex justify-center items-center border-dashed">
                            {t(
                              'upload'
                            )}
                          </Button>
                        </div>
                      </PicturesWall>
                    </div>
                  )}
                  {!isToggle && (
                    <div className="flex w-full flex-wrap justify-center background-200 border border-gray-300">
                      <ImageLoader
                        alt={
                          values.cover_photo
                        }
                        className="background-200 w-full "
                        src={
                          values.cover_photo
                        }
                      />
                      <div
                        onClick={toggle}
                        className="leading-relaxed text-center hover:bg-gray-400 font-bold w-full bg-gray-300 p-1">
                        {t(
                          'change preview photo'
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <FieldsFactory
                  formSchema={
                    formSchema
                  }
                />
                <div className="text-gray-600">
                  <span className="font-bold">
                    {t('note')}:
                  </span>{' '}
                  {t(
                    `changes here will affect how your story appears in public places like Article's homepage - not the story itself.`
                  )}
                </div>
                {isValid && (
                  <div
                    style={{
                      justifySelf: 'end'
                    }}
                    className="z-10 mt-8 p-3 sticky items-center bottom-0 flex justify-between background ">
                    <div className="flex-1"></div>
                    <Button
                      loading={
                        isLoading
                      }
                      className="ml-2"
                      type="primary"
                      onClick={
                        handleSubmit
                      }>
                      {t('save')}
                    </Button>
                  </div>
                )}
              </div>
            )}
          </Toggle>
        )
      }}
    </ApiInfoForm>
  )
}
export const EditArticle = ({
  id,
  onCancel
}) => {
  const t = useTranslate()
  const [
    current,
    setCurrent
  ] = useState('edit')
  const {
    handleGoBack,
    history
  } = useRouter()

  const [
    editorState,
    setEditorState
  ] = useState()
  const newItem = useSelector(state =>
    selectEntities(
      state,
      id,
      createBaseItemSchema({
        type: 'fixLinkUpdates'
      })
    )
  )
  const item = useSelector(state =>
    selectEntities(
      state,
      (newItem && newItem.newId) || id,
      articleSchema
    )
  )
  useEffect(() => {
    if (item) {
      setTimeout(() => {
        setEditorState(
          coverToEditorStateFromRaw(
            JSON.parse(item.content)
          )
        )
      }, 1)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!item])
  const dispath = useDispatch()
  useEffect(() => {
    dispath(
      createAsyncAction({
        apiInfo: article_getById_Api,
        query: {
          ':article_id': id
        }
      })
    )
  }, [dispath, id])
  const routes = useMemo(
    () => [
      <Route
        key="edit"
        path="edit"
        render={() =>
          (item || null) && (
            <EditContent item={item} />
          )
        }></Route>,
      <Route
        key="otherSettings"
        path="otherSettings"
        render={() =>
          (item || null) && (
            <OtherSettings
              item={item}
            />
          )
        }></Route>,
      <Route
        key="delete"
        path="delete"
        render={() =>
          (item || null) && (
            <div className="p-10 flex flex-col items-center text-center verticalList__medium">
              <div>
                <h2 className="text-2xl font-bold">
                  {t('delete')}
                </h2>
                <div>
                  {t(
                    'Deleted article are gone forever. Are you sure?'
                  )}
                </div>
              </div>
              <div>
                <AsyncAction
                  onSuccess={() => {
                    message.success(
                      'deleted'
                    )
                    history.replace(
                      `/${item.container._type}/${item.container.idname}`
                    )
                  }}
                  onError={() =>
                    message.error(
                      'failure'
                    )
                  }
                  apiInfo={
                    article_delete_Api
                  }
                  query={{
                    ':article_id':
                      item.id
                  }}>
                  {({
                    handleAsyncAction,
                    isLoading
                  }) => {
                    return (
                      <Button
                        onClick={() =>
                          handleAsyncAction()
                        }
                        loading={
                          isLoading
                        }
                        type="danger"
                        ghost>
                        {t('delete')}
                      </Button>
                    )
                  }}
                </AsyncAction>
              </div>
            </div>
          )
        }></Route>
    ],
    [history, item, t]
  )
  return (
    <FullSideModalLayout
      full
      title={
        <div className="font-bold">
          {t('article')}
        </div>
      }
      renderHeader={Null}
      onCancel={() =>
        history.replace(
          `/news/article/${item.id}`
        )
      }>
      {onCancel => (
        <>
          <div
            style={{
              height:
                'var(--header-height)'
            }}
            className="w-full right-0 flex justify-end">
            <HeaderButton
              onClick={onCancel}>
              <Icon
                style={{
                  fontSize: '1.4em'
                }}
                component={MdClose}
              />
            </HeaderButton>
          </div>
          {item ? (
            <SettingsLayout
              select={current}
              routes={routes}
              contextMenu={
                <Menu
                  className="border-0"
                  defaultSelectedKeys={[
                    current
                  ]}
                  onClick={({
                    key
                  }) => {
                    switch (key) {
                      default:
                        setCurrent(key)
                        break
                    }
                  }}>
                  {[
                    ['edit', 'edit'],
                    [
                      'otherSettings',
                      'change display title / subtitle / display image / categories'
                    ],
                    ['delete', 'delete']
                  ].map(
                    ([key, label]) => (
                      <Menu.Item
                        key={key}>
                        <span
                          title={t(
                            label
                          )}
                          className="font-semibold">
                          {t(label)}
                        </span>
                      </Menu.Item>
                    )
                  )}
                </Menu>
              }></SettingsLayout>
          ) : (
            <LoadingPage />
          )}
        </>
      )}
    </FullSideModalLayout>
  )
}
export default compose(
  mapProps(props => ({
    id: _.get(props, 'match.params.id')
  }))
)(EditArticle)
