import { Breadcrumb } from 'antd'
import _ from 'lodash'
import React, { useContext } from 'react'
import { BsListUl } from "react-icons/bs"
import { Link } from 'react-router-dom'
import { getChannelsByProps_Api, post_getPosts_Api } from '../../../apis'
import { channelModel, organizationModel } from '../../../apis/model'
import EmptyHolder from '../../../components/EmptyHolder'
import Description from '../../../components/Feed/Description'
import { FeedContentLayout, FeedContext } from '../../../components/Feed/FeedCard'
import { createTimeLineFeed } from '../../../components/Feed/TimeLineFeed'
import { ChannelIcon } from '../../../components/icons/ChannelIcon'
import Pure from '../../../components/Pure'
import StickyFrame from '../../../components/StickyFrame'
import { WithDetailPopup } from '../../../components/WithDetailPopup'
import { LazyPagination } from '../../../modules/asyncCache/components/LazyPagination'
import useTranslate from '../../../modules/local/useTranslate'
import { OrganizationContext } from '../OrganizationContext'

const Feed = createTimeLineFeed({
  Container: () => (
    <FeedContext.Consumer>
      {({ item }) => (
        <WithDetailPopup
          item={item.container}
          id={item.container}
          style={{
            textOverflow: 'ellipsis'
          }}>
          <span className=" font-bold flex items-center whitespace-no-wrap mr-2 cursor-pointer hover:underline">
            <span className="mr-2">
              <ChannelIcon
                className="mr-2  text-3xl w-8 h-8"
                premium={_.get(
                  item,
                  'container.premium',
                  0
                )}
              />
            </span>
            <span className="truncate flex-1">
              {item.container.title}
            </span>
          </span>
        </WithDetailPopup>
      )}
    </FeedContext.Consumer>
  ),
  ContentLayout: props => (
    <FeedContentLayout {...props} />
  )
})
const renderItem = item =>
  (item || null) && (
    <Pure
      key={item.id}
      input={[
        item &&
        item.content &&
        item.content.updated,
        _.get(
          item,
          'content.pin_status'
        )
      ]}>
      <Feed item={item.content} />
    </Pure>
  )

export function News() {
  const { organization } = useContext(
    OrganizationContext
  )

  const t = useTranslate()

  return (
    <div className="p-3 space-y-6">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link
            to={organizationModel.getLinkToDetail(
              organization
            )}>
            {organizationModel.getTitle(
              organization
            )}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {t('news')}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-y-3 md:gap-3">
        <section
          style={{
            overflow: 'initial'
          }}
          className="h-full py-3">
          <StickyFrame>
            <div className="space-y-3 py-3 border rounded">
              <div className="font-bold p-3 py-1 uppercase">
                {t('channels')}
              </div>
              <div className="p-3 space-y-3">
                <LazyPagination
                  key={
                    getChannelsByProps_Api.path
                  }
                  renderEmpty={() => (
                    <div className="w-full h-64 justify-center items-center">
                      <EmptyHolder
                        icon={() => (
                          <div
                            style={{
                              fontSize:
                                '3rem',
                              color:
                                'rgb(185,190,198)'
                            }}>
                            <ChannelIcon />
                          </div>
                        )}
                        title={null}
                      />
                    </div>
                  )}
                  apiInfo={
                    getChannelsByProps_Api
                  }
                  query={{
                    ':type':
                      'organization',
                    ':id':
                      organization.idname
                  }}
                  renderLoading={() => (
                    <>
                      <div className="col-span-1 h-16 background-100 rounded p-3" />
                      <div className="col-span-1 h-16 background-100 rounded p-3" />
                    </>
                  )}
                  renderItem={item => (
                    <div className="border-gray-300 cursor-pointer py-2 h-full flex flex-col border-t">
                      <div className="flex-wrap flex">
                        <ChannelIcon
                          premium={_.get(
                            item,
                            'premium',
                            0
                          )}
                          className="mx-3 text-color-200 text-3xl w-8 h-8"
                        />
                        <Link
                          to={channelModel.getLinkToDetail(
                            item
                          )}
                          className="font-medium">
                          {item.title}
                        </Link>
                      </div>
                      <Description
                        type={
                          'channel'
                        }
                        mentions={
                          item &&
                          item.mentions
                        }>
                        {
                          item.description
                        }
                      </Description>
                    </div>
                  )}
                />
              </div>
            </div>
          </StickyFrame>
        </section>
        <section className="space-y-3 col-span-2 py-3">
          <div className="font-bold p-3 uppercase">
            {t('the latest news')}
          </div>
          <LazyPagination
            id={post_getPosts_Api.path + '_type_organization_id_' + organization.id}
            key={post_getPosts_Api.path + '_type_organization_id_' + organization.id}
            renderEmpty={() => (
              <div className="items-center justify-center h-64">
                <EmptyHolder
                  icon={() => (
                    <div
                      style={{
                        fontSize:
                          '3rem',
                        color:
                          'rgb(170,176,184)'
                      }}>
                      <BsListUl />
                    </div>
                  )}
                  // title={null}
                  subTitle={null}
                />
              </div>
            )}
            apiInfo={post_getPosts_Api}
            query={{
              ':prop': 'organization',
              ':id': organization.idname
            }}
            values={{
              pinned: 0
            }}
            renderItem={renderItem}
          />
        </section>
      </div>
    </div>
  )
}
