import React, {
  lazy,
  Suspense
} from 'react'

const NewsLazy = lazy(() =>
  import('./News')
)

const News = props => (
  <Suspense fallback={null}>
    <NewsLazy {...props} />
  </Suspense>
)

export default News
