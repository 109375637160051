import { API_ROOT_URL } from 'envs/_current/config'
import { createAsyncAction } from 'modules/asyncCache'
import { schema } from 'normalizr'
import { chartSchema } from './chart'
import {
  articleSchema,
  bookmarkSchema,
  pollSchema,
  postSchema
} from './schema'
import { createTransform } from './utils/createTransform'

// Pinned Items
// Route::post('bookmarks/{container}/{container_id}/mark/{type}/{id}', 'BookmarkController@mark');
export const bookmarks_mark_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/bookmarks/:container/:container_id/mark/:type/:id',
  method: 'POST',
  fields: [],
  fieldTypes: {},
  transform: createTransform(
    bookmarkSchema,
    'data'
  )
}
export const create_bookmarks_mark_Api_action = () => ({
  prefixStr = 'bookmarks_mark_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr + '@bookmarks_mark_Api',
    query,
    values,
    apiInfo: bookmarks_mark_Api,
    maxAge
  })

// Route::post('bookmarks/{container}/{container_id}/unmark/{type}/{id}', 'BookmarkController@unMark');
export const bookmarks_unMark_Api = {
  backend: `Route::post('bookmarks/{container}/{container_id}/unmark/{type}/{id}', 'BookmarkController@unMark')`,
  root: API_ROOT_URL,
  path:
    '/bookmarks/:container/:container_id/unmark/:type/:id',
  method: 'POST',
  transform: createTransform(
    bookmarkSchema,
    'data'
  )
}

export const bookmarks_getBookmarks_Api = {
  backend: `Route::get('bookmarks/{container}/{container_id}/gets', 'BookmarkController@getBookmarks')`,
  root: API_ROOT_URL,
  path:
    '/bookmarks/:container/:container_id/gets',
  method: 'GET',
  schema: bookmarkSchema,
  transform: createTransform([
    new schema.Entity(
      'bookmarks',
      {
        owner: new schema.Union(
          {
            post: postSchema,
            poll_question: pollSchema,
            article: articleSchema,
            chart: chartSchema
          },
          '_type'
        )
      },
      {
        idAttribute: 'id'
      }
    )
  ])
}
export const bookmarks_getAll_Api = {
  backend: `BookmarkController@getAllBookmarks')`,
  root: API_ROOT_URL,
  path: '/bookmarks/auth/gets',
  method: 'GET',
  schema: bookmarkSchema,
  transform: createTransform([
    new schema.Entity(
      'bookmarks',
      {
        owner: new schema.Union(
          {
            post: postSchema,
            poll_question: pollSchema,
            article: articleSchema,
            chart: chartSchema
          },
          '_type'
        )
      },
      {
        idAttribute: 'id'
      }
    )
  ])
}
