import {
  emptyArray
} from 'helpers/emptyObjects'
import _ from 'lodash'
import {
  cacheKey,
  reducerKey
} from 'modules/asyncCache/const'
export const getAsynCacheSelector = (
  state,
  id
) => {
  return _.get(
    state,
    [reducerKey, id],
    {
      idle: true
    }
  )
}
export const getCacheSelector = (
  state,
  id,
  defaultValue
) => {
  return _.get(
    state,
    [cacheKey, id],
    defaultValue
  )
}
export const getAsyncPagination = (
  state,
  id
) => {
  const data = getAsynCacheSelector(
    state,
    id
  )
  const {
    success,
    updated,
    isLoading
  } = data
  const nextUrl = _.get(
    data,
    'response.data.meta.pagination.links.next'
  )

  const list =
    _.get(data, 'result') ||
    _.get(
      data,
      'response.data.data',
      emptyArray
    )
  return {
    success,
    nextUrl,
    isLoading,
    updated,
    list,
    done: success && !nextUrl
  }
}
