import { API_ROOT_URL } from 'envs/_current/config'
import { createAsyncAction } from 'modules/asyncCache'
import { normalize, schema } from 'normalizr'
import { baseFieldTypes } from './base/baseFieldTypes'
import { locationSchema } from "./location"
import { organizationSchema } from "./schema"
import { createTransform } from './utils/createTransform'

export const contactSchema = new schema.Entity(
  'contacts',
  {
    location: locationSchema
  },
  {
    idAttribute: 'id'
  }
)
const fields = [
  'name', //=> ['type' => 'text'],
  'phone', //=> ['type' => 'text'],
  'phone2', //=> ['type' => 'text'],
  'email', //=> ['type' => 'text'],
]
const fieldTypes = {
  name: baseFieldTypes.string, //=> ['type' => 'text'],
  phone: baseFieldTypes.string, //=> ['type' => 'text'],
  phone2: baseFieldTypes.string, //=> ['type' => 'text'],
  email: baseFieldTypes.string, //=> ['type' => 'text']
}
// Route::post('contacts/contact/{prop}/{id}/add', 'ContactController@addContact');
export const contact_addContact_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/contacts/contact/:type/:id/add',
  method: 'POST',
  fields,
  fieldTypes,
  // schema: contactSchema,
  // transform: createTransform(
  //   contactSchema
  // )
  transform: (res, { query }) => {
    if (res.status === 200) {
      const _type = query[':type']
      const idname = query[':id']
      const newOrganization = {
        idname,
        contacts: [res.data],
        _type
      }
      const {
        entities,
        result
      } = normalize(
        newOrganization,
        organizationSchema
      )
      return {
        status: 200,
        success: true,
        entities,
        result
      }
    }

    return {
      error: true,
      errorMessages: res.data
    }
  }
}

// Route::post('contacts/contact/{prop}/{id}/{contact_id}/edit', 'ContactController@updateContact');
export const contact_editContact_Api = {
  backend: `Route::post('contacts/contact/{prop}/{id}/{contact_id}/edit', 'ContactController@updateContact')`,
  root: API_ROOT_URL,
  path:
    '/contacts/contact/:type/:id/:contact_id/edit',
  method: 'POST',
  fields,
  fieldTypes,
  // schema: contactSchema,
  // transform: createTransform(
  //   contactSchema
  // )
  transform: (res, { query }) => {
    if (res.status === 200) {
      const _type = query[':type']
      const idname = query[':id']
      const newOrganization = {
        idname,
        locations: [res.data],
        _type
      }
      const {
        entities,
        result
      } = normalize(
        newOrganization,
        organizationSchema
      )
      return {
        status: 200,
        success: true,
        entities,
        result
      }
    }
    return {
      error: true,
      errorMessages: res.data
    }
  }
}

// Route::post('contacts/contact/{prop}/{id}/{contact_id}/delete', 'ContactController@deleteContact');
export const contact_deleteContact_Api = {
  backend: `Route::post('contacts/contact/{prop}/{id}/{contact_id}/delete', 'ContactController@deleteContact')`,
  root: API_ROOT_URL,
  path:
    '/contacts/contact/:type/:id/:contact_id/delete',
  method: 'POST',
  fields,
  fieldTypes,
  schema: contactSchema,
  transform: createTransform(
    contactSchema, 'data'
  )
}

export const create_contact_addContact_Api_action = () => ({
  prefixStr = 'contact_addContact_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@contact_addContact_Api',
    query,
    values,
    apiInfo: contact_addContact_Api,
    maxAge
  })
// Route::post('contacts/contact/{prop}/{id}/{contact_id}/edit', 'ContactController@updateContact');
export const contact_updateContact_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/contacts/contact/:type/:id/:contact_id/edit',
  method: 'POST',
  fields,
  fieldTypes,
  schema: contactSchema,
  transform: createTransform(
    contactSchema, 'data'
  )
}
export const create_contact_updateContact_Api_action = () => ({
  prefixStr = 'contact_updateContact_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@contact_updateContact_Api',
    query,
    values,
    apiInfo: contact_updateContact_Api,
    maxAge
  })
// Route::post('contacts/contact/{prop}/{id}/{contact_id}/delete', 'ContactController@deleteContact');


// Route::get('contacts/contact/{prop}/{id}/gets', 'ContactController@getContactsByOwner');
export const contact_getContactsByOwner_Api = {
  backend: `Route::get('contacts/contact/{prop}/{id}/gets', 'ContactController@getContactsByOwner')`,
  root: API_ROOT_URL,
  path:
    '/contacts/contact/:type/:id/gets',
  method: 'GET',
  fieldTypes,
  schema: contactSchema,
  transform: createTransform(
    [contactSchema]
  )
}