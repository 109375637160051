import _ from 'lodash'
import React from 'react'
export default function FacebookLoginBtn({
  onSuccess
}) {
  // const responseFacebookViaMob = () => {
  //   window.CordovaFacebook.login({
  //     permissions: [
  //       'email',
  //       'public_profile',
  //       'user_likes',
  //       'name'
  //     ],
  //     onSuccess: result => {
  //       const accessToken = _.get(result, 'accessToken')
  //       if (accessToken) {
  //         onSuccess({
  //           token:
  //             accessToken,
  //           provider: 'facebook'
  //         })
  //       } else {
  //         // alert('There was no error:' + result)
  //         window.CordovaFacebook.logout()
  //       }
  //     },
  //     onFailure: result => {
  //       if (result.cancelled) {
  //         // alert('The user doesn\'t like my app')
  //         if (
  //           window.CordovaFacebook
  //         )
  //           window.CordovaFacebook.logout()
  //       } else if (result.error) {
  //         // alert('There was an error:' + result.errorLocalized)
  //         if (
  //           window.CordovaFacebook
  //         )
  //           window.CordovaFacebook.logout()
  //       }
  //     }
  //   })
  // }
  const responseFacebookViaMob = () => {
    // {
    //   status: "connected",
    //   authResponse: {
    //     session_key: true,
    //     accessToken: "<long string>",
    //     expiresIn: 5183979,
    //     sig: "...",
    //     secret: "...",
    //     userID: "634565435"
    //   }
    // }
    window.facebookConnectPlugin.login(
      [
        'email',
        'name'
      ],
      result => {
        const accessToken = _.get(result, 'accessToken')
        if (accessToken) {
          onSuccess({
            token:
              accessToken,
            provider: 'facebook'
          })
        } else {
          // alert('There was no error:' + result)
          window.CordovaFacebook.logout()
        }
      },
      result => {
        if (result.cancelled) {
          // alert('The user doesn\'t like my app')
          if (
            window.CordovaFacebook
          )
            window.CordovaFacebook.logout()
        } else if (result.error) {
          // alert('There was an error:' + result.errorLocalized)
          if (
            window.CordovaFacebook
          )
            window.CordovaFacebook.logout()
        }
      }
    )
  }
  return (
    <div
      className="loginBtn loginBtn--facebook m-3"
      onClick={
        responseFacebookViaMob
      }>
      Facebook
    </div>
  )
}