import { Avatar, Divider } from 'antd'
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'
import {
  auth_fetchSuggestionArticlesByAuthCategories_Api,
  auth_fetchSuggestionChannelsByAuthCategories_Api,
  auth_fetchSuggestionOrganizationsByAuthCategories_Api,
  auth_fetchSuggestionUsersByAuthCategories_Api,
  auth_getMyFollowingArticle_Api,
  auth_getMyFollowingChannels_Api,
  auth_getMyFollowingOUser_Api,
  auth_getMyJoinedOrganization_Api,
  memberStatusTypes
} from 'apis'
import classnames from 'classnames'
import { FollowButton } from 'components/Feed/FollowButton'
import { ChannelIcon } from 'components/icons/ChannelIcon'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import { LoginContext } from 'components/LoginContext'
import { fnum } from 'helpers/fnum'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import { LazyPagination } from 'modules/asyncCache/components/LazyPagination'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
  useState
} from 'react'
import { FiRss } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import DiscoveryContext from './DiscoveryContext'

export const listHolder = (
  <div className="flex flex-wrap justify-center">
    {new Array(12)
      .fill(1)
      .map((item, i) => (
        <div
          className="m-3"
          key={i}
          style={{
            overflow: 'hidden',
            position: 'relative',
            width: '256px',
            height: '284px',
            background:
              'rgb(218, 218, 218)',
            borderRadius: '0.3em'
          }}
        />
      ))}
  </div>
)
const renderItem = (
  item,
  i,
  useSmallItemLayout
) => {
  if (!item) return null

  return (
    <Translate>
      {t => (
        <div className="py-2 px-4 flex w-full border-b border-gray-300">
          <div
            style={{
              flexShrink: 0
            }}
            className="flex">
            {_.get(
              item,
              '_type',
              item.owner_type
            ) === 'channel' ? (
              <div
                className="flex items-center justify-center"
                style={{
                  width: '40px'
                }}>
                <ChannelIcon
                  className="mr-2 text-gray-600 text-3xl w-8 h-8"
                  premium={item.premium}
                />
              </div>
            ) : (
              <Avatar
                size="large"
                style={{
                  background: '#f1f1f1',
                  marginRight: '1em'
                }}
                src={item.avatar}
                shape={
                  item._type === 'user'
                    ? 'circle'
                    : 'square'
                }>
                {getTitle(item)}
              </Avatar>
            )}
          </div>
          <div className="flex-1 overflow-hidden">
            <div className="flex items-start">
              <div className="flex-1 overflow-hidden">
                <Link
                  to={getLinkToDetail(
                    item
                  )}
                  className="cursor-pointer  font-bold leading-tight truncate">
                  {item.title ||
                    item.name}
                </Link>
                <div className="text-xs text-gray-600 leading-tight truncate">
                  <span>
                    {item.idname ||
                      item.username}
                  </span>
                </div>
              </div>
              <DiscoveryContext.Consumer>
                {({
                  searchParams: { type }
                }) => {
                  return (
                    type !==
                      'organization' && (
                      <FollowButton
                        item={item}
                      />
                    )
                  )
                }}
              </DiscoveryContext.Consumer>
            </div>
            {_.get(
              item,
              '_type',
              item.owner_type
            ) === 'channel' && (
              <div className="mt-2 flex font-normal items-center justify-center">
                <div className="flex-1 truncate">
                  {getTitle(item.owner)}
                </div>
                {item.follow_status ? (
                  <FiRss
                    style={{
                      color: '#36B303'
                    }}
                  />
                ) : (
                  <FiRss
                    style={{
                      color: '#bfbfbf'
                    }}
                  />
                )}
                <span className="px-1">
                  {fnum(
                    item.total_followers
                  )}
                </span>
              </div>
            )}
            {/* <div className="text-xs">
              {item.description ||
                item.about}
            </div> */}
            {_.has(
              item,
              'join_status'
            ) && (
              <div className="text-xs">
                {Number(
                  item.join_status
                ) !== 1 &&
                !_.isEmpty(
                  item.join_status
                )
                  ? t(
                      memberStatusTypes[
                        String(
                          Number(
                            item.join_status
                          )
                        )
                      ].label
                    )
                  : null}
              </div>
            )}
          </div>
        </div>
      )}
    </Translate>
  )
}
export const Selector = ({
  selector,
  children,
  equalityFn
}) => {
  const result = useSelector(
    selector,
    equalityFn
  )
  return children(result)
}

export function Recommendation({
  type = ' '
}) {
  const t = useTranslate()
  const { breakpoint } = useContext(
    LayoutContext
  )

  const [select, setSelect] = useState(
    'popular'
  )
  return (
    <div className="background w-full flex flex-wrap justify-center">
      <Divider
        horizontal="center"
        className="capitalize font-extrabold flex-1">
        <div className="flex">
          <a
            onClick={() =>
              setSelect('popular')
            }
            className={classnames(
              'm-2 cursor-pointer text-gray-400 hover:text-gray-700',
              select === 'popular' &&
                'text-gray-700'
            )}>
            {t('popular')}
          </a>
          <LoginContext.Consumer>
            {login =>
              login && (
                <DiscoveryContext.Consumer>
                  {({
                    searchParams: {
                      type
                    }
                  }) => {
                    if (
                      type ===
                      'organization'
                    ) {
                      return (
                        <a
                          onClick={() =>
                            setSelect(
                              'member'
                            )
                          }
                          className={classnames(
                            'm-2 cursor-pointer text-gray-400 hover:text-gray-700',
                            select ===
                              'member' &&
                              'text-gray-700'
                          )}>
                          {t('joined')}
                        </a>
                      )
                    }
                    return (
                      <a
                        onClick={() =>
                          setSelect(
                            'follow'
                          )
                        }
                        className={classnames(
                          'm-2 cursor-pointer text-gray-400 hover:text-gray-700',
                          select ===
                            'follow' &&
                            'text-gray-700'
                        )}>
                        {t('following')}
                      </a>
                    )
                  }}
                </DiscoveryContext.Consumer>
              )
            }
          </LoginContext.Consumer>
        </div>
      </Divider>
      {select === 'popular' ? (
        <ErrorBoundary>
          <LazyPagination
            key={type + 'popular'}
            {...{
              prefixStr:
                'Recommendation',
              query: {
                ':type': type
              },
              apiInfo: {
                article: auth_fetchSuggestionArticlesByAuthCategories_Api,
                channel: auth_fetchSuggestionChannelsByAuthCategories_Api,
                organization: auth_fetchSuggestionOrganizationsByAuthCategories_Api,
                user: auth_fetchSuggestionUsersByAuthCategories_Api
              }[type],
              renderItem: (item, i) =>
                renderItem(item, i)
            }}></LazyPagination>
        </ErrorBoundary>
      ) : (
        <ErrorBoundary>
          <LazyPagination
            key={type + 'follow'}
            {...{
              prefixStr:
                'Recommendation',

              apiInfo: {
                article: auth_getMyFollowingArticle_Api,
                channel: auth_getMyFollowingChannels_Api,
                organization: auth_getMyJoinedOrganization_Api,
                user: auth_getMyFollowingOUser_Api
              }[type],
              renderItem: (item, i) =>
                renderItem(item, i)
            }}></LazyPagination>
        </ErrorBoundary>
      )}
    </div>
  )
}
