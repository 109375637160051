import Null from './Null'
export function UseHook({
  hook,
  initalValue,
  deps,
  children = Null
}) {
  const hooked = hook(initalValue, deps)
  return children(hooked)
}
