import { Button, Spin } from 'antd'
import { create_loadMore_Api_action } from 'apis'
import EmptyHolder from 'components/EmptyHolder'
import Null from 'components/Null'
import { SelectEntityItem } from 'components/SelectEntityItem'
import { emptyArray } from 'helpers/emptyObjects'
import _ from 'lodash'
import React, {
  useEffect,
  useMemo,
  useState
} from 'react'
import { useDispatch } from 'react-redux'
import { createAsyncAction } from '..'
import useAsyncList from '../useAsyncList'
import {
  AsyncByAction,
  Error,
  Success
} from './Async'
import { DidOnMount } from './DidOnMount'
import { LazyList } from './LazyList'

const cache = {}
export const LazyPagination = ({
  refreshKey,
  reversed,
  cacheId,
  onDone,
  limit,
  sample,
  onChange,
  renderEmpty = () => <EmptyHolder />,
  renderNoMore,
  renderLoading = () => (
    <div className="w-full flex justify-center">
      <Spin className="m-auto" />
    </div>
  ),
  debug,
  query,
  children,
  renderList,
  renderItem = Null,
  apiInfo,
  values
}) => {
  const [
    errorAction,
    setErrorAction
  ] = useState()
  const firstAction = useMemo(
    () =>
      createAsyncAction({
        prefixStr: apiInfo.path,
        query,
        values,
        apiInfo
      }),
    [apiInfo, query, values]
  )
  const [done, setDone] = useState()
  useEffect(() => {
    done && onDone && onDone()
  }, [done, onDone])
  const [
    map,
    { set, reset }
  ] = useAsyncList(
    cache[cacheId] ? cache[cacheId] : []
  )
  useEffect(() => {
    if (cacheId) {
      cache[cacheId] = map
    }
    onChange && onChange(map)
  }, [cacheId, map, onChange])
  useEffect(() => {
    reset()
    set(0, firstAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey])
  const dispatch = useDispatch()
  const render = () => (
    <>
      <LazyList reversed={reversed}>
        {Object.values(map)
          .filter(
            (_, i) =>
              !limit || i < limit
          )
          .map((action, i) => {
            const schema = _.get(
              action,
              'apiInfo.schema'
            )
            return (
              <AsyncByAction
                key={action.asyncId}
                action={action}>
                <Error>
                  {() => (
                    <DidOnMount
                      onMount={() =>
                        setErrorAction(
                          action
                        )
                      }></DidOnMount>
                  )}
                </Error>
                <Success>
                  {data => {
                    const {
                      success,
                      response,
                      result = emptyArray
                    } = data
                    const nextUrl = _.get(
                      response,
                      'data.meta.pagination.links.next'
                    )
                    const list = schema
                      ? result
                      : _.get(
                          response,
                          'data.data',
                          emptyArray
                        ) || emptyArray
                    if (
                      i === 0 &&
                      success &&
                      list.length === 0
                    ) {
                      return (
                        <>
                          <DidOnMount
                            onMount={() =>
                              setDone(
                                true
                              )
                            }>
                            <div />
                          </DidOnMount>
                          {renderEmpty()}
                        </>
                      )
                    }
                    const nextid = i + 1
                    return (
                      <>
                        {renderList
                          ? renderList(
                              list,
                              schema
                            )
                          : list
                              .filter(
                                (
                                  _,
                                  iList
                                ) =>
                                  !sample ||
                                  (i ===
                                    0 &&
                                    iList <
                                      sample)
                              )
                              .map(
                                (
                                  item,
                                  i
                                ) =>
                                  schema ? (
                                    <SelectEntityItem
                                      key={
                                        i
                                      }
                                      item={
                                        item
                                      }
                                      schema={
                                        schema
                                      }>
                                      {item =>
                                        renderItem(
                                          item,
                                          i,
                                          action
                                        )
                                      }
                                    </SelectEntityItem>
                                  ) : (
                                    renderItem(
                                      item,
                                      i,
                                      action
                                    )
                                  )
                              )}

                        {i === 0 &&
                        success &&
                        list.length !==
                          0 &&
                        !!sample ? (
                          <>
                            <DidOnMount
                              onMount={() =>
                                setDone(
                                  true
                                )
                              }>
                              <div />
                            </DidOnMount>
                          </>
                        ) : null}

                        {!map[nextid] &&
                          nextUrl &&
                          !sample && (
                            <DidOnMount
                              onMount={() => {
                                set(
                                  nextid,
                                  create_loadMore_Api_action(
                                    {
                                      prefixStr:
                                        firstAction.asyncId,
                                      path: nextUrl,
                                      apiInfo:
                                        action.apiInfo
                                    }
                                  )
                                )
                              }}>
                              <div />
                            </DidOnMount>
                          )}
                        {!(
                          i === 0 &&
                          success &&
                          list.length ===
                            0
                        ) &&
                          success &&
                          !nextUrl && (
                            <>
                              <DidOnMount
                                onMount={() =>
                                  setDone(
                                    true
                                  )
                                }>
                                <div />
                              </DidOnMount>
                              {!!renderNoMore &&
                                renderNoMore()}
                            </>
                          )}
                      </>
                    )
                  }}
                </Success>
              </AsyncByAction>
            )
          })}
      </LazyList>
      {!!errorAction && (
        <Button
          onClick={() => {
            setErrorAction(null)
            dispatch(errorAction)
          }}
          block
          type="link"
          className="text-gray-600 font-bold">
          Load more
        </Button>
      )}
      {!errorAction &&
        !done &&
        renderLoading()}
    </>
  )
  if (children) {
    return children(render, map)
  }
  return render()
}
