import { baseItemSchema } from 'apis/schema'
import { denormalize } from 'normalizr'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

export const createSelectEnties = (
  id,
  schema
) => createSelector(
  state => state.entities,
  entities =>
    denormalize(id, schema, entities)
)

// const defaultEqual = (left, right) => {
//   return (
//     left.update &&
//     left.update === right.update
//   )
// }
const defaultEqual = (left, right) =>
  JSON.stringify(left) ===
  JSON.stringify(right)
export const useSelectEntities = (
  id,
  schema = baseItemSchema,
  defaultValue,
  equal = defaultEqual
) => {
  const selectEnties = useMemo(
    () =>
      createSelectEnties(id, schema),
    [id, schema]
  )
  const item = useSelector(
    selectEnties,
    equal
  )

  return item || defaultValue
}
