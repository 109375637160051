import {
  FB_ID
} from 'envs/_current/config'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'


export default function FacebookLoginBtn({
  onSuccess
}) {
  const t = useTranslate()
  const responseFacebook = response => {
    if (response['accessToken']) {
      onSuccess({
        token: response.accessToken,
        provider: 'facebook'
      })
    }
  }

  return (
    <FacebookLogin
      appId={FB_ID}
      isMobile={true}
      disableMobileRedirect={true}
      fields="name,email"
      callback={responseFacebook}
      render={renderProps => {
        return (
          <div
            className="loginBtn loginBtn--facebook m-3 cursor-pointer"
            onClick={
              renderProps.onClick
            }>
            {t('login with Facebook')}
          </div>
        )
      }}
    />
  )
}