import { channelSchema, organizationSchema } from 'apis/schema'
import { setTokenKey } from 'helpers/localStorage'
import {
  normalize,
  schema
} from 'normalizr'
import * as types from '../types'
import {
  LOGIN_FAILURE,
  LOGIN_SUCCESS
} from './types'

export function loginSuccess(data) {
  console.log(data)
  const { user, token } = data

  const {
    entities,
    result
  } = normalize(
    user,
    new schema.Entity(
      'users',
      {
        channels: [channelSchema],
        organizations: [
          organizationSchema
        ],
        primary_org: organizationSchema,
      },
      {
        idAttribute: 'username'
      }
    )
  )
  setTokenKey(token, null)
  return {
    type: LOGIN_SUCCESS,
    payload: {
      user,
      showSelectCategories: !user.categories || !user.categories.length,
      login: result,
      token,
      success: true
    },
    entities
  }
}
export function loginFailure() {
  return {
    type: LOGIN_FAILURE
  }
}
export function logutAction() {
  return {
    type: types.LOGOUT
  }
}
