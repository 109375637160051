import Icon from '@ant-design/icons'
import {Avatar, Badge, Menu} from 'antd'
import {userModel} from 'apis/model'
import classNames from 'classnames'
import ErrorBoundary from 'components/error/ErrorBoundary'
import {FlagIcon} from 'components/icons/FlagIcon'
import {LoginContext} from 'components/LoginContext'
import {InfoSvg} from 'components/Svgs/InfoSvg'
import {ShortFeedinSvg} from 'components/Svgs/ShortFeedinSvg'
import {isDevelop} from 'envs/ForDevelop'
import _ from 'lodash'
import {LocalContext} from 'modules/local/LocalContext'
import useTranslate from 'modules/local/useTranslate'
import {useHistory} from 'modules/navigation/useRouter'
import React, {useContext, useMemo} from 'react'
import {
  BsBag,
  BsBagFill,
  BsBell,
  BsBellFill,
  BsBookmark,
  BsBookmarkFill,
  BsChat,
  BsChatFill,
  BsInfoCircle,
  BsSearch
} from 'react-icons/bs'
import {FaSearch} from 'react-icons/fa'
import {FiLogOut} from 'react-icons/fi'
import {TiPlus} from "react-icons/ti"
import {ReactReduxContext, useSelector} from 'react-redux'
import {matchPath} from 'react-router'
import {Link} from "react-router-dom"
import {useLocation} from 'react-use'
import {nest, withProps} from 'recompose'
import {getLogin, getLoginBoolean, getMessageNotifications, getNotifications} from 'redux/selectors'
import {Selector} from 'views/Discovery/Recommendation'
import organizationModel from "../../../../apis/model/base"
import {organizationSchema, userSchema} from "../../../../apis/schema"
import Translate from "../../../../modules/local/Translate"
import {HistoryContext} from "../../../../modules/navigation/NavigationProvider"
import {SelectEntityItem} from "../../../SelectEntityItem"
import {LanguageSwitch} from './LanguageSwitch'
import './SiderMenu.css'

const MenuItemIcon = ({
  component,
  activeComponent,
  children,
  ...props
}) => {
  const location = useLocation()
  const isActive = matchPath(
    location.pathname,
    {
      path: props.path
    }
  )
  if (children) {
    return children(isActive)
  }
  return (
    <div className="SiderMenu-icon">
      <Icon
        {...{
          style: {
            fontSize: '1.5rem'
          }
        }}
        className={
          isActive
            ? 'text-primary'
            : 'text-color'
        }
        component={
          isActive
            ? activeComponent ||
            component
            : component
        }
        {...props}
      />
    </div>
  )
}
const User = () => {
  const login = useSelector(getLogin)
  return (
    <span>
      <Avatar
        size="large"
        className="background-200"
        src={userModel.getFullAvatarUrl(
          login
        )}></Avatar>
      <span className="SiderMenu-label">
        {userModel.getTitle(login)}
      </span>
    </span>
  )
}
const Lang = withProps(() => {
  const login = useContext(LoginContext)
  const { lang } = useContext(
    LocalContext
  )
  const _languages = _.get(
    login,
    'following_languages',
    ['vi']
  )
  const following_languages = _.isEmpty(
    _languages
  )
    ? ['vi']
    : _languages
  return {
    title: (
      <Badge
        count={
          <div className="flex inline-flex overflow-hidden">
            {following_languages.map(
              (e, i) => {
                return (
                  <Avatar
                    key={i}
                    className={classNames(
                      i !== 0
                        ? '-ml-2'
                        : '',
                      'w-4 h-4 border-2 border-white background-200 rounded-full inline-grid content-center justify-center'
                    )}
                    icon={
                      <FlagIcon
                        type={e}
                        {...{
                          style: {
                            fontSize:
                              '1.2rem',
                            color:
                              'var(--color-primary)'
                          }
                        }}
                      />
                    }
                  />
                )
              }
            )}
          </div>
        }
        offset={[-8, 8]}>
        <Avatar
          className={classNames(
            'w-8 h-8 border-2 border-white background-200 rounded-full m-1 mx-2 inline-grid content-center justify-center'
          )}
          icon={
            <FlagIcon
              type={lang}
              {...{
                style: {
                  fontSize: '3.2rem',
                  color:
                    'var(--color-primary)'
                }
              }}
            />
          }
        />
      </Badge>
    )
  }
})(LanguageSwitch)

const menuEntities = {
  home: {
    key: 'home',
    label: 'home',
    to: {
      pathname: '/'
    },
    type: 'item',
    icon: (
      <MenuItemIcon
        {...{
          component: ShortFeedinSvg,
          path: '/'
        }}
      />
    ),
    valid: []
  },
  mart: {
    key: 'mart',
    label: 'mart',
    to: {
      pathname: '/mart'
    },
    icon: (
      <MenuItemIcon
        {...{
          component: BsBag,
          activeComponent: BsBagFill,
          path: '/mart'
        }}
      />
    ),
    valid: ['dev']
  },
  messages: {
    key: 'messages',
    label: 'messages',
    to: {
      pathname: '/messages'
    },
    icon: (
      <Selector
        selector={getMessageNotifications}>
        {count => (
          <MenuItemIcon>
            {active => (
              <div className="SiderMenu-icon">
                <Badge count={count}>
                  <Icon
                    {...{
                      style: {
                        fontSize:
                          '1.5rem'
                      }
                    }}
                    className={
                      active
                        ? 'text-primary'
                        : 'text-color'
                    }>
                    {!active ? (
                      <BsChat />
                    ) : (
                      <BsChatFill />
                    )}
                  </Icon>
                </Badge>
              </div>
            )}
          </MenuItemIcon>
        )}
      </Selector>
    ),
    valid: ['dev']
  },
  user: {
    key: 'user',
    label: '',
    to: {
      pathname: '/user/home'
    },
    component: User,
    valid: ['login']
  },
  search: {
    key: 'search',
    label: 'search',
    to: {
      pathname: '/search',
      state: {
        isModal: true
      }
    },
    icon: (
      <MenuItemIcon
        {...{
          component: BsSearch,
          activeComponent: FaSearch,
        }}
      />
    ),
    valid: []
  },
  notification: {
    key: 'notification',
    label: 'notification',
    to: {
      pathname: '/notifications'
    },
    icon: (
      <Selector
        selector={getNotifications}>
        {count => (
          <MenuItemIcon>
            {active => (
              <div className="SiderMenu-icon">
                <Badge count={count}>
                  <Icon
                    {...{
                      style: {
                        fontSize:
                          '1.5rem'
                      }
                    }}
                    className={
                      active
                        ? 'text-primary'
                        : 'text-color'
                    }>
                    {!active ? (
                      <BsBell />
                    ) : (
                      <BsBellFill />
                    )}
                  </Icon>
                </Badge>
              </div>
            )}
          </MenuItemIcon>
        )}
      </Selector>
    ),
    valid: ['login']
  },
  discovery: {
    key: 'discovery',
    label: 'discovery',
    to: {
      pathname: '/discovery'
    },
    icon: (
      <MenuItemIcon
        {...{
          component: BsSearch,
          activeComponent: FaSearch,
          path: '/discovery'
        }}
      />
    ),
    valid: []
  },
  provider: {
    key: 'provider',
    label: 'provider',
    render: () => {
      return (
        <Menu.Item
          key={'provider'}
          title={'provider'}>
          <LoginContext.Consumer>
            {login => {
              if (login.primary_org) {
                return (
                  <HistoryContext.Consumer>
                    {history => (
                      <Translate>
                        {t => (
                          <SelectEntityItem item={login.username} schema={userSchema}>
                            {
                              login => (login || null) && <SelectEntityItem
                                item={
                                  login.primary_org
                                }
                                schema={
                                  organizationSchema
                                }>
                                {item => (
                                  <>
                                    <div
                                      className="SiderMenu-icon"
                                      onClick={() =>
                                        history.push(
                                          '/workspace'
                                        )
                                      }>
                                      <Avatar
                                        shape={
                                          'square'
                                        }
                                        className="rounded-lg border"
                                        style={{
                                          width:
                                            '80%',
                                          height:
                                            '80%'
                                        }}
                                        src={organizationModel.getFullAvatarUrl(
                                          item
                                        )}
                                      />
                                    </div>
                                    <span className="SiderMenu-label">
                                      {t(
                                        'provider'
                                      )}
                                    </span>
                                  </>
                                )}
                              </SelectEntityItem>
                            }
                          </SelectEntityItem>

                        )}
                      </Translate>
                    )}
                  </HistoryContext.Consumer>
                )
              }
              return (
                <Link
                  to={{
                    pathname:
                      '/CreateProvider',
                    state: {
                      isModal: true
                    }
                  }}>
                  <MenuItemIcon
                    {...{
                      style: {
                        fontSize:
                          '1.4em',
                        color:
                          'var(--color-primary)'
                      },
                      component: TiPlus
                    }}
                  />
                  <span className="SiderMenu-label">
                    {'provider'}
                  </span>
                </Link>
              )
            }}
          </LoginContext.Consumer>
        </Menu.Item>
      )
    },
    valid: ['login', 'dev']
  },
  bookmark: {
    key: 'bookmark',
    label: 'favorites',
    to: {
      pathname: '/bookmark'
    },
    icon: (
      <MenuItemIcon
        {...{
          // style: {
          //   fontSize: '1.6em',
          // },
          component: BsBookmark,
          activeComponent: BsBookmarkFill,
          path: '/bookmark'
        }}
      />
    ),
    valid: ['login']
  },
  start: {
    key: 'start',
    label: 'welcome',
    to: {
      pathname: '/start'
    },
    icon: (
      <MenuItemIcon
        {...{
          style: {
            fontSize: '1.4em',
            color:
              'var(--color-primary)'
          },
          component: InfoSvg
        }}
      />
    ),
    valid: []
  },
  about: {
    key: 'about',
    label: 'about us',
    to: {
      pathname: '/document/about-us'
    },
    valid: []
  },
  policy: {
    key: 'policy',
    label: 'privacy policy',
    to: {
      pathname:
        '/document/privacy-policy'
    },
    valid: []
  },
  help: {
    key: 'help',
    label: 'helps',
    to: {
      pathname: '/document/tutorials'
    },
    valid: []
  },
  cookies_policy: {
    key: 'cookies_policy',
    label: 'cookies policy',
    to: {
      pathname:
        '/document/cookies-policy'
    },
    valid: []
  },
  terms_of_service: {
    key: 'terms_of_service',
    label: 'terms of service',
    to: {
      pathname:
        '/document/terms-of-service'
    },
    valid: []
  },
  frequently_questions: {
    key: 'frequently_questions',
    label: 'frequently questions',
    to: {
      pathname:
        '/document/frequently-questions'
    },
    valid: []
  },
  contact_info: {
    key: 'contact_info',
    label: 'contact info',
    to: {
      pathname: '/document/contact-info'
    },
    valid: []
  },
  trust_safe: {
    key: 'trust_safe',
    label: 'trust and safe',
    to: {
      pathname: '/document/trust-safe'
    },
    valid: []
  },
  logout: {
    key: 'logout',
    label: 'logout',
    to: {
      pathname: '/logout'
    },
    icon: (
      <MenuItemIcon
        {...{
          style: {
            fontSize: '1.4em'
          },
          component: FiLogOut
        }}
      />
    ),
    valid: ['login']
  },
  language: {
    key: 'language',
    label: 'language',
    icon: (
      <MenuItemIcon
        {...{
          style: {
            fontSize: '1.4em'
          },
          component: Lang
        }}
      />
    )
  }
}

const menuConfig = [
  menuEntities.home,
  menuEntities.mart,
  menuEntities.discovery,
  menuEntities.bookmark,
  menuEntities.notification,
  menuEntities.messages,
  {
    key: 'help',
    label: 'helps',
    icon: (
      <MenuItemIcon
        {...{
          style: {
            fontSize: '1.4em'
          },
          component: BsInfoCircle,
          path: '/help'
        }}
      />
    ),
    subMenu: [
      menuEntities.start,
      menuEntities.about,
      menuEntities.contact_info,
      menuEntities.terms_of_service,
      menuEntities.policy,
      menuEntities.cookies_policy,
      menuEntities.trust_safe,
      menuEntities.help,
      menuEntities.frequently_questions
    ]
  },
  // menuEntities.start,
  // {
  //   key: 'divider',
  //   label: 'divider',
  //   type: 'divider'
  // },
  {
    key: 'space',
    label: 'space',
    type: 'space',
    render: () => (
      <div
        className="flex-1"
        key={_.uniqueId('space')}
      />
    )
  },
  menuEntities.language,
  menuEntities.provider
]

const validEntities = {
  login: ({ isLogin }) => isLogin,
  dev: isDevelop
}
const validateMenu = ({
  valid,
  ...rest
}) => {
  const isValid = valid
    ? !valid.find(
      key =>
        !validEntities[key]({
          ...rest
        })
    )
    : true
  return isValid
}
const renderMenuItem = ({
  isLogin,
  t
}) => (
  {
    key,
    label,
    type,
    subMenu,
    valid,
    component,
    render,
    icon
  } = {},
  i
) => {
    const isValid = validateMenu({
      valid,
      isLogin
    })
    if (!isValid) return null
    if (render) return render()
    if (type === 'divider')
      return (
        <Menu.Divider
          key={_.uniqueId('Menu.Divider')}
        />
      )
    const itemInner = (
      <>
        {icon && icon}
        <span className="SiderMenu-label">
          {t(label)}
        </span>
      </>
    )
    return !subMenu ? (
      <Menu.Item
        key={key}
        title={t(label)}>
        {itemInner}
      </Menu.Item>
    ) : (
      <Menu.SubMenu
        popupClassName="relative"
        key={key}
        title={itemInner}>
        {subMenu.map(
          (
            { key, label, icon, valid },
            i
          ) =>
            validateMenu({
              valid,
              isLogin
            }) ? (
              <Menu.Item
                title={t(label)}
                key={key}>
                {icon && icon}
                <span className="SiderSubMenu-label cursor-pointer">
                  {t(label)}
                </span>
              </Menu.Item>
            ) : null
        )}
      </Menu.SubMenu>
    )
  }
export const SiderMenu = nest(
  ErrorBoundary,
  () => {
    const { store } = useContext(
      ReactReduxContext
    )
    const t = useTranslate()
    const isLogin = getLoginBoolean(
      store.getState()
    )
    // const [setElement] = useState(null)
    const history = useHistory()
    const push = history && history.push
    return useMemo(
      () => (
        <Menu
          className="flex-1"
          prefixCls="SiderMenu"
          theme="light"
          defaultSelectedKeys={['1']}
          onClick={({ key, item }) => {
            if (item.props.to) {
              push(item.props.to)
              return
            }
            if (menuEntities[key].to) {
              push(menuEntities[key].to)
            }
          }}>
          {menuConfig.map(
            renderMenuItem({
              isLogin,
              t
            })
          )}
        </Menu>
      ),
      [isLogin, t, push]
    )
  }
)
