import { List } from 'antd'
import {
  channel_add_Api,
  getChannelsByProps_Api
} from 'apis'
import { channelModel } from 'apis/model'
import ApiInfoForm from 'components/form/ApiInfoForm'
import { ChannelIcon } from 'components/icons/ChannelIcon'
import BaseItemLayout from 'components/ItemLayout/BaseItemLayout'
import Modal from 'components/Modal/Modal'
import Toggle from 'components/Toggle'
import { emptyArray } from 'helpers/emptyObjects'
import getItemKeyId from 'helpers/getItemKeyId'
import getItemType from 'helpers/getItemType'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext,
  useEffect,
  useState
} from 'react'
import { Link } from 'react-router-dom'
import {
  nest,
  withProps
} from 'recompose'
import CreateChannelForm from 'views/CreateChannelForm'
import ServerContext from 'views/Server/ServerContext'
import { Block } from './Block'
import { EditAbleBlockHolder } from './EditAbleBlockHolder'

export const CreateChannelFormModal = nest(
  withProps({
    visible: true,
    footer: null
  })(Modal),
  props => (
    <ServerContext.Consumer>
      {({ item }) => {
        return (
          <div className="px-3">
            <ApiInfoForm
              onSuccess={
                props.onSuccess
              }
              prefixStr="CreateChannelFormModal"
              apiInfo={channel_add_Api}
              onPreSubmit={values => {
                return {
                  ...values,
                  privacy:
                    values.privacy ===
                    '1'
                      ? 2
                      : 1
                }
              }}
              initialValues={{
                owner_id: item.id,
                owner_type: item._type
              }}>
              {({ ...props }) => {
                return (
                  <CreateChannelForm
                    {...props}
                  />
                )
              }}
            </ApiInfoForm>
          </div>
        )
      }}
    </ServerContext.Consumer>
  )
)
export function Channels() {
  const t = useTranslate()
  const [
    forceRefreshKey,
    setForceRefreshKey
  ] = useState(new Date().getTime())
  const { item, type, id } = useContext(
    ServerContext
  )
  const {
    action,
    isLoading,
    success,
    errorMessages,
    result = emptyArray,
    handleAsyncAction,
    error
  } = useAsyncAction({
    apiInfo: getChannelsByProps_Api,
    query: {
      ':type': getItemType(item),
      ':id': getItemKeyId(item)
    }
  })
  useEffect(() => {
    handleAsyncAction()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceRefreshKey])
  const dataSource = (
    item.channels || emptyArray
  ).map(item => {
    const {
      title,
      description,
      id,
      _type
    } = item
    const to = channelModel.getLinkToDetail(
      item
    )
    return (
      <BaseItemLayout
        className="p-3 hover:shadow"
        {...{
          title: (
            <Link
              to={to}
              className="flex ">
              <span
                style={{
                  fontSize: '1.5em'
                }}
                className="mr-1 flex align-self-start ">
                {/*{channelPrefix}*/}
                <ChannelIcon
                  premium={item.premium}
                  className="mr-2 text-gray-600 text-3xl w-8 h-8"
                />
              </span>
              {title}
            </Link>
          ),
          description,
          to
        }}
      />
    )
  })
  return (
    <Block>
      <div className="flex w-full flex flex-col  font-semibold text-gray-600">
        <h2 className="text-2xl py-3 font-semibold flex-1">
          {t('channels')}
        </h2>
        <List
          grid={{
            gutter: 36,
            xs: 1,
            sm: 1,
            md: 1,
            lg: 2,
            xl: 2,
            xxl: 2
          }}
          dataSource={dataSource}
          renderItem={item => (
            <List.Item
              style={{
                height: '150px'
              }}>
              {item}
            </List.Item>
          )}
        />
      </div>
      {item.edit && (
        <Toggle>
          {(isToggle, toggle) => (
            <>
              <EditAbleBlockHolder
                onClick={toggle}
                title={t(
                  'create channel'
                )}
                description={`i take some issue with how they've defined "rewrite" in there.`}
              />
              {isToggle && (
                <CreateChannelFormModal
                  onSuccess={() =>
                    setForceRefreshKey(
                      new Date().getTime()
                    )
                  }
                  footer={null}
                  onCancel={toggle}
                  visible={true}
                />
              )}
            </>
          )}
        </Toggle>
      )}
    </Block>
  )
}
export default Channels
