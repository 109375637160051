import { LeftOutlined } from '@ant-design/icons'
import classnames from 'classnames'
import Count from 'components/Feed/Count'
import Null from 'components/Null'
import { emptyObject } from 'helpers/emptyObjects'
import useRouter from 'modules/navigation/useRouter'
import React from 'react'
import './PageHeader.css'
const innnerStyle = {
  width: '80%',
  height: '80%'
}
export const HeaderButton = ({
  component,
  className,
  round = 'full',
  innerClass,
  style = emptyObject,
  width = 50,
  height = 50,
  iconStyle = emptyObject,
  icon,
  onClick,
  children,
  ...props
}) => {
  return React.createElement(
    component ? component : 'div',
    {
      onClick,
      className: classnames(
        'flex justify-center items-center flex-shrink-0 text-lg',
        className
      ),
      style: {
        width,
        height: height,
        ...style
      },
      children: (
        <div
          data-effect="ripple"
          className={classnames(
            'flex PageHeaderButton  justify-center background hover:background-100  items-center flex-shrink-0 ',
            `rounded-${round}`,
            innerClass
          )}
          style={innnerStyle}>
          {icon && icon}
          {children}
        </div>
      ),
      ...props
    }
  )
}
export const PageHeader = ({
  title,
  onClose = Null,
  extra
}) => {
  const {
    canBack,
    history,
    handleGoBack
  } = useRouter()
  const handleBackClick = () => {
    return handleGoBack()
  }
  return (
    <div className="PageHeader flex background stickyTop items-center border-b border-gray-300">
      <Count
        className="p-5 px-5 text-lg"
        onClick={handleBackClick}>
        <LeftOutlined />
      </Count>
      <span className="capitalize font-semibold ml-2 truncate flex items-center flex-1">
        {title}
      </span>
      {extra}
    </div>
  )
}
