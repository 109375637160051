import React from 'react'

export const OrganizationLayout = ({
  admin,
  header,
  children
}) => {
  return (
    <div className="w-full">
      {admin}
      <div className="container mx-auto ">
        <div
          className="absolute"
          style={{
            top: '-50px'
          }}
          id="home" />
        {header}
        <div>{children}</div>
      </div>
    </div>
  )
}
