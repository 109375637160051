import Pure from 'components/Pure'
import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react'
import useThrottleFn from 'react-use/lib/useThrottleFn'
import useWindowScroll from 'react-use/lib/useWindowScroll'
import {ThemeContext} from './ThemeContext'

const useDetectWindowScrollUpDown = (
  onUp,
  onDown
) => {
  const [
    srollingUp,
    setSrollingUp
  ] = useState(true)
  const { x, y } = useWindowScroll()
  const lastYRef = useRef(y)
  useThrottleFn(
    value => {
      setSrollingUp(
        value < 100 ||
          value <= lastYRef.current
      )
      lastYRef.current = value
    },
    100,
    [y]
  )
  useEffect(() => {
    if (srollingUp) {
      onUp && onUp(x, y)
    } else {
      onDown && onDown(x, y)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [srollingUp])
  return null
}

const setCssVar = (e, name, value) => {
  if (
    !e ||
    !e.style ||
    !e.style.setProperty
  ) {
    console.error('e is not a element')
    return
  }
  e.style.setProperty(name, value)
}
const getCssVar = (e, name) => {
  if (
    !e ||
    !e.style ||
    !e.style.setProperty
  ) {
    console.error('e is not a element')
    return
  }
  var bodyStyles = window.getComputedStyle(
    e
  )
  return bodyStyles.getPropertyValue(
    name
  )
}

const TYPES = {
  HEADER_HEIGHT: '--header-height',
  LEFT_SIDEBAR_WIDTH:
    '--leftSideBar-width',
  STICKY_OFFSET_TOP: '--sticky-offset',
  STICKY_OFFSET_BOTTOM:
    '--stickyBottom-offset'
}
const handleSetCssVar = setCssVar.bind(
  null,
  document.body
)
const handleGetCssVar = getCssVar.bind(
  null,
  document.body
)
const createVarCss = str =>
  `var(${str})`
const defaulValues = {
  headerHeight: createVarCss(
    TYPES.HEADER_HEIGHT
  ),
  lefSideBarWidth: createVarCss(
    TYPES.LEFT_SIDEBAR_WIDTH
  ),
  stickyOffset: createVarCss(
    TYPES.STICKY_OFFSET_TOP
  ),
  stickyOffsetBottom: createVarCss(
    TYPES.STICKY_OFFSET_BOTTOM
  ),
  handleSetCssVar,
  handleGetCssVar,
  changeStickyTop: handleSetCssVar.bind(
    null,
    TYPES.STICKY_OFFSET_TOP
  ),
  changeStickyBottom: handleSetCssVar.bind(
    null,
    TYPES.STICKY_OFFSET_BOTTOM
  )
}
const onScrollUp = () => {
  requestAnimationFrame(() => {
    document.body.classList.remove(
      'scrollingDown'
    )
  })
}
const onScrollDown = () =>
  requestAnimationFrame(() => {
    document.body.classList.add(
      'scrollingDown'
    )
  })
const useDarkMode = (
  initialValue = 'auto'
) => {
  const getTheme = useCallback(
    () =>
      localStorage.getItem('theme') ||
      initialValue,
    [initialValue]
  )
  const [theme, setTheme] = useState(
    getTheme()
  )
  const getEffectTheme = useCallback(() => {
    let effectTheme = theme || 'light'
    if (
      theme === 'auto' &&
      window.matchMedia &&
      window.matchMedia(
        '(prefers-color-scheme: dark)'
      ).matches
    ) {
      effectTheme = 'dark'
    }
    return effectTheme
  }, [theme])

  useLayoutEffect(() => {
    localStorage.setItem('theme', theme)
    let element = document.body
    element.setAttribute('theme', theme)
  }, [theme])

  return {
    theme,
    getTheme,
    getEffectTheme,
    setTheme
  }
}
const ThemeProvider = ({
  initvalues = defaulValues,
  device,
  children
}) => {
  const datkModeProps = useDarkMode()
  const value = React.useMemo(() => {
    return {
      ...initvalues,
      device,
      ...datkModeProps
    }
  }, [
    datkModeProps,
    device,
    initvalues
  ])
  useLayoutEffect(() => {
    document.body.setAttribute(
      'device',
      device
    )
    if (device) {
      document.body.classList.add(
        'mobile'
      )
    }
  }, [device])

  useDetectWindowScrollUpDown(
    device && onScrollUp,
    device && onScrollDown
  )

  return (
    <Pure values={[value]}>
      <ThemeContext.Provider
        value={value}>
        {children}
      </ThemeContext.Provider>
    </Pure>
  )
}

export default ThemeProvider
