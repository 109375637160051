import { Avatar } from 'antd'
import { getLinkToDetail } from 'apis/model/base'
import getTitle from 'helpers/getTitle'
import getUpperFirstChar from 'helpers/getUpperFirstChar'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { Link } from 'react-router-dom'
import {
  branch,
  compose,
  mapProps
} from 'recompose'
import { ChannelIcon } from '../icons/ChannelIcon'
import './FeedCard.css'

const FeedContainerStateless = ({
  item
}) => {
  const t = useTranslate()
  return (
    <div className="inline-flex truncate items-center justify-start text-gray-600">
      <Avatar
        shape={
          _.get(
            item,
            'container._type'
          ) === 'user'
            ? 'circle'
            : 'square'
        }
        style={{
          marginRight: '0.5em'
        }}
        className="flex-shrink-0"
        src={_.get(
          item,
          'container.thumbnail'
        )}>
        {getUpperFirstChar(
          getTitle(
            _.get(item, 'container')
          )
        )}
      </Avatar>
      <div className="">
        <Link
          to={getLinkToDetail(
            item.container
          )}
          className="font-bold cursor-pointer">
          {_.get(
            item,
            'container.title'
          ) ||
            _.get(
              item,
              'container.name'
            )}
        </Link>
        <div className="font-normal text-xs">
          {t(
            _.get(
              item,
              'container._type',
              ''
            )
          )}
        </div>
      </div>
    </div>
  )
}

const FeedContainerChannelStateless = ({
  item
}) => (
  <div className="inline-flex truncate items-center justify-start text-gray-600">
    <div
      style={{
        marginRight: '0.5rem',
        fontSize: '2rem'
      }}
      className="flex-shrink-0">
      <ChannelIcon
        premium={_.get(
          item,
          'container.premium',
          0
        )}
        className="mr-2 text-gray-600 text-3xl w-8 h-8"
      />
    </div>
    <div className="">
      <Link
        to={getLinkToDetail(
          item.container
        )}
        className="font-bold cursor-pointer">
        {_.get(
          item,
          'container.title'
        ) ||
          _.get(item, 'container.name')}
      </Link>
      <div className="font-normal text-xs">
        {_.get(
          item,
          'container.owner.title'
        ) ||
          _.get(
            item,
            'container.owner.name'
          )}
      </div>
    </div>
  </div>
)

export default compose(
  mapProps(props => ({
    container_type: _.get(
      props,
      'item.container._type'
    ),
    ...props
  })),
  branch(
    ({ container_type }) =>
      container_type === 'channel',
    () => FeedContainerChannelStateless
  )
  // branch(
  //     ({container_type, ...props}) => container_type === 'user'
  //         && _.get(props, 'login.user_id') === _.get(props, 'item.container.user_id')
  //         && _.get(props, 'item.container.user_id') === _.get(props, 'item.creator.user_id'),
  //     () => FeedContainerAuthStateless
  // ),
)(FeedContainerStateless)
