import { MessageOutlined } from '@ant-design/icons'
import { Avatar, Button, Input, notification } from 'antd'
import classNames from 'classnames'
import _ from 'lodash'
import { useAppConfig } from 'modules/local'
import React, { useContext, useRef } from 'react'
import { FiPhoneOff } from "react-icons/fi"
import { useHistory } from 'react-router-dom'
import { create_message_sendRequestToOrg_Api } from "../../../apis"
import { baseModel, organizationModel, userModel } from '../../../apis/model'
import EmptyHolder from "../../../components/EmptyHolder"
import { API_ROOT_URL } from '../../../envs/_current/config'
import { createAsyncAction } from '../../../modules/asyncCache'
import { AsyncByActionWithNotify } from '../../../modules/asyncCache/components/Async'
import useDispatchAsyncAction from "../../../modules/asyncCache/useDispatchAsyncAction"
import useTranslate from '../../../modules/local/useTranslate'
import ServerContext from '../../../views/Server/ServerContext'
import { useRequiredLogin } from "../../Server/useRequiredLogin"

const SubmitRequirement = ({
  item,
  textAreaRef,
  children
}) => {
  const t = useTranslate()
  const [
    { response, isLoading },
    asyncSendRequestToProvider
  ] = useDispatchAsyncAction({
    onSuccess: (__, res) => {
      // const node = document.querySelector(
      //   '#messages'
      // )
      // node.value = ''
      // const id = _.get(
      //   res,
      //   'response.data.id'
      // )
      notification.success({
        message: t(
          'Your message have been sent.'
        ),
        // description: (
        //   <a
        //     className="float-right"
        //     href={`/messages?conversationId=${id}`}
        //     target="_blank">
        //     <Button>
        //       {'Go to conversation'}
        //     </Button>
        //   </a>
        // )
      })
    },
    onError: (...args) => {
      const [
        error,
        errorObj = {
          error_code: 'System error!',
          error: '-1'
        }
      ] = args;

      notification.error({
        message:
          typeof errorObj.error_code ===
            'string'
            ? errorObj.error_code
            : JSON.stringify(
              errorObj.error_code
            ),
        description:
          typeof errorObj.error ===
            'string'
            ? errorObj.error
            : JSON.stringify(
              errorObj.error
            ),
        duration: 3,
        placement: 'topRight'
      })
    }
  })
  const requireLogin = useRequiredLogin()
  const handleSendRequestToProvider = () => {
    // const node = document.querySelector(
    //   '#messages'
    // )
    const node = textAreaRef.current;
    if (node) {
      requireLogin(() =>
        asyncSendRequestToProvider(
          createAsyncAction({
            apiInfo: create_message_sendRequestToOrg_Api(),
            query: {
              ':organization_id':
                item.idname
            },
            values: {
              content: _.get(node, 'resizableTextArea.textArea.value')
            }
          })
        )
      )
    } else {
      console.error(
        '#messages not found'
      )
    }
  }
  return children({
    handleSendRequestToProvider,
    isLoading
  })
}

export const QuickContactBox = React.memo(
  ({ prefixCls }) => {
    const t = useTranslate()
    const { item } = useContext(
      ServerContext
    );
    const history = useHistory();

    const textAreaRef = useRef();

    const appConfig = useAppConfig()


    const role_titles = _.get(appConfig, 'role_titles', []);

    return (
      <div className={classNames(prefixCls, "border border-gray-300 background space-y-3 w-full h-full")}>
        <div className="leading-relaxed font-bold">
          {t('contact provider')}
        </div>
        {
          _.get(item, 'contacts.length') > 0
            ? (
              <React.Fragment>
                <div className="space-y-3">
                  <div className="flex items-center">
                    <Avatar
                      shape="circle"
                      size="large"
                      src={
                        _.get(
                          item,
                          'contacts.0.contact_member.user.avatar'
                        )
                      }
                      className="mr-3 flex-shrink-0 text-blue-600 bg-blue-200" />
                    <div className="flex-col">
                      <div className="flex flex-1 font-semibold">
                        {
                          _.has(item, 'contacts.0.prefix_name') && !_.isEmpty(_.get(item, 'contacts.0.prefix_name')) && (
                            <span className="pr-1">{t(_.get(item, 'contacts.0.prefix_name'))}</span>
                          )
                        }
                        {
                          _.has(item, 'contacts.0.name') && !_.isEmpty(_.get(item, 'contacts.0.name')) && (
                            <span className="pr-1">{_.get(item, 'contacts.0.name')}</span>
                          )
                        }
                      </div>
                      <div className="text-color-100">
                        {
                          role_titles[
                          _.get(
                            item,
                            'contacts.0.role_title'
                          )
                          ]
                        }
                      </div>

                      <AsyncByActionWithNotify
                        onSuccess={([
                          __,
                          res
                        ]) => {
                          const conversationId = _.get(
                            res,
                            'response.data.id'
                          )
                          history.push(
                            `/messages?conversationId=${conversationId}`
                          )
                        }}>
                        {(
                          data,
                          dispatch
                        ) => {
                          return (
                            <div
                              onClick={() => {
                                dispatch(
                                  createAsyncAction(
                                    {
                                      apiInfo: {
                                        backend: ``,
                                        root: API_ROOT_URL,
                                        path:
                                          '/messages/conversation/create',
                                        method:
                                          'POST'
                                      },
                                      values: {
                                        organization_id: organizationModel.getId(
                                          item
                                        ),
                                        receiver_id: userModel.getId(
                                          baseModel.get(
                                            item,
                                            'owner'
                                          )
                                        )
                                      }
                                    }
                                  )
                                )
                              }}
                              className="text-primary block cursor-pointer">
                              <MessageOutlined />{' '}
                              {t(
                                'leave a message'
                              )}
                            </div>
                          )
                        }}
                      </AsyncByActionWithNotify>
                    </div>
                  </div>
                </div>
                <div className="space-y-3 flex flex-col">
                  <Input.TextArea
                    id="messages"
                    className="block"
                    ref={textAreaRef}
                    rows={4}
                    placeholder={t(
                      'Enter message text with minimum requirement of 20, maximum of 4000 characters'
                    )} />
                  <SubmitRequirement
                    textAreaRef={textAreaRef}
                    item={item}>
                    {({
                      handleSendRequestToProvider,
                      isLoading
                    }) => {
                      return (
                        <Button
                          onClick={() =>
                            handleSendRequestToProvider()
                          }
                          loading={
                            isLoading
                          }
                          type="primary"
                          className="self-end">
                          {t(
                            'Submit requirement'
                          )}
                        </Button>
                      )
                    }}
                  </SubmitRequirement>
                </div>
              </React.Fragment>
            )
            : (
              <React.Fragment>
                <EmptyHolder
                  icon={
                    () => (
                      <div
                        style={{
                          fontSize: '3rem',
                          color: 'rgba(226, 232, 240, 1)'
                        }}>
                        <FiPhoneOff />
                      </div>
                    )
                  }
                  title={null} subTitle={null} />
              </React.Fragment>
            )
        }
      </div>
    )
  }
);