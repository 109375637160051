import Icon from '@ant-design/icons'
import {message} from 'antd'
import {function_article_createArticle_Api, post_createRepost_Api} from 'apis'
import DefaultLayout from 'components/layouts/Default'
import ContentLayout from 'components/layouts/Default/ContentLayout'
import Modal from 'components/Modal/Modal'
import {FullSideModalLayout} from 'components/Modal/SideModalLayout'
import Null from 'components/Null'
import logFunc from 'helpers/logFunc'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import {NavigationContext} from 'modules/navigation/NavigationContext'
import {useHistory} from 'modules/navigation/useRouter'
import React, {useContext, useEffect} from 'react'
import {MdClose} from 'react-icons/md'
import Loadable from 'react-loadable'
import {useSelector} from 'react-redux'
import {Redirect} from 'react-router-dom'
import {fromRenderProps, nest, withProps} from 'recompose'
import {compose} from 'redux'
import {getLogin} from 'redux/selectors'
import Account from 'views/Account'
import AccountSettings from 'views/AccountSettings'
import {ForgetPassword, Login, Logout, ResetPassword} from 'views/Auth'
import {AddPaymentFailure, AddPaymentSuccess, AddPaymentWithPlanSuccess, Billing} from 'views/Billing'
import {Bookmark} from 'views/Bookmark'
import Category from 'views/Category'
import {Channel} from 'views/Channel'
import ChannelSetting from 'views/ChannelSetting'
import {ChartLoadable} from 'views/Chart'
import CreateArticle from 'views/CreateArticle'
import CreateChannel from 'views/CreateChannel'
import CreatePoll from 'views/CreatePoll'
import CreateProvider from 'views/CreateProvider'
import {DataLoadable} from 'views/Data'
import DatasetSetting from 'views/DatasetSetting'
import DemoPage from 'views/DemoPage'
import Discovery from 'views/Discovery'
import DocumentEditor from 'views/DocumentEditor'
import EditArticle from 'views/EditArticle'
import Feed from 'views/Feed'
import GetQuotes from 'views/GetQuotes'
import Invite from 'views/Invite'
import InviteDataset from 'views/InviteDataset'
import InviteOrganization from 'views/InviteOrganization'
import LandingPage from 'views/LandingPage'
import LoadingPage from 'views/LoadingPage'
import ProductsSearch from 'views/Market/ProductsSearch.lazy'
import {marketRoutes} from 'views/Market/routes'
import Menu from 'views/Menu'
import Messages from 'views/Messages/Messages.lazy'
import News from 'views/News'
import NoFoundPage from 'views/NoFoundPage'
import Notification from 'views/Notification'
import {Organization} from 'views/Organization'
import {HeaderButton} from 'views/Organization/components/PageHeader'
import Settings from 'views/Organization/Settings'
import {SettingsModal} from 'views/Organization/Settings/SettingsModal'
import QuickLink from 'views/QuickLink'
import QuickLinkSetting from 'views/QuickLinkSetting'
import SelectCategory from 'views/SelectCategory'
import TestApi from 'views/TestApi/TestApi'
import TestFunc from 'views/TestApi/TestFunc'
import User from 'views/User'
import ValidateAccount from 'views/ValidateAccount'
import {workSpaceRoutes} from 'views/Workspace/routes'
import ContactProvider from "../views/ContactProvider"
import {createRequiredLogin, ModalLogin} from './createRequiredLogin'
import {createRequiredNonLogin} from './createRequiredNonLogin'
import {REQUIRED_LOGIN, REQUIRED_NON_LOGIN} from './routesValidate'
import {staticPaths} from './staticPaths'

const Topic = Loadable({
  loader: () =>
    import('../views/Topic/Topic'),
  loading: ({ isLoading }) =>
    isLoading && <LoadingPage />
})
const Search = Loadable({
  loader: () =>
    import('../views/Search/Search'),
  loading: ({ isLoading }) =>
    isLoading && <LoadingPage />
})
export const RequiredLogin = Loadable({
  loader: () => import('../views/403'),
  loading: ({ isLoading }) =>
    isLoading && <LoadingPage />
})

const Navigation = Loadable({
  loader: () =>
    import(
      '../views/Navigation/Navigation'
    ),
  loading: ({ isLoading }) =>
    isLoading && <LoadingPage />
})

const PageDetail = Loadable({
  loader: () =>
    import('../views/User/PageDetail'),
  loading: ({ isLoading }) =>
    isLoading && <LoadingPage />
})
const FeedSetting = Loadable({
  loader: () =>
    import('../views/Feed/FeedSetting'),
  loading: Null
})
const ChartSetting = Loadable({
  loader: () =>
    import(
      '../views/DatasetSetting/ChartSetting/ChartSetting'
    ),
  loading: Null
})
// const FeedCard = Loadable({
// 	loader: () =>
// 		import(
// 			'../views/Organization/components/FeedCard'
// 		),
// 	loading: ({ isLoading }) =>
// 		isLoading && <LoadingPage />
// });

const EnchanedCreateArticle = () => {
  const login = useSelector(getLogin)
  const { handleGoBack } = useContext(
    NavigationContext
  )
  const {
    result,
    response,
    success,
    isLoading,
    handleAsyncAction: handleAsyncPost
  } = useAsyncAction({
    apiInfo: function_article_createArticle_Api,
    query: {
      ':prop': login._type,
      ':id': login.username
    },
    onSuccess: () => {
      message.success('success')
    },
    onError: () =>
      message.error('error')
  })
  const history = useHistory()
  useEffect(() => {
    if (success) {
      history.replace({
        pathname: `/news/article/${_.get(
          response,
          'data.content.id'
        )}`
        // state: {isModal: true},
      })
    }
  }, [
    history,
    response,
    result,
    success
  ])
  return (
    <CreateArticle
      isLoading={isLoading}
      onCancel={handleGoBack}
      onSubmit={handleAsyncPost}
    />
  )
}
const EnchanedQuickLink = () => {
  const login = useSelector(getLogin)
  const { handleGoBack } = useContext(
    NavigationContext
  )
  const {
    success,
    result,
    isLoading,
    response,
    handleAsyncAction: handleAsyncPost
  } = useAsyncAction({
    apiInfo: post_createRepost_Api,
    query: {
      ':prop': login._type,
      ':id': login.id
    },
    onSuccess: result => {
      message.success('posted')
    },
    onError: () =>
      message.error('error')
  })
  const history = useHistory()
  useEffect(() => {
    if (success) {
      history.replace({
        pathname: `/news/post/${_.get(
          response,
          'data.data.content.id'
        )}`
        // state: { isModal: true },
      })
    }
  }, [
    history,
    response,
    result,
    success
  ])
  const t = useTranslate()
  return (
    <FullSideModalLayout
      title={t('quick link')}
      center
      onCancel={handleGoBack}>
      <QuickLink
        isLoading={isLoading}
        onSubmit={handleAsyncPost}
      />
    </FullSideModalLayout>
  )
}
const EnchanedQuickLinkSetting = () => {
  const login = useSelector(getLogin)
  const { handleGoBack } = useContext(
    NavigationContext
  )
  const {
    success,
    result,
    isLoading,
    response,
    handleAsyncAction: handleAsyncPost
  } = useAsyncAction({
    apiInfo: post_createRepost_Api,
    query: {
      ':prop': login._type,
      ':id': login.id
    },
    onSuccess: result => {
      message.success('posted')
    },
    onError: () =>
      message.error('error')
  })
  const history = useHistory()
  useEffect(() => {
    if (success) {
      history.replace({
        pathname: `/news/post/${_.get(
          response,
          'data.data.content.id'
        )}`
        // state: { isModal: true },
      })
    }
  }, [
    history,
    response,
    result,
    success
  ])
  const t = useTranslate()
  return (
    <FullSideModalLayout
      title={t('quick link setting')}
      center
      onCancel={handleGoBack}>
      <QuickLinkSetting
        isLoading={isLoading}
        onSubmit={handleAsyncPost}
      />
    </FullSideModalLayout>
  )
}

export const routesConfig = [


  {
    path: '/deposit/:orderId/success',
    name: 'AddPaymentSuccess',
    component: AddPaymentSuccess
  },
  {
    path: '/richeditor',
    name: 'richeditor',
    component: Loadable({
      loader: () =>
        import('components/RichEditor'),
      loading: ({ isLoading }) =>
        isLoading && <LoadingPage />
    })
  },
  {
    path:
      '/class-subscription-confirm/:planId/success',
    breadcrumbName:
      'payment subscription',
    component: AddPaymentWithPlanSuccess
  },
  {
    path: '/deposit/:orderId/failed',
    name: 'activate',
    component: AddPaymentFailure
  },
  {
    path:
      '/register/:username/activate/:activation_reset_code',
    name: 'activate',
    component: ValidateAccount
  },

  {
    path: '/account/billing',
    name: 'billing',
    component: createRequiredLogin()(
      DefaultLayout
    ),
    routes: [
      {
        path: '/account/billing',
        name: 'billing',
        component: Billing,
        exact: true
      },
      {
        path: '/account/billing/:sub',
        name: 'billing',
        component: Billing
      }
    ]
  },

  {
    key: 'reset-pwd',
    name: 'reset password',
    path: '/reset-pwd',
    exact: true,
    component: ResetPassword
  },
  {
    key: 'logout',
    name: 'logout',
    path: '/logout',
    exact: true,
    component: Logout
  },
  {
    path: '/invite/:id',
    name: 'invite',
    component: Invite,
    exact: true
  },
  {
    path: '/invite/organization/:id',
    name: 'invite',
    component: InviteOrganization,
    exact: true
  },
  {
    path: '/invite/dataset/:id',
    name: 'invite',
    component: InviteDataset,
    exact: true
  },
  {
    key: 'select_category',
    name: 'select-category',
    path: '/select-category',
    exact: true,
    component: () => {
      return (
        <Translate>
          {t => (
            <NavigationContext.Consumer>
              {({ handleGoBack }) => {
                return (
                  <FullSideModalLayout
                    title={t(
                      'categories'
                    )}
                    // center
                    onCancel={
                      handleGoBack
                    }>
                    <SelectCategory
                      onSuccess={
                        handleGoBack
                      }
                    />
                  </FullSideModalLayout>
                )
              }}
            </NavigationContext.Consumer>
          )}
        </Translate>
      )
    },
    meta: {
      validate: [REQUIRED_LOGIN]
    }
  },
  {
    path: '/',
    name: 'home',
    component: DefaultLayout,
    routes: [
      ...marketRoutes,
      ...workSpaceRoutes,
      {
        path: '/mart/get-quotes/product/:id',
        name: 'getQuotes',
        component: GetQuotes
      },
      {
        path: '/mart/contact-provider/:id',
        name: 'contact-provider',
        component: ContactProvider
      },
      {
        key: 'chart',
        name: 'chart',
        path: '/chart/:id',
        exact: true,
        component: ChartLoadable
      },
      {
        key: 'topics',
        name: 'topics',
        path: '/t/:id',
        exact: true,
        component: Topic
      },
      {
        key: 'welcome',
        name: 'welcome',
        path: '/welcome',
        exact: true,
        component: createRequiredNonLogin(
          () => (
            <Redirect
              to={staticPaths.home}
            />
          )
        )(LandingPage)
      },
      {
        key: 'demo',
        name: 'demo',
        path: '/demo',
        exact: true,
        component: DemoPage
      },
      {
        key: 'start',
        name: 'start',
        path: '/start',
        exact: true,
        component: LandingPage
      },
      {
        name: 'document',
        path: '/document/:id',
        component: DocumentEditor
      },
      {
        name: 'document',
        path: '/document',
        component: DocumentEditor
      },
      {
        path: '/account',
        name: 'account',
        component: createRequiredLogin()(
          Account
        )
      },

      {
        path: '/user/:id',
        name: 'user',
        component: User,
        exact: true
      },
      {
        path: '/menu',
        name: 'menu',
        component: createRequiredLogin(
          withProps({
            to: '/login',
            state: { isModal: true }
          })(Redirect)
        )(Menu),
        exact: true
      },
      {
        path: '/forgotpassword',
        name: 'forgot password',
        component: ForgetPassword,
        exact: true,
        meta: {
          validate: [REQUIRED_NON_LOGIN]
        }
      },
      {
        path: '/user/account',
        name: 'account center',
        component: PageDetail,
        exact: true,
        meta: {
          validate: [REQUIRED_LOGIN]
        }
      },

      {
        path: '/discovery',
        name: 'discovery',
        exact: true,
        component: Discovery
      },

      {
        path: '/organization/:id',
        name: 'provider',
        component: Organization
      },

      // {
      //   path: '/news',
      //   name: 'news',
      //   component: Articles,
      //   exact: true
      // },

      {
        path: '/article/:id/edit',
        name: 'articles',
        component: EditArticle,
        exact: true
      },

      {
        key: 'category',
        name: 'category',
        path: '/category',
        exact: true,
        component: Category
      },

      {
        path: '/login',
        name: 'login',
        component: Login,
        exact: true,
        meta: {
          validate: [REQUIRED_NON_LOGIN]
        }
      },
      {
        path: '/bookmark',
        name: 'bookmark',
        component: Bookmark,
        exact: true,
        meta: {
          validate: [REQUIRED_LOGIN]
        }
      },
      {
        path: '/bookmark/:id',
        name: 'bookmark',
        component: Bookmark,
        exact: true,
        meta: {
          validate: [REQUIRED_LOGIN]
        }
      },
      {
        key: 'menu',
        name: 'menu',
        path: '/navigationMenu',
        exact: true,
        component: Navigation
      },
      {
        path: '/data/:id/setting',
        name: 'data setting',
        component: createRequiredLogin(
          ModalLogin
        )(
          compose(
            fromRenderProps(
              NavigationContext.Consumer,
              ({ handleGoBack }) => ({
                onCancel: logFunc(
                  handleGoBack
                )
              })
            ),
            withProps(props => {
              return {
                id: _.get(
                  props,
                  'match.params.id'
                )
              }
            })
          )(DatasetSetting)
        )
      },
      {
        key: 'data',
        name: 'data',
        path: '/data/:id',
        component: DataLoadable
      },
      {
        key: 'loading',
        name: 'loading',
        path: '/loading',
        exact: true,
        component: LoadingPage
      },

      {
        path: '/channel/:id',
        name: 'channel',
        component: Channel,
        exact: true
      },
      {
        path: '/notifications',
        name: 'notifications',
        component: createRequiredLogin(
          () => (
            <Redirect
              to={{
                pathname: '/login',
                state: {
                  isModal: true
                }
              }}
            />
          )
        )(Notification),
        exact: true
      },

      {
        path: '/testApi',
        name: 'test api',
        component: TestApi,
        exact: false
      },
      {
        path: '/testFunc',
        name: 'test api',
        component: TestFunc,
        exact: false
      },

      {
        path: '/testApi/:id',
        name: 'test api',
        component: TestApi,
        exact: false
      },

      {
        path: '/DatasetManager',
        key: 'DatasetManager',
        name: 'pageNotFound',
        component: Loadable({
          loader: () =>
            import(
              '../views/DatasetManager'
            ),
          loading: ({ isLoading }) =>
            isLoading && <LoadingPage />
        })
      },
      {
        ...News
      },
      {
        path: '/news/article/:id',
        name: 'news',
        component: Loadable({
          loader: () =>
            import('../views/Article'),
          loading: LoadingPage
        })
      },
      {
        path: '/news/:type/:id',
        name: 'post',
        component: Feed,
        exact: true
      },

      {
        path: '/messages',
        name: 'messages',
        component: createRequiredLogin(
          ModalLogin
        )(Messages)
      },

      // {
      //   path: '/category/:cate',
      //   name: 'news',
      //   component: ArticlesByCategory,
      //   // exact: true
      // },

      {
        path: '/article',
        name: 'news',
        component: Loadable({
          loader: () =>
            import('../views/Article'),
          loading: Null
        })
      },
      // {
      //   path: '/404',
      //   key: '404',
      //   name: 'pageNotFound',
      //   component: NoFoundPage,
      // },
      {
        path: '/404',
        key: '404',
        name: 'pageNotFound',
        component: nest(
          withProps(() => ({
            renderHeader: Null,
            renderMenu: Null,
            renderRight: Null
          }))(ContentLayout),
          NoFoundPage
        )
      },
      {
        // must be at bottom
        path: '/',
        name: 'NoFoundPage',
        component: nest(
          withProps(() => ({
            renderHeader: Null,
            renderMenu: Null,
            renderRight: Null
          }))(ContentLayout),
          NoFoundPage
        )
      }
    ]
  }
]
const PageModal = ({
  children,
  ...props
}) => (
  <NavigationContext.Consumer>
    {({ handleGoBack }) => (
      <Modal
        visible="true"
        footer={null}
        header={
          <HeaderButton
            style={{
              position: 'absolute',
              top: 0,
              right: 0
            }}
            onClick={handleGoBack}>
            <Icon
              style={{
                fontWeight: 700,
                fontSize: '1.2em'
              }}
              component={MdClose}
            />
          </HeaderButton>
        }
        onCancel={handleGoBack}
        {...props}>
        <div className="mx-auto flex flex-1 flex-col">
          {children}
        </div>
      </Modal>
    )}
  </NavigationContext.Consumer>
)

export const modalRoutesConfig = [
  {
    path: '/login',
    name: 'login',
    component: createRequiredNonLogin(
      () => {
        const {
          handleGoBack
        } = useContext(
          NavigationContext
        )
        useEffect(() => {
          handleGoBack()
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [handleGoBack])
        return null
      }
    )(ModalLogin),
    exact: true
  },
  {
    key: 'logout',
    name: 'logout',
    path: '/logout',
    exact: true,
    component: createRequiredLogin(
      withProps({
        to: staticPaths.home
      })(Redirect)
    )(nest(PageModal, Logout))
  },
  {
    path: '/account/settings',
    name: 'account settings',
    component: createRequiredLogin(
      ModalLogin
    )(
      compose(
        fromRenderProps(
          NavigationContext.Consumer,
          ({ handleGoBack }) => ({
            onCancel: handleGoBack
          })
        ),
        withProps(props => ({
          maskClosable: false
        }))
      )(
        nest(
          SettingsModal,
          AccountSettings
        )
      )
    )
  },
  {
    path: '/channel/:id/setting',
    name: 'channel setting',
    component: createRequiredLogin(
      ModalLogin
    )(
      compose(
        fromRenderProps(
          NavigationContext.Consumer,
          ({ handleGoBack }) => ({
            onCancel: handleGoBack
          })
        ),
        withProps(props => {
          return {
            id: _.get(
              props,
              'match.params.id'
            )
          }
        })
      )(ChannelSetting)
    )
    // exact: true
  },

  {
    path: '/organization/:id/setting',
    name: 'provider setting',
    component: createRequiredLogin(
      ModalLogin
    )(
      compose(
        fromRenderProps(
          NavigationContext.Consumer,
          ({ handleGoBack }) => ({
            onCancel: () =>
              handleGoBack()
          })
        ),
        withProps(props => {
          return {
            id: _.get(
              props,
              'match.params.id'
            )
          }
        })
      )(Settings)
    )
    // exact: true
  },
  {
    path: '/createChannel',
    name: 'create channel',
    component: createRequiredLogin(
      ModalLogin
    )(nest(PageModal, CreateChannel))
  },

  {
    path: '/create-provider',
    name: 'Create provider profile',
    component: createRequiredLogin(
      ModalLogin
    )(
      nest(
        PageModal,
        CreateProvider
      )
    ),
    exact: true
  },
  {
    path: '/createPoll',
    name: 'Create Poll',
    component: createRequiredLogin(
      ModalLogin
    )(nest(PageModal, CreatePoll)),
    exact: true
  },
  {
    path: '/createArticle',
    name: 'Write a article',
    component: createRequiredLogin(
      ModalLogin
    )(EnchanedCreateArticle),
    exact: true
  },
  {
    path: '/quickLink',
    name: 'quick link',
    component: createRequiredLogin(
      ModalLogin
    )(EnchanedQuickLink),
    exact: true
  },
  {
    path: '/quickLink/:id/setting',
    name: 'setting quick link',
    component: createRequiredLogin(
      ModalLogin
    )(EnchanedQuickLinkSetting),
    exact: true
  },
  {
    path: '/navigationMenu',
    name: 'menu',
    component: nest(
      PageModal,
      Navigation
    ),
    exact: true
  },
  {
    path: '/news/article/:id/edit',
    name: 'post',
    component: EditArticle,
    exact: true
  },
  {
    path: '/news/:type/:id/edit',
    name: 'post',
    component: createRequiredLogin()(
      FeedSetting
    ),
    exact: true
  },
  {
    path: '/chart/:id/edit',
    name: 'chart',
    component: createRequiredLogin()(
      ChartSetting
    ),
    exact: true
  },
  // {
  //   path: '/news/:type/:id',
  //   name: 'post',
  //   component: ({ location }) => (
  //     <Redirect
  //       to={location.pathname.replace(
  //         '/feed/',
  //         '/news/'
  //       )}
  //     />
  //   ),
  //   exact: true,
  // },

  {
    path: '/search',
    name: 'search',
    component: compose(
      fromRenderProps(
        NavigationContext.Consumer,
        ({ handleGoBack }) => ({
          onCancel: handleGoBack
        })
      ),
      withProps(props => ({}))
    )(Search),
    exact: true
  },
  {
    path: '/mart/search',
    name: 'search products',
    component: compose(
      fromRenderProps(
        NavigationContext.Consumer,
        ({ handleGoBack }) => ({
          onCancel: handleGoBack
        })
      )
    )(ProductsSearch),
    exact: true
  }
  // {
  //   // must be at bottom
  //   path: '/',
  //   name: 'NoFoundPage',

  //   component: nest(({ children }) => {
  //     return (
  //       <NavigationContext.Consumer>
  //         {({ handleGoBack }) => (
  //           <Modal
  //             bodyStype={{
  //               padding: 0
  //             }}
  //             title="404"
  //             header={null}
  //             footer={null}
  //             visible={true}
  //             onCancel={() =>
  //               handleGoBack()
  //             }>
  //             {children}
  //           </Modal>
  //         )}
  //       </NavigationContext.Consumer>
  //     )
  //   }, NoFoundPage)
  // }
]
