import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import ErrorBoundary from 'components/error/ErrorBoundary';
import ContentLayout from 'components/layouts/Default/ContentLayout';
import { FullSideModalLayout } from 'components/Modal/SideModalLayout';
import ScrollTopOnChange from 'components/ScrollTopOnChange';
import GlobalAmchartConfig from 'modules/chart/GlobalAmchartConfig.lazy';
import useTranslate from 'modules/local/useTranslate';
import NavigationProvider from 'modules/navigation/NavigationProvider';
import GlobalShortCuts from 'modules/shortcuts';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import { useToggle } from 'react-use';
import { nest, withProps } from 'recompose';
import NoFoundPage from 'views/NoFoundPage';
import SelectCategory from 'views/SelectCategory';
import LoadMeta from '../meta/LoadMeta';
import { CheckEmail } from './CheckEmail';
import RouteWithSubRoutes from './RouteWithSubRoutes';



export const RedirectToModal = withProps(
  props => ({
    to: {
      ...props.location,
      state: {
        isModal: true
      }
    }
  })
)(Redirect)

export const createRoutes = ({ modalRoutesConfig = [], routesConfig = [] }) => {
  const modalRoutes = modalRoutesConfig.map(
    (route, i) => (
      <RouteWithSubRoutes
        key={i}
        {...route} />
    )
  );
  const redirectModalRoutes = modalRoutesConfig.map(
    (route, i) => (
      <Route
        key={i}
        {...route}
        component={RedirectToModal}
      />
    )
  );
  const mainRoutes = [
    ...redirectModalRoutes,
    ...routesConfig.map((route, i) => (
      <RouteWithSubRoutes
        key={i}
        {...route} />
    )),
    <Route
      key="page_not_found"
      path="/"
      render={nest(
        ContentLayout,
        NoFoundPage
      )} />
  ];
  const Routes = ({
    match,
    location,
    history
  }) => {
    const isModal = !!(
      location.state &&
      location.state.isModal
    );
    const saveLocationRef = useRef(
      location
    );

    if (!isModal) {
      saveLocationRef.current = location;
    }

    const mainLocation = isModal
      ? saveLocationRef.current
      : location;
    return (
      <ErrorBoundary>
        <NavigationProvider
          {...{
            match,
            history,
            location,
            isModal,
            mainLocation,
            saveLocation: saveLocationRef.current,
            routesConfig,
            modalRoutesConfig
          }}>
          <ErrorBoundary>
            <CheckEmail />
          </ErrorBoundary>
          <ErrorBoundary>
            <GlobalAmchartConfig />
          </ErrorBoundary>
          <ErrorBoundary>
            <LoadMeta />
          </ErrorBoundary>
          <ErrorBoundary>
            <FollowStater />
          </ErrorBoundary>
          <ErrorBoundary>
            <GlobalShortCuts />
          </ErrorBoundary>
          <ErrorBoundary>
            <ScrollTopOnChange
              value={mainLocation.pathname} />
          </ErrorBoundary>
          <ErrorBoundary>
            <Switch
              location={mainLocation}>
              {mainRoutes}
            </Switch>
          </ErrorBoundary>
          {isModal && (
            <ErrorBoundary>
              <Switch location={location}>
                {modalRoutes}
              </Switch>
            </ErrorBoundary>
          )}
        </NavigationProvider>
      </ErrorBoundary>
    );
  };
  return Routes;
};


const FollowStater = () => {
  const [open, setOpen] = useState()
  const t = useTranslate()
  const showSelectCategories = useSelector(state => state.auth && state.auth.showSelectCategories)
  const [isToggle, toggle] = useToggle()
  useEffect(() => {
    let to
    if (showSelectCategories)
      to = setTimeout(() => {
        setOpen(true)
      }, 2000)
  }, [showSelectCategories])
  return <><Modal maskClosable={false} centered width={400} footer={null} closable onCancel={() => { setOpen(false) }} destroyOnClose visible={open} title={<div className="pr-4">{t(
    'Choose to follow the information catalog to get more news on your wall'
  )}</div>}>
    <Button onClick={() => {
      setOpen(false)
      toggle()
    }} block type="primary">{t(
      'select category'
    )}</Button>
  </Modal >
    {isToggle && (
      <FullSideModalLayout
        onCancel={
          toggle
        }>
        <SelectCategory
          onSuccess={
            toggle
          }
        />
      </FullSideModalLayout>
    )}
  </>
}