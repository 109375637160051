import Request from 'apis/middleware/request'
import { defaultTranform } from 'apis/utils/createTransform'
import createFormData from 'helpers/createFormData'
import {
  emptyArray,
  emptyObject
} from 'helpers/emptyObjects'
import _ from 'lodash'
import queryString from 'query-string'

export const callApi = ({
  apiInfo,
  query = emptyObject,
  values = emptyObject
}) => {
  const data = _.omit(
    values,
    _.isUndefined
  )
  const search = (
    apiInfo.paramsFields || emptyArray
  ).reduce((result, key) => {
    if (values[key])
      result[key] = values[key]
    return result
  }, {})

  const searchString = queryString.stringify(
    search,
    { arrayFormat: 'comma' }
  )
  const url = Object.keys(query).reduce(
    (result, key) => {
      if (
        query[key]
        // &&
        // query[key].length > 0
      ) {
        if (key[0] === ':') {
          return result.replace(
            key,
            query[key]
          )
        }
        return result.replace(
          ':' + key,
          query[key]
        )

      }
      return result
    },
    apiInfo.path
  )
  let fn = Request.get
  if (
    _.get(apiInfo, 'method') === 'POST'
  ) {
    fn = Request.post
  }
  const root = apiInfo.root || ''
  return fn(
    root +
    url +
    (_.isEmpty(searchString)
      ? ''
      : `?${searchString}`),
    createFormData(data)
  ).then(
    apiInfo.transform
      ? res =>
        apiInfo.transform(res, {
          apiInfo,
          query,
          values
        })
      : defaultTranform
  )
}
