import classNames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {
  PureComponent
} from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import './ShadowScrollbar.css'
class ShadowScrollbar extends PureComponent {
  state = {
    scrollTop: 0,
    scrollHeight: 0,
    clientHeight: 0
  }

  constructor(props, ...rest) {
    super(props, ...rest)
    this.viewRef = React.createRef()
  }
  handleUpdate = values => {
    const {
      actionAtBottom
    } = this.props
    if (actionAtBottom) {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
        clientWidth
      } = values
      const bottomScrollTop =
        scrollHeight - clientHeight
      if (
        bottomScrollTop - scrollTop <
        20 &&
        clientHeight > 1 &&
        clientWidth > 1
      ) {
        if (actionAtBottom)
          actionAtBottom()
      }
    }
  }
  handleScrollFrame = values => {
    this.props.handleScrollFrame &&
      this.props.handleScrollFrame(
        values,
        this.viewRef.current
      )
  }
  _debouncedHandleUpdate = _.debounce(
    this.handleUpdate,
    400
  )

  renderThumbVertical({ ...props }) {
    return (
      <div
        className="ShadowScrollbarThumb"
        {...props}
      />
    )
  }

  renderTrackVertical({ ...props }) {
    return (
      <div
        className="ShadowScrollbarTrack"
        {...props}
      />
    )
  }

  renderView = ({
    style,
    ...props
  }) => {
    return (
      <div
        {...props}
        style={{
          ...style,
          overflowX: 'hidden',
          WebkitOverflowScrolling:
            'touch',
          marginBottom: 0,
          overscrollBehavior: 'contain',
          overflowY: 'scroll'
        }}
      />
    )
  }

  shadowTopStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 2,
    background:
      'linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%)'
  }
  shadowBottomStyle = {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 2,
    background:
      'linear-gradient(to top, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%)'
  }
  render() {
    const {
      style,
      actionAtBottom,
      id,
      hideTop,
      hideBottom,
      className,
      onScrollStop,
      handleScrollFrame,
      ...props
    } = this.props
    return (
      <div
        className={classNames(
          'ShadowScrollbar',
          className
        )}
        style={style}>
        <Scrollbars
          className="ShadowScrollbarContent"
          renderView={this.renderView}
          id={id}
          ref={this.viewRef}
          onUpdate={
            this._debouncedHandleUpdate
          }
          autoHide={false}
          hideTracksWhenNotNeeded
          // autoHeight
          // autoHeightMin={`100%`}
          onScrollFrame={
            this.handleScrollFrame
          }
          autoHideTimeout={2000}
          // Duration for hide animation in ms.
          autoHideDuration={200}
          renderThumbVertical={
            this.renderThumbVertical
          }
          renderTrackVertical={
            this.renderTrackVertical
          }
          renderTrackHorizontal={props => (
            <div />
          )}
          onScrollStop={() => {
            onScrollStop &&
              onScrollStop(
                this.viewRef.current
              )
          }}
          {...props}
        />
        {!hideTop && (
          <div
            ref="shadowTop"
            style={this.shadowTopStyle}
          />
        )}
        {!hideBottom && (
          <div
            ref="shadowBottom"
            style={
              this.shadowBottomStyle
            }
          />
        )}
        <div className="clear" />
      </div>
    )
  }
}

ShadowScrollbar.propTypes = {
  ...Scrollbars.propTypes,
  style: PropTypes.object
}

export default ShadowScrollbar
