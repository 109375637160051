import { API_ROOT_URL } from 'envs/_current/config'
import _ from 'lodash'
import { createAsyncAction } from 'modules/asyncCache'
import { normalize, schema } from 'normalizr'
import { compose } from 'redux'
import { channelSchema, organizationSchema, userSchema } from './schema'
import { createTransform } from './utils/createTransform'

const fixStpProperty = entity => {
  const { status, role_id } = entity
  const statusMap = createStpProperty(
    memberFieldTypes.status,
    status
  )
  const roleMap = createStpProperty(
    memberFieldTypes.role_id,
    role_id
  )
  return {
    ...entity,
    ...statusMap,
    ...roleMap
  }
}

export const memberFieldTypes = {
  status: [
    {
      value: -9,
      label: 'rejected'
    },
    {
      value: -8,
      label: 'kicked'
    },
    {
      value: -7,
      label: 'banned'
    },
    {
      value: -2,
      label: 'invited'
    },
    {
      value: -1,
      label: 'registered'
    },
    {
      value: 0,
      label: 'removed'
    },
    {
      value: 1,
      label: 'active'
    }
  ],
  role_id: [
    {
      value: 1,
      label: 'owner',
      description: 'owner_description'
    },
    {
      value: 2,
      label: 'admin',
      description: 'admin_description'
    },
    {
      value: 3,
      label: 'member',
      description: 'member_description'
    }
  ]
}
export const memberStatusTypes = {
  '-9': {
    value: -9,
    label: 'rejected'
  },
  '-8': {
    value: -8,
    label: 'kicked'
  },
  '-7': {
    value: -7,
    label: 'banned'
  },
  '-2': {
    value: -2,
    label: 'invited to become member'
  },
  '-1': {
    value: -1,
    label:
      'sent a request to become member'
  },
  '0': {
    value: 0,
    label: 'removed'
  },
  '1': {
    value: 1,
    label: 'active'
  }
}

export const memberSchema = new schema.Entity(
  'members',
  {
    user: userSchema
  },
  {
    idAttribute: 'id',
    processStrategy: compose(
      fixStpProperty
    )
  }
)
const createStpProperty = (
  options,
  value
) =>
  options.reduce((result, option) => {
    result[
      `is${_.capitalize(option.label)}`
    ] = '' + value === '' + option.value
    return result
  }, {})

// Member
// Route::post('members/{prop}/{id}/member/{m_id}/accept', 'MemberController@manageMemberAccept');
// Route::post('members/{prop}/{id}/member/{m_id}/reject', 'MemberController@manageMemberReject');
// Route::post('members/{prop}/{id}/member/{m_id}/set-role', 'MemberController@setRole');
export const member_setRole_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/members/:prop/:id/member/:m_id/set-role',
  method: 'POST',
  schema: memberSchema,
  fields: ['role_id'],
  fieldTypes: {
    role_id: memberFieldTypes.role_id
  },
  transform: createTransform(
    memberSchema,
    'data'
  )
}
export const create_member_setRole_Api_action = () => ({
  prefixStr = 'member_setRole_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr + '@member_setRole_Api',
    query,
    values,
    apiInfo: member_setRole_Api,
    maxAge
  })
// Route::post('members/{prop}/{id}/member/{m_id}/add', 'MemberController@manageMemberAdd');
// Route::post('members/{prop}/{id}/member/{m_id}/kick', 'MemberController@manageMemberKick');
export const member_kick_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/members/:prop/:id/member/:mId/kick',
  method: 'POST',
  schema: memberSchema,
  fields: [],
  fieldTypes: {},
  transform: createTransform(
    memberSchema,
    'data'
  )
}
export const create_member_kick_Api_action = () => ({
  prefixStr = 'member_kick_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr + '@member_kick_Api',
    query,
    values,
    apiInfo: member_kick_Api,
    maxAge
  })
// Route::post('members/{prop}/{id}/member/{m_id}/ban', 'MemberController@manageMemberBan');
export const member_ban_Api = {
  backend: `Route::post('members/{prop}/{id}/member/{m_id}/ban', 'MemberController@manageMemberBan')`,
  root: API_ROOT_URL,
  path:
    '/members/:prop/:id/member/:mId/ban',
  method: 'POST',
  transform: createTransform(
    memberSchema,
    'data'
  )
}
export const create_member_ban_Api_action = () => ({
  prefixStr = '',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr + '@member_ban_Api',
    query,
    values,
    apiInfo: member_ban_Api,
    maxAge
  })
// Route::post('members/{prop}/{id}/member/{m_id}/remove-banned', 'MemberController@manageMemberRemoveBan');
export const member_unBan_Api = {
  backend: `Route::post('members/{prop}/{id}/member/{m_id}/remove-banned', 'MemberController@manageMemberRemoveBan');`,
  root: API_ROOT_URL,
  path:
    '/members/:prop/:id/member/:mId/remove-banned',
  method: 'POST',
  transform: createTransform(
    memberSchema,
    'data'
  )
}
export const create_member_unBan_Api_action = () => ({
  prefixStr = '',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr + '@member_unBan_Api',
    query,
    values,
    apiInfo: member_unBan_Api,
    maxAge
  })

// Route::get('members/{prop}/{id}/get-contact-members', 'MemberController@getContactMembers');;
export const member_getContactMembers_Api = {
  backend: `Route::get('members/{prop}/{id}/get-contact-members', 'MemberController@getContactMembers');`,
  root: API_ROOT_URL,
  path: '/members/:prop/:id/get-contact-members',
  method: 'GET',
  schema: memberSchema,
  fields: [],
  fieldTypes: {},
  transform: createTransform([
    memberSchema
  ])
}

// Route::get('members/{prop}/{id}/gets', 'MemberController@getMembers');
export const member_getMembers_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/members/:prop/:id/gets',
  method: 'GET',
  schema: memberSchema,
  fields: [],
  paramsFields: ['status', 'role_id', 'contact_role'],
  fieldTypes: {},
  transform: createTransform([
    memberSchema
  ])
}
export const create_member_getMembers_Api_action = () => ({
  prefixStr = 'member_getMembers_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@member_getMembers_Api',
    query,
    values,
    apiInfo: member_getMembers_Api,
    maxAge
  })

// Route::post('members/{prop}/{id}/user/join', 'MemberController@joinAsMember');
export const member_joinAsMember_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/members/:prop/:id/user/join',
  method: 'POST',
  fields: [],
  fieldTypes: {},
  transform: createTransform(
    new schema.Entity(
      'members',
      {
        user: userSchema,
        owner: new schema.Union(
          {
            channel: channelSchema,
            organization: organizationSchema
          },
          '_type'
        )
      },
      {
        idAttribute: 'id'
      }
    ),
    'data'
  )
}
export const create_member_joinAsMember_Api_action = () => ({
  prefixStr = 'member_joinAsMember_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@member_joinAsMember_Api',
    query,
    values,
    apiInfo: member_joinAsMember_Api,
    maxAge
  })
// Route::post('members/{prop}/{id}/user/leave', 'MemberController@leaveAsMember');
export const member_leaveAsMember_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/members/:prop/:id/user/leave',
  method: 'POST',
  fields: [],
  fieldTypes: {},
  transform: createTransform(
    new schema.Union(
      {
        channel: channelSchema,
        organization: organizationSchema
      },
      '_type'
    ),
    'data'
  )
}
export const create_member_leaveAsMember_Api_action = () => ({
  prefixStr = 'member_leaveAsMember_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@member_leaveAsMember_Api',
    query,
    values,
    apiInfo: member_leaveAsMember_Api,
    maxAge
  })
// Route::get('members/{prop}/{id}/get-requested-users', 'MemberController@getRequestedUsers');
export const member_getRequestedUsers_Api = {
  name: 'member_getRequestedUsers',
  backend:
    "Route::get('members/{prop}/{id}/get-requested-users', 'MemberController@getRequestedUsers')",
  root: API_ROOT_URL,
  path:
    '/members/:prop/:id/get-requested-users',
  method: 'GET'
}
// Route::post('members/{prop}/{id}/member/{m_id}/accept', 'MemberController@manageMemberAccept');
export const member_manageMemberAccept_Api = {
  name: 'member_manageMemberAccept',
  backend:
    "Route::post('members/{prop}/{id}/member/{m_id}/accept', 'MemberController@manageMemberAccept')",
  root: API_ROOT_URL,
  path:
    '/members/:prop/:id/member/:m_id/accept',
  method: 'POST'
}
// Route::post('members/{prop}/{id}/user/reject', 'MemberController@rejectAsMember');
// Route::post('members/{prop}/{id}/member/{m_id}/set-as-owner', 'MemberController@setRoleAsOwner');
export const member_switchOwner_Api = {
  name: 'member_switchOwner',
  backend:
    "Route::post('members/{prop}/{id}/member/{m_id}/set-as-owner', 'MemberController@setRoleAsOwner')",
  root: API_ROOT_URL,
  path:
    '/members/:prop/:id/member/:m_id/set-as-owner',
  method: 'POST',
  transform: createTransform(
    channelSchema,
    'data'
  )
}

// Route::post('roles/assign-role/{prop}/{id}/{role_id}/{m_id}/assign', 'RoleController@assignRoleToMember');
export const member_assignRole_Api = {
  name: 'member_assignRole',
  backend:
    "Route::post('roles/assign-role/{prop}/{id}/{role_id}/{m_id}/assign', 'RoleController@assignRoleToMember')",
  root: API_ROOT_URL,
  path:
    '/roles/assign-role/:prop/:id/:role_id/:m_id/assign',
  method: 'POST',
  transform: createTransform(
    memberSchema,
    'data'
  )
}
// Route::post('roles/assign-role/{prop}/{id}/{role_id}/{m_id}/remove', 'RoleController@removeRoleFromMember');
export const member_removeRoleFromMember_Api = {
  name: 'member_removeRoleFromMember',
  backend:
    "Route::post('roles/assign-role/{prop}/{id}/{role_id}/{m_id}/remove', 'RoleController@removeRoleFromMember')",
  root: API_ROOT_URL,
  path:
    '/roles/assign-role/:prop/:id/:role_id/:m_id/remove',
  method: 'POST',
  transform: (response, { query }) => {
    const {
      entities,
      result
    } = normalize(
      response.data,
      new schema.Entity(
        'members',
        {
          user: userSchema,
          owner: channelSchema
        },
        {
          idAttribute: 'id',
          processStrategy: entity => {
            return {
              ...entity,
              _roles: {
                [query[
                  ':role_id'
                ]]: false
              }
            }
          }
        }
      )
    )
    return {
      entities,
      result
    }
  }
}

// Route::get('members/{prop}/{id}/user/search-non-member', 'MemberController@searchUsersForMember');
export const member_searchUsersForMember_Api = {
  name: 'member_searchUsersForMember',
  backend: `Route::get('members/{prop}/{id}/user/search-non-member', 'MemberController@searchUsersForMember');`,
  root: API_ROOT_URL,
  path:
    '/members/:prop/:id/user/search-non-member',
  method: 'GET',
  paramsFields: ['keyword']
}

// Route::post('members/{prop}/{id}/member/{u_id}/add', 'MemberController@manageMemberAdd');
export const members_manageMemberAdd_Api = {
  name: 'members_manageMemberAdd',
  backend: `Route::post('members/{prop}/{id}/member/{u_id}/add', 'MemberController@manageMemberAdd')`,
  root: API_ROOT_URL,
  path:
    '/members/:prop/:id/member/:u_id/add',
  method: 'POST',
  schema: memberSchema,
  transform: createTransform(
    memberSchema,
    'data'
  )
}
