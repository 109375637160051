import {Button, Input} from 'antd'
import {auth_register_Api} from 'apis'
import ApiInfoForm from 'components/form/ApiInfoForm'
import FieldDecorator from 'components/form/FieldDecorator'
import {LocalContext} from 'modules/local/LocalContext'
import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import * as Yup from 'yup'
import './RegisterForm.css'

export const Register = () => {
  const { translate } = useContext(
    LocalContext
  )
  return (
    <ApiInfoForm
      apiInfo={auth_register_Api}
      initialValues={{
        email: '',
        name: '',
        password: '',
        confirm_password: ''
      }}
      validationSchema={Yup.object().shape(
        {
          email: Yup.string()
            .email(
              translate(
                'invalid email address'
              )
            )
            .required(
              translate('required field')
            ),
          password: Yup.string()
            .min(
              8,
              translate(
                'Password must have min 8 characters'
              )
            )
            .required(
              translate(
                'password required'
              )
            ),
          confirm_password: Yup.string().oneOf(
            [Yup.ref('password'), null],
            translate(
              'Password and confirm password does not match'
            )
          )
        }
      )}>
      {props => {
        const {
          values,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          success
        } = props
        return (
          <div>
            {success ? (
              <div
                className="flex flex-col"
                style={{
                  display:
                    'inline-block',
                  padding: '16px 0',
                  color: '#161616'
                }}>
                <div>
                  <span>
                    {translate(
                      'the activation email has been sent to'
                    )}
                  </span>
                  <span className="px-1 color-primary font-bold">
                    {values.email}.
                  </span>
                </div>
                <div>
                  {translate(
                    'check your email and click on the activation link to complete the registration'
                  )}
                </div>
              </div>
            ) : (
              <form
                onSubmit={handleSubmit}
                className="verticalList">
                <FieldDecorator
                  isRequired={true}
                  errors={errors.email}>
                  <Input
                    size="large"
                    name="email"
                    value={values.email}
                    onChange={
                      handleChange
                    }
                    onBlur={handleBlur}
                    className={`register-control ${
                      errors.email
                        ? 'register-control'
                        : ''
                    }`}
                    type="text"
                    placeholder={translate(
                      'email account'
                    )}
                  />
                </FieldDecorator>
                <FieldDecorator
                  isRequired={true}
                  errors={
                    errors.password
                  }>
                  <Input
                    size="large"
                    type="password"
                    name="password"
                    value={
                      values.password
                    }
                    onChange={
                      handleChange
                    }
                    onBlur={handleBlur}
                    placeholder={translate(
                      'password'
                    )}
                  />
                </FieldDecorator>
                <FieldDecorator
                  className="wishare-field errors"
                  errors={
                    errors.confirm_password
                  }>
                  <Input
                    size="large"
                    type="password"
                    name="confirm_password"
                    value={
                      values.confirm_password
                    }
                    onChange={
                      handleChange
                    }
                    onBlur={handleBlur}
                    placeholder={translate(
                      'retype password'
                    )}
                  />
                </FieldDecorator>
                <FieldDecorator
                  className="wishare-field errors"
                  errors={errors.namee}>
                  <Input
                    size="large"
                    name="name"
                    type="text"
                    value={values.name}
                    onChange={
                      handleChange
                    }
                    onBlur={handleBlur}
                    className="register-control"
                    placeholder={translate(
                      'fullname'
                    )}
                  />
                </FieldDecorator>
                <div>
                  <Button
                    // disabled={!isValid}
                    onClick={
                      handleSubmit
                    }
                    type="primary"
                    className="w-full"
                    size="large">
                    {isSubmitting
                      ? translate(
                          'waiting'
                        )
                      : translate(
                          'register'
                        )}
                  </Button>
                  <div className="text-right color-primary">
                    <Link to="/forgotpassword">
                      {translate(
                        'forgotpass'
                      )}
                    </Link>
                  </div>
                </div>
              </form>
            )}
          </div>
        )
      }}
    </ApiInfoForm>
  )
}

export default Register
