import Icon from '@ant-design/icons'
import { Avatar } from 'antd'
import { InfoSvg } from 'components/Svgs/InfoSvg'
import Toggle from 'components/Toggle'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import {
  FaAngleDown,
  FaAngleUp
} from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { HeaderButton } from 'views/Organization/components/PageHeader'

export const Helps = ({ }) => {
  const t = useTranslate()

  return (
    <div className="">
      {[
        {
          id: 'about',
          children: [
            {
              id: 'welcome',
              to: '/start',
              render: () => (
                <div className="flex items-center px-3 bg-green-200">
                  <Avatar
                    size={32}
                    className="bg-transparent text-gray-600">
                    <Icon
                      component={
                        InfoSvg
                      }
                    />
                  </Avatar>
                  <Link
                    to={'/start'}
                    className="hover:bg-gray-300 items-center px-1 py-3 truncate font-semibold flex-1">
                    {t('welcome')}
                  </Link>
                </div>
              )
            },
            {
              id: 'about-us',
              to: '/document/about-us'
            },
            {
              id: 'contact-info',
              to:
                '/document/contact-info'
            }
          ]
        },
        {
          id: 'policy',
          children: [
            {
              id: 'terms-of-service',
              to:
                '/document/terms-of-service'
            },
            {
              id: 'privacy-policy',
              to:
                '/document/privacy-policy'
            },
            {
              id: 'trust-safe',
              to: '/document/trust-safe'
            },
            {
              id: 'cookies-policy',
              to:
                '/document/cookies-policy'
            }
          ]
        },
        {
          id: 'help',
          children: [
            {
              id: 'tutorials',
              to: '/document/tutorials'
            },
            {
              id:
                'frequently-questions',
              to:
                '/document/frequently-questions'
            }
          ]
        }
      ].map((e, i) => {
        return (
          <div
            key={i}
            className="verticalList__small">
            <Toggle>
              {(isToggle, toggle) => (
                <>
                  <div
                    className="flex items-center h-6 mt-6 mb-3"
                    onClick={() =>
                      toggle()
                    }>
                    <div className="px-2 flex-1 uppercase font-bold text-sm text-gray-600">
                      {t(e.id)}
                    </div>
                    <HeaderButton>
                      {isToggle ? (
                        <FaAngleUp />
                      ) : (
                          <FaAngleDown />
                        )}
                    </HeaderButton>
                  </div>

                  <div className="background-100">
                    {isToggle &&
                      e.children.map(
                        ei => {
                          if (ei.render)
                            return ei.render()
                          return (
                            <div
                              className="flex items-center px-3"
                              key={
                                ei.id
                              }>
                              <Avatar
                                size={
                                  32
                                }
                                className="bg-transparent text-gray-600">
                                <Icon
                                  component={
                                    AiOutlineQuestionCircle
                                  }
                                />
                              </Avatar>
                              <Link
                                to={
                                  ei.to
                                }
                                className="hover:bg-gray-300 items-center px-1 py-3 truncate font-semibold flex-1">
                                {t(
                                  ei.id
                                )}
                              </Link>
                            </div>
                          )
                        }
                      )}
                  </div>
                </>
              )}
            </Toggle>
          </div>
        )
      })}
    </div>
  )
}
