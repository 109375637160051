import React, { useState } from 'react'
import { UseHook } from './UseHook'
export default function UseState({
  initalValue,
  ...props
}) {
  return (
    <UseHook
      hook={useState}
      initalValue={initalValue}
      {...props}
    />
  )
}
