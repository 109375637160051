import { Input, Space } from 'antd'
import { dataset_add_Api } from 'apis'
import ApiInfoForm from 'components/form/ApiInfoForm'
import FormActionBar from 'components/form/FormActionBar'
import { FormikConsumer } from 'formik'
import React from 'react'

const CreateDataset = ({
  type,
  id,
  onSuccess
}) => (
  <>
    <ApiInfoForm
      initialValues={{
        title: '',
        owner_type: type,
        owner_id: id
      }}
      onSuccess={onSuccess}
      {...{
        apiInfo: dataset_add_Api
      }}>
      <FormikConsumer>
        {({ handleChange }) => (
          <Space
            size="large"
            direction="vertical"
            className="container max-w-lg mx-auto p-3">
            <Input
              size="large"
              name="title"
              onChange={handleChange}
              placeholder="title"
            />
            <Input
              size="large"
              name="description"
              onChange={handleChange}
              placeholder="description"
            />
            <FormActionBar />
          </Space>
        )}
      </FormikConsumer>
    </ApiInfoForm>
  </>
)

export default CreateDataset
