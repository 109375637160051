import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {AiOutlineFileSearch} from 'react-icons/ai'

const EmptyHolder = ({
  icon = () => (
    <div
      style={{
        fontSize: '3rem',
        color: 'rgba(226, 232, 240, 1)'
      }}>
      <AiOutlineFileSearch />
    </div>
  ),
  title = 'No Data',
  subTitle = 'No data available at this time.'
}) => {
  const t = useTranslate()
  return (
    <div className="pb-8 pt-6 px-4 m-3 flex flex-col items-center m-auto max-w-2xl text-center rounded-full">
      {icon && (
        <div className="m-3">
          {icon()}
        </div>
      )}

      {title && (
        <div className="text-xl font-bold text-color mb-4">
          {typeof title === 'string'
            ? t(title)
            : title()}
        </div>
      )}
      {subTitle && (
        <div className="p-3 text-sm border-gray-300">
          {typeof subTitle === 'string'
            ? t(subTitle)
            : subTitle()}
        </div>
      )}
    </div>
  )
}
export default EmptyHolder
