import { PlusOutlined } from '@ant-design/icons'
import { Input, Upload } from 'antd'
import { image_upload_Api } from 'apis'
import { getBase64 } from 'components/form/fields/ImageUpload'
import FieldsFactory from 'components/form/FieldsFactory'
import { StepsForm } from 'components/form/StepsForm'
import { createValue } from 'components/form/utils'
import ImagesLightBox from 'components/ImagesLightBox/ImagesLightBox'
import { API_ROOT_URL } from 'envs/_current/config'
import { emptyArray } from 'helpers/emptyObjects'
import _ from 'lodash'
import { callApi } from 'modules/asyncCache/callApi'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import React from 'react'

const PicturesWall = withTranslate(
  class extends React.PureComponent {
    state = {
      previewVisible: false,
      previewImage: '',
      fileList:
        this.props.values.fileList ||
        emptyArray
    }

    handleCancel = () =>
      this.setState({
        previewVisible: false
      })

    handlePreview = async file => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(
          file.originFileObj
        )
      }

      this.setState({
        previewImage:
          file.url || file.preview,
        previewVisible: true
      })
    }

    handleChange = ({ fileList }) => {
      this.setState({ fileList })
      this.props.onChange(
        createValue(
          'fileList',
          fileList
        )
      )
      this.props.onChange(
        createValue(
          'photos',
          fileList.map(info =>
            _.get(
              info,
              'response.data.ids.0'
            )
          )
        )
      )
    }

    render() {
      const {
        translate: t
      } = this.props
      const {
        previewVisible,
        previewImage,
        fileList
      } = this.state
      const uploadButton = (
        <div>
          <PlusOutlined />
          <div className="ant-upload-text">
            {t('upload')}
          </div>
        </div>
      )

      return (
        <div className="w-full">
          <Upload
            customRequest={({
              onSuccess,
              ...args
            }) =>
              callApi({
                apiInfo: image_upload_Api,
                values: {
                  'photos[]': args.file
                }
              }).then(onSuccess)
            }
            multiple
            action={`${API_ROOT_URL}/images/image/upload`}
            listType="picture-card"
            fileList={fileList}
            onPreview={
              this.handlePreview
            }
            onChange={
              this.handleChange
            }>
            {fileList.length >= 16
              ? null
              : uploadButton}
          </Upload>
          {previewVisible && (
            <ImagesLightBox
              images={[previewImage]}
              onCloseRequest={
                this.handleCancel
              }
            />
          )}
        </div>
      )
    }
  }
)
const MessageForm = () => {
  const translate = useTranslate()
  return (
    <>
      <FieldsFactory
        formSchema={[
          {
            children: {
              type: 'message',
              name: 'description',
              label: translate(
                'your message'
              ),
              component: Input.TextArea,
              withProps: {
                autosize: {
                  minRows: 4,
                  maxRows: 12
                },
                type: 'text',
                size: 'large',
                placeholder: translate(
                  'Write your sharing'
                )
              }
            }
          }
        ]}
      />
    </>
  )
}
export const CreateImagePostRoutes = [
  {
    path: '/questtionAndAnswers',
    name: 'post your images',
    component: PicturesWall,
    exact: true
  },
  {
    path: '/message',
    name: 'post your images',
    component: MessageForm,
    exact: true
  }
]
export default function CreateImagePost({
  handleAsyncPost,
  isLoading
}) {
  return (
    <StepsForm
      isLoading={isLoading}
      routes={CreateImagePostRoutes}
      initialValues={{
        description: '',
        photos: []
      }}
      onSubmit={
        handleAsyncPost
      }></StepsForm>
  )
}
