import {Avatar, Checkbox, Modal, Radio} from 'antd'
import classnames from 'classnames'
import {FlagIcon} from 'components/icons/FlagIcon'
import _ from 'lodash'
import {LocalContext} from 'modules/local/LocalContext'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useState} from 'react'
import {ReactReduxContext} from 'react-redux'
import useToggle from 'react-use/lib/useToggle'
import {getLogin} from 'redux/selectors'

export const LanguageSwitch = ({
  title
}) => {
  const t = useTranslate()
  const [isToggle, toggle] = useToggle()
  const {
    lang,
    changeLang,
    changeContentLang
  } = useContext(LocalContext)
  const [
    displayLanguage,
    setDisplayLanguage
  ] = useState(lang)
  const { store } = useContext(
    ReactReduxContext
  )
  const login = getLogin(
    store.getState()
  )
  const following_languages_maximum = _.get(
    login,
    'following_languages_maximum',
    1
  )
  const _languages = _.get(
    login,
    'following_languages',
    ['vi']
  )
  const following_languages = _.isEmpty(
    _languages
  )
    ? ['vi']
    : _languages
  const [
    contentLanguages,
    setContentLanguages
  ] = useState(following_languages)
  const [
    indeterminate,
    setIndeterminate
  ] = useState(true)
  const [
    checkAll,
    setCheckAll
  ] = useState(false)
  const langArray = ['vi', 'en']

  return (
    <div>
      <div className="flex items-center justify-center">
        <div onClick={() => toggle()}>
          {title}
        </div>
      </div>
      {isToggle && (
        <Modal
          wrapClassName="rounded-lg"
          style={{minHeight: 420, borderRadius: 10}}
          title={
            <div className="text-lg">
              {t('Choose language')}
            </div>
          }
          visible={isToggle}
          header={null}
          footer={null}
          onCancel={toggle}>
          <div>
            <div className="flex-col space-y-3">
              <div className="font-medium">
                {t(
                  'Interface language'
                )}
                :
              </div>

              <div className="flex-col">
                <Radio.Group
                  className="w-full"
                  onChange={e => {
                    setDisplayLanguage(
                      e.target.value
                    )
                    changeLang(
                      e.target.value
                    )
                  }}
                  value={
                    displayLanguage
                  }>
                  <React.Fragment>
                    {langArray.map(
                      (value, key) => (
                        <Radio
                          key={key}
                          value={value}
                          className={classnames(
                            'flex items-center hover:background-200 px-3',
                            lang ===
                              value
                              ? 'text-primary font-extrabold'
                              : 'font-normal'
                          )}>
                          <div className="flex justify-center items-center py-1">
                            <Avatar
                              shape="square"
                              size={40}
                              className="bg-transparent">
                              <FlagIcon
                                type={
                                  value
                                }
                                style={{
                                  fontSize:
                                    '2.5rem'
                                }}
                              />
                            </Avatar>

                            <div className="px-3">
                              {t(value)}
                            </div>
                          </div>
                        </Radio>
                      )
                    )}
                  </React.Fragment>
                </Radio.Group>
              </div>
            </div>
            <div className="flex-col">
              <div className="font-medium">
                {t('Content language')}:
              </div>
              <div className="text-gray-500">
                {t('the content will be delivered to you in the language you choose')}
              </div>
              <div className="flex-col">
                <div className="px-3">
                  <Checkbox
                    disabled={
                      langArray.length >
                      following_languages_maximum
                    }
                    indeterminate={
                      indeterminate
                    }
                    onChange={e => {
                      setContentLanguages(
                        e.target.checked
                          ? langArray
                          : []
                      )
                      setIndeterminate(
                        false
                      )
                      setCheckAll(
                        e.target.checked
                      )

                      // Update to Server
                      changeContentLang(
                        langArray.reduce(
                          (
                            result,
                            value,
                            i
                          ) => {
                            result +=
                              i === 0
                                ? value
                                : ',' +
                                  value
                            return result
                          },
                          ''
                        )
                      )
                    }}
                    checked={checkAll}>
                    <span className="text-color">
                      {t('check all')}
                    </span>
                  </Checkbox>
                </div>
                <Checkbox.Group
                  style={{
                    width: '100%'
                  }}
                  value={
                    contentLanguages
                  }
                  onChange={checkedValues => {
                    if (
                      checkedValues.length >
                      following_languages_maximum
                    ) {
                      Modal.warning({
                        title: t(
                          'Hello you'
                        ),
                        content: t(
                          'Your current account only allows to follow in one language, if you want to remove this limit, please upgrade to a higher package. Thank you'
                        )
                      })
                      return
                    }
                    setContentLanguages(
                      checkedValues
                    )
                    setIndeterminate(
                      !!checkedValues.length &&
                        checkedValues.length <
                          langArray.length
                    )
                    setCheckAll(
                      checkedValues.length ===
                        langArray.length
                    )
                    changeContentLang(
                      checkedValues.reduce(
                        (
                          result,
                          value,
                          i
                        ) => {
                          result +=
                            i === 0
                              ? value
                              : ',' +
                                value
                          return result
                        },
                        ''
                      )
                    )
                  }}>
                  <React.Fragment>
                    {langArray.map(
                      (value, key) => (
                        <div
                          key={key}
                          className="clearfix">
                          <Checkbox
                            key={key}
                            value={
                              value
                            }
                            className={classnames(
                              'flex items-center hover:background-200 px-3',
                              _.includes(
                                contentLanguages,
                                value
                              )
                                ? 'text-primary font-extrabold'
                                : 'font-normal'
                            )}>
                            <div className="flex justify-center items-center py-1">
                              <Avatar
                                shape="square"
                                size={
                                  40
                                }
                                className="bg-transparent">
                                <FlagIcon
                                  type={
                                    value
                                  }
                                  style={{
                                    fontSize:
                                      '2.5rem'
                                  }}
                                />
                              </Avatar>

                              <div className="px-3">
                                {t(
                                  value
                                )}
                              </div>
                            </div>
                          </Checkbox>
                        </div>
                      )
                    )}
                  </React.Fragment>
                </Checkbox.Group>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}
