import Icon, {
  LineOutlined
} from '@ant-design/icons'
import preventDefaultEvent from 'helpers/preventDefaultEvent'
import PropTypes from 'prop-types'
import React from 'react'
const DividerButton = ({
  children,
  ...props
}) => {
  const onClick = event => {
    event.preventDefault()
    const editorState = props.getEditorState()
    const newEditorState = props.addDivider(
      editorState
    )

    props.setEditorState(newEditorState)
  }

  return (
    <button
      onMouseDown={preventDefaultEvent}
      onClick={onClick}
      style={{
        fontSize: '24px'
      }}
      className="button font-semibold px-2">
      <Icon
        component={LineOutlined}></Icon>
    </button>
  )
}

DividerButton.propTypes = {
  theme: PropTypes.object,
  getEditorState:
    PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  setEditorState:
    PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  addDivider: PropTypes.func.isRequired
}

export default DividerButton
