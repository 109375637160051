import { API_ROOT_URL } from 'envs/_current/config'
import { createAsyncAction } from 'modules/asyncCache'
import { schema } from 'normalizr'
import { baseFieldTypes } from './base/baseFieldTypes'
import { createListTransform } from './utils/createListTransform'
import { createTransform } from './utils/createTransform'

export const referralSchema = new schema.Entity(
  'referrals',
  {},
  {
    idAttribute: 'id'
  }
)

// Referral: response: referral_code -> url: https://.../invite/:referral_code
// Route::post('referrals/get-referral-code', 'ReferralController@invite');

export const referral_getReferralCode_Api = {
  backend: `// Route::post('referrals/get-referral-code', 'ReferralController@invite');`,
  root: API_ROOT_URL,
  path: '/referrals/get-referral-code',
  method: 'POST',
  fields: [
    'target_id',
    'target_type',
    'no_expired',
    'timeout'
  ],
  fieldTypes: {
    target_id: baseFieldTypes.string,
    no_expired: [
      {
        value: 0,
        label: 'no_expired_0'
      },
      {
        value: 1,
        label: 'no_expired_1'
      }
    ]
  }
}
export const create_referral_getReferralCode_Api_action = () => ({
  prefixStr = '',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@referral_getReferralCode_Api',
    query,
    values,
    apiInfo: referral_getReferralCode_Api,
    maxAge
  })
// Nếu chưa đăng nhập thì chuyển trang đăng ký, nếu đã đăng nhập thì gọi tiếp API này để accept-referral
// Route::post('referrals/{referral_code}/accept-referral-code', 'ReferralController@acceptReferral');
export const referral_acceptReferral_Api = {
  backend: `// Route::post('referrals/{referral_code}/accept-referral-code', 'ReferralController@acceptReferral');`,
  root: API_ROOT_URL,
  path:
    '/referrals/:referral_code/accept-referral-code',
  method: 'POST'
}
export const create_referral_acceptReferral_Api_action = () => ({
  prefixStr = '',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@referral_acceptReferral_Api',
    query,
    values,
    apiInfo: referral_acceptReferral_Api,
    maxAge
  })
// Route::get('referrals/{referral_id}/get-referral-info', 'ReferralController@getById');
export const referral_getById_Api = {
  backend: `// Route::get('referrals/{referral_id}/get-referral-info', 'ReferralController@getById');`,
  root: API_ROOT_URL,
  path:
    '/referrals/:referral_id/get-referral-info',
  method: 'GET'
}
export const create_referral_getById_Api_action = () => ({
  prefixStr = 'referral_getById_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@referral_getById_Api',
    query,
    values,
    apiInfo: referral_getById_Api,
    maxAge
  })
// get('referrals/{prop}/{id}/get-referrals', 'ReferralController@getReferrals');
export const referral_getReferrals_Api = {
  name: 'referral_getReferrals',
  backend: `get('referrals/{prop}/{id}/get-referrals', 'ReferralController@getReferrals');`,
  root: API_ROOT_URL,
  path:
    '/referrals/:prop/:id/get-referrals',
  method: 'GET',
  schema: referralSchema,
  transform: createListTransform(
    referralSchema
  )
}

// Route::post('referrals/{referral_id}/delete-referral', 'ReferralController@deleteReferral');
export const referral_deleteReferral_Api = {
  name: 'referral_deleteReferral',
  backend:
    "Route::post('referrals/{referral_id}/delete-referral', 'ReferralController@deleteReferral')",
  root: API_ROOT_URL,
  path:
    '/referrals/:referral_id/delete-referral',
  method: 'POST',
  transform: createTransform(
    referralSchema,
    'data'
  )
}
