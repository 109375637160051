import { Carousel } from 'antd'
import classnames from 'classnames'
import React, { useContext } from 'react'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import ImageLoader from '../../components/ImageLoader'
import productImageHolder from '../../views/Market/productImageHolder'
import { OrganizationContext } from './OrganizationContext'
import './Slider.css'

function SampleNextArrow(props) {
  const {
    className,
    style,
    onClick
  } = props
  return (
    <div
      className={classnames(
        'shadow opacity-75 hover:opacity-100',
        className
      )}
      style={{
        ...style,
        marginTop: '-20px',
        right: 0,
        zIndex: 10,
        background: 'var(--background)',
        width: '40px',
        height: '40px',
        borderRadius: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#545454',
        transform: 'trans'
      }}
      onClick={onClick}>
      <FaAngleRight size={24} />
    </div>
  )
}

function SamplePrevArrow(props) {
  const {
    className,
    style,
    onClick
  } = props
  return (
    <div
      className={classnames(
        'shadow opacity-75 hover:opacity-100',
        className
      )}
      style={{
        ...style,
        marginTop: '-20px',
        left: 0,
        zIndex: 10,
        background: 'var(--background)',
        width: '40px',
        height: '40px',
        borderRadius: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#545454',
        transform: 'trans'
      }}
      onClick={onClick}>
      <FaAngleLeft size={24} />
    </div>
  )
}
export function Slider() {
  const {
    allProducts,
    url,
    id
  } = useContext(OrganizationContext)

  return (
    <div className="flex">
      <div className="flex-1 w-0">
        <Carousel
          className="Slider "
          arrows
          infinite={false}
          {...{
            autoplay: false,
            dots: false,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 5,
            nextArrow: (
              <SampleNextArrow />
            ),
            prevArrow: (
              <SamplePrevArrow />
            ),
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 4
                }
              },
              {
                breakpoint: 800,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  arrows: false
                }
              }
            ]
          }}>
          {allProducts.map(
            (item, index) => {
              return (
                <div
                  key={item.id}
                  className="p-2">
                  <div className="border border-gray-300 rounded-lg relative overflow-hidden">
                    <div
                      className="block relative overflow-hidden"
                      style={{
                        paddingTop:
                          '70%'
                      }}>
                      <ImageLoader
                        alt="ecommerce"
                        className="object-cover absolute top-0 left-0 w-full h-full block"
                        src={
                          item.avatar ||
                          productImageHolder
                        }
                      />
                    </div>
                    <div
                      style={{
                        background: `linear-gradient(
                        rgba(255, 255, 255, 0) 20%,
                        rgb(42 42 42) 100%
                      )`
                      }}
                      className="p-2 pt-6 flex items-end absolute left-0 bottom-0 w-full">
                      <Link
                        to={`${url}/products#${item.idname}`}>
                        <h2 className="hover:underline text-white title-font md:text-lg font-medium text-black">
                          {item.title}
                        </h2>
                      </Link>
                    </div>
                  </div>
                </div>
              )
            }
          )}
        </Carousel>
      </div>
    </div>
  )
}
