import Null from 'components/Null'
import { emptyArray } from 'helpers/emptyObjects'
import {
  useEffect,
  useRef
} from 'react'
export const DidOnChange = ({
  input = emptyArray,
  handler = Null
}) => {
  const handlerRef = useRef(handler)
  handlerRef.current = handler
  useEffect(() => {
    handlerRef.current()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, input)
  return null
}
