import {MoreOutlined} from '@ant-design/icons'
import {Button, Menu, Space} from 'antd'
import {dataset_getById_Api, datasets_getDatasetsByOwner_Api} from 'apis'
import DrawMenu from 'components/drawer/DrawMenu'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import {LazyPagination} from 'modules/asyncCache/components/LazyPagination'
import useTranslate from 'modules/local/useTranslate'
import {useHistory} from 'modules/navigation/useRouter'
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {Link, Route, Switch} from 'react-router-dom'
import Delete from 'views/Data/Delete'
import CreateDataset from 'views/DatasetManager/components/CreateDataset'
import {routeUrls, UpdateData} from 'views/DatasetManager/components/UpdateData'
import LoadingPage from 'views/LoadingPage'

function DatasetSettings({type, id}) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [select, setSelect] = useState(
    routeUrls.list
  )
  const t = useTranslate()
  return (
    <Space
      className="block max-w-3xl w-full mx-auto p-3"
      direction="vertical ">
      <Switch
        location={{
          pathname: select
        }}>
        <Route
          key={routeUrls.list}
          path={routeUrls.list}
          exact={true}
          render={() => {
            return (
              <Space
                className="block w-full"
                direction="vertical">
                <div className="flex items-center py-3 border-b border-gray-300">
                  <div className="flex-1 font-bold">
                    {t('all dataset')}
                  </div>
                  <div>
                    <Button
                      type="primary"
                      onClick={() =>
                        setSelect(
                          routeUrls.create
                        )
                      }>
                      {t('create new')}
                    </Button>
                  </div>
                </div>
                <LazyPagination
                  apiInfo={
                    datasets_getDatasetsByOwner_Api
                  }
                  query={{
                    ':id': id,
                    ':type': type
                  }}
                  renderItem={item => {
                    return (
                      <div className="border-b border-gray-300 py-3 flex items-center">
                        <div className="flex-1">
                          <Link
                            to={`/data/${item.idname}`}
                            className="text-lg font-bold">
                            {item.title}
                          </Link>
                          <div>
                            {
                              item.description
                            }
                          </div>
                        </div>
                        <DrawMenu
                          component={
                            <Button
                              className="border-none"
                              shape="circle"
                              icon={
                                <MoreOutlined />
                              }
                              onClick={e =>
                                e.preventDefault()
                              }
                            />
                          }>
                          {(
                            isToggle,
                            toggle
                          ) => (
                            <Menu
                              onClick={props => {
                                switch (
                                  props.key
                                ) {
                                  case 'edit':
                                    setSelect(
                                      routeUrls.update.replace(
                                        ':id',
                                        item.idname
                                      )
                                    )
                                    break
                                  case 'delete':
                                    setSelect(
                                      routeUrls.delete.replace(
                                        ':id',
                                        item.idname
                                      )
                                    )
                                    break
                                  default:
                                    break
                                }
                                toggle()
                              }}>
                              <Menu.Item key="edit">
                                <div>
                                  {t(
                                    'edit'
                                  )}
                                </div>
                              </Menu.Item>
                              <Menu.Item key="delete">
                                <div>
                                  {t(
                                    'delete'
                                  )}
                                </div>
                              </Menu.Item>
                            </Menu>
                          )}
                        </DrawMenu>
                      </div>
                    )
                  }}
                />
              </Space>
            )
          }}
        />
        <Route
          key={routeUrls.create}
          path={routeUrls.create}
          render={() => {
            return (
              <Space
                direction="vertical"
                className="w-full">
                <div className="border-b border-gray-300 py-3 flex items-center">
                  <div className="flex-1 font-bold">
                    {t(
                      'create dataset'
                    )}
                  </div>
                  <div>
                    <Button
                      onClick={() =>
                        setSelect(
                          routeUrls.list
                        )
                      }>
                      {t('close')}
                    </Button>
                  </div>
                </div>
                <CreateDataset
                  {...{
                    type: type,
                    id: id,
                    onSuccess: () =>
                      setSelect(
                        routeUrls.list
                      )
                  }}
                />
              </Space>
            )
          }}
        />
        <Route
          key={routeUrls.update}
          path={routeUrls.update}
          render={({ match }) => {
            return (
              <Space
                direction="vertical"
                className="w-full">
                <div className="flex items-center">
                  <div className="flex-1 font-bold">
                    {t(
                      'update dataset'
                    )}
                  </div>
                  <div>
                    <Button
                      onClick={() =>
                        setSelect(
                          routeUrls.list
                        )
                      }>
                      {t('close')}
                    </Button>
                  </div>
                </div>
                <UpdateData
                  {...{
                    id: match.params.id,
                    onSuccess: () =>
                      setSelect(
                        routeUrls.list
                      )
                  }}
                />
              </Space>
            )
          }}
        />
        <Route
          key={routeUrls.delete}
          path={routeUrls.delete}
          render={({ match }) => {
            return (
              <Space
                direction="vertical"
                className="w-full">
                <div className="border-b border-gray-300 py-3 flex items-center">
                  <div className="flex-1 font-bold">
                    {t(
                      'delete dataset'
                    )}
                  </div>
                  <div>
                    <Button
                      onClick={() =>
                        setSelect(
                          routeUrls.list
                        )
                      }>
                      {t('close')}
                    </Button>
                  </div>
                </div>
                <Async
                  apiInfo={
                    dataset_getById_Api
                  }
                  query={{
                    ':id':
                      match.params.id
                  }}>
                  {({
                    response,
                    success
                  }) => {
                    const item = _.get(
                      response,
                      'data',
                      []
                    )
                    if (!item)
                      return (
                        <LoadingPage />
                      )
                    return (
                      <Delete
                        {...{
                          item,
                          onSuccess: () =>
                            setSelect(
                              routeUrls.list
                            )
                        }}
                      />
                    )
                  }}
                </Async>
              </Space>
            )
          }}
        />
      </Switch>
    </Space>
  )
}

export default DatasetSettings
