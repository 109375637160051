import { Image } from "antd"
import _ from 'lodash'
import { useAppConfig } from "modules/local"
import moment from 'moment'
import React, { useContext } from 'react'
import { FcCheckmark } from "react-icons/fc"
import ReactPlayer from "react-player"
import StickyFrame from "../../../components/StickyFrame"
import PhotoSvg from "../../../components/Svgs/PhotoSvg"
import { Photo } from "../../../conts/imageBase64"
import { FB_ID } from "../../../envs/_current/config"
import useTranslate from '../../../modules/local/useTranslate'
import { QuickContactBox } from "../Contact/QuickContactBox"
import { OrganizationContext } from '../OrganizationContext'
import { Article } from './Article'

export function AboutUs() {
  const { organization } = useContext(
    OrganizationContext
  )
  const appConfig = useAppConfig()
  const t = useTranslate()

  return (
    <div className="flex grid grid-cols-1 lg:grid-cols-4 gap-y-3 lg:gap-3 relative">
      <div id="company-profiles"
        className="flex col-span-1 md:col-span-3 flex-col space-y-3">
        <div id="business-profiles"
          className="flex flex-col background-100 border rounded p-3 grid grid-cols-3 gap-x-3">
          <div className="flex col-span-1">
            <Image
              // width={110}
              style={{
                minHeight: 110,
                height: '19Vw',
                minWidth: 110,
                width: '19Vw',
              }}
              src={_.get(
                organization,
                'avatar', "#"
              )}
              className="object-contain rounded-lg"
              placeholder={
                <PhotoSvg
                  style={{
                    minHeight: 100,
                    height: '18Vw',
                    minWidth: 100,
                    width: '18Vw',
                    position: 'absolute'
                  }}
                />
              }
              fallback={Photo}
            />
          </div>
          <div className="flex col-span-2 p-0">
            <div className="flex flex-col w-full space-y-2">
              {[
                [
                  t('Legal Status of Firm'),
                  appConfig &&
                  appConfig.company_types &&
                  appConfig.company_types[
                  organization
                    .company_type
                  ]
                ],
                [
                  t('Nature of Business'),
                  appConfig &&
                  appConfig.business_types &&
                  appConfig.business_types[
                  organization
                    .business_type
                  ]
                ],
                [
                  t('Number of Employees'),
                  appConfig &&
                  appConfig.number_of_employees &&
                  appConfig
                    .number_of_employees[
                  organization
                    .number_of_employees
                  ]
                ],
                [
                  t('Annual Turnover'),
                  appConfig &&
                  appConfig.annual_turnover &&
                  appConfig.annual_turnover[
                  organization
                    .annual_turnover
                  ]
                ],
                [
                  t('Year of Establishment'),
                  organization.established_year
                ],
                [
                  t('FeedIn Member Since'),
                  moment(
                    organization.created
                  ).format('MMMM YYYY')
                ],
                [
                  t('Website'),
                  organization.website
                ],
              ]
                .filter(
                  ([__, value]) =>
                    !_.isEmpty(value)
                )
                .map(([label, value], i) => (
                  <div
                    key={i}
                    className="grid grid-cols-1 md:grid-cols-2 py-1  items-center gap-x-3 gap-y-1">
                    <div className="col-span-1 flex  items-center">
                      <FcCheckmark className="text-xl mx-2" />
                      <div className="text-color-200 text-sm">
                        {label}:
                      </div>
                    </div>
                    <div className="col-span-1 pl-10 md:pl-3 leading-tight font-semibold">
                      {value}
                    </div>
                  </div>
                ))}
            </div>
          </div>

        </div>
        <div id="about-company" className="flex background-100 border rounded">
          <Article className="p-3 relative w-0 flex-1" />
        </div>

        {Boolean(organization.video_url) && (
          <section className=" body-font relative">
            <div id="video-company" className="flex">
              <div className="flex flex-col text-center w-full mb-6 md:mb-12">
                <h2 className="sm:text-3xl text-2xl font-medium title-font mb-2 ">
                  {t('Videos')}
                </h2>

                <div
                  style={{
                    position: 'relative',
                    paddingTop: '60%'
                  }}
                  className="w-full background-200">
                  <div className="w-full h-full absolute inset-0">
                    <ReactPlayer
                      style={{
                        fontSize: '1em'
                      }}
                      width="100%"
                      height="100%"
                      url={organization.video_url}
                      config={{
                        youtube: {
                          playerVars: {
                            controls: 1,
                            showinfo: 1
                          }
                        },
                        facebook: {
                          appId: FB_ID
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

            </div>
          </section>
        )}

      </div>
      <section id="contact-provider"
        className="col-span-1 w-full h-full">
        <StickyFrame offset={16} className="w-full">
          <div style={{ height: 'fit-content' }}>
            <QuickContactBox prefixCls="p-3" />
          </div>
        </StickyFrame>
      </section>
    </div>
  )
}
