import {
  Avatar,
  Checkbox,
  Input,
  Tooltip
} from 'antd'
import {
  create_poll_unVoteOnPoll_Api_action,
  create_poll_vote_Api_action,
  poll_addAnswer_Api,
  poll_getVoters_Api
} from 'apis'
import { userModel } from 'apis/model'
import classnames from 'classnames'
import { emptyArray } from 'helpers/emptyObjects'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import { LazyPagination } from 'modules/asyncCache/components/LazyPagination'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useDispatchAsyncActionWithNotify from 'modules/asyncCache/useDispatchAsyncActionWithNotify'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import PermissionContext from 'modules/permissions/PermissionContext'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from '../Modal/Modal'
import Toggle from '../Toggle'
import { FollowButton } from './FollowButton'

const renderItem = (item, i) => {
  if (!item) return null
  return (
    <Translate>
      {t => (
        <div className="py-2 px-4 flex w-full border-b border-gray-300">
          <div
            style={{
              flexShrink: 0
            }}
            className="flex">
            <Avatar
              size="large"
              style={{
                background: '#f1f1f1',
                marginRight: '1em'
              }}
              src={item.avatar}
              shape={
                item._type === 'user'
                  ? 'circle'
                  : 'square'
              }>
              {getTitle(item)}
            </Avatar>
          </div>
          <div className="flex-1 overflow-hidden">
            <div className="flex items-start">
              <div className="flex-1 overflow-hidden">
                <Link
                  to={getLinkToDetail(
                    item
                  )}
                  className="cursor-pointer  font-bold leading-tight truncate">
                  {item.title ||
                    item.name}
                </Link>
                <div className="text-xs text-gray-600 leading-tight truncate">
                  <span>
                    {item.idname ||
                      item.username}
                  </span>
                </div>
              </div>
            </div>
            <div className="text-xs">
              {item.description ||
                item.about}
            </div>
          </div>
          <div>
            <FollowButton />
          </div>
        </div>
      )}
    </Translate>
  )
}
export function PollEmbed({ poll }) {
  const {
    id,
    title: question = 'What framework do you prefer?',
    total_votes,
    poll_answers = []
  } = poll
  const getPercent = voteNumber =>
    total_votes
      ? Math.floor(
          (voteNumber * 100) /
            total_votes
        )
      : 0
  const [
    newAnswer,
    setNewAnswer
  ] = useState('')
  const {
    success,
    isLoading,
    handleAsyncAction: addNewAnswer,
    loading
  } = useAsyncAction({
    onSuccess: () => setNewAnswer(''),
    apiInfo: poll_addAnswer_Api,
    query: {
      ':poll_question_id': id
    }
  })
  const t = useTranslate()
  const [
    asynVoteData,
    asyncdispath
  ] = useDispatchAsyncActionWithNotify()
  const handleVote = (
    poll_answer_id,
    vote_status
  ) => {
    if (asynVoteData.isLoading) return
    const vote = (
      poll_question_id,
      poll_answer_id
    ) => {
      const action = create_poll_vote_Api_action()(
        {
          query: {
            ':poll_question_id': poll_question_id,
            ':poll_answer_id': poll_answer_id
          }
        }
      )
      asyncdispath(action)
    }
    const unVote = (
      poll_question_id,
      poll_answer_id
    ) => {
      const action = create_poll_unVoteOnPoll_Api_action()(
        {
          query: {
            ':poll_question_id': poll_question_id,
            ':poll_answer_id': poll_answer_id
          }
        }
      )
      asyncdispath(action)
    }
    if (!vote_status) {
      vote(id, poll_answer_id)
    } else {
      unVote(id, poll_answer_id)
    }
  }
  return (
    <div
      style={{
        borderRadius: '0.3em'
      }}>
      <div className="whitespace-pre-wrap mb-2 mr-2 pt-1">
        <div className="font-bold text-lg">
          {question}
        </div>
      </div>
      {poll_answers.map(
        (
          {
            title,
            total_votes: voteNumber,
            vote_status,
            voters = emptyArray,
            id: poll_answer_id
          },
          i
        ) => {
          const percent = getPercent(
            voteNumber
          )
          const restNumber =
            voters.length - 5
          return (
            <div
              key={i}
              className="mt-2 w-full ">
              <div className="color-primary flex font-semibold">
                <div className="flex-1">
                  {percent}%
                </div>
                <div className="mb-1">
                  {voters.map(
                    (item, i) =>
                      i < 3 && (
                        <Tooltip
                          key={i}
                          title={userModel.getTitle(
                            item
                          )}>
                          <Avatar
                            size="small"
                            className="background-100 background border border-gray-300"
                            src={userModel.getThumbnailUrl(
                              item
                            )}>
                            {userModel.getTitle(
                              item
                            )}
                          </Avatar>
                        </Tooltip>
                      )
                  )}
                  {restNumber > 0 && (
                    <Toggle>
                      {(
                        isToggle,
                        toggle
                      ) => {
                        return (
                          <>
                            <Avatar
                              size="small"
                              className="background-100 background border border-gray-300">
                              +
                              {
                                restNumber
                              }
                            </Avatar>
                            <Modal
                              maskClosable
                              onCancel={
                                toggle
                              }
                              visible={
                                isToggle
                              }
                              title={Translate(
                                'users'
                              )}>
                              <LazyPagination
                                apiInfo={
                                  poll_getVoters_Api
                                }
                                query={{
                                  ':poll_question_id': id,
                                  ':poll_answer_id': poll_answer_id
                                }}
                                renderItem={
                                  renderItem
                                }></LazyPagination>
                            </Modal>
                          </>
                        )
                      }}
                    </Toggle>
                  )}
                </div>
              </div>
              <div
                onClick={() => {
                  handleVote(
                    poll_answer_id,
                    vote_status
                  )
                }}
                data-effect="ripple"
                className={classnames(
                  vote_status ? '' : '',
                  '  relative background font-semibold  hover:font-bold rounded overflow-hidden  px-3 py-2 flex items-center'
                )}>
                <div
                  style={{
                    transition:
                      'width 0.25s linear',
                    height: '2px',
                    background:
                      'var(--color-primary)',
                    width: `${percent}%`
                  }}
                  className=" h-full left-0 top-0 absolute "
                />
                <div className="flex-1">
                  <Checkbox
                    checked={
                      vote_status
                    }>
                    {title}
                  </Checkbox>
                </div>
              </div>
            </div>
          )
        }
      )}
      <PermissionContext.Consumer>
        {({ add_new_answer }) =>
          add_new_answer && (
            <Input.Search
              onChange={e =>
                setNewAnswer(
                  e.target.value
                )
              }
              loading={isLoading}
              className="mt-3"
              placeholder={t(
                'new answer'
              )}
              enterButton={t('add')}
              value={newAnswer}
              onSearch={value =>
                addNewAnswer({
                  title: value
                })
              }></Input.Search>
          )
        }
      </PermissionContext.Consumer>
    </div>
  )
}
