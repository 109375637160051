import {
  BookFilled,
  BookOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
  MessageOutlined,
  PushpinFilled,
  PushpinOutlined
} from '@ant-design/icons'
import { Menu, Popconfirm } from 'antd'
import { baseModel } from 'apis/model'
import { getLinkToDetail } from 'apis/model/base'
import classnames from 'classnames'
import { emptyObject } from 'helpers/emptyObjects'
import preventParentEvent from 'helpers/preventParentEvent'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import PermissionContext from 'modules/permissions/PermissionContext'
import React, {
  useContext
} from 'react'
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom'
import {
  compose,
  fromRenderProps
} from 'recompose'
import { Widget } from 'views/NewFeed/FeedWidget'
import DrawMenu from '../drawer/DrawMenu'
import ImageLoader from '../ImageLoader'
import {
  BaseEmbed,
  DetailEmbed
} from './BaseEmbed'
import Count from './Count'
import Description from './Description'
import './FeedCard.css'
import { GalleryEmbed } from './GalleryEmbed'
import { PollEmbed } from './PollEmbed'
import { Share } from './Share'
import Timestamp from './Timestamp'
import { VideoEmbed } from './VideoEmbed'
import { Vote } from './Vote'

export const FeedContext = React.createContext(
  {
    avatar: '',
    cover: '',
    title: '',
    description: '',
    verified: false,
    id: '',
    to: emptyObject
  }
)

export const FeedFooter = () => {
  const {
    actions,
    handleClick
  } = useContext(FeedContext)
  const translate = useTranslate()
  return (
    <div
      className="FeedCardFooter  flex color-primary w-full cursor-pointer mr-10 font-semibold"
      style={{
        lineHeight: '32px'
      }}>
      <FeedActions />
      {actions.to && (
        <div
          onClick={() =>
            handleClick('to')
          }
          className="flex-1 text-right truncate  hover:underline">
          {translate('show this post')}
        </div>
      )}
    </div>
  )
}

export const FeedContentMeta = () => {
  const {
    item,
    created,
    actions,
    creatorName,
    handleClick
  } = useContext(FeedContext)
  const permissions = useContext(
    PermissionContext
  )

  const handleMenuClick = e => {
    handleClick(e.key)
  }
  const t = useTranslate()
  const availbleActions = [
    // [icon,label,key]
    [BookOutlined, 'bookmark'],
    [BookOutlined, 'rebookmark'],
    [BookFilled, 'unbookmark'],
    !item.pin_status
      ? [PushpinOutlined, 'pin']
      : [PushpinOutlined, 'unpin'],
    [EditOutlined, 'edit'],
    [DeleteOutlined, 'delete']
  ].filter(
    ([, key]) => permissions[key]
  )

  return (
    <div className="w-full mt-2 px-2 text-xs ">
      <Link
        className=" font-bold whitespace-no-wrap cursor-pointer hover:underline"
        to={baseModel.getLinkToDetail(
          item.container
        )}>
        {' '}
        {baseModel.getTitle(
          item.container
        )}{' '}
      </Link>
      <div className="flex-1 ">
        {!!item.pin_status &&
          (actions.pin &&
            !!item.edit ? (
            <Popconfirm
              placement="bottomLeft"
              title={t('unpin this?')}
              onConfirm={() =>
                handleClick('unpin')
              }
              okText="Yes"
              cancelText="No">
              <span
                deta-effect="ripple"
                className="mr-2 align-middle">
                <PushpinFilled
                  style={{
                    color:
                      'var(--color-primary)',
                    fontSize: '1.4em'
                  }}
                />
              </span>
            </Popconfirm>
          ) : (
            <span
              deta-effect="ripple"
              className="mr-2 align-middle">
              <PushpinFilled
                style={{
                  color:
                    'var(--color-primary)',
                  fontSize: '1.4em'
                }}
              />
            </span>
          ))}
        <span className="text-gray-600 ">{`${t(
          'posted by'
        )} `}</span>
        <Link
          to={getLinkToDetail(
            item.owner
          )}
          rel="noopener noreferrer nofollow"
          className=" font-bold whitespace-no-wrap cursor-pointer hover:underline">
          {creatorName}
        </Link>{' '}
        <Timestamp
          timestamp={created}
          className="text-gray-600 mr-1 inline-block"
        />
      </div>

      {availbleActions.length && (
        <DrawMenu
          component={
            <Count
              deta-effect="ripple"
              className="extraIcon mt-0 mr-1">
              <EllipsisOutlined
                style={{
                  fontSize: '1.6em'
                }}
              />
            </Count>
          }>
          {(isToggle, toggle) => (
            <Menu

              onClick={e => {
                handleMenuClick(e)
                toggle()
              }}>
              {availbleActions.map(
                ([
                  Icon,
                  key,
                  label
                ]) => (
                  <Menu.Item
                    icon={<Icon />}
                    key={key || label}>
                    {t(
                      label ||
                      key ||
                      ''
                    )}
                  </Menu.Item>
                )
              )}
            </Menu>
          )}
        </DrawMenu>
      )}
    </div>
  )
}

export const FeedActions = () => {
  const {
    actions,
    shareUrl,
    handleClick,
    item
  } = useContext(FeedContext)
  return (
    <div className="flex items-center">
      {actions.vote && (
        <Vote id={item.id} />
      )}
      {actions.message && (
        <Count
          onClick={() =>
            handleClick('to')
          }
          defaultNumber={_.get(
            item,
            'total_comments'
          )}
          key="message"
          color="color-primary"
          component={MessageOutlined}
        />
      )}

      {actions.view &&
        _.has(item, 'total_views') &&
        _.get(item, 'total_views', 0) >
        0 && (
          <Count
            defaultNumber={_.get(
              item,
              'total_views'
            )}
            key="view"
            color="color-primary"
            component={EyeOutlined}
          />
        )}
      {actions.share && (
        <Share
          url={shareUrl}
          id={item.id}
        />
      )}
    </div>
  )
}
export const FeedEmbed = () => {
  const {
    item,
    article,
    cover,
    type,
    photos,
    handleClick,
    embed_data,
    title,
    poll,
    shareUrl,
    description
  } = useContext(FeedContext)
  const translate = useTranslate()
  return (
    <>
      {type === 'post' && (
        <>
          {photos && (
            <GalleryEmbed
              images={photos.map(
                item => item.path
              )}
            />
          )}
          {embed_data &&
            (!!ReactPlayer.canPlay(
              embed_data.url
            ) ? (
              <VideoEmbed
                title={_.get(
                  embed_data,
                  'title'
                )}
                url={_.get(
                  embed_data,
                  'url'
                )}
                subTitle={
                  _.get(
                    embed_data,
                    'host_url'
                  ) ||
                  _.get(
                    embed_data,
                    'url'
                  )
                }
                description={_.get(
                  embed_data,
                  'description'
                )}
                image={_.get(
                  embed_data,
                  'photo'
                )}
                categories={_.get(
                  item,
                  'categories'
                )}
                keywords={_.get(
                  item,
                  'keywords'
                )}
              />
            ) : (
              <BaseEmbed
                title={_.get(
                  embed_data,
                  'title'
                )}
                url={_.get(
                  embed_data,
                  'url'
                )}
                linkTo={_.get(
                  embed_data,
                  'url'
                )}
                subTitle={
                  _.get(
                    embed_data,
                    'host_url'
                  ) ||
                  _.get(
                    embed_data,
                    'url'
                  )
                }
                description={_.get(
                  embed_data,
                  'description'
                )}
                image={_.get(
                  embed_data,
                  'photo'
                )}
                categories={_.get(
                  item,
                  'categories'
                )}
                keywords={_.get(
                  item,
                  'keywords',
                  []
                )}
              />
            ))}
        </>
      )}
      {type === 'poll_question' &&
        poll && (
          <PollEmbed poll={poll} />
        )}
      {article && (
        <div
          style={{
            borderRadius: '0.3em'
          }}
          className=" background-100 p-3 flex ">
          <div className="flex overflow-hidden flex-1">
            <div className="break-words flex-1 flex-col verticalList__small">
              {!_.isEmpty(cover) && (
                <div
                  style={{
                    minWidth: '100px',
                    maxWidth: '30%',
                    minHeight: '70px',
                    maxHeight: '20vw',
                    flexShrink: '0',
                    margin:
                      '0 0 6px 6px',
                    float: 'right'
                  }}
                  className="w-40 rounded background-100 overflow-hidden h-32 flex-shrink-0 mt-2">
                  <ImageLoader
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                    alt={title}
                    src={cover}
                  />
                </div>
              )}
              <div className="truncate text-gray-600 hover:underline leading-none font-bold text-xs capitalize">
                {translate('article')}
              </div>
              <a
                onClick={e => {
                  preventParentEvent(e)
                  handleClick('to')
                }}
                href={shareUrl}
                className="font-semibold hover:underline color-primary text-lg leading-6">
                {title}
              </a>
              <div className="leading-snug">
                {description}
              </div>
              <div className="text-xs">
                {_.get(
                  item,
                  'categories',
                  []
                ).map(e => {
                  return (
                    <span
                      key={e.id}
                      className="text-gray-600">
                      {'•'}
                      <span className="px-2">
                        {e.name}
                      </span>
                    </span>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export const FeedContent = () => {
  const {
    description,
    mentions
  } = useContext(FeedContext)

  return (
    <div className="verticalList__small overflow-x-hidden">
      {description && (
        <Description
          type={'article'}
          mentions={mentions}>
          {description}
        </Description>
      )}
      <FeedEmbed />
    </div>
  )
}

export const DetailFeedContent = () => {
  const {
    item,
    type,
    photos,
    embed_data,
    poll,
    description,
    mentions
  } = useContext(FeedContext)

  let contentArr = []
  if (
    _.has(embed_data, 'content_data')
  ) {
    contentArr = _.get(
      embed_data,
      'content_data'
    )
  } else {
    contentArr = _.get(
      embed_data,
      'content',
      ''
    )
      .split('\n')
      .map(function (item) {
        return {
          type: 'p',
          value: item
        }
      })
  }
  return (
    <div className="verticalList__small overflow-x-hidden">
      {type === 'post' && (
        <>
          {description && (
            <Description
              type={'article'}
              mentions={mentions}>
              {description}
            </Description>
          )}
          {photos && (
            <GalleryEmbed
              images={photos.map(
                item => item.path
              )}
            />
          )}
          {embed_data &&
            (!!ReactPlayer.canPlay(
              embed_data.url
            ) ? (
              <VideoEmbed
                title={_.get(
                  embed_data,
                  'title'
                )}
                url={_.get(
                  embed_data,
                  'url'
                )}
                subTitle={
                  _.get(
                    embed_data,
                    'host_url'
                  ) ||
                  _.get(
                    embed_data,
                    'url'
                  )
                }
                description={_.get(
                  embed_data,
                  'description'
                )}
                image={_.get(
                  embed_data,
                  'photo'
                )}
                categories={_.get(
                  item,
                  'categories'
                )}
              />
            ) : (
              <DetailEmbed
                id={_.get(item, 'id')}
                title={_.get(
                  embed_data,
                  'title'
                )}
                url={_.get(
                  embed_data,
                  'url'
                )}
                subTitle={
                  _.get(
                    embed_data,
                    'host_name'
                  ) ||
                  _.get(
                    embed_data,
                    'host_url'
                  ) ||
                  _.get(
                    embed_data,
                    'url'
                  )
                }
                description={_.get(
                  embed_data,
                  'description'
                )}
                keywords={_.get(
                  item,
                  'keywords'
                )}
                content={contentArr}
                image={_.get(
                  embed_data,
                  'photo'
                )}
                categories={_.get(
                  item,
                  'categories'
                )}
                others={_.get(
                  item,
                  'others_in_container',
                  []
                )}
              />
            ))}
        </>
      )}
      {type === 'poll_question' &&
        poll && (
          <PollEmbed poll={poll} />
        )}
      <div className="verticalList__small">
        {_.get(item, 'widgets', []).map(
          (e, i) => {
            return (
              <Widget
                key={i}
                index={i}
                item={e}
              />
            )
          }
        )}
      </div>
    </div>
  )
}

export const FeedContentLayout = ({
  style,
  children
}) => {
  return (
    <div
      style={style}
      className="FeedCardContent verticalList__small">
      {children}
    </div>
  )
}
export const withFeedContext = compose(
  fromRenderProps(
    FeedContext.Consumer,
    props => props
  )
)
export const FeedWrapper = ({
  className,
  children
}) => {
  const { item } = useContext(
    FeedContext
  )
  return (
    <div
      onContextMenu={
        item.content_disabled
          ? preventParentEvent
          : undefined
      }
      className={classnames(
        'FeedCard',
        item.content_disabled &&
        'content_disabled',
        className
      )}>
      {children}
    </div>
  )
}
