import {
  Button,
  Input,
  message
} from 'antd'
import { auth_login_Api } from 'apis'
import FieldDecorator from 'components/form/FieldDecorator'
import { Form, Formik } from 'formik'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { loginSuccess } from 'redux/actions'
import * as Yup from 'yup'

const LoginForm = () => {
  const t = useTranslate()
  const dispatch = useDispatch()
  const {
    isLoading,
    handleAsyncAction
  } = useAsyncAction({
    apiInfo: auth_login_Api,
    onSuccess: (...args) => {
      dispatch(
        loginSuccess(
          _.get(
            args,
            '1.response.data'
          ),
        )
      )
    },
    onError: (...args) =>
      message.error(
        _.get(args, '1.error')
      )
  })
  return (
    <Formik
      initialValues={{
        email: '',
        password: ''
      }}
      onSubmit={handleAsyncAction}
      validationSchema={Yup.object().shape(
        {
          email: Yup.string()
            .email(
              t('invalid email address')
            )
            .required(t('Required')),
          password: Yup.string()
            .min(
              8,
              t(
                'Password must have min 8 characters'
              )
            )
            .required(
              t('password required')
            )
        }
      )}>
      {props => {
        const {
          isValid,
          values,
          isSubmitting,
          errors,
          handleChange,
          handleBlur,
          handleSubmit
        } = props
        return (
          <div>
            <Form
              onKeyDown={event => {
                if (
                  event.keyCode === 13
                ) {
                  event.preventDefault()
                  handleSubmit()
                }
              }}
              onSubmit={handleSubmit}
              className="verticalList">
              <FieldDecorator
                isRequired={true}
                error={[errors.email]}
                errors={errors.email}>
                <Input
                  size="large"
                  name="email"
                  value={values.email}
                  onChange={
                    handleChange
                  }
                  type="text"
                  placeholder={t(
                    'email account'
                  )}
                />
              </FieldDecorator>
              <FieldDecorator
                isRequired={true}
                errors={[
                  errors.password
                ]}>
                <Input
                  size="large"
                  type="password"
                  name="password"
                  value={
                    values.password
                  }
                  onChange={
                    handleChange
                  }
                  placeholder={t(
                    'password'
                  )}
                />
              </FieldDecorator>
              <div className="w-full">
                <Button
                  block
                  // disabled={!isValid}
                  loading={isLoading}
                  onClick={handleSubmit}
                  type="primary"
                  className="w-full"
                  size="large">
                  {t('login')}
                </Button>
                <div className="text-right color-primary">
                  <Link to="/forgotpassword">
                    {t('forgotpass')}
                  </Link>
                </div>
              </div>
            </Form>
          </div>
        )
      }}
    </Formik>
  )
}

export default LoginForm
