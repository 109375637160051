import mapObject from 'helpers/mapObject'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'

export class WithDynamicState extends PureComponent {
  static propTypes = {
    initialState: PropTypes.any,
    handleUpdateState: PropTypes.any,
    children: PropTypes.func.isRequired
  }
  constructor(props) {
    super(props)
    this.state = {
      ...this.props.initialState
    }
    const {
      handleUpdateState = {}
    } = this.props
    this.handleUpdateState = mapObject(
      handleUpdateState,
      (func, key) => {
        return {
          key,
          action: (...args) => {
            this.setState(state => ({
              ...state,
              ...func(state, ...args)
            }))
          }
        }
      }
    ).reduce((result, option) => {
      result[option.key] = option.action
      return result
    }, {})
  }

  render() {
    const { children } = this.props
    return children(
      this.state,
      this._updateState,
      this.handleUpdateState
    )
  }
  _updateState = newState =>
    this.setState({ ...newState })
}

export default WithDynamicState
