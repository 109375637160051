import {chartSchema} from 'apis/chart'
import ErrorBoundary from 'components/error/ErrorBoundary'
import Null from 'components/Null'
import ChartSvg from 'components/Svgs/ChartSvg'
import {emptyObject} from 'helpers/emptyObjects'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {useSelectEntities} from 'redux/entities/useSelectEntities'
// import {createSeriesOption} from 'views/ChartsPlayground/ChartEditor'
// import Compared3Series from 'views/ChartsPlayground/Compared3Series'
// import DynamicLightweightChart from 'views/ChartsPlayground/DynamicLightweightChart.lazy'
// import SeriesChart from 'views/ChartsPlayground/Series'
import {ChartProvider, PremiumChartProvider, TableChartProvider} from '../ChartContext'
import AmChart from './AmChart'
import AmGeoChart from './AmGeoChart'
import AmPieChart from './AmPieChart'
import AmVNGeoChart from './AmVNGeoChart'
import AntTable from './AntTable'
import ChartWrapper from './ChartWrapper/ChartWrapper'
import DetailChartWrapper from './ChartWrapper/DetailChartWrapper'
import GoogleChart from './GoogleChart'
import PremiumChartHolder from './PremiumChartHolder/PremiumChartHolder.lazy'
import TradingView from './TradingView'
import {Spin} from "antd";

export const ChartStateless = ({
                                 header = Null,
                                 item,
                                 CWrapper = ChartWrapper,
                                 ...props
                               }) => {
  const t = useTranslate()
  let chartElement = null
  if (!item) return (
    <CWrapper {...props}>
      <div className="relative h-48 flex justify-center items-center flex-col space-y-4 my-auto">
        <div className="h-40 inset-0 opacity-25 opacity-50">
          <ChartSvg
            width="100%"
            height="100%"/>
        </div>
        <Spin size={"large"}></Spin>
      </div>
    </CWrapper>
  );
  if (item.data_empty) {
    return (
      <ChartProvider item={item}>
        <CWrapper {...props}>
          <div className="relative h-48 flex justify-center items-center flex-col space-y-4 my-auto">
            <div className="h-40 inset-0 opacity-25">
              <ChartSvg
                width="100%"
                height="100%"/>
            </div>
            <h3 className="text-center font-bold text-xl opacity-75">
              {t('no data found')}
            </h3>
          </div>
        </CWrapper>
      </ChartProvider>
    )
  }
  switch (true) {
    case item.subscribe_required:
      chartElement = (
        <PremiumChartProvider
          item={item}>
          <CWrapper {...props}>
            <PremiumChartHolder
              {...props}
            />
          </CWrapper>
        </PremiumChartProvider>
      )
      break
    case item.chart_source ===
    'TradingView':
      chartElement = (
        <ChartProvider item={item}>
          <CWrapper {...props}>
            <TradingView
              key={item.id}
              {...props}
              className=""
            />
          </CWrapper>
        </ChartProvider>
      )
      break
    case item.chart_source ===
    'GoogleChart' &&
    item.chart_type === 'Table':
      chartElement = (
        <TableChartProvider item={item}>
          <CWrapper {...props}>
            <GoogleChart {...props} />
          </CWrapper>
        </TableChartProvider>
      )
      break
    case item.chart_source ===
    'AntDesign' &&
    item.chart_type === 'Table':
      chartElement = (
        <TableChartProvider item={item}>
          <CWrapper {...props}>
            <AntTable {...props} />
          </CWrapper>
        </TableChartProvider>
      )
      break
    case item.chart_source ===
    'GoogleChart':
      chartElement = (
        <ChartProvider item={item}>
          <CWrapper {...props}>
            <GoogleChart {...props} />
          </CWrapper>
        </ChartProvider>
      )
      break
    case item.chart_source ===
    'AmChart' &&
    item.chart_type === 'VNGeoChart':
      chartElement = (
        <ChartProvider item={item}>
          <CWrapper {...props}>
            <AmVNGeoChart {...props} />
          </CWrapper>
        </ChartProvider>
      )
      break
    case item.chart_source ===
    'AmChart' &&
    item.chart_type === 'GeoChart':
      chartElement = (
        <ChartProvider item={item}>
          <CWrapper {...props}>
            <AmGeoChart {...props} />
          </CWrapper>
        </ChartProvider>
      )
      break
    case item.chart_source ===
    'AmChart' &&
    item.chart_type === 'PieChart':
      chartElement = (
        <ChartProvider item={item}>
          <CWrapper {...props}>
            <AmPieChart {...props} />
          </CWrapper>
        </ChartProvider>
      )
      break
    case item.chart_source ===
    'AmChart':
      chartElement = (
        <ChartProvider item={item}>
          <CWrapper {...props}>
            <AmChart {...props} />
          </CWrapper>
        </ChartProvider>
      )
      break
    // case item.chart_source ===
    //   'LightWeightChart':
    //   chartElement = (
    //     <ChartProvider item={item}>
    //       <CWrapper {...props}>
    //         <LightWeightChartStateless
    //           {...item}
    //         />
    //       </CWrapper>
    //     </ChartProvider>
    //   )
    //   break
    default:
      break
  }
  return (
    <ErrorBoundary
      fallbackComponent={() => {
        return (
          <div className="w-full h-full flex justify-center items-center font-bold text-gray-600 text-2xl">
            Error
          </div>
        )
      }}>
      {chartElement}
    </ErrorBoundary>
  )
}

export const Chart = ({
                        id,
                        ...props
                      }) => {
  const item = useSelectEntities(
    id,
    chartSchema,
    emptyObject
  )

  return (
    <ChartStateless
      item={item}
      CWrapper={
        props.detail
          ? DetailChartWrapper
          : ChartWrapper
      }
      {...props}
    />
  )
}

// export const LightWeightChartStateless = ({
//   id,
//   params,
//   chart_type,
//   width,
//   height,
//   series
// }) => {
//   const { theme } = useContext(
//     ThemeContext
//   )
//   const { setChartWrap } = useContext(
//     ChartContext
//   )
//   const chartRef = useCallback(
//     chart =>
//       setChartWrap({
//         getChart: () => chart,
//         toBlob: cb =>
//           chart.takeScreenshot &&
//           chart
//             .takeScreenshot()
//             .toBlob(cb),
//         toDataURL: cb =>
//           chart.takeScreenshot &&
//           chart
//             .takeScreenshot()
//             .toDataURL(cb)
//       }),
//     [setChartWrap]
//   )
//   let theme2 =
//     theme === 'auto'
//       ? window.matchMedia &&
//         window.matchMedia(
//           '(prefers-color-scheme: dark)'
//         ).matches
//         ? 'Dark'
//         : 'Light'
//       : theme.charAt(0).toUpperCase() +
//         theme.slice(1)
//   const cloneSeries = series.map(
//     item => ({
//       ...item,
//       data: item.data.map(item => ({
//         ...item,
//         time: item.time
//       }))
//     })
//   )
//   switch (chart_type) {
//     case 'Bar':
//     case 'Area':
//     case 'Candlestick':
//     case 'Histogram':
//       return (
//         <ErrorBoundary
//           fallbackComponent={() => {
//             return (
//               <div className="w-full h-full flex justify-center items-center font-bold text-gray-600 text-2xl">
//                 Error
//               </div>
//             )
//           }}>
//           <DynamicLightweightChart
//             ref={chartRef}
//             theme={theme2}
//             width={width}
//             options={{
//               chart: {},
//               series: cloneSeries.map(
//                 createSeriesOption()
//               )
//             }}
//             series={cloneSeries}
//             height={height}
//           />
//         </ErrorBoundary>
//       )
//
//     case 'Series':
//       return (
//         <ErrorBoundary
//           fallbackComponent={() => {
//             return (
//               <div className="w-full h-full flex justify-center items-center font-bold text-gray-600 text-2xl">
//                 Error
//               </div>
//             )
//           }}>
//           <SeriesChart
//             ref={chartRef}
//             chartOption={params}
//             series={cloneSeries}
//             theme={theme2}
//             width={width}
//             height={height}
//           />
//         </ErrorBoundary>
//       )
//
//     case 'Compared3Series':
//       return (
//         <ErrorBoundary
//           fallbackComponent={() => {
//             return (
//               <div className="w-full h-full flex justify-center items-center font-bold text-gray-600 text-2xl">
//                 Error
//               </div>
//             )
//           }}>
//           <Compared3Series
//             ref={chartRef}
//             series={cloneSeries}
//             theme={theme2}
//             width={width}
//             height={
//               height
//             }></Compared3Series>
//         </ErrorBoundary>
//       )
//     default:
//       return null
//   }
// }

export const GetChartComponent = ({
                                    header = Null,
                                    width,
                                    height,
                                    item,
                                    ...props
                                  }) => {
  switch (true) {
    case item.subscribe_required:
      return (
        <PremiumChartHolder
          {...props}
        />
      )
    case item.chart_source ===
    'TradingView':
      return (
        <TradingView
          key={item.id}
          {...props}
          className=""
        />
      )
    case item.chart_source ===
    'GoogleChart' &&
    item.chart_type === 'Table':
      return <GoogleChart {...props} />
    case item.chart_source ===
    'AntDesign' &&
    item.chart_type === 'Table':
      return <AntTable {...props} />
    case item.chart_source ===
    'GoogleChart':
      return <GoogleChart {...props} />
    case item.chart_source ===
    'AmChart' &&
    item.chart_type === 'VNGeoChart':
      return <AmVNGeoChart {...props} />
    case item.chart_source ===
    'AmChart' &&
    item.chart_type === 'GeoChart':
      return <AmGeoChart {...props} />
    case item.chart_source ===
    'AmChart' &&
    item.chart_type === 'PieChart':
      return <AmPieChart {...props} />
    case item.chart_source ===
    'AmChart':
      return <AmChart {...props} />
    // case item.chart_source ===
    //   'LightWeightChart':
    //   return (
    //     <LightWeightChartStateless
    //       {...{ width, height }}
    //       {...item}
    //     />
    //   )
    default:
      return null
  }
}
export default React.memo(Chart)
