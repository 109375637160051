import { API_ROOT_URL } from 'envs/_current/config'
import { createAsyncAction } from 'modules/asyncCache'
import { baseFieldTypes } from './base/baseFieldTypes'
import { organizationSchema } from './schema'
import { createTransform } from './utils/createTransform'

const organization_transform = createTransform(
  organizationSchema,
  'data'
)
organization_transform.toString = () =>
  'organization_transform'
const organization_transformArray = createTransform(
  [organizationSchema]
)
organization_transform.toString = () =>
  'organization_transformArray'

const fields = [
  'idname', // unique ~ username
  'user_id', // auto fill by login user
  'owner_id', // auto fill by login user
  'owner_type', // auto fill by login user
  'org_type', // 100: company
  'title', // Ten cong ty
  'description', // mo ta cong ty
  'avatar', // avatar
  'thumbnail', // tu gen ra boi avatar (kich thuoc nho hon)
  'cover_photo', // banner
  'status', // auto fill = 1
  'certified', // auto fill = 0
  'privacy', // 1: public, 2: private
  'public', // 1: search allow, 0: no searchable -> Feedin control
  'active', // 1: active (default), 0: inactive -> user control,
  'categories',
  'about' // rich text
]

const organization_FieldTypes = {
  user_id: baseFieldTypes.auto,
  owner_id: baseFieldTypes.auto,
  avatar: baseFieldTypes.image,
  cover_photo: baseFieldTypes.image,
  thumbnail: baseFieldTypes.auto,
  about: baseFieldTypes.string
  // status: baseFieldTypes.status,
  // active: baseFieldTypes.active,
  // certified: baseFieldTypes.certified,
  // privacy: baseFieldTypes.privacy,
  // public: baseFieldTypes.public
}

// Route::post('organizations/add', 'OrganizationController@store');
export const organization_Create_Api = {
  backend: `Route::post('organizations/add', 'OrganizationController@store')`,
  root: API_ROOT_URL,
  path: '/organizations/add',
  method: 'POST',
  fields,
  fieldTypes: organization_FieldTypes,
  transform: organization_transform
}

// Route::post('organizations/{id}/edit', 'OrganizationController@update');
export const organization_Edit_Api = {
  backend: `Route::post(''organizations/{id}/edit'', 'OrganizationController@update')`,
  root: API_ROOT_URL,
  path: '/organizations/:id/edit',
  method: 'POST',
  fields,
  fieldTypes: organization_FieldTypes,
  transform: organization_transform
}
// Route::post('organizations/{id}/delete', 'OrganizationController@deletePollQuestion');
export const organization_Delete_Api = {
  backend: `Route::post(''organizations/{id}/delete'', 'OrganizationController@deletePollQuestion')`,
  root: API_ROOT_URL,
  path: '/organizations/:id/delete',
  method: 'POST',
  fields,
  fieldTypes: organization_FieldTypes,
  transform: organization_transform
}

// Route::get('organizations/get-typical-organizations', 'OrganizationController@getTypicalOrganizations');
export const organization_GetTypicalOrganizations_Api = {
  backend: `Route::get('organizations/get-typical-organizations', 'OrganizationController@getTypicalOrganizations')`,
  root: API_ROOT_URL,
  path:
    '/organizations/get-typical-organizations',
  method: 'GET',
  fieldTypes: organization_FieldTypes,
  transform: organization_transformArray
}

// Route::get('organizations/{id}/get-following-users', 'OrganizationController@getFollowingUsers');
export const organization_GetFollowingUsers_Api = {
  backend: `Route::get('organizations/{id}/get-following-users', 'OrganizationController@getFollowingUsers')`,
  root: API_ROOT_URL,
  path:
    '/organizations/:id/get-following-users',
  method: 'GET',
  fieldTypes: organization_FieldTypes,
  transform: organization_transformArray
}

// Route::get('organizations/{id}/get', 'OrganizationController@getById');
export const organization_getById_Api = {
  backend: `Route::get('organizations/:id/get', 'OrganizationController@getById')`,
  root: API_ROOT_URL,
  path: '/organizations/:id/get',
  method: 'GET',
  fieldTypes: organization_FieldTypes,
  transform: createTransform(
    organizationSchema,
    'data'
  )
}
export const create_organization_getById_Api_action = () => ({
  prefixStr = 'organization_getById_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@organization_getById_Api',
    query,
    values,
    apiInfo: organization_getById_Api,
    maxAge
  })
// Route::get('organizations/{id}/show', 'OrganizationController@show');
export const organization_show_Api = {
  backend: `Route::get('organizations/{id}/show', 'OrganizationController@show')`,
  root: API_ROOT_URL,
  path: '/organizations/:id/show',
  method: 'GET',
  fieldTypes: organization_FieldTypes,
  transform: organization_transform
}

// Route::post('organizations/{id}/request-verify', 'OrganizationController@requestVerifyTaxCode');
export const organization_RequestVerify_Api = {
  backend: `Route::post('organizations/{id}/request-verify', 'OrganizationController@requestVerifyTaxCode');`,
  root: API_ROOT_URL,
  path:
    '/organizations/:id/request-verify',
  method: 'POST',
  fields,
  fieldTypes: organization_FieldTypes,
  transform: organization_transform
}
