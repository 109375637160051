import Icon, {
  OrderedListOutlined,
  PlusOutlined,
  UnorderedListOutlined
} from '@ant-design/icons'
import { Popover } from 'antd'
import Count from 'components/Feed/Count'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {
  BiRedo,
  BiUndo
} from 'react-icons/bi'
import { MdVerticalAlignCenter } from 'react-icons/md'

export default function StatusBar({
  editorState
}) {
  const t = useTranslate()
  return (
    <div className="w-full py-3 sticky top-0 z-10">
      <div className="w-full background rounded border border-gray-300 shadow-lg py-2 px-3 flex justify-between space-x-3">
        <div>
          {/* <WorkCounter
            editorState={editorState}
          />{' '}
          {t('words')} */}
        </div>
        <Popover
          defaultVisible={true}
          placement="bottomRight"
          title={t(
            'Use keyboard shortcuts'
          )}
          content={
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 ">
              {[
                [
                  [
                    'ctrl',
                    'b',
                    <span className="font-bold">
                      BOLD
                    </span>
                  ],
                  [
                    'ctrl',
                    'i',
                    <span className="italic">
                      italic
                    </span>
                  ],
                  [
                    '1',
                    '.',
                    'space',
                    <OrderedListOutlined />
                  ],
                  [
                    '*',
                    'space',
                    <UnorderedListOutlined />
                  ]
                ],
                [
                  [
                    'shift',
                    'enter',
                    <>
                      <Icon
                        component={
                          MdVerticalAlignCenter
                        }></Icon>
                    </>
                  ],
                  [
                    'ctrl',
                    'z',
                    <>
                      <Icon
                        component={
                          BiUndo
                        }></Icon>{' '}
                      undo
                    </>
                  ],
                  [
                    'shift',
                    'ctrl',
                    'z',
                    <>
                      <Icon
                        component={
                          BiRedo
                        }></Icon>{' '}
                      redo
                    </>
                  ]
                ]
              ].map((list, i) => (
                <div
                  key={i}
                  className="col-span-1 space-y-3">
                  {list.map(
                    (
                      item,
                      i,
                      array
                    ) => {
                      return (
                        <div
                          key={i}
                          className="flex space-x-1 items-center ">
                          {item.map(
                            (
                              e,
                              i,
                              array
                            ) => {
                              if (
                                i ===
                                array.length -
                                  1
                              ) {
                                return e
                              }
                              return (
                                <React.Fragment
                                  key={
                                    i
                                  }>
                                  <div className="py-1 px-3 rounded text-xs border border-gray-300 text-gray-600">
                                    {e}
                                  </div>
                                  {i <
                                    array.length -
                                      2 && (
                                    <PlusOutlined className="text-xs text-gray-500"></PlusOutlined>
                                  )}
                                </React.Fragment>
                              )
                            }
                          )}
                        </div>
                      )
                    }
                  )}
                </div>
              ))}
            </div>
          }
          trigger="click">
          <Count className="text-sm">
            {t(
              'Use keyboard shortcuts ?'
            )}
          </Count>
        </Popover>
      </div>
    </div>
  )
}
