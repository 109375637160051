import classnames from 'classnames'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'

const FieldDecorator = ({
  className,
  label,
  errors,
  children,
  isRequired
}) => {
  const t = useTranslate()
  return (
    <div
      className={classnames(
        'relative',
        { errors },
        className
      )}>
      {label && (
        <label className="capitalize text-sm font-semibold pb-2 block text-gray-600">
          {t(label)}
          {isRequired && (
            <span className="text-red-500">
              *
            </span>
          )}
        </label>
      )}
      {children}
      <div
        style={{
          minHeight: '1em',
          lineHeight: '1em'
        }}
        className="text-sm text-red-500">
        {errors}
      </div>
    </div>
  )
}
export default FieldDecorator
