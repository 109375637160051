import { Button } from 'antd'
import Null from 'components/Null'
import { convertToRaw } from 'draft-js'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
  useState
} from 'react'
import { SettingsModal } from 'views/Organization/Settings/SettingsModal'
import { parseRawContent } from './parseRawContent'
import RichEditor, {
  defaultRawContent
} from './RichEditor'
const ModalRichEditorContainer = ({
  isEmpty = false,
  title = 'Edit article',
  value = JSON.stringify(
    defaultRawContent
  ),
  isLoading,
  toggle,
  onSubmit = Null,
  setToggle,
  ...props
}) => {
  const translate = useTranslate()
  const [
    editorState,
    setEditorState
  ] = useState(() =>
    parseRawContent(value)
  )
  const handleOnChange = useCallback(
    editorState => {
      setEditorState(editorState)
    },
    []
  )
  return (
    <>
      {!isEmpty && (
        <div className="w-full">
          <RichEditor
            key={value}
            readOnly
            editorState={editorState}
            onChange={handleOnChange}
          />
        </div>
      )}
      {toggle && (
        <SettingsModal
          name="edit"
          onCancel={() =>
            setToggle(false)
          }
          e
          {...props}>
          <div className="p-3">
            <RichEditor
              editorState={editorState}
              onChange={handleOnChange}
            />
            <div className="flex justify-end sticky bottom-0 z-10 p-4 background">
              <Button
                key="back"
                onClick={() =>
                  setToggle(false)
                }>
                {translate('cancel')}
              </Button>
              <Button
                style={{
                  marginLeft: '1em'
                }}
                key="submit"
                type="primary"
                loading={isLoading}
                onClick={() =>
                  onSubmit(
                    JSON.stringify(
                      convertToRaw(
                        editorState.getCurrentContent()
                      )
                    )
                  )
                }>
                {translate('save')}
              </Button>
            </div>
          </div>
        </SettingsModal>
      )}
    </>
  )
}
export default ModalRichEditorContainer
