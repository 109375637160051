import { UploadOutlined } from '@ant-design/icons'
import { Button, message, Upload } from 'antd'
import Null from 'components/Null'
import { Field } from 'formik'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { createValue } from '../utils'

export function getBase64(file) {
  return new Promise(
    (resolve, reject) => {
      const reader = new FileReader()
      console.log({ file })
      reader.readAsDataURL(file)
      reader.onload = () =>
        resolve(reader.result)
      reader.onerror = error =>
        reject(error)
    }
  )
}

export default function ImageUpload({
  value,
  name,
  onChange,
  success = Null,
  error = Null,
  renderPreview,
  showUploadList = true,
  children
}) {
  const translate = useTranslate()
  const handleOnchange = function (
    info
  ) {
    console.log({ info })
    if (!!info && !info.file.preview) {
      getBase64(
        info.file
      ).then(result => {
        onChange(
          createValue(
            name + '_preview',
            result
          )
        )
        onChange(
          createValue(
            name,
            info.file
          )
        )
      })
    }
    if (
      !!info && info.file.status !== 'uploading'
    ) {
      console.log(
        info.file,
        info.fileList
      )
    }
    if (!!info && info.file.status === 'done') {
      success(
        `${info.file.name} ${translate(
          'file uploaded successfully'
        )}`
      )
    } else if (
      !!info && info.file.status === 'error'
    ) {
      !!info &&
        error(
          `${info.file.name} ${translate(
            'file upload failed'
          )}.`
        )
    }
  }
  const beforeUpload = function beforeUpload(
    file
  ) {
    const isLt2M =
      file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error(
        `${translate(
          'image must smaller than'
        )} 2MB!`
      )
    }
    const isJpgOrPng =
      file.type === 'image/jpeg' ||
      file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error(
        `${translate(
          'You can only upload JPG/PNG file'
        )}!`
      )
    }

    return false
  }
  return (
    <div className="flex flex-col items-center">
      <Field
        name={name + '_preview'}
        render={({
          field,
          form: {
            touched = {},
            errors = {}
          } = {
            touched: {},
            errors: {}
          }
        }) =>
          renderPreview ? (
            renderPreview({
              src: field.value
            })
          ) : (
            <img
              style={{
                width: '100%',
                minHeight: '300px',
                height: 'auto',
                objectFit: 'cover'
              }}
              src={field.value}
              size={100}
              className="bg-gray-300"
              alt={field.value}
            />
          )
        }
      />
      <br />
      <Upload
        {...{
          showUploadList,
          beforeUpload,
          name: 'file',
          onChange: handleOnchange
        }}>
        {children ? (
          children
        ) : (
          <Button>
            <UploadOutlined />{' '}
            {translate(
              'click to upload'
            )}
          </Button>
        )}
      </Upload>
    </div>
  )
}
