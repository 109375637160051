import Icon from '@ant-design/icons'
import {Image} from 'antd'
import _ from 'lodash'
import React, {useContext} from 'react'
import {FaFacebookF, FaShareAlt, FaTwitter} from 'react-icons/fa'
import {MdLocationOn} from 'react-icons/md'
import {Link, NavLink} from 'react-router-dom'
import {organizationModel} from '../../apis/model'
import PhotoSvg from "../../components/Svgs/PhotoSvg"
import {Photo} from "../../conts/imageBase64"
import {callNativeShare, createFacebookShareLink, createTwitterShareLink} from '../../helpers'
import useTranslate from '../../modules/local/useTranslate'
import {HeaderButton} from '../../views/Organization/components/PageHeader'
import {validateServiceType} from "./functions/validateServiceType"
import {OrganizationContext} from './OrganizationContext'

export const Header = React.memo(
  function Header({ url }) {
    const t = useTranslate()
    const {
      organization: item
    } = useContext(OrganizationContext)

    const shareLink = organizationModel.getLinkToShare(
      item
    );

    return React.useMemo(
      () => (
        <header className="background z-30 w-full relative">
          <nav className="flex flex-wrap flex-col md:flex-row items-center ">
            <div className="w-full flex-wrap md:flex-no-wrap  items-start flex mb-4 md:mb-0">
              <div
                style={{
                  minWidth: 300
                }}
                className="flex-1 ">
                <div
                  style={{ zIndex: 11 }}
                  className="w-full relative HomeHeader">
                  <div className="background overflow-hidden relative w-full h-auto"
                    style={{
                      zIndex: 11,
                    }}>
                    <div id="shadow" className="absolute w-full top-0 left-0 object-cover h-auto"
                      style={{
                        backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 20%,  var(--background) 100%)`
                      }}
                    />
                    <div className="flex p-3 w-full flex-col">
                      <div className="flex">
                        <div className="table">
                          <Image
                            width={80}
                            height={80}
                            src={organizationModel.getFullAvatarUrl(
                              item
                            ) || "#"}
                            className="object-contain w-full h-full rounded-lg border-white table"
                            placeholder={<PhotoSvg />}
                            fallback={Photo}
                          />
                        </div>

                        <div className="flex flex-col px-3">
                          <Link
                            title={
                              item.title
                            }
                            to={organizationModel.getLinkToDetail(
                              item
                            )}
                            className="">
                            <h2 className="title-font text-base md:text-lg font-bold">
                              {item.title}
                            </h2>
                          </Link>

                          <div className="flex flex-wrap md:flex-wrap text-sm">
                            {_.has(
                              item,
                              'locations[0]'
                            ) && (
                                <div className="flex items-start mr-4">
                                  <MdLocationOn
                                    style={{
                                      marginTop: 4
                                    }}
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={1}
                                    className="text-primary w-4 h-4 flex-shrink-0 mr-2"
                                  />
                                  <div>
                                    {!_.isEmpty(
                                      _.get(
                                        item,
                                        'locations.0.address',
                                        null
                                      )
                                    )
                                      ? _.get(
                                        item,
                                        'locations.0.address'
                                      ) +
                                      ', '
                                      : ''}
                                    {!_.isEmpty(
                                      _.get(
                                        item,
                                        'locations.0.ward',
                                        null
                                      )
                                    )
                                      ? _.get(
                                        item,
                                        'locations.0.ward.type'
                                      ) +
                                      ' ' +
                                      _.get(
                                        item,
                                        'locations.0.ward.name'
                                      ) +
                                      ', '
                                      : ''}
                                    {!_.isEmpty(
                                      _.get(
                                        item,
                                        'locations.0.district',
                                        null
                                      )
                                    ) &&
                                      _.get(
                                        item,
                                        'locations.0.district.type'
                                      ) +
                                      ' ' +
                                      _.get(
                                        item,
                                        'locations.0.district.name'
                                      )}
                                    {!_.isEmpty(
                                      _.get(
                                        item,
                                        'locations.0.province',
                                        null
                                      )
                                    ) &&
                                      ', ' +
                                      _.get(
                                        item,
                                        'locations.0.province.type'
                                      ) +
                                      ' ' +
                                      _.get(
                                        item,
                                        'locations.0.province.name'
                                      )}
                                    {!_.isEmpty(
                                      _.get(
                                        item,
                                        'locations.0.country',
                                        null
                                      )
                                    ) &&
                                    ', ' +
                                    _.get(
                                      item,
                                      'locations.0.country.common_name'
                                    )}
                                  </div>
                                </div>
                              )}

                            {item.tax_code && (
                              <div className="flex items-center mr-4">
                                <svg
                                  fill="none"
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={1}
                                  className="text-primary w-4 h-4 flex-shrink-0 mr-2"
                                  viewBox="0 0 24 24">
                                  <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                                  <path d="M22 4L12 14.01l-3-3" />
                                </svg>
                                <div>
                                  {t('tax code')}{' '}
                                  <b className="font-semibold">
                                    {
                                      item.tax_code
                                    }
                                  </b>
                                </div>
                              </div>
                            )}
                            {item.certified >
                              0 && (
                                <div className="flex items-center mr-4">
                                  <svg
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={1}
                                    className="text-primary w-4 h-4 flex-shrink-0 mr-2"
                                    viewBox="0 0 24 24">
                                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                                    <path d="M22 4L12 14.01l-3-3" />
                                  </svg>
                                  <div>
                                    <b className="font-bold">
                                      {t(
                                        'verified'
                                      )}{' '}
                                    </b>
                                    {t('provider')}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="relative rounded-lg w-full text-base mt-3 flex justify-center items-center">
                        <div className="py-3 flex w-full space-x-2 items-center ">
                          <div className="font-bold text-gray-600 text-xs uppercase hidden md:block">
                            {t('share')}:{' '}
                          </div>
                          <HeaderButton
                            component="a"
                            href={createFacebookShareLink(
                              shareLink
                            )}
                            target="_blank"
                            width={34}
                            height={34}
                            icon={
                              <Icon
                                component={
                                  FaFacebookF
                                }></Icon>
                            }></HeaderButton>
                          <HeaderButton
                            component="a"
                            href={createTwitterShareLink(
                              shareLink
                            )}
                            target="_blank"
                            width={34}
                            height={34}
                            icon={
                              <Icon
                                component={
                                  FaTwitter
                                }></Icon>
                            }></HeaderButton>
                          <HeaderButton
                            onClick={() => {
                              callNativeShare(
                                {
                                  title: organizationModel.getTitle(
                                    item
                                  ),
                                  url
                                }
                              )
                            }}
                            width={34}
                            height={34}
                            icon={
                              <Icon
                                component={
                                  FaShareAlt
                                }></Icon>
                            }></HeaderButton>
                        </div>

                        {
                          _.has(item, 'contacts.0.phone')
                          && (
                            <div
                              className="py-3 text-lg flex flex-1 float-right md:flex-none justify-center lg:justify-end sm:flex-col lg:flex-row">
                              <button
                                style={{
                                  minWidth: 150
                                }}
                                className="truncate flex-1 sm:flex-none py-1 md:flex-1 lg:flex-none leading-tight border max-w-md  px-3 rounded text-primary hover:border-2 border-primary flex flex-col items-center"
                                data-effect="ripple">

                                <div className="font-bold  ">
                                  {t('Call')}
                                  <a className="font-bold px-2" href={"tel:" + _.get(item, 'contacts.0.phone')}>
                                    {
                                      _.get(item, 'contacts.0.phone')
                                    }
                                  </a>
                                </div>
                              </button>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <nav className="md:mr-auto py-1 flex flex-row text-base overflow-auto w-full">
              <NavLink
                to={url}
                exact
                activeClassName="border-primary text-primary"
                className="flex-shrink-0 border-b-2 border-transparent uppercase mt-1 px-3 font-bold py-1 mr-1 background-100 hover:text-primary ">
                {t('home')}
              </NavLink>
              {
                validateServiceType(_.get(item, 'service_type', '000'), "product") && (
                  <NavLink
                    to={url + `/products`}
                    activeClassName="border-primary text-primary"
                    className="flex-shrink-0 border-b-2 border-transparent uppercase mt-1 px-3 font-bold py-1 mr-1 background-100 hover:text-primary ">
                    {t('products')}
                  </NavLink>
                )
              }
              {
                validateServiceType(_.get(item, 'service_type', '000'), "channel") && (
                  <NavLink
                    to={url + `/news`}
                    activeClassName="border-primary text-primary"
                    className="flex-shrink-0 border-b-2 border-transparent uppercase mt-1 px-3 font-bold py-1 mr-1 background-100 hover:text-primary ">
                    {t('news')}
                  </NavLink>
                )
              }
              {
                validateServiceType(_.get(item, 'service_type', '000'), "dataset") && (
                  <NavLink
                    to={url + `/data`}
                    activeClassName="border-primary text-primary"
                    className="flex-shrink-0 border-b-2 border-transparent uppercase mt-1 px-3 font-bold py-1 mr-1 background-100 hover:text-primary ">
                    {t('data')}
                  </NavLink>
                )
              }

              <NavLink
                to={url + `/about-us`}
                activeClassName="border-primary text-primary"
                className="flex-shrink-0 border-b-2 border-transparent uppercase mt-1 px-3 font-bold py-1 mr-1 background-100 hover:text-primary ">
                {t('about us')}
              </NavLink>

              {Boolean(
                _.get(
                  item,
                  'contacts.length'
                )
              ) && (
                  <NavLink
                    to={url + `/contact`}
                    activeClassName="border-primary text-primary"
                    className="flex-shrink-0 border-b-2 border-transparent uppercase mt-1 px-3 font-bold py-1 mr-1 background-100 hover:text-primary ">
                    {t('contact')}
                  </NavLink>
                )}
            </nav>
          </nav>
        </header>
      ),
      [item, shareLink, t, url]
    )
  }
)
