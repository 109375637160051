import { FullscreenOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'
import useToggle from 'react-use/lib/useToggle'
import ImageLoader from '../ImageLoader'
import ImagesLightBox from '../ImagesLightBox/ImagesLightBox'

export function ImageEmbed({ image }) {
  const [open, toggle] = useToggle()
  return (
    <>
      <div
        onClick={toggle}
        style={{
          // paddingTop: '70%'
          maxHeight: '70vh',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex'
        }}
        className="relative  w-full background-200 rounded overflow-hidden">
        <ImageLoader
          style={{
            // position: 'absolute',
            // top: 0,
            // left: 0,
            height: 'auto',
            // height: '100%',
            width: '100%',
            objectFit: 'cover'
          }}
          className=" w-full "
          src={image}
        />
        <Button.Group
          size="small"
          className="z-10 absolute bottom-0 right-0 p-4">
          <Button
            style={{
              border: 'none',
              background:
                'rgba(0, 0, 0, 0.53)'
            }}
            type="primary"
            icon={
              <FullscreenOutlined />
            }
          />
        </Button.Group>
      </div>
      {open && (
        <ImagesLightBox
          images={[image]}
          onCloseRequest={toggle}
        />
      )}
    </>
  )
}
