import {
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons'
import { Switch } from 'antd'
import classnames from 'classnames'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'

export const ToggleField = ({
  className,
  onChange,
  value,
  defaultValue,
  name,
  title,
  description,
  disabled
}) => {
  const t = useTranslate()
  return (
    <div
      className={classnames(
        'flex items-start justify-start w-full space-x-3',
        className
      )}>
      <div className="flex-1">
        {t(description)}
      </div>
      <Switch
        disabled={disabled}
        onChange={onChange}
        checkedChildren={
          <CheckOutlined />
        }
        unCheckedChildren={
          <CloseOutlined />
        }
        defaultChecked={defaultValue}
        {...(value
          ? {
            checked: value
          }
          : {})}
      />
    </div>
  )
}
