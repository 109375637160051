export default {
  "{n} categories": '{n} chuyên mục',
  "{n} languages": '{n} ngôn ngữ',
  "{n} provider(s)": '{n} nhà cung cấp',
  "1 year": '1 năm',
  "1D": 'Hàng ngày',
  "1M": 'Hàng tháng',
  "1Y": 'Hàng năm',
  "2 years": '2 năm',
  "2Y": '2 năm',
  "3 months": '3 tháng',
  "3M": '3 tháng',
  "6 months": '6 tháng',
  "6M": '6 tháng',
  "about FeedIn": 'Về FeedIn',
  "about us": 'Về Chúng tôi',
  "about-us": 'Về chúng tôi',
  "accept": 'Chấp nhận',
  "accept invitation": 'Chấp nhận lời mời',
  "accepted as member": 'được chấp nhận làm thành viên',
  "access denied": 'Truy cập bị từ chối',
  "account": 'Tài khoản',
  "account settings": 'Cài đặt tài khoản',
  "active": 'Hoạt động',
  "active subscription": 'Gói đăng ký hoạt động',
  "activity": 'Hoạt động',
  "add": 'Thêm',
  "add chart": 'Thêm biểu đồ',
  "add image": 'Thêm hình ảnh',
  "add link": 'Thêm liên kết',
  "add map": 'Thêm bản đồ',
  "add member": 'Thêm thành viên',
  "add money to wallet": 'Nạp tiền vào tài khoản',
  "add more": 'Bổ sung thêm',
  "Add new and manage members here {here}": 'Thêm mới và quản lý thành viên tại đây {here}',
  "add new answer": 'Thêm mới trả lời',
  "add new contact": 'Thêm mới liên hệ',
  "add new location": 'Thêm mới địa điểm',
  "add payment to keep using {subscription} or change back to free": 'Thanh toán khoản phí để tiếp tục sử dụng {subscription} hoặc đổi về lại miễn phí',
  "add payment to keep using or change back to free": 'Thanh toán khoản phí để tiếp tục sử dụng hoặc đổi về lại miễn phí',
  "add photo": 'Thêm hình ảnh',
  "add price": 'Nạp giá',
  "Add product photos": 'Thêm ảnh sản phẩm',
  "add subscriber": 'Thêm người đăng ký',
  "Add Tax Number": 'Thêm Mã số thuế',
  "Add to Home Screen": 'Thêm vào màn hình',
  "add video": 'Gắn video',
  "adding content to the channel is limited due to its policies": 'Thêm nội dung vào kênh bị hạn chế do chính sách của kênh',
  "additional details": 'Chi tiết bổ sung',
  "address": 'Địa chỉ',
  "admin": 'Quản trị',
  "admin description": 'Vai trò quản trị',
  "advanced filter": 'Bộ lọc nâng cao',
  "After select, click Save to finish": 'Sau khi chọn, nhấn Lưu để hoàn tất',
  "after your trial ends": 'Sau khi đợt dùng thử của bạn kết thúc',
  "all": 'Tất cả',
  "all categories": 'Tất cả danh mục',
  "all dataset": 'Tất cả bộ dữ liệu',
  "all products": 'Tất cả sản phẩm',
  "all vietnam": 'Tất cả Việt Nam',
  "allow add new answers": 'Cho phép thêm mới lựa chọn',
  "Allow channel content to be found through search or suggestion": 'Nội dung kênh cho phép được tìm thấy thông qua tìm kiếm hoặc đề xuất',
  "Allow users to find your channel via search function.": 'Cho phép người dùng có thể tìm thấy kênh của bạn thông qua chức năng tìm kiếm.',
  "allowed be found": 'Được phép tìm thấy',
  "Alternate Email": 'Địa chỉ Email (phụ)',
  "Alternate Mobile No.": 'Số điện thoại (phụ)',
  "Alternate Phone Number": 'Số điện thoại (phụ)',
  "alternative phone": 'Số điện thoại (phụ)',
  "amount": 'Số tiền',
  "and": 'Và',
  "Annual Turnover": 'Doanh thu hàng năm',
  "another": 'Khác',
  "answers": 'Các câu trả lời',
  "Anyone can view the content of the data. Only members can post articles and comment freely on this": 'Mọi người đều có thể xem nội dung dữ liệu. Chỉ thành viên mới có thể đăng bài viết và bình luận trên này',
  "apply": 'Áp dụng',
  "approved": 'Đã duyệt',
  "Are you sure delete this article?": 'Bạn muốn xóa bài viết này chứ?',
  "Are you sure delete this chart?": 'Bạn chắc chắn muốn xóa biểu đồ này?',
  "Are you sure delete this poll?": 'Bạn có chắc là muốn xóa bình chọn này?',
  "Are you sure delete this task?": 'Bạn có chắc chắn xóa nhiệm vụ này?',
  "Are you sure delete this template?": 'Bạn có chắc chắn xóa mẫu này không?',
  "article": 'Bài viết',
  "articles": 'Bài viết',
  "ask for the best deal": 'Cho thỏa thuận tốt nhất',
  "automatically register with Google, Facebook... account": 'Tự động đăng ký với tài khoản Google, Facebook...',
  "available balance": 'Số dư khả dụng',
  "avatar": 'Ảnh đại diện',
  "back": 'Quay về',
  "Back Home": 'Trở về trang chủ',
  "back to account": 'Quay về tài khoản',
  "ban": 'Cấm hoạt động thành viên',
  "banned": 'Bị cấm hoạt động',
  "banned. can not post any": 'Cấm. Hạn chế đăng bài',
  "bans": 'Cấm',
  "Basic": 'Cơ bản',
  "basic": 'Cơ bản',
  "basic info": 'Thông tin cơ bản',
  "because you are looking for {keyword}": 'Bạn đang tìm kiếm {keyword}?',
  "Become a provider?": 'Trở thành nhà cung cấp?',
  "Become a verified seller and get {check1} Higher Listing on FeedIn {check2} More Buyer Enquiries {check3} Preferred Number Service": 'Xác minh thông tin bán hàng để {check1} Có thứ hạng cao hơn trên Feedin {check2} Tiếp cận khách hàng nhiều hơn {check3} Các dịch vụ đặc biệt khác',
  "bill estimate": 'Hóa đơn kỳ tới',
  "billing": 'Thanh toán',
  "billing address": 'Địa chỉ thanh toán',
  "billing contact": 'Liên hệ thanh toán',
  "billing details": 'Chi tiết thông tin thanh toán',
  "billing history": 'Lịch sử thanh toán',
  "billing information": 'Thông tin thanh toán',
  "billing overview": 'Tổng quan thanh toán',
  "billing period": 'Kỳ thanh toán',
  "birthday": 'Ngày sinh',
  "bookmark": 'Đánh dấu',
  "bookmark all": 'Đánh dấu tất cả',
  "bookmarked": 'Đã đánh dấu',
  "brief introduction to yourself": 'Giới thiệu ngắn về bản thân',
  "bulk price update": 'Cập nhật giá hàng theo danh sách',
  "bulletin": 'Bản tin',
  "by categories": 'Theo chuyên mục',
  "by clicking Submit, you agree to the policy of FeedIn": 'Bằng cách nhấp vào Gửi, bạn đồng ý với chính sách của FeedIn',
  "By clicking Submit, you agree to the policy of FeedIn {term1} and {term2}.": 'Bằng cách nhấp vào \'Gửi đi\', bạn đồng ý với chính sách của FeedIn {term1} và {term2}.',
  "By clicking Subscribe, you agree to {term1} and {term2}.": 'Bằng cách nhấp vào Đăng ký, bạn đồng ý với {term1} và {term2}.',
  "by making a channel private, only members will have access to this channel": 'Bằng cách đặt kênh riêng tư, chỉ thành viên mới có quyền truy cập vào kênh này',
  "by using FeedIn, you agree to our": 'Để sử dụng Feedin, bạn đồng ý với',
  "Call": 'Gọi',
  "Call verification": 'Xác minh bằng cuộc gọi',
  "cancel": 'Hủy bỏ',
  "catalog": 'Danh mục',
  "categories": 'Chuyên mục',
  "categories affect how your story appears in public": 'Thay đổi lựa chọn chuyên mục ảnh hưởng đến cách xuất hiện bài viết của bạn',
  "category": 'Chuyên mục',
  "category groups": 'Nhóm chuyên mục',
  "change address": 'Thay đổi địa chỉ',
  "change display title / subtitle / display image / categories": 'Thay đổi tiêu đề, chuyên mục hiển thị',
  "change following categories": 'Thay đổi chuyên mục theo dõi',
  "change password": 'Thay đổi mật khẩu',
  "change preview photo": 'Thay đổi ảnh xem trước',
  "change role": 'Chuyển đổi vai trò',
  "change subscription": 'Thay đổi gói đăng ký',
  "changes here will affect how your story appears in public places like Article's homepage - not the story itself.": 'Các thay đổi ở đây sẽ ảnh hưởng đến cách câu chuyện của bạn xuất hiện ở những nơi công cộng như trang tin - không phải nội dung bài viết.',
  "Changes to the watchlist are allowed within {m} hours. And the next change is made in {d} days.": 'Việc thay đổi chuyên mục theo dõi được phép thực hiện liên tục trong vòng {m} giờ. Và lần thay đổi tiếp theo được thực hiện sau {d} ngày.',
  "Changing the language on this screen will change the labels displayed on the application to suit the language you have selected. To set up content filtering by language, please access the account's configuration to edit": 'Thay đổi ngôn ngữ trên màn hình này sẽ thay đổi các nhãn hiển thị trên ứng dụng phù  hợp với ngôn ngữ bạn đã chọn. Để thiết lập lọc nội dung theo ngôn ngữ, vui lòng truy cập vào cấu hình của tài khoản để hiệu chỉnh',
  "channel": 'Kênh',
  "channel name": 'Tên kênh',
  "channel subscriptions": 'Đăng ký kênh',
  "channels": 'Kênh',
  "channels in category": 'Kênh trong chuyên mục',
  "Channels that you may be interested in": 'Các kênh mà bạn có thể quan tâm',
  "channels to follow": 'Kênh giới thiệu',
  "charged fee data": 'Dữ liệu có tính phí',
  "charges apply": 'Có áp dụng phí',
  "chart management": 'Quản lý biểu đồ',
  "charts": 'Biểu đồ',
  "chat with provider": 'Trao đổi cùng nhà cung cấp',
  "check all": 'Chọn tất cả',
  "check your email and click on the activation link to complete the registration": 'Kiểm tra email của bạn và nhấp vào liên kết kích hoạt để hoàn tất đăng ký',
  "check your email and click on the link": 'Kiểm tra email của bạn và nhấp vào liên kết',
  "choose": 'Chọn',
  "choose another FeedIn subscription": 'Chọn gói FeedIn khác',
  "choose file": 'Chọn file',
  "Choose language": 'Chọn ngôn ngữ',
  "Choose one from your existing conversations, or start a new one.": 'Chọn một cuộc trò chuyện từ các cuộc trò chuyện hiện có của bạn hoặc bắt đầu cuộc trò chuyện mới.',
  "Choose the channel for posting": 'Chọn kênh để đăng bài viết',
  "Choose to follow the information catalog to get more news on your wall": 'Chọn theo dõi danh mục thông tin để nhận thêm tin tức trên tường của bạn',
  "choose your default skin tone": 'Chọn tông màu mặc định của bạn',
  "city": 'Thành phố',
  "clear": 'Xóa',
  "clear all": 'Xóa bỏ tất cả',
  "clear role permissions": 'Xóa bỏ phân quyền trên vai trò',
  "click here": 'Nhấp vào đây',
  "Click the {icon} button on the chart page you want to keep track of, the chart will be updated regularly here.": 'Nhấp vào nút {icon} trên trang biểu đồ mà bạn muốn theo dõi, biểu đồ sẽ được cập nhật thường xuyên tại đây.',
  "click to upload": 'Nhấp để tải lên',
  "close": 'Đóng',
  "code of conduct": 'Quy tắc ứng xử',
  "color theme": 'Màu giao diện',
  "comment": 'Bình luận',
  "commented your post": 'đã bình luận bài viết của bạn',
  "Commodity market information": 'Thông tin thị trường hàng hóa',
  "company": 'Công ty',
  "company profile": 'Hồ sơ công ty',
  "confidentiality of member information": 'Bảo mật thông tin các thành viên',
  "configure content by language": 'Cấu hình nội dung theo ngôn ngữ',
  "confirm password": 'Xác nhận mật khẩu',
  "Confirmation change email address has been sent to": 'Xác nhận thay đổi địa chỉ email đã được gửi tới',
  "Confirmation letter will be sent to your email address": 'Thư xác nhận sẽ được gửi đến địa chỉ email của bạn',
  "contact": 'Liên hệ',
  "contact details": 'Thông tin liên hệ chi tiết',
  "contact email": 'Địa chỉ mail liên hệ',
  "contact info": 'Thông tin liên hệ',
  "contact messages": 'Tin nhắn liên hệ',
  "contact name": 'Tên người liên hệ',
  "contact now": 'Liên hệ ngay',
  "contact phone": 'Số điện thoại liên hệ',
  "contact provider": 'Liên hệ nhà cung cấp',
  "contact to member": 'Liên hệ đến thành viên',
  "contact us": 'Liên hệ với chúng tôi',
  "Contact us about your bill": 'Liên hệ với chúng tôi về hóa đơn của bạn',
  "contact with provider": 'Liên hệ nhà cung cấp',
  "contact-info": 'Thông tin liên hệ',
  "contacts": 'Liên hệ',
  "content": 'Nội dung',
  "content allowed to be found": 'Nội dung cho phép được tìm thấy',
  "content displayed by language": 'Nội dung hiển thị theo ngôn ngữ',
  "content is for members only": 'Nội dung chỉ dành cho thành viên',
  "Content language": 'Ngôn ngữ nội dung',
  "Continue to {provider}": 'Tiếp tục với {provider}',
  "Continue to PayPal": 'Tiếp tục với PayPal',
  "conversations": 'Hội thoại',
  "cookies policy": 'Chính sách Cookies',
  "cookies-policy": 'Chính sách Cookies',
  "copied": 'Đã sao chép',
  "copy": 'Sao chép',
  "Copy and share the page link below to your friends. For everyone you need to share easily access and follow this channel": 'Sao chép, chia sẻ liên kết trang bên dưới đến bạn bè. Để mọi người bạn cần chia sẻ dễ dàng truy cập và theo dõi kênh này',
  "copy link": 'Sao chép liên kết',
  "copyright": 'Bản quyền',
  "country": 'Quốc gia',
  "cover": 'Phong nền',
  "create": 'Tạo mới',
  "create a free channel": 'Tạo miễn phí kênh thông tin',
  "Create a new chart for dataset": 'Tạo mới biểu đồ cho bộ dữ liệu',
  "Create a new provider so you can post products or manage your information channels.": 'Tạo một nhà cung cấp mới để bạn có thể đăng sản phẩm hoặc quản lý các kênh thông tin của mình.',
  "create a poll": 'Tạo bình chọn',
  "create a product": 'Tạo sản phẩm',
  "create a provider": 'Tạo mới nhà cung cấp',
  "create category": 'Tạo chuyên mục',
  "create channel": 'Tạo kênh',
  "create chart": 'Tạo biểu đồ',
  "create dataset": 'Tạo Bộ dữ liệu',
  "create new": 'Tạo mới',
  "Create new channel": 'Tạo kênh mới',
  "create new group": 'Tạo nhóm mới',
  "create one": 'Tạo một cái mới',
  "create post": 'Tạo bài viết',
  "create product": 'Tạo sản phẩm',
  "create role": 'Tạo vai trò',
  "Create provider profile": 'Tạo hồ sơ nhà cung cấp',
  "credit/debit card": 'Credit/Debit Card',
  "custom": 'Tùy chỉnh',
  "custom url": 'Tùy chỉnh tên miền',
  "daily": 'Hàng ngày',
  "Daily update, the latest commodity, agriculture and finance market prices every day at FeedIn.": 'Cập nhật thông tin, giá thị trường Hàng hóa, Nông nghiệp, Tài chính mới nhất hàng ngày tại FeedIn.',
  "dark mode": 'Chế độ tối',
  "dark skin tone": 'Tông màu tối',
  "data": 'Dữ liệu',
  "data & charts": 'Dữ liệu & Biểu đồ',
  "Data content is viewable by everyone, anyone is free to join and can post articles and comment freely on this": 'Mọi người đều có thể xem nội dung dữ liệu, mọi người có thể tự do tham gia và có thể đăng bài viết và bình luận trên này',
  "data explorer": 'Khám phá dữ liệu',
  "data frequency": 'Tần xuất dữ liệu',
  "data in category": 'Dữ liệu trong chuyên mục',
  "data security": 'Bảo vệ dữ liệu',
  "Data set you might be interested in": 'Tập dữ liệu bạn có thể quan tâm',
  "dataset": 'Dữ liệu',
  "dataset list": 'DS dữ liệu',
  "dataset management": 'Quản lý bộ dữ liệu',
  "dataset subscriptions": 'Đăng ký dữ liệu',
  "date": 'Ngày',
  "deactive": 'Ngừng hoạt động',
  "Declare the location for your provider": 'Khai báo địa điểm cho nhà cung cấp',
  "default": 'Mặc định',
  "default skin tone": 'Tông màu mặc định',
  "delete": 'Xóa',
  "delete channel": 'Xóa bỏ kênh',
  "delete data": 'Xóa dữ liệu',
  "delete dataset": 'Xóa dữ liệu',
  "delete provider": 'Xóa nhà cung cấp',
  "Deleted article are gone forever. Are you sure?": 'Bài viết đã bỏ sẽ mất vĩnh viễn. Bạn chắc chắn đi tiếp?',
  "Deleted channel are gone forever. Are you sure?": 'Kênh được xóa sẽ mất dữ liệu mãi mãi. Bạn có chắc chắn muốn xóa?',
  "Deleted dataset are gone forever. Are you sure?": 'Bộ dữ liệu muốn xóa sẽ biến mất vĩnh viễn. Bạn có chắc chắn?',
  "Deleted provider are gone forever. Are you sure?": 'Đơn vị cung cấp bị xóa sẽ biến mất mãi mãi. Bạn có chắc không?',
  "delivery frequency": 'Tần xuất cung cấp dữ liệu',
  "delivery_time": 'Thời gian giao hàng',
  "demo": 'Bản giới thiệu',
  "description": 'Mô tả',
  "designed and provided by": 'Được thiết kế và cung cấp bởi',
  "desktop app": 'Desktop App',
  "detail": 'Chi tiết',
  "discovery": 'Khám phá',
  "Discovery": 'Khám phá',
  "discovery more providers": 'Tìm kiếm các nhà cung cấp',
  "do not show members information for each other members": 'Không hiển thị thông tin về các thành viên cho từng thành viên khác',
  "do you want cancel the subscription?": 'Bạn có muốn hủy đăng ký?',
  "do you want to cancel?": 'Bạn muốn tiếp tục hủy?',
  "Do you want to deactivate this product?": 'Bạn có muốn hủy kích hoạt sản phẩm này không?',
  "do you want to logout?": 'Bạn muốn tiếp tục đăng xuất?',
  "document (PDF)": 'tài liệu (PDF)',
  "documentation": 'Tài liệu',
  "Doesn't have any bookmarked item": 'Không có bất kỳ mục nào được đánh dấu',
  "done": 'Đã xong',
  "downgrade": 'Hạ cấp',
  "downgrade subscription": 'Hạ cấp gói đăng ký',
  "downgrade your subscription to": 'Hạ cấp đăng ký của bạn xuống',
  "download": 'Tải về',
  "draft": 'Bản nháp',
  "draft products": 'Sản phẩm đang khai báo',
  "edit": 'Chỉnh sửa',
  "edit message": 'Chỉnh sửa tin nhắn',
  "edit name": 'Sửa tên',
  "edit your post": 'Chỉnh sửa bài viết',
  "editor choices": 'Chọn hiển thị bởi biên tập',
  "email": 'Thư điện tử',
  "email (example@company.com)": 'Thư điện tử (example@company.com)',
  "email account": 'Email đăng nhập',
  "emailplaceholder": 'Thư điện tử (example@company.com)',
  "Emoji categories": 'Emoji categories',
  "en": 'English',
  "Encouraging business initiatives creating social impact. Geared towards building a sustainable living environment for generations today and tomorrow.": 'Khuyến khích các sáng kiến kinh doanh tạo tác động xã hội. Hướng đến xây dựng một môi trường sống bền vững cho các thế hệ hôm nay và mai sau.',
  "english": 'English',
  "enter an url": 'Nhập vào đường dẫn liên kết',
  "enter channel name": 'Nhập vào tên của kênh',
  "Enter Dataset Name": 'Nhập tên dữ liệu',
  "Enter message text with minimum requirement of 20, maximum of 4000 characters": 'Nhập nội dung tin nhắn yêu cầu tối thiểu 20, lớn nhất 4000 ký tự',
  "Enter products / services search": 'Nhập sản phẩm / dịch vụ tìm kiếm',
  "Enter some information so the provider knows your requirements": 'Nhập một số thông tin để nhà cung cấp biết yêu cầu của bạn',
  "enter provider name": 'Nhập tên nhà cung cấp',
  "enter your email": 'Nhập vào thư điện tử',
  "enter your keyword": 'Nhập từ khóa tìm kiếm',
  "expires": 'Hết hạn',
  "Explore related products from verified Companies": 'Sản phẩm liên quan từ các nhà cung cấp',
  "explore similar products": 'Sản phẩm tương tự',
  "extra request": 'Yêu cầu bổ sung',
  "failure purchase!": 'Mua hàng thất bại!',
  "FAQ": 'Các câu hỏi thường gặp',
  "favorites categories": 'Danh mục ưa thích',
  "Featured Categories": 'Mục nổi bật',
  "featured charts": 'Biểu đồ tiêu biểu',
  "featured topics": 'Chủ đề nổi bật',
  "features": 'Tính năng',
  "fee": 'Phí',
  "fee management": 'Quản lý phí',
  "fee settings": 'Cấu hình sử dụng phí',
  "feed": 'Feed',
  "FeedIn gives you the latest information on market data.": 'FeedIn cung cấp cho bạn thông tin mới nhất về dữ liệu thị trường.',
  "FeedIn has grouped the information by categories of goods to help you better access information and avoid missing information. Please choose the categories you like below": 'FeedIn đã nhóm thông tin theo danh mục hàng hóa để giúp bạn truy cập thông tin tốt hơn và tránh bỏ sót thông tin. Vui lòng chọn các danh mục bạn thích dưới đây',
  "FeedIn is a platform that connects buyers with providers, creating a leading information sharing network about agribusiness.": 'FeedIn là nền tảng kết nối người mua với nhà cung cấp, tạo mạng lưới chia sẻ thông tin hàng đầu về nông nghiệp và thị trường hàng hóa. ',
  "FeedIn Member Since": 'Thành viên của FeedIn từ',
  "FeedIn will update the latest and fastest information and data about the goods market to you": 'FeedIn sẽ cập nhật thông tin và dữ liệu mới nhất và nhanh nhất về thị trường hàng hóa cho bạn',
  "feeds": 'Feeds',
  "female": 'Nữ',
  "file upload failed": 'Tập tin tải lên bị lổi',
  "file uploaded successfully": 'Tập tin đã tải lên thành công',
  "filter": 'Lọc tìm kiếm',
  "Filter results": 'Lọc kết quả',
  "first subscription on": 'Đăng ký lần đầu ngày',
  "Flags": 'Quốc kỳ',
  "folders": 'Thư mục',
  "follow": 'Theo dõi',
  "follow more categories": 'Theo dõi thêm các chuyên mục',
  "Follow to get notifications from this channel!": 'Theo dõi để nhận thông báo từ kênh này!',
  "followers": 'Người theo dõi',
  "following": 'Đang theo dõi',
  "following categories": 'Chuyên mục đang theo dõi',
  "following channels": 'Kênh theo dõi',
  "For buyers, investors and those interested. Just select the area of interest, you are suggested to connect with the professionals and the latest news of the market.": 'Đối với người mua, nhà đầu tư và những người quan tâm. Chỉ cần chọn lĩnh vực quan tâm, bạn được đề nghị kết nối với các chuyên gia và tin tức mới nhất của thị trường.',
  "For inquiring about product quotes, sending product information, or contacting cooperation, please contact us": 'Cho yêu cầu gửi báo giá sản phẩm, gửi thông tin về sản phẩm, hay liên hệ hợp tác, vui lòng liên hệ với chúng tôi',
  "for sales": 'Dành cho bán hàng',
  "for you": 'Dành cho bạn',
  "forgotpass": 'Quên mật khẩu',
  "free": 'Miễn phí',
  "frequently questions": 'Câu hỏi thường gặp',
  "Frequently Used": 'Thường xuyên sử dụng',
  "frequently-questions": 'Câu hỏi thường gặp',
  "from": 'Từ',
  "From - To": 'Từ - Đến',
  "from the sender": 'Bài viết cùng người gửi',
  "fullname": 'Tên đầy đủ',
  "gender": 'Giới tính',
  "general permission": 'Phân quyền chung',
  "general settings": 'Cài đặt chung',
  "Get a quote": 'Nhận báo giá',
  "Get Best Price": 'Nhận giá tốt nhất',
  "Get in touch with us": 'Liên hệ với chúng tôi',
  "Get latest price": 'Nhận giá mới nhất',
  "Get Quotes": 'Nhận báo giá',
  "Get quotes from seller": 'Nhận báo giá từ người bán',
  "get started": 'Bắt đầu',
  "get started with {name}": 'Bắt đầu với {name}',
  "Get your business details verified with us for higher rankings in FeedIn!": 'Xác minh chi tiết doanh nghiệp của bạn với chúng tôi để có thứ hạng cao hơn trong FeedIn!',
  "go": 'Bắt đầu',
  "go modal": 'Mở khung hình',
  "go to": 'Chuyển đến',
  "group": 'Nhóm',
  "haven't comments yet": 'Chưa có bình luận nào',
  "head office": 'Văn phòng chính',
  "hello": 'Xin chào',
  "Hello you": 'Chào bạn',
  "help": 'Giúp đỡ',
  "Help people update the latest market information, multi-dimensional, confidential and customized according to need.": 'Giúp mọi người cập nhật thông tin thị trường hàng hóa, nông nghiệp mới nhất, đa chiều, bảo mật và hỗ trợ gói dịch vụ theo nhu cầu.',
  "helps": 'Giúp đỡ',
  "Here is a list of all invite links. You can revoke any one.": 'Dưới đây là danh sách tất cả các liên kết mời. Bạn có thể thu hồi bất kỳ.',
  "hide interaction between members": 'Ẩn hiển thị tương tác giữa các thành viên',
  "Hide interactions between members. But, retaining interaction between members and administrators": 'Ẩn các tương tác giữa các thành viên. Nhưng, duy trì sự tương tác giữa các thành viên và quản trị viên',
  "history": 'Lịch sử',
  "home": 'Trang chủ',
  "hometown": 'Quê quán',
  "If you need assistance, please feel free to contact us via": 'Nếu bạn cần hỗ trợ, xin vui lòng liên hệ với chúng tôi qua',
  "If you want to switch to annual payment, {contactus}": 'Nếu bạn muốn chuyển sang thanh toán hàng năm, {contactus}',
  "ignore": 'Bỏ qua',
  "image": 'Hình ảnh',
  "image must smaller than": 'Kích thước ảnh phải nhỏ hơn',
  "inactive": 'Không hoạt động',
  "incoming requests": 'Yêu cầu gửi đến',
  "index type": 'Loại chỉ số',
  "index types": 'Loại dữ liệu',
  "info chart editing": 'Chỉnh sửa thông tin biểu đồ',
  "interested": 'Quan tâm',
  "Interface language": 'Ngôn ngữ giao diện',
  "introduce": 'Giới thiệu',
  "invalid email address": 'Địa chỉ email không đúng',
  "invalid template": 'biểu mẫu không hợp lệ',
  "invite": 'Thư mời',
  "invite and share": 'Mời và chia sẻ',
  "invite code": 'Mã mời người dùng',
  "invite members": 'Mời thành viên',
  "invite people": 'Mời mọi người',
  "invite people to join this channel": 'Mời mọi người tham gia kênh',
  "invite you to join": 'gửi mời bạn tham gia',
  "Invite your friends to this page": 'Mời bạn tham gia trang này',
  "invited": 'Đã mời',
  "invited to become member": 'Được mời làm thành viên',
  "inviter": 'Người mời',
  "invites": 'Mời tham gia',
  "is served by clicking on a": 'được phục vụ bằng cách nhấp vào',
  "item not found": 'Không tìm thấy nội dung',
  "join": 'Tham gia',
  "join channel": 'Tham gia vào kênh',
  "Join for free now": 'Tham gia miễn phí ngay',
  "Join the channel to share the article, comment or click to follow so that the system will automatically update the channel's latest news to you": 'Tham gia kênh để chia sẻ bài viết, bình luận hoặc nhấp để theo dõi để hệ thống sẽ tự động cập nhật tin tức mới nhất của kênh cho bạn',
  "Join this channel to start chatting": 'Tham gia kênh để được phép đăng bài',
  "joined": 'Đã tham gia',
  "keep subscription": 'Giữ lại đăng ký',
  "keyword": 'Từ khóa',
  "keywords": 'Từ khóa',
  "kick": 'Loại bỏ thành viên',
  "kicked": 'Đã bỏ khỏi thành viên',
  "language": 'Ngôn ngữ',
  "languages": 'Ngôn ngữ',
  "last updated": 'Lần cập nhật cuối',
  "latest": 'Mới nhất',
  "latest news": 'Tin mới',
  "Latest updates on the commodity market": 'Cập nhật mới nhất về thị trường hàng hóa',
  "leave": 'Rời đi',
  "leave a message": 'Để lại lời nhắn',
  "Leave a Message, we will contact you back!": 'Để lại tin nhắn, chúng tôi sẽ liên hệ lại với bạn!',
  "leave channel": 'Rời khỏi kênh',
  "Legal Status of Firm": 'Loại hình công ty',
  "less": 'Thu lại',
  "license": 'Cấp phép',
  "licenses": 'Cấp phép',
  "light skin tone": 'Tong màu sáng',
  "link": 'Liên kết',
  "Link original post": 'Link bài viết gốc',
  "list of data": 'Danh sách bộ dữ liệu',
  "listed products": 'Sản phẩm đang niêm yết',
  "location": 'Vị trí',
  "Location (Province/district/ward)": 'Địa điểm (Tỉnh (Thành) / Quận (Huyện)/ Phường (Xã))',
  "location & contact": 'Địa điểm & Liên hệ',
  "location name": 'Tên địa danh',
  "login": 'Đăng nhập',
  "Login now": 'Đăng nhập ngay',
  "Login now to select and follow the content that you interested in": 'Đăng nhập ngay để lựa chọn và theo dõi mục bạn quan tâm',
  "login with account": 'Đăng nhập với tài khoản',
  "login with Facebook": 'Đăng nhập với Facebook',
  "login with Google": 'Đăng nhập với Google',
  "logout": 'Đăng xuất',
  "Make your channel get more exciting activities by inviting other members": 'Hãy làm cho trang kênh của bạn có thêm nhiều hoạt động hơn bằng cách mời thêm các thành viên khác',
  "Make your provider get more exciting activities by inviting other members": 'Hãy làm cho trang nhà cung cấp của bạn có thêm nhiều hoạt động hơn bằng cách mời thêm các thành viên khác',
  "male": 'Nam',
  "manage": 'Quản lý',
  "manage dataset": 'Quản lý Bộ dữ liệu',
  "manage products": 'Quản lý sản phẩm',
  "manage services": 'Quản lý dịch vụ',
  "ManageChannelSubscriptions": 'Quản lý đăng ký kênh',
  "managed channels": 'Kênh quản trị',
  "management": 'Quản lý',
  "mark all as read": 'Đánh dấu đã xem tất cả',
  "mark as read": 'Đánh dấu đã xem',
  "market": 'Thị trường',
  "Market news, analysis, comment, market rating, market price, price chart, commodity market, financial markets, agricultural markets, commodity import and export": '',
  "market summary": 'Tóm tắt thị trường',
  "mart": 'Cửa hàng',
  "me to the channel": 'Đến với kênh thông tin',
  "medium skin tone": 'Tông màu trung bình',
  "medium-dark skin tone": 'Tông màu trung bình - tối',
  "medium-light skin tone": 'Tông màu trung bình - sáng',
  "member": 'Thành viên',
  "member description": 'Mô tả thành viên',
  "member management": 'Quản lý thành viên',
  "members": 'Thành viên',
  "members list": 'DS thành viên',
  "mention": 'nhắc đến',
  "menu": 'Danh mục',
  "Message": 'Tin nhắn',
  "messages": 'Tin nhắn',
  "min_order_qtty": 'Đặt hàng tối thiểu',
  "Minimum payment required": 'Yêu cầu thanh toán tối thiểu',
  "miss": 'Chị.',
  "modal": 'Khung hình',
  "monthly": 'Hàng tháng',
  "more": 'Thêm',
  "more actions": 'Cấu hình khác',
  "More data from": 'Xem thêm dữ liệu từ',
  "More like This": 'Xem thêm',
  "mr": 'Ông.',
  "mrs": 'Bà.',
  "ms": 'Cô.',
  "my channels": 'Kênh của tôi',
  "my page": 'Trang cá nhân',
  "my post": 'Bài của tôi',
  "name": 'Tên',
  "name of chart": 'Tên của biểu đồ',
  "name of your company": 'Tên công ty của bạn',
  "Nature of Business": 'Lĩnh vực kinh doanh',
  "new answer": 'Trả lời mới',
  "New contact": 'Thông tin liên hệ mới',
  "new contact": 'Thông tin liên hệ mới',
  "new folder": 'Thư mục mới',
  "new group": 'Tạo nhóm mới',
  "New location": 'Địa điểm mới',
  "new password": 'Mật khẩu mới',
  "New template": 'Biểu mẫu mới',
  "newest data": 'Dữ liệu mới nhất',
  "news": 'Tin tức',
  "news page": 'Trang tin tức',
  "next": 'Tiếp theo',
  "next change": 'Thay đổi lần tiếp theo',
  "No categories have been selected": 'Không có chuyên mục được chọn',
  "no categorized": 'Chưa phân mục',
  "No channel being followed": '0 kênh đang được theo dõi',
  "no channels": 'Chưa có kênh thông tin',
  "No Data": 'Không có dữ liệu',
  "No data available at this time.": 'Không có dữ liệu vào lúc này.',
  "no data found": 'Không tìm thấy dữ liệu',
  "No data set are being followed": '0 tập dữ liệu đang được theo dõi',
  "no dataset": 'Chưa có bộ dữ liệu',
  "no description": 'Không có mô tả',
  "No Emoji Found": 'Không tìm thấy Emoji',
  "No filter available": 'Không có lọc dữ liệu',
  "no invites yet": 'Hiện chưa có lời mời nào',
  "no item found": 'Nội dung không được tìm thấy',
  "No news": 'Chưa có tin tức',
  "no office found": 'Không tìm thấy địa điểm',
  "No posts were found": 'Không bài viết nào được tìm thấy',
  "no price": 'Không có giá',
  "no results found": 'Không tìm thấy kết quả',
  "not a member yet?": 'Bạn chưa phải là thành viên?',
  "note": 'Ghi chú',
  "notes about chart!": 'Ghi chú cho biểu đồ',
  "notification": 'Thông báo',
  "Notifications": 'Thông báo',
  "Number of Employees": 'Số lượng nhân viên',
  "Number of your channels:": 'Số kênh hiện tại của bạn:',
  "Objects": 'Đối tượng',
  "offices": 'Văn phòng',
  "OK": 'OK',
  "old password": 'Mật khẩu cũ',
  "oldest data": 'Dữ liệu xa nhất',
  "on": 'Trên',
  "One more step to become a Verified Seller": 'Hoàn tất khai báo thông tin bán hàng',
  "Only members can view data content, post articles or comment on this": 'Chỉ thành viên mới có thể xem nội dung dữ liệu, đăng bài viết hoặc bình luận trên này',
  "oops...": 'Rất tiếc...',
  "opps, no channels available": 'Rất tiếc, chưa có kênh tin tức',
  "or": 'Hoặc',
  "or continue with": 'hoặc tiếp tục với',
  "organization": 'Nhà cung cấp',
  "other": 'Khác',
  "other categories": 'Chuyên mục khác',
  "other channels": 'Kênh khác',
  "other charts": 'Biểu đồ khác',
  "others": 'Khác',
  "otherSettings": 'Cài đặt khác',
  "Our Channels": 'Kênh thông tin',
  "Our data": 'Dữ liệu',
  "Our mission is to:": 'Sứ mệnh của chúng tôi hướng đến:',
  "Our Videos": 'Video về chúng tôi',
  "overview": 'Tổng quan',
  "owner": 'Chủ quản',
  "owner description": 'Vai trò chủ quản',
  "package in use": 'Gói đang dùng',
  "packaging_details": 'Chi tiết đóng gói',
  "paid channel": 'Kênh có thu phí',
  "partner": 'Đối tác',
  "password": 'Mật khẩu',
  "Password and confirm password does not match": 'Mật khẩu xác nhận lại không trùng khớp',
  "Password must have min 6 characters": 'Mật khẩu phải tồn tại ít nhất 6 ký tự',
  "password required": 'Mật khẩu được yêu cầu',
  "passwordplaceholder": 'Mật khẩu',
  "Passwords must match": 'Mật khẩu nhập phải trùng khớp',
  "paste your link here": 'Dán liên két của bạn vào đây',
  "pay": 'Thanh toán',
  "Pay and subscribe": 'Trả và đăng ký',
  "payment detail": 'Chi tiết thanh toán',
  "payment subscription": 'Thanh toán gói đăng ký',
  "Payment via {provider}": 'Thanh toán qua {provider}',
  "Payment via Paypal": 'Thanh toán qua Paypal',
  "Payment will be sent to continue on {provider}. Please confirm the amount to pay": 'Thanh toán sẽ được tiếp tục qua {provider}, Vui lòng xác nhận lại số tiền để tiếp tục',
  "Payment will be sent to continue on Paypal. Please confirm the amount to pay": 'Thanh toán sẽ được chuyến đến tiếp tục trên Paypal. Vui lòng xác nhận số tiền cần thanh toán',
  "paypal": 'PayPal',
  "pending": 'Đang chờ',
  "pending approval": 'Đang chờ duyệt',
  "people to follow": 'Bạn bè giới thiệu',
  "Permanent link": 'Địa chỉ vĩnh viễn',
  "permission denied": 'Quyền truy cập bị từ chối',
  "permissions": 'Phân quyền',
  "personal": 'Cá nhân',
  "personal channels": 'Kênh cá nhân',
  "personal profile": 'Hồ sơ cá nhân',
  "phone": 'Điện thoại',
  "Phone Number": 'Số điện thoại',
  "Phone number how we can contact you": 'Số điện thoại cách chúng tôi có thể liên hệ với bạn',
  "photos": 'Hình ảnh',
  "Pictures on the home page": 'Hình ảnh đặt trên trang chủ',
  "pin": 'Ghim',
  "Pin code": 'Mã PIN',
  "pinned": 'Đã ghim',
  "pinned posts": 'Bài viết đã ghim',
  "pinnedPosts": 'Bài viết đã ghim',
  "Please check the information and follow the steps to subscribe.": 'Vui lòng kiểm tra thông tin và làm theo các bước để đăng ký.',
  "Please choose to follow related channels for the system to filter the updates to you": 'Vui lòng chọn theo dõi các kênh liên quan để hệ thống lọc các thông tin cập nhật đến bạn',
  "Please choose to save the interest charts so you can view them easily each day": 'Vui lòng chọn lưu các biểu đồ quan tâm để bạn có thể xem các biểu đồ này một cách dễ dàng mỗi ngày',
  "please enter name of product": 'Vui lòng nhập tên sản phẩm',
  "please input your email": 'Vui lòng nhập địa chỉ thư điện tử của bạn',
  "please input your phone number": 'Vui lòng nhập số điện thoại của bạn',
  "Please log in to FeedIn to leave your comment in the post": 'Vui lòng đăng nhập vào FeedIn để để lại bình luận của bạn trong bài viết',
  "Please login to use the message function in the system.": 'Vui lòng đăng nhập để sử dụng chức năng nhắn tin trong hệ thống.',
  "Please register to view this content": 'Vui lòng đăng ký để xem nội dung này',
  "please select": 'Vui lòng chọn',
  "Please select at least 1 topic to complete. FeedIn will help you find relevant information": 'Vui lòng chọn ít nhất 1 chủ đề để hoàn tất. FeedIn sẽ giúp bạn tìm thấy thông tin phù hợp',
  "Please select the unit you want to choose as the default": 'Vui lòng nhấp chọn một công ty mà bạn muốn chọn làm mặc định',
  "policy": 'Chính sách',
  "poll": 'Thăm dò ý kiến',
  "popular": 'Phổ biến',
  "post": 'bài viết',
  "Post something": 'Viết điều gì đó',
  "post your images": 'Đăng ảnh của bạn',
  "postal code": 'Mã bưu điện',
  "posted": 'Đã đăng',
  "posted by": 'Được đăng bởi',
  "posts": 'Bài viết',
  "Premium": 'Cao cấp',
  "premium": 'Cao cấp',
  "Premium channel, when enabled, allow users to join the channel for a fee. The owner of the channel will receive revenue from the members.": 'Kênh cao cấp, khi được bật, cho phép người dùng tham gia kênh có tính phí. Chủ sở hữu của kênh sẽ nhận được doanh thu từ các thành viên.',
  "premium providers": 'Các nhà cung cấp lớn',
  "press enter to search": 'Nhấn ENTER để tìm kiếm',
  "preview description": 'Mô tả hiển thị',
  "preview title": 'Tựa đề hiển thị',
  "price": 'Giá bán',
  "pricing": 'Giá cả',
  "Primary E-mail": 'Email (chính)',
  "Primary Mobile No.": 'Số ĐT (chính)',
  "privacy": 'Bảo mật thông tin',
  "privacy policy": 'Chính sách riêng tư',
  "privacy settings": 'Cấu hình chính sách riêng tư',
  "privacy-policy": 'Chính sách riêng tư',
  "private": 'Riêng tư',
  "private channel": 'Kênh riêng tư',
  "pro": 'Gói nâng cao',
  "Pro": 'Chuyên nghiệp',
  "product": 'Sản phẩm',
  "product catalogs": 'Danh mục sản phẩm',
  "product certificates": 'Chứng nhận sản phẩm',
  "product description": 'Mô tả về sản phẩm',
  "product details": 'Chi tiết sản phẩm',
  "product filter": 'Lọc tìm sản phẩm',
  "product images": 'Hình ảnh sản phẩm',
  "product must have at least five character": 'tên sản phẩm phải có ít nhất 5 ký tự ',
  "product must have at least one image": 'sản phẩm phải có ít nhất một hình ảnh',
  "product name": 'Tên sản phẩm',
  "product settings": 'Quản lý sản phẩm',
  "product specifications": 'Đặc tính sản phẩm',
  "Product/Service Name": 'Sản phẩm / Tên dịch vụ',
  "production_capacity": 'Khả năng sản xuất',
  "products": 'Sản phẩm',
  "products list": 'DS sản phẩm',
  "products page": 'Trang sản phẩm',
  "Professional network in the industry": 'Mạng lưới chuyên môn trong ngành nghề',
  "profile": 'Hồ sơ',
  "profile settings": 'Quản lý tài khoản',
  "Promote people to share knowledge and experience, so that people can continuously learn and prosper together.": 'Thúc đẩy mọi người chia sẻ kiến thức, kinh nghiệm, cùng nhau học hỏi, phát triển thịnh vượng.',
  "provided services": 'Các dịch vụ cung cấp',
  "Province/City": 'Tỉnh/Thành',
  "Province/district/ward": 'Tỉnh (Thành) / Quận (Huyện) / Phường (Xã)',
  "provinces": 'Tỉnh / Thành',
  "public": 'Công cộng',
  "public timeline": 'Công khai dòng thời gian',
  "publish": 'Xuất bản',
  "publish your product so people can search it": 'Xuất bản sản phẩm của bạn để mọi người có thể tìm kiếm',
  "published by": 'Xuất bản bởi',
  "publisher": 'Xuất bản',
  "quick link": 'Liên kết nhanh',
  "quote": 'Trích dẫn',
  "read": 'đọc bài',
  "read more": 'Đọc thêm',
  "read news": 'Xem tin',
  "ready to publish?": 'Sẵn sàng xuất bản',
  "rebookmark": 'Đánh dấu lại',
  "recent news": 'Tin gần đây',
  "recently news": 'Tin mới',
  "Recommendations": 'Khuyến nghị',
  "refreshed": 'Cập nhật lần cuối',
  "region": 'Vùng / Miền',
  "regions": 'Vùng / Miền',
  "register": 'Đăng ký',
  "registered": 'Đã đăng ký',
  "Registering to use advanced data, you will have to pay": 'Đăng ký sử dụng dữ liệu nâng cao, bạn sẽ phải trả',
  "rejected": 'Đã bị từ chối',
  "related articles": 'Bài viết cùng thể loại',
  "related categories": 'Chuyên mục liên quan',
  "related channels": 'Kênh liên quan',
  "related charts": 'Biểu đồ liên quan',
  "related keywords": 'Từ khóa liên quan',
  "Related to items you've viewed": 'Liên quan đến sản phẩm trước bạn đã xem',
  "relevant": 'Có liên quan',
  "remove": 'Xóa bỏ',
  "remove all": 'Xóa tất cả',
  "removed": 'Đã xóa',
  "renew / expires on": 'Gia hạn / Hết hạn ngày',
  "replied": 'Đã phản hồi',
  "reply": 'Trả lời',
  "request": 'Yêu cầu',
  "Request a call": 'Yêu cầu cuộc gọi',
  "Request a quote": 'Yêu cầu báo giá',
  "request have been sent successfully!": 'Yêu cầu đã được gửi đi thành công!',
  "request to join": 'Gửi yêu cầu tham gia',
  "request-to-join": 'Gửi yêu cầu tham gia',
  "requested to join": 'Gửi yêu cầu tham gia',
  "required field": 'Phần bắt buộc nhập',
  "Requirement Details": 'Chi tiết về yêu cầu',
  "resend": 'Gửi lại',
  "reset": 'Nhập lại',
  "reset your password": 'Nhập lại mật khẩu',
  "response rate": 'Tỷ lệ phản hồi',
  "responses": 'Phản hồi',
  "restricted": 'Hạn chế',
  "results": 'Kết quả',
  "return home": 'Quay về trang chủ',
  "return owner page": 'Trờ về trang chủ quản',
  "return user page": 'Trở về trang người dùng',
  "retype password": 'Nhập lại mật khẩu',
  "Review": 'Xem lại',
  "role": 'Vai trò',
  "role name": 'Tên vai trò',
  "role title": 'Chức danh / Vai trò',
  "roles": 'Vai trò',
  "sales information": 'Thông tin bán hàng',
  "save": 'Lưu',
  "save & complete": 'Lưu & Hoàn tất',
  "Save and continue": 'Lưu và tiếp tục',
  "schedule to cancel": 'Đang trong lịch hủy bỏ',
  "search": 'Tìm kiếm',
  "search anythings in FeedIn": 'Tìm kiếm trong Feedin',
  "Search by Name": 'Tìm kiếm theo tên',
  "Search Results": 'Kết quả tìm kiếm',
  "secret channel": 'Kênh riêng tư',
  "section": 'Phân khúc',
  "See all": 'Xem tất cả',
  "See all charts in the data": 'Xem tất cả biểu đồ trong dữ liệu',
  "see less": 'Thu gọn',
  "see more": 'Xem thêm',
  "select / create a group": 'Chọn / Tạo 1 nhóm',
  "Select a content category or follow the authors to get relevant information": 'Chọn chuyên mục nội dung hoặc theo dõi các tác giả để có được thông tin liên quan',
  "select category": 'Chọn chuyên mục',
  "select preview photo": 'Chọn hình xem đại diện',
  "Select the display order in the category": 'Chọn thứ tự hiển thị trong danh mục',
  "Select the type of content you want to see. And FeedIn will collect and display news that suits your interests.": 'Chọn loại nội dung bạn muốn xem. Và FeedIn sẽ thu thập và hiển thị tin tức phù hợp với sở thích của bạn.',
  "select your country": 'Chọn quốc gia của bạn',
  "selected": 'Đã chọn',
  "seller": 'Người bán',
  "Seller Contact Details": 'Thông tin liên hệ người bán',
  "seller name": 'Tên người bán',
  "send": 'Gửi',
  "Send Email": 'Gửi thư',
  "Send invitation to join the channel to your friends, by sending a copy of the page link below": 'Gửi lời mời tham gia kênh đến bạn bè, bằng cách gửi sao chép liên kết trang bên dưới',
  "Send invitation to join the provider to your friends, by sending a copy of the page link below": 'Gửi lời mời tham gia là thành viên nhà cung cấp đến bạn bè, bằng cách gửi sao chép liên kết trang bên dưới ',
  "send request": 'Gửi yêu cầu',
  "Send your message to this provider": 'Gửi tin nhắn của bạn đến nhà cung cấp này',
  "sending": 'Đang gửi',
  "sent a request to become member": 'Đã gửi một yêu cầu để làm thành viên',
  "services": 'Dịch vụ',
  "set main": 'Thiết lập làm chính',
  "set up content selection by language": 'Thiết lập chọn nội dung theo ngôn ngữ',
  "setting": 'Cài đặt',
  "settings": 'Cài đặt',
  "share": 'Chia sẻ',
  "share a post": 'Chia sẻ bài viết',
  "share button": 'Nút Chia sẻ',
  "share your ideas": 'Chia sẻ ý tưởng của bạn',
  "Share your knowledgeable or your information to enhance your presence, build a brand, make friends with potential partners or even provide information for a fee.": 'Chia sẻ thông tin và kiến ​​thức của bạn để tăng cường sự hiện diện của bạn, xây dựng thương hiệu, kết bạn với các đối tác tiềm năng hoặc cung cấp thông tin có tính phí.',
  "Sharing the information": 'Chia sẻ thông tin',
  "short description": 'Mô tả ngắn',
  "Short description about chart!": 'Mô tả ngắn về biểu đồ',
  "Short description about your company!": 'Mô tả ngắn về công ty của bạn!',
  "Short description about your dataset!": 'Mô tả ngắn về bộ dữ liệu',
  "show in category": 'Hiển thị trong nhóm mục',
  "show more": 'Hiển thị thêm',
  "show this post": 'Xem bài viết',
  "sign in": 'Đăng nhập',
  "sign in with": 'Đăng nhập với',
  "sign up": 'Đăng ký',
  "signin / signup": 'Đăng nhập / Đăng ký',
  "site": 'Địa chỉ trang web',
  "sku": 'Mã hàng',
  "Smileys & People": 'Smileys & People',
  "sold by": 'bán bởi',
  "some messages...some messages...": 'some messages...some messages...',
  "Sorry, the page you visited does not exist.": 'Rất tiếc, trang bạn truy cập không tồn tại.',
  "Sorry, the page you visited has expired or does not exist.": 'Xin lỗi, trang bạn truy cập đã hết hạn sử dụng hoặc không tồn tại.',
  "source": 'Nguồn',
  "Specification/Additional Details": 'Thông số / Thuộc tính bổ sung',
  "Specifications": 'Thông số',
  "start free trial": 'Bắt đầu dùng thử miễn phí',
  "start trial": 'Bắt đầu dùng thử',
  "start your no-cost {subscription} trial today": 'Bắt đầu dùng thử {subscription} miễn phí ngay hôm nay',
  "start your no-cost trial today": 'Bắt đầu dùng thử miễn phí ngay hôm nay',
  "state": 'Bang',
  "status": 'Trạng thái',
  "stay connected": 'Giữ kết nối',
  "submit": 'Gửi đi',
  "Submit requirement": 'Gửi yêu cầu',
  "subscribe": 'Đăng ký',
  "subscribe channel": 'Đăng ký kênh',
  "Subscribe to the categories to display the charts you're interested in": 'Đăng ký theo dõi các danh mục để hiển thị các biểu đồ mà bạn quan tâm đến',
  "Subscribe to the channels for information displayed on this page": 'Đăng ký các kênh để biết thông tin hiển thị trên trang này',
  "subscribed data": 'Dữ liệu đã đăng ký',
  "subscribers": 'Người đăng ký',
  "subscribing this channel will cost you": 'Đăng ký kênh này sẽ khiến bạn mất phí',
  "subscriptions": 'Đăng ký',
  "success": 'Thành công',
  "successful": 'Thành công',
  "successfully purchase!": 'Mua thành công!',
  "suggested products": 'Sản phẩm để xuất',
  "summary": 'Tóm lược',
  "Summary of the latest news today": 'Tóm tắt những tin tức mới nhất hôm nay',
  "supplied by": 'Được cung cấp bởi',
  "provider": 'Nhà cung cấp',
  "provider address": 'Địa chỉ nhà cung cấp',
  "provider details": 'Chi tiết về nhà cung cấp',
  "provider homepage": 'Trang chủ nhà cung cấp',
  "provider name": 'Tên nhà cung cấp',
  "provider profile": 'Hồ sơ nhà cung cấp',
  "provider profile was created successful": 'Hồ sơ nhà cung cấp đã được tạo thành công',
  "provider profiles": 'Hồ sơ nhà cung cấp',
  "provider's channels": 'Kênh của nhà cung cấp',
  "providers": 'Nhà cung cấp',
  "Supporting brand promotion and market information to enhance business and networking opportunities.": 'Hỗ trợ quảng bá thương hiệu, thông tin thị trường nhằm tăng cường cơ hội kết nối, giao thương.',
  "Symbols": 'Symbols',
  "table of contents": 'Mục lục',
  "Tap": 'Nhấp',
  "tax": 'Thuế',
  "tax code": 'Mã số thuế',
  "Tax ID": 'Mã số thuế',
  "tax inclusive": 'Đã bao gồm thuế',
  "tax number": 'Mã Số Thuế',
  "Tell us what you need": 'Nói với chúng tôi điều bạn cần tìm kiếm',
  "template": 'biểu mẫu',
  "terms of service": 'Điều khoản dịch vụ',
  "terms-of-service": 'Điều khoản dịch vụ',
  "the {package} subscription will be continue effect until {date}": 'Đăng ký {package} sẽ tiếp tục có hiệu lực cho đến {date}',
  "the activation email has been sent to": 'Email kích hoạt đã được gửi tới',
  "The banner image will be placed on the company's homepage. Best view with 256 x 1280 pixels resolution. Image type: PNG, JPG.": 'Hình ảnh banner sẽ được đặt trên trang chủ của công ty. Xem tốt nhất với độ phân giải 256 x 1280 pixels. Loại ảnh: PNG, JPG.',
  "the channel has been deleted": 'Kênh đã bị xóa',
  "the channel so that the system will automatically help you update with the latest news": 'kênh để hệ thống sẽ tự động giúp bạn cập nhật những tin tức mới nhất',
  "The chart is enabled and ready to be displayed to the user": 'Biểu đồ được kích hoạt và sẵn sàng để hiển thị cho người dùng',
  "The chart marked as typical and used to represent the data set": 'Biểu đồ được đánh dấu là điển hình và được sử dụng để làm đại diện cho tập dữ liệu',
  "The content (images, posts ...) is shared in the channel, only members of the channel see, discuss. Other users cannot view or respond to this content": 'Các nội dung (hình ảnh, bài viết...) được chia sẻ trong kênh, chỉ thành viên trong kênh nhìn thấy, cùng thảo luận. Người dùng khác không thể xem, phản hồi nội dung này',
  "the information that interests you": 'Thông tin mà bạn quan tâm',
  "The interaction between that member and the channel administrator of each member will be strictly confidential. Only the administrator or the individual in the channel can see each other's information.": 'Các tương tác giữa chính thành viên đó với quản trị viên kênh của từng thành viên một sẽ bảo mật tuyệt đối. Chỉ quản trị viên hoặc riêng cá nhân trong kênh mới nhìn thấy thông tin của nhau.',
  "the latest news": 'Tin tức mới nhất',
  "The members shown here include: Owner, administrator, member as contact person.": 'Các thành viên hiển thị ở đây bao gồm: Chủ sở hữu, quản trị viên, thành viên là người liên hệ.',
  "The page content is not yet available or being written": 'Nội dung trang chưa có sẵn hoặc đang được viết',
  "The page you are looking for does not exist. Tap the button below to return to the homepage.": 'Trang bạn đang tìm kiếm không tồn tại. Nhấp vào nút bên dưới để quay lại trang chủ.',
  "the restore email has been sent to": 'Email khôi phục đã được gửi đến',
  "The state of the data set. If \"On\", the dataset is ready to use": 'Trạng thái của bộ dữ liệu. Nếu \"Bật\", bộ dữ liệu sẵn sàng sử dụng',
  "the provider has been deleted": 'Đơn vị cung cấp đã được xóa',
  "the providers i am involved in": 'Đơn vị cung cấp mà tôi tham gia',
  "The tax code value has been saved successfully": 'Mã số thuế đã được lưu thành công',
  "The verification call is pending": 'Cuộc gọi xác minh đang chờ xử lý',
  "This account has not registered an email address for authentication, please register an email address to verify the ownership of the account": 'Tài khoản này chưa đăng ký địa chỉ mail để xác thực, vui lòng đăng ký địa chỉ email để xác thực tính sở hữu của tài khoản',
  "This channel content is limited to members only, join now to view this channel content.": 'Nội dung kênh này chỉ giới hạn cho thành viên, hãy tham gia ngay để xem nội dung kênh này.',
  "This channel doesn't have any pinned post": 'Kênh này không có bài đăng nào được ghim',
  "this channel supports communication in the form of hidden interactions between members": 'Kênh này hỗ trợ giao tiếp dưới dạng tương tác ẩn giữa các thành viên',
  "this channel to your friends": 'kênh này cho bạn bè của bạn',
  "This channel will charge you": 'Kênh sẽ thu phí từ bạn',
  "This is a description.": 'Đây là đoạn mô tả',
  "this is a regular channel": 'Đây là kênh thông thường',
  "This is an error message": 'Có 1 một thông báo lỗi',
  "This product will be removed from your Catalog and won't be visible to buyers. You will also stop receiving BuyLeads for this product gradually. Are you sure you want to continue?": 'Sản phẩm này sẽ bị xóa khỏi Danh mục của bạn và sẽ không hiển thị với người mua. Bạn cũng sẽ dần dần ngừng nhận BuyLeads cho sản phẩm này. Bạn có chắc chắn muốn tiếp tục không?',
  "Time for updating the tracking list:": 'Thời gian cho phép cập nhật chuyên mục theo dõi:',
  "timeline": 'Dòng thời gian',
  "timeline is only viewable with accounts that are members of this channel": 'Dòng thời gian chỉ có thể xem được với các tài khoản là thành viên của kênh này',
  "timeline is viewed internally only": 'Dòng thời gian chỉ được xem nội bộ',
  "title": 'Tựa đề',
  "title or question": 'Câu hỏi',
  "to channel": 'Đến kênh',
  "to create new channel on your company": 'để tạo kênh mới về công ty của bạn',
  "to create your new channel": 'để tạo mới kênh của bạn',
  "To edit your details, please visit {profile}": 'Để chỉnh sửa chi tiết, vui lòng truy cập {profile}',
  "to find content that interests you": 'để tìm nội dung mà bạn quan tâm',
  "To join the channel and follow the content regularly, you must Login / Register (free) account to become a member": 'Để tham gia vào kênh và theo dõi nội dung thường xuyên, bạn phải Đăng nhập / Đăng ký mới (miễn phí) tài khoản để trở thành thành viên',
  "To make changes to your subscriptions , go to": 'Để thay đổi đăng ký của bạn, hãy đi đến',
  "To manage products visit at {manage_products} or continue with {call_verification}": 'Để quản lý sản phẩm, hãy truy cập tại {management_products} hoặc tiếp tục với {call_verification}',
  "To stay on your current plan after your trial ends, add payment now.": 'Để giữ đăng ký của bạn sau khi đợt dùng thử của bạn kết thúc, hãy nạp thanh toán ngay bây giờ.',
  "to upgrade. This subscription cost you": 'để nâng cấp. Gói đăng ký này lấy phí từ bạn',
  "topic": 'Chủ đề',
  "total": 'Tổng cộng',
  "Total due": 'Tổng số đến ngày',
  "Total due {date}": 'Tổng đến ngày {date}',
  "Travel & Places": 'Travel & Places',
  "trial": 'Dùng thử',
  "trial days": 'Số ngày dùng thử',
  "trust and safe": 'Tin cậy và An toàn',
  "trust-safe": 'Tin tưởng và An toàn',
  "tutorials": 'Hướng dẫn',
  "type product name": 'Gõ tên sản phẩm',
  "type your message": 'Gõ tin nhắn của bạn',
  "typify chart": 'Biểu đồ tiêu biểu',
  "unban": 'Bỏ cấm thành viên',
  "unbookmark": 'Bỏ đánh dấu',
  "unbookmarked": 'Đã bỏ đánh dấu',
  "unclassified products": 'Sản phẩm chưa được phân loại',
  "unfollow": 'Bỏ theo dõi',
  "unit": 'Đơn vị',
  "unlimited": 'Không giới hạn',
  "unpin": 'Bỏ ghim',
  "unpin this?": 'Bỏ ghim mục này',
  "unsave": 'Bản chưa lưu',
  "unsubscribe": 'Hủy đăng ký',
  "update": 'Cập nhật',
  "update about success": 'Giới thiệu cập nhật thành công',
  "update dataset": 'Cập nhật bộ dữ liệu',
  "update fail": 'Cập nhật bị lổi',
  "update success": 'Cập nhật thành công',
  "Updates to you the information you have chosen to follow": 'Cập nhật đến bạn thông tin bạn đã theo dõi',
  "upgrade": 'Nâng cấp',
  "upgrade now. This subscription cost you": 'Nâng cấp ngay bây giờ. Đăng ký này lấy phí của bạn',
  "upgrade subscription": 'Nâng cấp gói đăng ký',
  "upgrade this channel": 'Nâng cấp kênh',
  "upgrade to": 'Nâng cấp lên',
  "upgrade to {name}": 'Nâng cấp lên {name}',
  "upload": 'Tải lên',
  "url": 'Đường dẫn',
  "us to share the news in this channel": 'với chúng tôi để chia sẻ tin tức trong kênh này',
  "Usage: for direct communication purposes through messaging.": 'Sử dụng: cho các mục đích liên lạc trực tiếp thông qua tin nhắn',
  "use email": 'Đăng nhập Email',
  "use the selected system language": 'Dùng theo ngôn ngữ hệ thống đã chọn',
  "used group": 'Các nhóm đã dùng',
  "user": 'Người dùng',
  "user choices": 'Chọn hiển thị bởi người dùng',
  "user management": 'Quản lý tài khoản',
  "uses": 'Đã dùng',
  "value": 'Giá trị',
  "Verification request has been sent, please wait for a response": 'Yêu cầu xác minh đã được gửi đi, vui lòng chờ phản hồi',
  "verified provider": 'Nhà cung cấp có xác nhận',
  "Verify Account": 'Xác nhận tài khoản',
  "Verify on Call": 'Xác minh qua cuộc gọi',
  "Verify sales information": 'Xác thực thông tin bán hàng',
  "vi": 'Tiếng Việt',
  "video url": 'youtube url',
  "vietnamese": 'Tiếng Việt',
  "view": 'Xem',
  "view all": 'Xem tất cả',
  "View all categories": 'Xem tất cả danh mục',
  "View all products in {name}": 'Xem tất cả các sản phẩm trong {name}',
  "View complete detail": 'Xem chi tiết',
  "view full estimate": 'Xem đầy đủ kỳ thanh toán tiếp theo',
  "view more charts": 'Xem thêm biểu đồ',
  "view product page": 'Xem trang sản phẩm',
  "views": 'Lượt truy cập',
  "visit page": 'Ghé thăm trang',
  "Visit the page to preview": 'Truy cập trang để xem trước',
  "vnpay": 'VNPay',
  "volume": 'Số lượng',
  "vote": 'Ủng hộ',
  "voted": 'Đã ủng hộ',
  "waiting": 'Đang chờ',
  "waiting admin accept your request": 'Chờ quản trị viên chấp nhận yêu cầu của bạn',
  "waiting members list": 'DS đăng ký tham gia',
  "wanna more features?": 'Bạn muốn thêm tính năng?',
  "we will upgrade you immediately, you won't have to pay anything until your trial ends": 'Chúng tôi sẽ nâng cấp bạn ngay lập tức, bạn sẽ không phải trả bất cứ điều gì cho đến khi đợt dùng thử của bạn kết thúc',
  "we will upgrade you to {subscription} immediately, you won't have to pay anything until your trial ends": 'Chúng tôi sẽ nâng cấp bạn lên {subscription} ngay lập tức, bạn sẽ không phải trả bất cứ điều gì cho đến khi đợt dùng thử của bạn kết thúc',
  "website": 'Trang web',
  "welcome": 'Chào bạn',
  "What are you looking for?": 'Bạn đang cần tìm kiếm gì?',
  "what is your chart name?": 'Tên của biểu đồ của bạn là gì?',
  "what is your company name?": 'Công ty của bạn tên là?',
  "what is your name?": 'Tên của bạn là gì?',
  "what is your question?": 'Câu hỏi của bạn là gì?',
  "what your channel name?": 'Kênh của bạn tên là gì?',
  "what your product name?": 'Sản phẩm của bạn tên là gì?',
  "when you're ready": 'Khi bạn thấy sẵn sàng',
  "which categories are your interests?": 'Bạn quan tâm đến chuyên mục nào?',
  "with account": 'Bằng tài khoản',
  "write a response": 'Viết bình luận',
  "write article": 'Viết bài',
  "write description for this page": 'Viết mô tả cho trang này',
  "write something about this page": 'Viết điều gì đó về trang này',
  "Write your sharing": 'Viết chia sẻ của bạn',
  "written by": 'Được viết bởi',
  "Year of Establishment": 'Năm thành lập',
  "You are not choosing to follow this chart category. Please choose follow up in advance": 'Bạn không chọn theo dõi chuyên mục thuộc biểu đồ này. Vui lòng chọn theo dõi trước',
  "you can not change your following categories before {time}": 'Bạn không thể thay đổi chuyên mục theo dõi trước thời điểm {time}',
  "you can not follow more {n} categories": 'Bạn không thể theo dõi quá {n} chuyên mục',
  "You can only upload JPG/PNG file": 'Bạn chỉ có thể tải hình định dạng JPG/PNG',
  "you commented on {owner}'s post": 'Bạn đã bình luận trên bài viết của {owner}',
  "you currently have a maximum limit of {n} channels allowed to own. You can change it by upgrading your account": 'Bạn hiện có giới hạn tối đa là {n} kênh được phép sở hữu. Bạn có thể thay đổi nó bằng cách nâng cấp tài khoản của bạn',
  "you currently have a maximum limit of {n} providers allowed to own. You can change it by upgrading your account": 'Bạn hiện có giới hạn tối đa là {n} tổ chức được phép sở hữu. Bạn có thể thay đổi giới hạn bằng cách nâng cấp tài khoản của bạn',
  "you didn't enter the channel name correctly": 'Bạn đã không nhập tên kênh đúng chính xác',
  "you didn't enter the provider name correctly": 'Bạn đã nhập tên công ty chưa đúng',
  "You do not have a conversation selected": 'Bạn không có cuộc trò chuyện nào được chọn',
  "you don't have permission to post": 'Không được phép đăng bài',
  "you have not any notification!": 'Bạn chưa có thông báo nào!',
  "You have the maximum of {n} items to follow. The news will be filtered according to the category you choose.": 'Bạn được chọn tối đa {n} mục để theo dõi. Các tin tức sẽ được lọc theo danh mục bạn chọn.',
  "You have the maximum of {n} items to follow. The news will be filtered according to the language you choose.": 'Bạn được chọn tối đa {n} mục để theo dõi. Các tin tức sẽ được lọc theo ngôn ngữ bạn chọn.',
  "You need {amount} to be able subscribe this channel.": 'Bạn cần {amount} để có thể đăng ký kênh này.',
  "you need login first": 'Bạn cần đăng nhập trước khi thực hiện',
  "Your account has been activated": 'Tài khoản của bạn đã được kích hoạt',
  "your answer": 'Câu trả lời của bạn',
  "Your business information has been fully updated, thank you": 'Thông tin doanh nghiệp của bạn đã được cập nhật đầy đủ, xin cảm ơn',
  "your chart": 'Biểu đồ của bạn',
  "Your current account only allows to follow in one language, if you want to remove this limit, please upgrade to a higher package. Thank you": 'Tài khoản hiện tại của bạn chỉ cho phép theo một ngôn ngữ, nếu bạn muốn xóa giới hạn này, vui lòng nâng cấp lên gói cao hơn. Cảm ơn bạn',
  "Your current active subscription package will continue to hold until it expires": 'Gói đăng ký của bạn đang hoạt động hiện tại của bạn sẽ tiếp tục giữ cho đến khi hết hạn',
  "your device don't support this!": 'Thiết bị không hỗ trợ chức năng này',
  "your invite link expires": 'Liên kết của bạn sẽ hết hạn',
  "your message": 'Tin nhắn của bạn',
  "Your phone number": 'Số điện thoại của bạn',
  "your post": 'bài viết của bạn',
  "your request is pending approval": 'Yêu cầu của bạn đang chờ phê duyệt',
  "your subscription will be renew on {date}": 'Đăng ký của bạn sẽ được gia hạn vào {date}',
  "new template": 'Mẫu tin mới',
  "Your message have been sent": 'Tin nhắn của bạn đã được gửi đi',
  "verified": 'Đã xác minh',
  "price history": 'Lịch sử giá',
  "price type": 'Loại giá',
  "product groups": 'Nhóm sản phẩm',
  "representative": 'Đại diện',
  "representative chart": 'Biểu đồ đại diện',
  "the content will be delivered to you in the language you choose": 'Nội dung sẽ được chuyển đến bạn bằng ngôn ngữ bạn chọn',
  "favorites": 'Mục yêu thích',
  "chart name": 'Tên biểu đồ',
  "select categories": 'Chọn chuyên mục',
  "the dataset has been deleted": 'Tập dữ liệu đã được xóa',
  "create article": 'Tạo bài viết',
  "have sent you invitation": 'đã gửi đến bạn lời mời',
  "related dataset": 'Các bộ dữ liệu liên quan',
  "Send invitation to your friends, by sending a copy of the page link below": 'Gửi lời mời đến bạn bè của bạn, bằng cách gửi đi liên kết trang bên dưới',
  "Copy and share the page link below to your friends.": 'Sao chép và chia sẻ liên kết trang dưới đây cho bạn bè của bạn.',
  "Response from provider will be sent via {email}": 'Phản hồi từ nhà cung cấp sẽ được gửi qua {email}',
  "options": 'Tùy chọn',
  "action": 'Hành động',
  "timeout": 'Thời gian có hiệu lực',
  "limit": 'Giới hạn số người mời',
  "change": 'Thay đổi',
  "none": 'Không có',
  "5 minutes": '5 phút',
  "30 minutes": '30 phút',
  "1h": '1 giờ',
  "1day": '1 ngày',
  "Join As Member": 'Tham gia với tư cách thành viên',
  "Join As Admin": 'Tham gia với tư cách quản trị viên',
  "others data": 'Dữ liệu khác',
}
