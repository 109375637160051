import {
  convertFromRaw,
  EditorState
} from 'draft-js'
import { defaultRawContent } from './RichEditor'

export const parseRawContent = string => {
  try {
    return EditorState.createWithContent(
      convertFromRaw(JSON.parse(string))
    )
  } catch (error) {
    console.error(error)
    return EditorState.createWithContent(
      convertFromRaw(defaultRawContent)
    )
  }
}
