import {
  DatabaseOutlined,
  HomeOutlined,
  LeftOutlined,
  SettingOutlined,
  ShopOutlined,
  TeamOutlined
} from '@ant-design/icons'
import {Modal} from 'antd'
import {getChannelsByOrganization_Api} from 'apis'
import {channelModel, organizationModel} from 'apis/model'
import {getLinkToSetting} from 'apis/model/organization'
import Count from 'components/Feed/Count'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import {createLazyComponent} from 'helpers/createLazyComponent'
import _ from 'lodash'
import {LazyPagination} from 'modules/asyncCache/components/LazyPagination'
import Translate from 'modules/local/Translate'
import {NavigationContext} from 'modules/navigation/NavigationContext'
import React from 'react'
import {IoMdPaper} from 'react-icons/io'
import {RiQuestionAnswerLine, RiQuestionnaireLine, RiUserSettingsLine} from 'react-icons/ri'
import {Redirect} from 'react-router-dom'
import {createRequiredLogin} from 'routes/createRequiredLogin'
import {SellerContext} from 'views/Market/Seller/SellerContext'
import DatasetSettings from 'views/Organization/Settings/DatasetSettings'
import ServerContainer from 'views/Server/ServerContainer'
import {OrganizationBanner} from "../Organization/Organization";

const Workspace = createLazyComponent(
  () => import('./Workspace')
)
const ManageProducts = createLazyComponent(
  () =>
    import(
      'views/Market/Seller/ManageProducts'
      )
)
const AddProduct = createLazyComponent(
  () =>
    import(
      'views/Market/Seller/AddProduct'
      )
)
const Product = createLazyComponent(
  () =>
    import(
      'views/Market/Seller/Product'
      )
)
const Preview = createLazyComponent(
  () =>
    import(
      'views/Market/Seller/Preview'
      )
)
const CompanyProfileSettings = createLazyComponent(
  () =>
    import(
      'views/Market/Seller/CompanyProfileSettings'
      )
)
const Messages = createLazyComponent(
  () =>
    import(
      'views/Market/Seller/Messages'
      )
)
const Requests = createLazyComponent(
  () => import('./RequestsManager')
)
const Members = createLazyComponent(
  () => import('./Members')
)
const Market = createLazyComponent(() =>
  import('./Market')
)
const EnhancedWorkspace = createRequiredLogin()(
  Workspace
)
const createWorkSpaceRenderer = Component => {
  return props => {
    const workspaceId = _.get(
      props,
      'match.params.workspaceId'
    )
    return (
      <>
        <EnhancedWorkspace
          id={workspaceId}>
          <Component/>
        </EnhancedWorkspace>
      </>
    )
  }
}
export const routeEntities = {
  home: {
    name: 'home',
    path: '/workspace/home',
    breadcrumbName: 'home',
    exact: false,
    iconComponent: <HomeOutlined/>,
    render: createWorkSpaceRenderer(
      () => (
        <SellerContext.Consumer>
          {({organization}) => (
            <ServerContainer
              item={organization}>
              <OrganizationBanner/>
            </ServerContainer>
          )}
        </SellerContext.Consumer>
      )
    )
  },
  news: {
    name: 'news',
    path: '/workspace/news',
    breadcrumbName: 'news',
    exact: false,
    iconComponent: <IoMdPaper/>,
    render: createWorkSpaceRenderer(
      () => (
        <Translate>
          {t => (
            <LayoutContext.Consumer>
              {({isLg}) => (
                <NavigationContext.Consumer>
                  {({
                      handleGoBack,
                      history
                    }) => (
                    <SellerContext.Consumer>
                      {({
                          organization
                        }) => (
                        <div className="w-full min-h-screen  flex flex-col">
                          <div className=" background-100 border-r w-full p-3 space-y-2">
                            <div className="flex items-center ">
                              {isLg ? null : (
                                <Count
                                  component={
                                    LeftOutlined
                                  }
                                  onClick={() =>
                                    handleGoBack(
                                      true
                                    )
                                  }></Count>
                              )}
                              <div className="text-lg font-bold capitalize">
                                <Translate>
                                  {t =>
                                    t(
                                      'news'
                                    )
                                  }
                                </Translate>
                              </div>
                            </div>
                          </div>
                          <div className="p-3 flex flex-col space-y-3">
                            <div className="font-bold">
                              {t(
                                'channels'
                              )}
                            </div>
                            <LazyPagination
                              apiInfo={
                                getChannelsByOrganization_Api
                              }
                              query={{
                                ':id': organizationModel.getId(
                                  organization
                                )
                              }}
                              renderItem={item => {
                                return (
                                  <div
                                    data-effect="ripple"
                                    onClick={() => {
                                      Modal.destroyAll()
                                      history.push(
                                        channelModel.getLinkToDetail(
                                          item
                                        )
                                      )
                                    }}
                                    className="verticalList__small ">
                                    <div
                                      className="p-3 block cursor-pointer border border-gray-100 hover:border-gray-300  background-100  rounded-lg">
                                      <div className="flex items-center mb-2">
                                        <a className="font-semibold flex-1 text-lg">
                                          {channelModel.getTitle(
                                            item
                                          )}
                                        </a>
                                      </div>
                                      {item.description && (
                                        <div className="">
                                          {
                                            item.description
                                          }
                                        </div>
                                      )}
                                      <div className="mt-3">
                                        <span className="mr-3">
                                          <span className="font-semibold mr-1">
                                            {
                                              item.total_members
                                            }
                                          </span>
                                          {t(
                                            'members'
                                          )}
                                        </span>
                                        <span className="mr-3">
                                          <span className="font-semibold mr-1">
                                            {
                                              item.total_articles
                                            }
                                          </span>
                                          {t(
                                            'articles'
                                          )}
                                        </span>
                                      </div>
                                      <div className="flex flex-wrap text-sm">
                                        {item.categories.map(
                                          (
                                            e,
                                            i
                                          ) => {
                                            return (
                                              <span
                                                key={
                                                  i
                                                }
                                                className="mr-2 border border-gray-300 mt-2 px-2 background rounded-lg">
                                                {
                                                  e.name
                                                }
                                              </span>
                                            )
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )
                              }}></LazyPagination>
                          </div>
                        </div>
                      )}
                    </SellerContext.Consumer>
                  )}
                </NavigationContext.Consumer>
              )}
            </LayoutContext.Consumer>
          )}
        </Translate>
      )
    )
  },
  setting: {
    name: 'general settings',
    path: '/workspace/setting',
    breadcrumbName: 'general settings',
    exact: true,
    iconComponent: <SettingOutlined/>,
    render: createWorkSpaceRenderer(
      () => (
        <SellerContext.Consumer>
          {({organization}) => (
            <Redirect
              to={getLinkToSetting(
                organization
              )}
            />
          )}
        </SellerContext.Consumer>
      )
    )
  },
  market: {
    name: 'market',
    path: '/workspace/market',
    breadcrumbName: 'market',
    iconComponent: <ShopOutlined/>,
    render: createWorkSpaceRenderer(
      Market
    )
  },
  dataset: {
    name: 'dataset',
    path: '/workspace/dataset',
    breadcrumbName: 'dataset',
    exact: true,
    iconComponent: <DatabaseOutlined/>,
    render: createWorkSpaceRenderer(
      () => (
        <LayoutContext.Consumer>
          {({isLg}) => (
            <NavigationContext.Consumer>
              {({handleGoBack}) => (
                <SellerContext.Consumer>
                  {({
                      organization
                    }) => (
                    <div className="w-full min-h-screen  flex flex-col">
                      <div className=" background-100 border-r w-full p-3 space-y-2">
                        <div className="flex items-center ">
                          {isLg ? null : (
                            <Count
                              component={
                                LeftOutlined
                              }
                              onClick={() =>
                                handleGoBack(
                                  true
                                )
                              }></Count>
                          )}
                          <div className="text-lg font-bold capitalize">
                            <Translate>
                              {t =>
                                t(
                                  'data'
                                )
                              }
                            </Translate>
                          </div>
                        </div>
                      </div>
                      <div className="p-3 flex flex-col">
                        <DatasetSettings
                          {...{
                            type: organizationModel.getType(
                              organization
                            ),
                            id: organizationModel.getId(
                              organization
                            )
                          }}
                        />
                      </div>
                    </div>
                  )}
                </SellerContext.Consumer>
              )}
            </NavigationContext.Consumer>
          )}
        </LayoutContext.Consumer>
      )
    )
  },
  members: {
    name: 'members',
    path: '/workspace/members',
    breadcrumbName: 'members',
    exact: true,
    iconComponent: <TeamOutlined/>,
    render: createWorkSpaceRenderer(
      Members
    )
  },
  workspace: {
    name: 'workspace',
    path: '/workspace',
    breadcrumbName: 'workspace',
    exact: true,
    render: createWorkSpaceRenderer(
      () => (
        <SellerContext.Consumer>
          {({organization}) => (
            <Redirect
              to={`/workspace/home`}
            />
          )}
        </SellerContext.Consumer>
      )
    )
  },
  dashboard: {
    name: 'dashboard',
    path: '/workspace',
    breadcrumbName: 'dashboard',
    exact: true,
    render: createWorkSpaceRenderer(
      () => (
        <SellerContext.Consumer>
          {({organization}) => (
            <Redirect
              to={`/workspace/home`}
            />
          )}
        </SellerContext.Consumer>
      )
    )
  },
  manageProducts: {
    name: 'manageProducts',
    path:
      '/workspace/manageProducts',
    breadcrumbName: 'manageProducts',
    render: createWorkSpaceRenderer(
      ManageProducts
    )
  },
  addProduct: {
    name: 'addProduct',
    path:
      '/workspace/addProduct',
    breadcrumbName: 'addProduct',
    render: createWorkSpaceRenderer(
      AddProduct
    )
  },
  product: {
    name: 'product',
    path:
      '/workspace/product/:id',
    breadcrumbName: 'product',
    render: createWorkSpaceRenderer(
      Product
    )
  },
  preview: {
    name: 'preview',
    path:
      '/workspace/preview',
    breadcrumbName: 'preview',
    render: createWorkSpaceRenderer(
      Preview
    )
  },
  companySettings: {
    name: 'companySettings',
    path:
      '/workspace/companySettings',
    breadcrumbName: 'contact info',
    render: createWorkSpaceRenderer(
      CompanyProfileSettings
    ),
    iconComponent: (
      <RiUserSettingsLine/>
    )
  },
  messages: {
    name: 'messages',
    path:
      '/workspace/messages',
    breadcrumbName: 'contact messages',
    render: createWorkSpaceRenderer(
      Messages
    ),
    iconComponent: <RiQuestionAnswerLine/>
  },
  incomingRequests: {
    name: 'incoming requests',
    path:
      '/workspace/incoming-requests',
    breadcrumbName: 'incoming requests',
    render: createWorkSpaceRenderer(
      Requests
    ),
    iconComponent: <RiQuestionnaireLine/>
  }
}

export const workSpaceRoutes = [
  routeEntities.home,
  routeEntities.workspace,
  routeEntities.setting,
  routeEntities.market,
  routeEntities.news,
  routeEntities.dataset,
  routeEntities.members,
  routeEntities.messages,
  routeEntities.incomingRequests,
  routeEntities.dashboard,
  routeEntities.companySettings
]
