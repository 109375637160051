import { Button } from 'antd'
import classnames from 'classnames'
import { useFormikContext } from 'formik'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
function FormActionBar({
  alwayVisible = true,
  submitColor,
  submitText = 'submit',
  resetText = 'reset',
  actions,
  className,
  children
}) {
  const t = useTranslate()
  const {
    touched,
    isValid,
    isSubmitting,
    handleSubmit,
    dirty,
    resetForm,
    initialValues,
    setSubmitting
  } = useFormikContext()
  const show = dirty && touched
  if (children)
    return children(
      <div
        style={{
          borderRadius: '0.3em'
        }}
        className={classnames(
          'flex  items-centershadow-lg pointer-events-auto background-100 justify-end border border-gray-300 p-2  ',
          !alwayVisible && {
            'fadeInUp faster opacity-0 pointer-events-auto animated': show,
            fadeOutDown: !show
          }
        )}>
        {actions ? (
          actions
        ) : (
          <>
            <span
              className="mr-3 font-bold hover:underline cursor-pointer"
              onClick={() =>
                resetForm({
                  values: initialValues
                })
              }>
              {t(resetText)}
            </span>
            <Button
              color={submitColor}
              disabled={!isValid}
              loading={isSubmitting}
              onClick={() => {
                handleSubmit()

              }}
              type="primary"
              className="font-bold">
              {t(submitText)}
            </Button>
          </>
        )}
      </div>
    )
  return (
    <div
      className={classnames(
        'z-10 py-2 sticky bottom-0 pointer-events-none',
        className
      )}>
      <div
        style={{
          borderRadius: '0.3em'
        }}
        className={classnames(
          'flex shadow-lg items-center pointer-events-auto  background-100 justify-end border border-gray-300 p-2  ',
          !alwayVisible && {
            'opacity-0 faster fadeInUp  animated':
              alwayVisible || show,
            fadeOutDown: !(
              alwayVisible || show
            )
          }
        )}>
        {actions ? (
          actions
        ) : (
          <>
            <span
              className="mr-3 font-bold hover:underline cursor-pointer"
              onClick={() =>
                resetForm({
                  values: initialValues
                })
              }>
              {t(resetText)}
            </span>
            <Button
              color={submitColor}
              disabled={!isValid || !dirty}
              loading={isSubmitting}
              onClick={() => {
                handleSubmit()
              }}
              type="primary"
              className="font-bold">
              {t(submitText)}
            </Button>
          </>
        )}
      </div>
    </div>
  )
}

export default FormActionBar
