import Icon from '@ant-design/icons'
import {Select} from 'antd'
import classNames from 'classnames'
import Count from 'components/Feed/Count'
import filterOption from 'helpers/filterOption'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useMemo, useRef, useState} from 'react'
import {FaMapMarkerAlt} from 'react-icons/fa'
import {useProvinces} from 'views/Organization/Settings/SelectProvinces'

export function SelectLocation({
  value: province,
  onChange,
  related_provinces
}) {
  const t = useTranslate()
  const cacherelated_provincesRef = useRef(
    related_provinces
  )
  if (!_.isEmpty(related_provinces)) {
    cacherelated_provincesRef.current = related_provinces
  }
  const {
    provinceList,
    isLoading,
    handleAsyncAction,
    onChange: handleChange
  } = useProvinces({
    onChange: onChange
  })
  const options = useMemo(() => {
    return [
      {
        value: '',
        label: t('all vietnam')
      },
      ...provinceList
    ].map(o => ({
      ...o,
      customlabel: (
        <span>
          <span className="hidden md:inline text-sm font-semibold mr-1 text-gray-600 capitalize">
            {t('location')}:{' '}
          </span>
          <span>{o.label}</span>
        </span>
      )
    }))
  }, [provinceList, t])
  const [fosus, setfocus] = useState()
  const customfilterOption = filterOption
  return (
    <div className="w-full py-1 m-auto flex overflow-y-hidden overflow-x-auto rounded-lg space-x-2">
      <div
        style={
          fosus
            ? {
              width: '100%'
            }
            : {
              width: 160,
              maxWidth: '25vw'
            }
        }
        className=" flex-shrink-0 background-200 sflex-shrink-0 sticky left-0 z-10 flex items-center rounded-full background-200">
        <Select
          showSearch
          onDropdownVisibleChange={
            setfocus
          }
          className="text-xs md:text-base block w-full"
          bordered={false}
          virtual={false}
          value={province}
          optionFilterProp="searchString"
          filterOption={
            customfilterOption
          }
          defaultValue={''}
          placeholder={t('provinces')}
          // optionLabelProp="customlabel"
          suffixIcon={
            <Icon
              component={FaMapMarkerAlt}
            />
          }
          // {...props}
          onChange={handleChange}
          options={options}
        />
      </div>
      <Count
        style={{ padding: 0 }}
        active={province === ''}
        color="text-primary font-bold p-0 flex-shrink-0">
        <div
          onClick={() =>
            handleChange('')
          }
          tabIndex={0}
          className={classNames(
            'px-4 py-1 rounded-full background-100 text-xs md:text-base flex-shrink-0 truncate focus:outline-none border-b-2 border-transparent flex items-center cursor-pointer hover:opacity-75'
          )}>
          {t('all vietnam')}
        </div>
      </Count>
      {(
        cacherelated_provincesRef.current ||
        provinceList
      )
        .filter((item, i) => i < 6)
        .map(({ value, label }) => {
          return (
            <Count
              style={{ padding: 0 }}
              active={
                province === '' + value
              }
              color="text-primary font-bold  flex-shrink-0"
              key={value}>
              <div
                onClick={() =>
                  handleChange(value)
                }
                tabIndex={0}
                className={classNames(
                  'px-4 py-1 p-0 text-xs md:text-base rounded-full background-100 flex-shrink-0  focus:outline-none border-b-2 border-transparent flex items-center cursor-pointer hover:opacity-75'
                )}>
                {label}
              </div>
            </Count>
          )
        })}
    </div>
  )
}
