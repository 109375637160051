import DoubleRightOutlined from '@ant-design/icons/DoubleRightOutlined'
import { Spin } from 'antd'
import _ from 'lodash'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { datasets_getDatasetsByOwner_Api } from '../../../apis'
import EmptyHolder from '../../../components/EmptyHolder'
import Description from "../../../components/Feed/Description"
import { DatasetIcon } from '../../../components/icons/DatasetIcon'
import VisibleWhenHaveDataPagination from '../../../modules/asyncCache/components/VisibleWhenHaveDataPagination'
import useTranslate from '../../../modules/local/useTranslate'
import { OrganizationContext } from '../OrganizationContext'

export function OurDatas() {
  const t = useTranslate()
  const { id, url } = useContext(
    OrganizationContext
  )

  return (
    <VisibleWhenHaveDataPagination
      sample={3}
      id={
        datasets_getDatasetsByOwner_Api.path + '_type_organization_id_' + id
      }
      key={
        datasets_getDatasetsByOwner_Api.path + '_type_organization_id_' + id
      }
      apiInfo={
        datasets_getDatasetsByOwner_Api
      }
      query={{
        ':id': id,
        ':type': 'organization'
      }}
      renderLoading={() => (
        <div className="w-full flex justify-center">
          <Spin className="m-auto" />
        </div>
      )}
      renderItem={datasetEl => (
        <div
          key={datasetEl.id}
          className="border rounded-lg border-gray-300 cursor-pointer p-3 flex background-100 items-start">
          <DatasetIcon
            className="m-2 mr-3 text-gray-500 text-3xl w-10 h-10"
            premium={_.get(
              datasetEl,
              'premium',
              0
            )}
          />
          <div className="flex flex-1 flex-col">
            <Link
              to={`/data/${datasetEl.idname}`}
              className="text-lg font-medium">
              {datasetEl.title}
            </Link>
            <Description
              className="text-color-200 italic"
              type={
                'channel'
              }
              mentions={
                datasetEl &&
                datasetEl.mentions
              }>
              {
                datasetEl.description
              }
            </Description>
          </div>
        </div>
      )}
      renderEmpty={() => (
        <EmptyHolder
          icon={() => (
            <div
              style={{
                fontSize: '2rem',
                color:
                  'rgba(226, 232, 240, 1)'
              }}>
              <DatasetIcon />
            </div>
          )}
          subTitle={t('no dataset')}
          title={null}
        />
      )}
      renderWrap={children => (
        <div className="max-w-6xl mx-auto flex flex-col space-y-3">
          <h2 className="flex justify-center items-center font-bold text-xl uppercase">
            {t('Our data')}
          </h2>
          <div className="flex flex-col space-y-3">
            {children}
          </div>
          <div className="bottom-0 flex w-full">
            <div className="py-3  cursor-pointer px-3 flex-wrap flex w-full justify-center color-primary">
              <div className="flex-1" />
              <Link to={url + '/data'}>
                <span>
                  {t('see more')}
                </span>
                <DoubleRightOutlined />
              </Link>
            </div>
          </div>
        </div>
      )}
    />
  )
}
