import CaretRightFilled from '@ant-design/icons/CaretRightFilled'
import CheckOutlined from '@ant-design/icons/CheckOutlined'
import {Button, Input, List, message, Modal, Space, Switch, Tag} from 'antd'
import {auth_updateFollowedCategories_Api} from 'apis'
import {FlagIcon} from 'components/icons/FlagIcon'
import Null from 'components/Null'
import {emptyArray, emptyObject} from 'helpers/emptyObjects'
import mapObject from 'helpers/mapObject'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import {LocalContext} from 'modules/local/LocalContext'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React, {useContext, useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
import {getAllPackages, getCurrentPackageIndex, getLogin} from 'redux/selectors'
import News from 'views/News'
import {DelayRender} from '../Discovery/DelayRender'
import {useAppConfig} from "../../modules/local";

const normalizeString = str => {
  const newStr = _.lowerCase(str)
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
  return newStr
}
const memoNormalizeString = _.memoize(
  normalizeString
)

function filter(parent, child) {
  return memoNormalizeString(
    parent
  ).includes(memoNormalizeString(child))
}

export const SelectCategory = ({
                                 onSuccess = Null,
                                 minItemNumber = 0,
                                 maxItemNumber = 2
                               }) => {
  const t = useTranslate()
  const history = useHistory()
  const login = useSelector(getLogin)
  const [
    selectedItems,
    setSelectedItems
  ] = useState(
    (
      login.categories || emptyArray
    ).reduce((result, value) => {
      result[value.id] = true
      return result
    }, {})
  )

  const {hierarchy_categories, categories} = useAppConfig();

  // const {
  //   response
  // } = useAsyncWithCache({
  //   prefixStr: 'categories',
  //   cacheId:
  //     'category_getCategories_Api',
  //   apiInfo: category_getCategories_Api
  // })
  // const other_categories_data = _.get(
  //   response,
  //   'data.other_categories',
  //   emptyArray
  // )
  // const data = _.get(
  //   response,
  //   'data.category_by_root',
  //   emptyArray
  // )
  // const dataList = _.get(
  //   response,
  //   'data.data',
  //   emptyArray
  // )
  const {translate} = useContext(
    LocalContext
  )

  const restCount = Math.max(
    minItemNumber -
    mapObject(selectedItems).filter(
      item => item
    ).length,
    0
  )

  const [
    selectedCateNumber,
    setSelectedCateNumber
  ] = useState(
    mapObject(selectedItems).filter(
      item => item
    ).length
  )

  const [
    toggleFinish,
    setToggleFinish
  ] = useState(false)

  const currentSelectedCateNumber = mapObject(
    selectedItems
  ).filter(item => item).length
  useEffect(() => {
    if (
      currentSelectedCateNumber >=
      _.get(
        login,
        'following_category_maximum',
        maxItemNumber
      ) &&
      selectedCateNumber !==
      currentSelectedCateNumber
    ) {
      setSelectedCateNumber(
        currentSelectedCateNumber,
        setToggleFinish(true)
      )
    }
    setSelectedCateNumber(
      currentSelectedCateNumber
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedCateNumber])

  const isValid =
    restCount === 0 &&
    mapObject(
      selectedItems,
      (value, key) =>
        value ? key : undefined
    ).filter(item => item).length <=
    _.get(
      login,
      'following_category_maximum',
      maxItemNumber
    )
  const handleSelectItem = id =>
    setSelectedItems({
      ...selectedItems,
      [id]: !selectedItems[id]
    })

  const updateCategoriesRequiredFields = useMemo(
    () => ({
      cate_ids: mapObject(
        selectedItems,
        (value, key) =>
          value ? key : undefined
      ).filter(item => item)
    }),
    [selectedItems]
  )

  const {
    success,
    isLoading,
    handleAsyncAction
  } = useAsyncAction({
    apiInfo: auth_updateFollowedCategories_Api,
    values: updateCategoriesRequiredFields,
    onSuccess: () => {
      onSuccess()
      // history.replace('/')
    },
    onError: (...args) =>
      message.error(
        _.get(args, '1.error')
      )
  })
  const momentObj = moment(
    login.following_category_available_change_at
  )
  const isAfter = momentObj.isAfter(
    moment()
  )
  const [
    keyword,
    setKeyword
  ] = useState('')
  const [
    following,
    setFollowing
  ] = useState(false)

  const allPackages =
    getAllPackages(login) || emptyArray
  const current =
    getCurrentPackageIndex(login) || 0
  const currentPackage =
    _.get(allPackages, current) ||
    emptyObject

  const following_category_maximum = _.get(
    currentPackage,
    'following_category_maximum'
  )
  const following_category_locked_in = _.get(
    currentPackage,
    'following_category_locked_in'
  )
  const following_category_allow_in = _.get(
    currentPackage,
    'following_category_allow_in'
  )

  return (
    <div className="background max-w-5xl mx-auto  w-full relative space-y-3">
      <div className="p-3">
        {t(
          'FeedIn has grouped the information by categories of goods to help you better access information and avoid missing information. Please choose the categories you like below'
        )}
      </div>
      <h2 className="p-3 text-2xl font-bold">
        {translate(
          'which categories are your interests?'
        )}
      </h2>
      <div className=" mx-3 flex flex-wrap items-end justify-end ">
        <div className="flex flex-col w-full">
          <div className="">
            <Input
              placeholder={translate(
                'filter'
              )}
              onChange={e =>
                setKeyword(
                  e.target.value
                )
              }/>
          </div>
          <div className="flex py-3">
            <div className="flex-1">
              <DelayRender>
                {() =>
                  categories.map(
                    (item, i) => {
                      if (
                        !selectedItems[
                          item.id
                          ]
                      )
                        return null

                      return (
                        <Tag
                          key={i}
                          visible={true}
                          onClose={() =>
                            handleSelectItem(
                              item.id
                            )
                          }
                          closable
                          className="font-bold truncate cursor-pointer">
                          {item.name}
                        </Tag>
                      )
                    }
                  )
                }
              </DelayRender>
              {!_.isEmpty(
                selectedItems
              ) && (
                <Tag
                  visible={true}
                  onClick={() =>
                    setSelectedItems({})
                  }
                  className="font-bold truncate cursor-pointer">
                  {t('remove all')}
                </Tag>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="space-x-2 flex items-center justify-end px-3">
        <span>
          {translate('following')}:
        </span>
        <Switch
          size="default"
          defaultChecked={following}
          onChange={setFollowing}
        />
      </div>
      <DelayRender>
        {() => hierarchy_categories.map((item, i) => {

          const _categories = _.get(item, 'children', []).reduce(function (a, b) {
            return a.concat(b.children);
          }, []);
          if (
            _categories.filter(
              item =>
                filter(
                  item.name,
                  keyword
                ) &&
                (!following ||
                  selectedItems[
                    item.id
                    ])
            ).length <= 0
          )
            return null;


          return (
            <div key={i}>
              <div className="p-4 z-10 top-0 text-base mb-2 border-b-2 border-bg-gray-900">
                <div className="flex-col truncate">
                  <div className="text-xs">
                    {_.get(
                      item,
                      'root.name'
                    )}
                  </div>
                  <div className="flex truncate text-xl font-bold items-center">
                    <CaretRightFilled/>
                    {_.get(
                      item,
                      'name'
                    )}
                  </div>
                </div>
              </div>
              {
                <div className="px-4">
                  <List
                    dataSource={_categories
                      .filter(
                        item =>
                          filter(
                            item.name,
                            keyword
                          ) &&
                          (!following ||
                            selectedItems[
                              item.id
                              ])
                      )
                      .map(
                        (
                          citem,
                          ci
                        ) => {
                          return (
                            <div key={ci} className="w-full">
                              <div className="">
                                <div className="flex-1">
                                  <div className="flex text-gray-600 text-xs">
                                    {_.get(
                                      citem,
                                      'segments',
                                      []
                                    ).map(
                                      se => {
                                        return (
                                          <span
                                            key={
                                              se.id
                                            }>
                                              {
                                                se.name
                                              }

                                            ,{' '}
                                            </span>
                                        )
                                      }
                                    )}{' '}
                                    /
                                  </div>
                                  <Link
                                    to={News.paths.categoryPath.replace(
                                      ':category',
                                      citem.id
                                    ).replace(
                                      ':refcategory',
                                      citem.alt_idname
                                    )
                                    + "?lang=" + citem.language}
                                    className="font-bold truncate">
                                    {
                                      citem.name
                                    }
                                  </Link>
                                </div>
                                <Button
                                  className="float-right"
                                  onClick={() =>
                                    handleSelectItem(
                                      citem.id
                                    )
                                  }
                                  type={
                                    selectedItems[
                                      citem
                                        .id
                                      ]
                                      ? 'default'
                                      : 'primary'
                                  }>
                                    <span className="font-semibold">
                                      {selectedItems[
                                        citem
                                          .id
                                        ] ? (
                                        <>
                                          <CheckOutlined
                                            style={{
                                              fontSize:
                                                '1.2rem',
                                              marginRight:
                                                '8px'
                                            }}
                                          />
                                          <span>
                                            {translate(
                                              'selected'
                                            )}
                                          </span>
                                        </>
                                      ) : (
                                        translate(
                                          'follow'
                                        )
                                      )}
                                    </span>
                                </Button>
                              </div>
                              {_.has(
                                citem,
                                'description'
                              ) &&
                              !_.isEmpty(
                                citem.description
                              ) && (
                                <div
                                  style={{
                                    height:
                                      '3em',
                                    overflow:
                                      'hidden',
                                    textOverflow:
                                      'ellipsis'
                                  }}
                                  className=" text-xs max-w-lg ">
                                      <span>
                                        {
                                          citem.description
                                        }
                                      </span>
                                </div>
                              )}
                              <div className="text-xs max-w-lg flex truncate">
                                {citem &&
                                _.has(
                                  citem,
                                  'extra_info'
                                ) &&
                                _.get(
                                  citem,
                                  'extra_info',
                                  []
                                ).map(
                                  einfo => (
                                    <div
                                      key={
                                        einfo.language
                                      }
                                      className="flex items-center justify-center">
                                      {_.get(
                                        einfo,
                                        'total_channels'
                                      )}
                                      <span className="px-1">
                                            {translate(
                                              'channels'
                                            )}
                                          </span>
                                      <FlagIcon
                                        type={
                                          einfo.language
                                        }
                                      />
                                      <span className="px-2">
                                            {
                                              '•'
                                            }
                                          </span>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          )
                        }
                      )}
                    renderItem={item => (
                      <List.Item
                        key={item.id}>
                        {item}
                      </List.Item>
                    )}
                  />
                </div>
              }
            </div>
          )
        })}
      </DelayRender>

      {/*{other_categories_data.filter(*/}
      {/*  item =>*/}
      {/*    filter(item.name, keyword) &&*/}
      {/*    (!following ||*/}
      {/*      selectedItems[item.id])*/}
      {/*).length > 0 && (*/}
      {/*    <div>*/}
      {/*      <div className="px-4 pb-2 pt-6 z-10 top-0 text-base mb-2 border-b-2 border-bg-gray-900">*/}
      {/*        <div className="flex-col truncate">*/}
      {/*          <div className="flex truncate text-xl font-bold items-center">*/}
      {/*            <CaretRightFilled />*/}
      {/*            {translate('others')}*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="px-4 flex flex-wrap">*/}
      {/*        <DelayRender>*/}
      {/*          {() =>*/}
      {/*            other_categories_data*/}
      {/*              .filter(*/}
      {/*                item =>*/}
      {/*                  filter(*/}
      {/*                    item.name,*/}
      {/*                    keyword*/}
      {/*                  ) &&*/}
      {/*                  (!following ||*/}
      {/*                    selectedItems[*/}
      {/*                    item.id*/}
      {/*                    ])*/}
      {/*              )*/}
      {/*              .map((citem, ci) => {*/}
      {/*                return (*/}
      {/*                  <div className="w-full py-1">*/}
      {/*                    <div className="">*/}
      {/*                      <div className="flex-1">*/}
      {/*                        <Link*/}
      {/*                          to={paths.categoryPath.replace(*/}
      {/*                            ':category',*/}
      {/*                            citem.id*/}
      {/*                          ).replace(*/}
      {/*                            ':refcategory',*/}
      {/*                            citem.alt_idname*/}
      {/*                          )*/}
      {/*                            + "?lang=" + citem.language}*/}
      {/*                          className="font-bold truncate">*/}
      {/*                          {*/}
      {/*                            citem.name*/}
      {/*                          }*/}
      {/*                        </Link>*/}
      {/*                      </div>*/}
      {/*                      <Button*/}
      {/*                        className="float-right"*/}
      {/*                        onClick={() =>*/}
      {/*                          handleSelectItem(*/}
      {/*                            citem.id*/}
      {/*                          )*/}
      {/*                        }*/}
      {/*                        type={*/}
      {/*                          selectedItems[*/}
      {/*                            citem.id*/}
      {/*                          ]*/}
      {/*                            ? 'default'*/}
      {/*                            : 'primary'*/}
      {/*                        }>*/}
      {/*                        <span className="font-semibold">*/}
      {/*                          {selectedItems[*/}
      {/*                            citem.id*/}
      {/*                          ] ? (*/}
      {/*                            <>*/}
      {/*                              <CheckOutlined*/}
      {/*                                style={{*/}
      {/*                                  fontSize:*/}
      {/*                                    '1.2rem',*/}
      {/*                                  marginRight:*/}
      {/*                                    '8px'*/}
      {/*                                }}*/}
      {/*                              />*/}
      {/*                              <span>*/}
      {/*                                {translate(*/}
      {/*                                  'selected'*/}
      {/*                                )}*/}
      {/*                              </span>*/}
      {/*                            </>*/}
      {/*                          ) : (*/}
      {/*                            translate(*/}
      {/*                              'follow'*/}
      {/*                            )*/}
      {/*                          )}*/}
      {/*                        </span>*/}
      {/*                      </Button>*/}
      {/*                    </div>*/}
      {/*                    {_.has(*/}
      {/*                      citem,*/}
      {/*                      'description'*/}
      {/*                    ) &&*/}
      {/*                      !_.isEmpty(*/}
      {/*                        citem.description*/}
      {/*                      ) && (*/}
      {/*                        <div*/}
      {/*                          style={{*/}
      {/*                            height:*/}
      {/*                              '3em',*/}
      {/*                            overflow:*/}
      {/*                              'hidden',*/}
      {/*                            textOverflow:*/}
      {/*                              'ellipsis'*/}
      {/*                          }}*/}
      {/*                          className=" text-xs max-w-lg ">*/}
      {/*                          <span>*/}
      {/*                            {*/}
      {/*                              citem.description*/}
      {/*                            }*/}
      {/*                          </span>*/}
      {/*                        </div>*/}
      {/*                      )}*/}
      {/*                    <div className="text-xs max-w-lg flex truncate">*/}
      {/*                      {citem &&*/}
      {/*                        _.has(*/}
      {/*                          citem,*/}
      {/*                          'extra_info'*/}
      {/*                        ) &&*/}
      {/*                        _.get(*/}
      {/*                          citem,*/}
      {/*                          'extra_info',*/}
      {/*                          []*/}
      {/*                        ).map(*/}
      {/*                          einfo => (*/}
      {/*                            <div*/}
      {/*                              key={*/}
      {/*                                einfo.language*/}
      {/*                              }*/}
      {/*                              className="flex items-center justify-center">*/}
      {/*                              {_.get(*/}
      {/*                                einfo,*/}
      {/*                                'total_channels'*/}
      {/*                              )}*/}
      {/*                              <span className="px-1">*/}
      {/*                                {translate(*/}
      {/*                                  'channels'*/}
      {/*                                )}*/}
      {/*                              </span>*/}
      {/*                              <FlagIcon*/}
      {/*                                type={*/}
      {/*                                  einfo.language*/}
      {/*                                }*/}
      {/*                              />*/}
      {/*                              <span className="px-2">*/}
      {/*                                {*/}
      {/*                                  '•'*/}
      {/*                                }*/}
      {/*                              </span>*/}
      {/*                            </div>*/}
      {/*                          )*/}
      {/*                        )}*/}
      {/*                    </div>*/}
      {/*                  </div>*/}
      {/*                )*/}
      {/*              })*/}
      {/*          }*/}
      {/*        </DelayRender>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  )}*/}

      <div
        className="w-full sticky bottom-0 z-20 right-0 justify-end background border-t border-gray-300 p-4 flex items-center font-semibold text-orange-600">
        <div className="flex-1">
          {(isAfter &&
            momentObj.isValid() &&
            _.template(
              translate(
                'you can not change your following categories before {time}',
                {
                  time: momentObj.calendar()
                }
              )
            )({
              count: restCount
            })) ||
          (!isValid &&
            _.template(
              translate(
                'you can not follow more {n} categories',
                {
                  n: _.get(
                    login,
                    'following_category_maximum',
                    maxItemNumber
                  )
                }
              )
            )({
              count: restCount
            })) ||
          translate(
            'After select, click Save to finish'
          )}
        </div>

        <Space>
          <Button
            onClick={() => {
              if (
                _.isEmpty(selectedItems)
              ) {
                Modal.info({
                  title: translate(
                    'No categories have been selected'
                  ),
                  content: (
                    <div>
                      <p>
                        {translate(
                          'Please select at least 1 topic to complete. FeedIn will help you find relevant information'
                        )}
                      </p>
                    </div>
                  ),
                  onOk() {
                  }
                })
              } else {
                handleAsyncAction(
                  updateCategoriesRequiredFields
                )
              }
            }}
            loading={isLoading}
            disabled={
              !isValid || isAfter
            }
            type="primary">
            {translate('save')}
          </Button>
        </Space>
      </div>
    </div>
  )
}

export default SelectCategory
