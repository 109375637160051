import LazyComponent from 'components/LazyComponent'
import React, { useState } from 'react'

export const LazyList = ({
  children,
  placeholder,
  reversed
}) => {
  const [
    current,
    setCurrent
  ] = useState(0)

  return children.map
    ? children.map((e, i) => {
        if (i > current) return null
        if (!!!e) return null

        return (
          <LazyComponent
            placeholder={placeholder}
            reversed={reversed}
            bottom={i === 0 ? 0 : 200}
            height={'200px'}
            key={i}
            onVisible={() =>
              setCurrent(i + 1)
            }>
            {e}
          </LazyComponent>
        )
      })
    : children
}
