import { API_ROOT_URL } from 'envs/_current/config'
import createPath from 'helpers/createPath'
import { createAsyncAction } from 'modules/asyncCache'
import { schema } from 'normalizr'
import { baseFieldTypes } from './base/baseFieldTypes'
import {
  articleSchema,
  channelSchema,
  organizationSchema,
  userSchema
} from './schema'
import { createTransform } from './utils/createTransform'

const fields = ['keyword']
const fieldTypes = {
  keyword: baseFieldTypes.string
}
const transform = createTransform()
// Route::get('search/keyword', 'SearchController@getRecommendKeywords');
export const search_getRecommendKeywords_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/search/keyword/:params',
  method: 'GET',
  fields: ['keyword'],
  fieldTypes: {
    keyword: baseFieldTypes.string
  },
  transform
}
export const create_search_getRecommendKeywords_Api_action = () => ({
  prefixStr = '',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@search_getRecommendKeywords_Api',
    query,
    values,
    apiInfo: search_getRecommendKeywords_Api,
    maxAge
  })

// Route::get('search/object/{type}', 'SearchController@getDataWithType');
export const search_getDataWithType_ApiS_schema = new schema.Entity(
  'searchItems',
  {
    owner: new schema.Union(
      {
        article: articleSchema,
        user: userSchema,
        channel: channelSchema,
        organization: organizationSchema
      },
      '_type'
    )
  },
  {
    idAttribute: value =>
      `${value.owner_type}_${value.owner_id}`
  }
)
export const search_getDataWithType_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/search/object/:type',
  method: 'GET',
  fields,
  paramsFields: [
    'keyword',
    'categories'
  ],
  fieldTypes,
  schema: search_getDataWithType_ApiS_schema,
  transform: createTransform([
    search_getDataWithType_ApiS_schema
  ])
}
export const create_search_getDataWithType_Api_action = () => ({
  prefixStr = 'search_getDataWithType_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@search_getDataWithType_Api',
    query,
    values,
    apiInfo: search_getDataWithType_Api,
    maxAge
  })

// search/filter-data/{type?}
export const search_loadFilterData_Api = {
  name: 'search_loadFilterData',
  backend: ``,
  root: API_ROOT_URL,
  path: '/search/filter-data/:type',
  method: 'GET',
  schema: search_getDataWithType_ApiS_schema,
  transform: createTransform([
    search_getDataWithType_ApiS_schema
  ])
}

// Route::get('search/filter-params/{type?}', 'SearchController@getFilterParams');
export const search_filterParams_Api = {
  name: 'search_filterParams',
  backend: ``,
  root: API_ROOT_URL,
  path: '/search/filter-params/:type',
  method: 'GET'
}

export const search_getTags_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/tags/keyword/:keyword/search',
  method: 'GET'
}

// Route::get('search/keyword', 'SearchController@getSuggestedKeywords'); // param: keyword
export const create_getSuggestedKeywords_Api = ({
  query,
  ...props
} = {}) => ({
  backend: ``,
  method: 'GET',
  root: API_ROOT_URL,
  paramsFields: ['keyword'],
  path: createPath({
    path: '/search/keyword'
  }),

  ...props
})
