import Null from 'components/Null'
import React from 'react'
import { connect } from 'react-redux'
import {
  Redirect,
  Route
} from 'react-router-dom'
import {
  branch,
  mapProps
} from 'recompose'
import { compose } from 'redux'
import { getLogin } from 'redux/selectors'
import RequiredLogin from 'views/403'
const FallbackOnlyNonLoginComponent = RequiredLogin
const FallbackOnlyLoginComponent = () => (
  <Redirect to={'/'} />
)
export const REQUIRED_LOGIN =
  'REQUIRED_LOGIN'
export const REQUIRED_NON_LOGIN =
  'REQUIRED_NON_LOGIN'
export const getValidate = props =>
  props.meta && props.meta.validate
    ? props.meta.validate
    : []
export const createTestValidate = type => props =>
  getValidate(props).includes(type)

export const withValidateRoute = compose(
  connect(state => ({
    login: getLogin(state)
  })),
  mapProps(props => {
    if (
      !props.login &&
      createTestValidate(
        REQUIRED_LOGIN
      )(props)
    ) {
      return {
        ...props,
        component: RequiredLogin
      }
    }
    if (
      props.login &&
      createTestValidate(
        REQUIRED_NON_LOGIN
      )(props)
    ) {
      return {
        ...props,
        component: FallbackOnlyLoginComponent
      }
    }
    return props
  })
)
export const ValidateRoute = withValidateRoute(
  Route
)
export const ValidateComponent = compose(
  connect(state => ({
    login: getLogin(state)
  })),
  mapProps(props => {
    if (
      !props.login &&
      createTestValidate(
        REQUIRED_LOGIN
      )(props)
    ) {
      return {
        ...props,
        component: RequiredLogin
      }
    }
    if (
      props.login &&
      createTestValidate(
        REQUIRED_NON_LOGIN
      )(props)
    ) {
      return {
        ...props,
        component: FallbackOnlyLoginComponent
      }
    }
    return props
  })
)(({ component: C, children }) =>
  C ? <C /> : children
)

export const RequiredLoginComponent = compose(
  connect(state => ({
    login: getLogin(state)
  })),
  branch(
    props => !props.login,
    ({ fallbackComponent: C = Null }) =>
      C
  )
)(({ children }) => children)
