import { cacheKey } from 'modules/asyncCache/const'
import { unstable_batchedUpdates } from 'react-dom'
import {
  applyMiddleware,
  compose,
  createStore
} from 'redux'
import { batchedSubscribe } from 'redux-batched-subscribe'
import {
  persistReducer,
  persistStore
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import rootSagas from 'redux/sagas'
import getReducers from '../reducers'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [cacheKey
  ]
}

const createReducer = history =>
  persistReducer(
    persistConfig,
    getReducers(history)
  )
const middlewares = []

const sagaMiddleware = createSagaMiddleware()
middlewares.push(sagaMiddleware)
if (
  process.env.NODE_ENV === `development`
) {
  const {
    logger
  } = require(`redux-logger`)
  middlewares.push(logger)
}
// this enables the chrome devtools for redux only in development
const composeEnhancers =
  (process.env.NODE_ENV !==
    'production' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

export const initStore = (
  initialState = {},
  history
) => {
  // const debounceNotify = debounce(
  //   notify => notify()
  // )

  const store = createStore(
    createReducer(history),
    initialState,
    composeEnhancers(
      applyMiddleware(...middlewares),
      // batchedSubscribe(debounceNotify)
      batchedSubscribe(
        unstable_batchedUpdates
      )
    )
  )
  let reduxSaga = sagaMiddleware.run(
    rootSagas
  )
  let persistor = persistStore(store)
  return {
    store,
    persistor,
    reduxSaga
  }
}
