import { useState } from 'react';

export default function Toggle({
	defaultValue,
	children
}) {
	const [
		isToggle,
		setToggle
	] = useState(defaultValue);
	const toggle = () =>
		setToggle(!isToggle);
	return children(isToggle, toggle);
}
