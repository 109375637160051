export const roleCodes = {
  admin: 'admin',
  guest: 'guest',
  user: 'user',
}
export const permissionCodes = {
  canInvite: 'canInvite',
  canJoin: 'canJoin',
  canSubscribe: 'canSubscribe',
  canBookmark: 'canBookmark',
  canPostArticle: 'canPostArticle',
  canCanPostArticle:
    'canCanPostArticle',
  canCopy_link: 'canCopy_link',
  canDelete: 'canDelete',
  canEdit: 'canEdit',
  canEessage: 'canEessage',
  canRemove: 'canRemove',
  canShare: 'canShare',
  canTo: 'canTo',
  canView: 'canView',
  canVote: 'canVote',
}
