import { Input, Radio } from 'antd'
import * as apis from 'apis'
import { baseFieldTypes } from 'apis/base/baseFieldTypes'
import Request from 'apis/middleware/request'
import ImageUpload from 'components/form/fields/ImageUpload'
import ContentLayout from 'components/layouts/Default/ContentLayout'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import { API_ROOT_URL } from 'envs/_current/config'
import { Formik } from 'formik'
import createFormData from 'helpers/createFormData'
import {
  emptyArray,
  emptyObject
} from 'helpers/emptyObjects'
import _ from 'lodash'
import { createAsyncAction } from 'modules/asyncCache'
import React, {
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  mapProps,
  nest,
  withProps
} from 'recompose'

const getField = (
  param,
  fieldTypes = baseFieldTypes
) => {
  const fieldType = fieldTypes[param]
  switch (true) {
    case baseFieldTypes.auto ===
      fieldType:
      return {
        title: param,
        children: {
          type: param,
          title: param,
          component: Input,
          withProps: {
            disabled: true,
            type: 'text',
            placeholder: 'auto'
          },
          name: param
        }
      }
    case baseFieldTypes.image ===
      fieldType:
      return {
        title: param,
        children: {
          type: param,
          title: param,
          component: ImageUpload,
          name: param
        }
      }
    case fieldType instanceof Array:
      return {
        title: param,
        children: {
          type: param,
          title: param,
          name: param,
          component: mapProps(
            ({ ...props }) => {
              return {
                ...props,
                buttonStyle: 'solid',
                children: fieldType.map(
                  ({
                    value,
                    label
                  }) => {
                    return (
                      <Radio.Button
                        key={value}
                        value={value}>
                        {label}
                        {'_'}
                        <span className="font-bold">
                          {value}
                        </span>
                      </Radio.Button>
                    )
                  }
                )
              }
            }
          )(Radio.Group)
        }
      }
    default:
      return {
        title: param,
        children: {
          type: param,
          title: param,
          component: Input,
          withProps: {
            type: 'text',
            placeholder: param
          },
          name: param
        }
      }
  }
}

const list = Object.values(apis).filter(
  apiObj => apiObj.path
)
const TestApiContext = React.createContext()

const Result = () => {
  const [open, setOpen] = useState()
  return (
    <div className="w-full background-200">
      <div
        onClick={() => setOpen(!open)}
        className="w-full font-bold text-center p-2 border-b border-white">
        {open ? 'hide' : 'show'}
      </div>
      <div
        className="w-full overflow-y-scroll"
        style={{
          height: open ? '50vh' : 0
        }}>
        <div className="p-3">
          result
        </div>
      </div>
    </div>
  )
}
var STRIP_COMMENTS = /((\/\/.*$)|(\/\*[\s\S]*?\*\/))/gm
var ARGUMENT_NAMES = /([^\s,]+)/g
function getParamNames(func) {
  var fnStr = func
    .toString()
    .replace(STRIP_COMMENTS, '')
  var result = fnStr
    .slice(
      fnStr.indexOf('(') + 1,
      fnStr.indexOf(')')
    )
    .match(ARGUMENT_NAMES)
  if (result === null) result = []
  return result
}
export const logFunc = func => (
  ...args
) => {
  const argsName = getParamNames(func)
  const result = func(...args)
  console.group()
  console.log(
    `logFunc`,
    'background: green; color: white; display: block;',
    func
  )
  console.table(
    args.map((value, index) => ({
      argName: argsName[index],
      value
    }))
  )
  console.log(
    `%cflogFuncresult`,
    'background: green; color: white; display: block;',
    result
  )
  console.groupEnd()
  return result
}
const TestFunc = () => {
  const argsName = getParamNames(
    stupidFuction
  )
  return (
    <div className="p-5 verticalList w-full max-w-2xl m-auto">
      {stupidFuction.toString()}
      {JSON.stringify(argsName)}
      {argsName.reduce(
        (result, value) => {
          return result + value
        },
        ''
      )}
    </div>
  )
}
const stupidFuction = ({
  fuck,
  you
}) => {
  return fuck + you
}
export const callApi = ({
  apiInfo,
  url,
  data
}) => {
  let fn = Request.get
  if (
    _.get(apiInfo, 'method') === 'POST'
  ) {
    fn = Request.post
  }
  console.log(apiInfo)
  return fn(
    url,
    createFormData(data)
  ).then(apiInfo.transform)
}
const TestApiProvider = ({
  apiInfo = emptyObject,
  params = emptyObject,
  children
}) => {
  const dispath = useDispatch()

  const value = useMemo(() => {
    const formQuerySchema = Object.values(
      params
    ).map(param => ({
      title: param,
      children: {
        type: param,
        title: param,
        component: Input,
        withProps: {
          type: 'text',
          placeholder: param
        },
        name: param
      }
    }))
    const fieldTypes = {
      ...((fields = []) => {
        return fields.reduce(
          (result, field) => {
            result[field] =
              baseFieldTypes[field]
            return result
          },
          {}
        )
      })(apiInfo.fields),
      ...apiInfo.fieldTypes
    }
    const formDataSchema = Object.values(
      apiInfo.fields || emptyArray
    ).map(param =>
      getField(param, fieldTypes)
    )
    const initialValues = {
      ...params,
      ...(
        apiInfo.fields || emptyArray
      ).reduce((result, value) => {
        result[value] = undefined
        return result
      }, {})
    }

    const columns = [
      {
        title: 'Title',
        dataIndex: 'key',
        render: text => <a>{text}</a>
      },
      {
        title: 'value',
        dataIndex: 'value'
      }
    ]

    const data = Object.keys(
      apiInfo
    ).map(key => ({
      key: key,
      value:
        typeof apiInfo[key] ===
        'function' ? (
          <div className="break-all">
            {apiInfo[key].toString()}
          </div>
        ) : (
          <div className="break-all">
            {JSON.stringify(
              apiInfo[key]
            )}
          </div>
        )
    }))
    const handleCallApi = (
      url,
      data
    ) => {
      const action = createAsyncAction({
        apiInfo,
        url,
        data
      })
      dispath(action)
    }
    return {
      formQuerySchema,
      formDataSchema,
      initialValues,
      columns,
      data,
      handleCallApi
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiInfo])

  return (
    <Formik
      onSubmit={values => {
        value.handleCallApi(
          Object.values(params).reduce(
            (result, key) => {
              console.log(
                result,
                values[key]
              )
              if (
                values[key] &&
                values[key].length > 0
              )
                return result.replace(
                  key,
                  values[key]
                )
              return result
            },
            API_ROOT_URL + apiInfo.path
          ),
          (
            apiInfo.fields || emptyArray
          ).reduce((result, key) => {
            result[key] = values[key]
            return result
          }, {})
        )
      }}
      initialValues={
        value.initialValues
      }>
      {({
        values,
        handleSubmit,
        handleChange
      }) => {
        return (
          <TestApiContext.Provider
            value={{
              handleChange,
              columns: value.columns,
              data: value.data,
              params,
              values,
              path: apiInfo.path,
              formQuerySchema:
                value.formQuerySchema,
              formDataSchema:
                value.formDataSchema,
              handleSubmit
            }}>
            {children}
          </TestApiContext.Provider>
        )
      }}
    </Formik>
  )
}
const TestApi = withRouter(
  function TestApi({
    location,
    children
  }) {
    window.stupidFuction = stupidFuction
    const { setLeftInner } = useContext(
      LayoutContext
    )
    useEffect(() => {
      setLeftInner(
        <div className="px-3">
          {stupidFuction.name}
        </div>
      )
      return () =>
        setLeftInner(undefined)
    }, [setLeftInner])
    return <div>{children}</div>
  }
)

export default withProps({
  initialEntries: list.map(
    obj => obj.path
  ),
  initialIndex: 0,
  title: (
    <div className="capitalize font-bold  block text-gray-600">
      title
    </div>
  ),
  right: (
    <div className="p-5 verticalList w-full max-w-2xl">
      Right
    </div>
  )
})(
  nest(TestApi, ContentLayout, TestFunc)
)
