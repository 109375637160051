import { getTokenKey } from 'helpers/localStorage'
import { watchAsyncData } from 'modules/asyncCache/sagas'
import {
  all,
  fork,
  put
} from 'redux-saga/effects'
import Auth from './auth/sagas'

function* initialApp() {
  if (getTokenKey()) {
    yield put({
      type: 'LOGIN'
    })
  }
}
function* rootSagas() {
  yield all([
    ...Auth,
    fork(watchAsyncData),
    fork(initialApp)
  ])
}
export default rootSagas
