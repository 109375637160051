import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Col, Input, Row, Select } from 'antd'
import FieldDecorator from 'components/form/FieldDecorator'
import FormActionBar from 'components/form/FormActionBar'
import { createValue } from 'components/form/utils'
import { API_ROOT_URL } from 'envs/_current/config'
import { useFormikContext } from 'formik'
import { emptyArray } from 'helpers/emptyObjects'
import logFunc from 'helpers/logFunc'
import _ from 'lodash'
import { AsyncWithCahce } from 'modules/asyncCache/components/Async'
import useAsyncWithCache from 'modules/asyncCache/useAsyncWithCache'
import { useAppConfig } from 'modules/local'
import useTranslate from 'modules/local/useTranslate'
import React, { useContext } from 'react'
import { DelayRender } from 'views/Discovery/DelayRender'
import { ProductContext } from './ProductContainer'
import { SelectCategories } from './SelectCategories'


const { Option, OptGroup } = Select

export const children = []

for (let i = 10; i < 36; i++) {
  children.push(
    <Option key={i.toString(36) + i}>
      {i.toString(36) + i}
    </Option>
  )
}


function AdditionalDetails({ }) {
  const { id, owner = {} } = useContext(
    ProductContext
  )
  const {
    values,
    handleChange
  } = useFormikContext()
  const t = useTranslate()
  const { response } = useAsyncWithCache({
    cacheId: '/products/get-default-specs-by-categories?categories=' + values.categories.toString(),
    apiInfo: {
      root: API_ROOT_URL,
      path:
        '/products/get-default-specs-by-categories?categories=' + values.categories.toString(),
      method: 'GET',
    }
  })
  console.log({ response, values })
  const specifications = _.get(response, 'data.data', []).map(
    (attr, i) => {
      const item = values.specifications.find(item => item.id === attr.id) || attr
      return item
    }
  )
  const { product_categories } = useAppConfig();
  return (
    <Row
      className="relative"
      gutter={12}>
      <Col xs={24}>
        <div
          style={{
            height: '100%'
          }}
          className=" w-full  border-white verticalList py-6">
          <>
            <FieldDecorator
              name="categories"
              label="categories">
              <SelectCategories type="product" categoryOptions={[...values.categoryOptions, ...product_categories]} selected={values.categories} onChange={logFunc(value => handleChange(createValue('categories', value)))} />
            </FieldDecorator>
            <FieldDecorator
              name="product_catalog_title"
              label={t(
                'product catalogs'
              )}>
              <AsyncWithCahce
                cacheId={`/products/${owner._type}/${owner.idname}/get-product-catalogs`}
                apiInfo={{
                  root: API_ROOT_URL,
                  path: `/products/${owner._type}/${owner.idname}/get-product-catalogs`,
                  method: 'GET'
                }}>
                {({
                  response,
                  isLoading,
                  handleDispatchAsyncAction
                }) => {
                  const options = _.uniq(
                    _.filter(
                      _.get(
                        response,
                        'data.data',
                        emptyArray
                      )
                      , function (o) {
                        return o.id !== '';
                      }).map(
                        ({ title }) =>
                          title
                      )
                  )
                  return (
                    <Select
                      allowClear
                      loading={
                        isLoading
                      }
                      value={
                        values.product_catalog_title ||
                        ''
                      }
                      onChange={value => {
                        handleChange(
                          createValue(
                            'product_catalog_title',
                            value || ''
                          )
                        )
                        handleChange(
                          createValue(
                            'group_keyword',
                            ''
                          )
                        )
                      }}
                      onFocus={() =>
                        handleDispatchAsyncAction()
                      }
                      showSearch
                      className="w-full"
                      placeholder={t(
                        'select / create a group'
                      )}
                      onSearch={value => {
                        handleChange(
                          createValue(
                            'group_keyword',
                            value
                          )
                        )
                      }}
                      dropdownRender={menu => (
                        <DelayRender>
                          {menu}
                        </DelayRender>
                      )}>
                      {!!(
                        values.group_keyword &&
                        values
                          .group_keyword
                          .length
                      ) && (
                          <Option
                            key="@new group"
                            value={
                              values.group_keyword
                            }>
                            <PlusOutlined />{' '}
                            {t(
                              'new group'
                            )}{' '}
                            <b>
                              {
                                values.group_keyword
                              }
                            </b>
                          </Option>
                        )}

                      <Option
                        key="default"
                        title={
                          t(
                            'unclassified products'
                          )
                        }
                        value={
                          ""
                        }>
                        {t(
                          'unclassified products'
                        )}
                      </Option>

                      <OptGroup
                        label={t(
                          'used group'
                        )}>
                        {options.map(
                          o => (
                            <Option
                              key={o}
                              value={o}>
                              {o}
                            </Option>
                          )
                        )}
                      </OptGroup>
                    </Select>
                  )
                }}
              </AsyncWithCahce>
            </FieldDecorator>
            <FieldDecorator
              name="Specifications"
              label={t(
                'Specifications'
              )}>
              <Row gutter={[4, 4]}>
                {specifications.map(
                  (attr, i) => {
                    const item = values.specifications.find(item => item.id === attr.id) || attr
                    return item
                  }
                ).map((item, i) => {
                  return (
                    <React.Fragment
                      key={item.id}>
                      <Col xs={8}>
                        <div
                          style={{
                            padding:
                              '4px 11px'
                          }}
                          className="background-100 h-full flex items-center border border-gray-300"
                          children={
                            item.title
                          }></div>
                      </Col>
                      <Col xs={16}>
                        <Input
                          defaultValue={
                            item.value
                          }
                          onBlur={e => {
                            if (item.value !== e
                              .target
                              .value) {
                              const newspecifications = [...specifications]
                              newspecifications[i] = {
                                ...item, value: e
                                  .target
                                  .value
                              }
                              handleChange(
                                createValue(
                                  'specifications',
                                  newspecifications
                                )
                              )
                            }

                          }}
                          placeholder={t(
                            'value'
                          )}></Input>
                      </Col>
                    </React.Fragment>
                  )
                })}
              </Row>
            </FieldDecorator>
            <FieldDecorator
              label={t(
                'additional details'
              )}
              name="attributes">
              {!!(
                values.attributes &&
                values.attributes.length
              ) && (
                  <Row gutter={[4, 4]}>
                    {values.attributes.map(
                      (attr, i) => (
                        <React.Fragment
                          key={i}>
                          <Col xs={8}>
                            <Input
                              placeholder={t(
                                'name'
                              )}
                              defaultValue={
                                attr.title
                              }
                              onBlur={e => {
                                handleChange(
                                  createValue(
                                    'attributes',
                                    values.attributes.map(
                                      (
                                        attr,
                                        index
                                      ) => {
                                        if (
                                          index ===
                                          i
                                        ) {
                                          return {
                                            ...attr,
                                            title:
                                              e
                                                .target
                                                .value
                                          }
                                        }
                                        return attr
                                      }
                                    )
                                  )
                                )
                              }}></Input>
                          </Col>
                          <Col xs={16}>
                            <Input
                              defaultValue={
                                attr.value
                              }
                              onBlur={e => {
                                handleChange(
                                  createValue(
                                    'attributes',
                                    values.attributes.map(
                                      (
                                        attr,
                                        index
                                      ) => {
                                        if (
                                          index ===
                                          i
                                        ) {
                                          return {
                                            ...attr,
                                            value:
                                              e
                                                .target
                                                .value
                                          }
                                        }
                                        return attr
                                      }
                                    )
                                  )
                                )
                              }}
                              placeholder={t(
                                'value'
                              )}
                              addonAfter={
                                <CloseOutlined
                                  onClick={() => {
                                    const newArray = [
                                      ...values.attributes
                                    ]
                                    newArray.splice(
                                      i,
                                      1
                                    )
                                    handleChange(
                                      createValue(
                                        'attributes',
                                        newArray
                                      )
                                    )
                                  }}
                                />
                              }></Input>
                          </Col>
                        </React.Fragment>
                      )
                    )}
                  </Row>
                )}
            </FieldDecorator>
            <div className="flex justify-end">
              <Button
                onClick={() =>
                  handleChange(
                    createValue(
                      'attributes',
                      [
                        ...(values.attributes ||
                          []),
                        {
                          name: '',
                          value: ''
                        }
                      ]
                    )
                  )
                }
                icon={<PlusOutlined />}>
                {t('add more')}
              </Button>
            </div>
            <FormActionBar />
          </>
        </div>
      </Col>
    </Row>
  )
}
export default AdditionalDetails
