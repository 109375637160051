import {
  PlusOutlined,
  SettingOutlined,
  UserAddOutlined
} from '@ant-design/icons'
import { Menu } from 'antd'
import { organization_getById_Api } from 'apis'
import { organizationSchema } from 'apis/schema'
import Async from 'modules/asyncCache/components/Async'
import Translate from 'modules/local/Translate'
import PermissionProvider from 'modules/permissions/PermissionProvider'
import React, { useRef } from 'react'
import { useSelectEntities } from 'redux/entities/useSelectEntities'
import ServerContainer from '../Server/ServerContainer'
import { routes } from './routes'

const OrganizationProvider = ({
  id,
  children
}) => {
  const item = useSelectEntities(
    id,
    organizationSchema
  )

  const e = useRef(null)
  const newE = item && (
    <PermissionProvider
      permissions={{
        canJoin:
          Number(item.privacy === 1) ||
          item.joined_status,
        canInvite: Number(
          item.privacy === 1 ||
            item.edit ||
            item.join_status === 1
        )
      }}>
      <ServerContainer
        // item={item}
        {...{
          item,
          schema: organizationSchema,
          id: item.id,
          type: item._type,
          routes: routes,
          createMenuContext: ({
            setCurrent,
            handleActionClick
          }) =>
            item && (
              <Menu
                onClick={({ key }) => {
                  switch (key) {
                    default:
                      handleActionClick(
                        key
                      )
                      break
                  }
                }}>
                {[
                  [
                    'share',
                    'share',
                    <UserAddOutlined />
                  ],
                  ...(item.edit
                    ? [
                        [
                          'createChannel',
                          'create channel',
                          <PlusOutlined />
                        ],
                        ['divider'],
                        [
                          'setting',
                          'general settings',
                          <SettingOutlined />
                        ]
                      ]
                    : [])
                ].map(
                  (
                    [key, label, icon],
                    i
                  ) =>
                    key ===
                    'divider' ? (
                      <Menu.Divider
                        key={i}
                      />
                    ) : (
                      <Menu.Item
                        key={key}>
                        {icon}
                        <span className="font-semibold">
                          <Translate>
                            {t =>
                              t(label)
                            }
                          </Translate>
                        </span>
                      </Menu.Item>
                    )
                )}
              </Menu>
            )
        }}>
        {children}
      </ServerContainer>
    </PermissionProvider>
  )
  if (newE && newE !== null) {
    e.current = newE
  }
  return (
    <>
      <Async
        key={id}
        apiInfo={
          organization_getById_Api
        }
        query={{
          ':id': id
        }}
      />
      {e.current}
    </>
  )
}

export default OrganizationProvider
