import React from 'react'

export const FlagVISvg = props => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      data-name="Layer 1"
      viewBox="0 0 128 128">
      <path
        fill="#d60a2e"
        d="M12 27.751h104v72.497H12z"></path>
      <path
        fill="#fed953"
        d="M85.167 59.248l-16.417.377L64 43.869l-4.75 15.756-16.417-.377 13.417 9.627-5.332 15.256L64 75l13.082 9.131-5.332-15.256z"></path>
    </svg>
  )
}
