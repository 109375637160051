import { Button, Card, InputNumber, notification, Spin } from 'antd'
import { billing_getStaticAmountList_Api, payments_payWithPayPal_Api } from 'apis'
import { emptyArray } from 'helpers/emptyObjects'
import logParams from 'helpers/logParams'
import _ from 'lodash'
import { createAsyncAction } from 'modules/asyncCache'
import useAsync from 'modules/asyncCache/useAsync'
import useDispatchAsyncAction from 'modules/asyncCache/useDispatchAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

export const Paymentmethod = () => {
  const {
    response,
    isLoading
  } = useAsync({
    apiInfo: billing_getStaticAmountList_Api
  })
  const options = _.get(
    response,
    'data',
    emptyArray
  )
  const [
    data = {},
    handler
    // eslint-disable-next-line react-hooks/rules-of-hooks
  ] = useDispatchAsyncAction({
    onError: (...args) => {
      const [
        error,
        errorObj = {
          error_code: 'System error!',
          error: '-1'
        }
      ] = args
      notification.error({
        message: typeof errorObj.error_code === 'string' ? errorObj.error_code : JSON.stringify(
          errorObj.error_code
        ),
        description: typeof errorObj.error === 'string' ? errorObj.error : JSON.stringify(
          errorObj.error
        ),
        duration: 3,
        placement: 'topRight'
      })
    }
  })
  const history = useHistory()
  const gateway_checkout_url = _.get(
    data,
    'response.data.checkout_url'
  )
  useEffect(() => {
    if (gateway_checkout_url) {
      window.location.href = gateway_checkout_url
    }
  }, [history, gateway_checkout_url])

  const { planId, amount } = useParams()
  const [select, setSelect] = useState(
    amount
  )
  const plans = _.get(
    response,
    'data.data',
    emptyArray
  )
  const t = useTranslate()
  return (
    <div style={{ fontWeight: '500' }}>
      <Card
        style={{
          width: '100%',
          border: 'none'
        }}
        tabList={[
          {
            key: 'tab1',
            tab: t('paypal')
          },
          {
            key: 'tab2',
            tab: t('credit/debit card'),
            disabled: true
          }
        ]}
        bodyStyle={{
          border: '1px solid #cecece'
        }}
        activeTabKey={'tab1'}
        onTabChange={logParams}>
        <Spin spinning={isLoading}>
          {
            {
              tab1: (
                <div className="verticalList">
                  <div className="font-bold text-xl ">
                    {t(
                      'Ppaypal payment'
                    )}
                  </div>
                  <div className="text-gray-600">
                    {t(
                      'Payment will be sent to continue on Paypal. Please confirm the amount to pay'
                    )}
                    .
                  </div>
                  <div>
                    <InputNumber
                      disabled
                      defaultValue={Number(
                        amount
                      )}
                      min={Number(
                        amount
                      )}
                      formatter={value =>
                        `$ ${value}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ','
                        )
                      }
                      parser={value =>
                        value.replace(
                          /\$\s?|(,*)/g,
                          ''
                        )
                      }
                      size="large"
                      className="w-56 mr-3"
                    // onChange={v =>
                    //   setSelect(v)
                    // }
                    />
                  </div>
                  <Button
                    loading={
                      data.isLoading ||
                      data.success
                    }
                    onClick={() => {
                      handler(
                        createAsyncAction(
                          {
                            apiInfo: payments_payWithPayPal_Api,
                            values: {
                              action: planId,
                              amount: select
                            }
                          }
                        )
                      )
                    }}
                    size="large"
                    block
                    type="primary">
                    {t(
                      'continue to paypal'
                    )}
                  </Button>
                </div>
              ),
              tab2: <p>content2</p>
            }['tab1']
          }
        </Spin>
      </Card>
    </div>
  )
}
const AddPaymentWithPlan = () => {
  return (
    <div className="px-3 max-w-xl mx-auto pt-6 verticalList__large">
      <Paymentmethod />
    </div>
  )
}
export default AddPaymentWithPlan
