export const defaultConfig = {
  style: 'currency',
  currency: 'VND'
}
const formatCurrency = (
  number,
  config = defaultConfig
) =>
  new Intl.NumberFormat(
    'en-US',
    config
  ).format(Number(number))
formatCurrency.defaultConfig = defaultConfig
export default formatCurrency
