import Null from 'components/Null'
import { emptyObject } from 'helpers/emptyObjects'
import {
  useEffect,
  useState
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import { createAsyncAction } from '.'
import { getAsynCacheSelector } from './selectors'

function useAsyncAction({
  query = emptyObject,
  values = emptyObject,
  apiInfo,
  onSuccess = Null,
  onError = Null
}) {
  const [
    action = emptyObject,
    setAction
  ] = useState()
  const {
    isLoading,
    success,
    errorMessages,
    result,
    time,
    error,
    ...rest
  } = useSelector(state =>
    getAsynCacheSelector(
      state,
      action.asyncId
    )
  )
  const dispatch = useDispatch()
  const handleAsyncAction = (
    _values = emptyObject,
    _query = emptyObject
  ) => {
    const action = createAsyncAction({
      query: {
        ...query,
        ..._query
      },
      apiInfo,
      values: {
        ...values,
        ..._values
      }
    })
    setAction(action)
    dispatch(action)
    return action
  }

  useEffect(() => {
    if (error) {
      // message.error('errorMessages')
      console.error(errorMessages)
      onError(error, errorMessages)
    }
    if (success) {
      onSuccess(result, {
        action,
        isLoading,
        success,
        errorMessages,
        result,
        error,
        time,
        ...rest,
        handleAsyncAction
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])
  return {
    action,
    isLoading,
    success,
    errorMessages,
    result,
    error,
    time,
    ...rest,
    handleAsyncAction
  }
}
export default useAsyncAction
