import {
  Button,
  Divider,
  Input,
  Radio
} from 'antd'
import * as apis from 'apis'
import { baseFieldTypes } from 'apis/base/baseFieldTypes'
import Request from 'apis/middleware/request'
import classnames from 'classnames'
import ImageUpload from 'components/form/fields/ImageUpload'
import FieldsFactory from 'components/form/FieldsFactory'
import ContentLayout from 'components/layouts/Default/ContentLayout'
import { API_ROOT_URL } from 'envs/_current/config'
import { Form, Formik } from 'formik'
import createFormData from 'helpers/createFormData'
import {
  emptyArray,
  emptyObject
} from 'helpers/emptyObjects'
import _ from 'lodash'
import { createAsyncAction } from 'modules/asyncCache'
import { getAsynCacheSelector } from 'modules/asyncCache/selectors'
import React, {
  useContext,
  useMemo,
  useState
} from 'react'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  Route,
  withRouter
} from 'react-router-dom'
import {
  mapProps,
  nest,
  withProps
} from 'recompose'

const getField = (
  param,
  fieldTypes = baseFieldTypes
) => {
  const fieldType = fieldTypes[param]
  switch (true) {
    case baseFieldTypes.auto ===
      fieldType:
      return {
        title: param,
        children: {
          type: param,
          title: param,
          component: Input,
          withProps: {
            disabled: true,
            type: 'text',
            placeholder: 'auto'
          },
          name: param
        }
      }
    case baseFieldTypes.image ===
      fieldType:
      return {
        title: param,
        children: {
          type: param,
          title: param,
          component: ImageUpload,
          name: param
        }
      }
    case fieldType instanceof Array:
      return {
        title: param,
        children: {
          type: param,
          title: param,
          name: param,
          component: mapProps(
            ({ ...props }) => {
              return {
                ...props,
                buttonStyle: 'solid',
                children: fieldType.map(
                  ({
                    value,
                    label
                  }) => {
                    return (
                      <Radio.Button
                        key={value}
                        value={value}>
                        {label}
                        {'_'}
                        <span className="font-bold">
                          {value}
                        </span>
                      </Radio.Button>
                    )
                  }
                )
              }
            }
          )(Radio.Group)
        }
      }
    default:
      return {
        title: param,
        children: {
          type: param,
          title: param,
          component: Input,
          withProps: {
            type: 'text',
            placeholder: param
          },
          name: param
        }
      }
  }
}

const list = Object.values(apis).filter(
  apiObj => apiObj.path
)
const TestApiContext = React.createContext()

const Result = () => {
  const [open, setOpen] = useState()
  const { result } = useContext(
    TestApiContext
  )
  return (
    <div className="w-full background-200">
      <div
        onClick={() => setOpen(!open)}
        className="w-full font-bold text-center p-2 border-b border-white">
        {open ? 'hide' : 'show'}
      </div>
      <div
        className="w-full overflow-y-scroll"
        style={{
          height: open ? '50vh' : 0
        }}>
        <div className="p-3 whitespace-pre-line">
          {JSON.stringify(
            result,
            null,
            2
          )}
        </div>
      </div>
    </div>
  )
}

const TestApiContent = () => {
  const {
    path,
    params,
    values,
    formQuerySchema,
    formDataSchema,
    handleSubmit,
    handleChange
  } = useContext(TestApiContext)
  return (
    <Form>
      <div className="p-5 verticalList w-full max-w-2xl m-auto">
        <div className="p-3 background-200 rounded font-bold">
          {Object.values(params).reduce(
            (result, key) => {
              if (
                values[key] &&
                values[key].length > 0
              )
                return result.replace(
                  key,
                  values[key]
                )
              return result
            },
            API_ROOT_URL + path
          )}
        </div>
        <Divider>
          <div className="uppercase ">
            Query
          </div>
        </Divider>

        <div>
          <FieldsFactory
            formSchema={formQuerySchema}
          />
        </div>
        <Divider>
          <div className="uppercase ">
            Data
          </div>
        </Divider>

        <div>
          <FieldsFactory
            formSchema={formDataSchema}
          />
        </div>
        <div className="border-t pt-3 border-gray-300 verticalList stickyBottom background flex flex-wrap justify-end">
          <Button
            onClick={handleSubmit}
            type="primary">
            Call
          </Button>
          <Result />
        </div>
      </div>
    </Form>
  )
}
export const callApi = ({
  apiInfo,
  url,
  data
}) => {
  let fn = Request.get
  if (
    _.get(apiInfo, 'method') === 'POST'
  ) {
    fn = Request.post
  }
  console.log(apiInfo)
  return fn(
    url,
    createFormData(data)
  ).then(apiInfo.transform)
}
const TestApiProvider = ({
  apiInfo = emptyObject,
  params = emptyObject,
  children
}) => {
  const [action, setAction] = useState()
  const result = useSelector(state =>
    getAsynCacheSelector(
      state,
      action && action.asyncId
    )
  )
  const dispath = useDispatch()

  const value = useMemo(() => {
    const formQuerySchema = Object.values(
      params
    ).map(param => ({
      title: param,
      children: {
        type: param,
        title: param,
        component: Input,
        withProps: {
          type: 'text',
          placeholder: param
        },
        name: param
      }
    }))
    const fieldTypes = {
      ...((fields = []) => {
        return fields.reduce(
          (result, field) => {
            result[field] =
              baseFieldTypes[field]
            return result
          },
          {}
        )
      })(apiInfo.fields),
      ...apiInfo.fieldTypes
    }
    const formDataSchema = Object.values(
      apiInfo.fields || emptyArray
    ).map(param =>
      getField(param, fieldTypes)
    )
    const initialValues = {
      ...params,
      ...(
        apiInfo.fields || emptyArray
      ).reduce((result, value) => {
        result[value] = undefined
        return result
      }, {})
    }

    const columns = [
      {
        title: 'Title',
        dataIndex: 'key',
        render: text => <a>{text}</a>
      },
      {
        title: 'value',
        dataIndex: 'value'
      }
    ]

    const data = Object.keys(
      apiInfo
    ).map(key => ({
      key: key,
      value:
        typeof apiInfo[key] ===
        'function' ? (
          <div className="break-all">
            {apiInfo[key].toString()}
          </div>
        ) : (
          <div className="break-all">
            {JSON.stringify(
              apiInfo[key]
            )}
          </div>
        )
    }))
    const handleCallApi = (
      url,
      data
    ) => {
      const action = createAsyncAction({
        apiInfo: {
          ...apiInfo,
          path: url
        },
        values: data
      })
      setAction(action.asyncData)
      dispath(action)
    }
    return {
      formQuerySchema,
      formDataSchema,
      initialValues,
      columns,
      data,
      handleCallApi
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiInfo])

  return (
    <Formik
      onSubmit={values => {
        value.handleCallApi(
          Object.values(params).reduce(
            (result, key) => {
              console.log(
                result,
                values[key]
              )
              if (
                values[key] &&
                values[key].length > 0
              )
                return result.replace(
                  key,
                  values[key]
                )
              return result
            },
            apiInfo.path
          ),
          (
            apiInfo.fields || emptyArray
          ).reduce((result, key) => {
            result[key] = values[key]
            return result
          }, {})
        )
      }}
      initialValues={
        value.initialValues
      }>
      {({
        values,
        handleSubmit,
        handleChange
      }) => {
        return (
          <TestApiContext.Provider
            value={{
              result,
              handleChange,
              columns: value.columns,
              data: value.data,
              params,
              values,
              path: apiInfo.path,
              formQuerySchema:
                value.formQuerySchema,
              formDataSchema:
                value.formDataSchema,
              handleSubmit
            }}>
            {children}
          </TestApiContext.Provider>
        )
      }}
    </Formik>
  )
}
const Menu = () => {
  const [input, setinput] = useState('')
  return (
    <>
      <div
        style={{
          height: 'var(--header-height)'
        }}
        className="p-3 flex items-center background sticky top-0 border-b border-gray-300">
        <Input
          placeholder="search..."
          className="block"
          onChange={e =>
            setinput(e.target.value)
          }
        />
      </div>
      <div className="px-3">
        {Object.values(list)
          .filter(
            apiObj =>
              input.length === 0 ||
              apiObj.path.includes(
                input
              )
          )
          .map((apiObj, i) => (
            <Route
              path={
                '/testApi' + apiObj.path
              }
              exact={true}
              key={apiObj.path}
              children={({
                match,
                history
              }) => (
                <div
                  // className="font-bold p-2 cursor-pointer animated rubberBand faster"
                  className={classnames(
                    'font-bold p-2 cursor-pointer',
                    {
                      'color-primary': match
                    }
                  )}>
                  <div
                    onClick={() =>
                      history.push(
                        '/testApi' +
                          apiObj.path
                      )
                    }>
                    {apiObj.path}
                  </div>
                </div>
              )}
            />
          ))}
      </div>
    </>
  )
}
const TestApi = withRouter(
  function TestApi({
    location,
    children
  }) {
    return (
      <ContentLayout
        {...{
          title: (
            <div className="capitalize font-bold  block text-gray-600">
              {location.pathname}
            </div>
          ),
          menu: <Menu />
        }}>
        {Object.values(list).map(
          (apiObj, i) => (
            <Route
              path={
                '/testApi' + apiObj.path
              }
              exact={true}
              key={apiObj.path}
              render={({
                match,
                history
              }) => (
                <TestApiProvider
                  path={
                    location.pathname
                  }
                  params={match.params}
                  apiInfo={apiObj}
                  children={children}
                />
              )}
            />
          )
        )}
      </ContentLayout>
    )
  }
)

export default withProps({
  initialEntries: list.map(
    obj => obj.path
  ),
  initialIndex: 0
})(nest(TestApi, TestApiContent))
