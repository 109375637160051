import history from 'envs/_current/history'
import { emptyObject } from 'helpers/emptyObjects'
import {
  call,
  put,
  select,
  takeEvery
} from 'redux-saga/effects'
import { getLogin } from 'redux/selectors'
import { callApi } from './callApi'
import {
  cacheKey,
  reducerKey
} from './const'

function* doAsync({
  type,
  start,
  asyncId,
  cacheId,
  apiInfo,
  query = emptyObject,
  values = emptyObject,
  meta
}) {
  try {
    if (apiInfo.requireLogin) {
      const login = yield select(
        getLogin
      )
      if (!login) {
        history.push({
          pathname: '/login'
          // state: {
          //   isModal: true
          // }
        })
        return
      }
    }
    const _start = start || Date.now()
    yield put({
      type: `${type}_REQUEST`,
      asyncId,
      [reducerKey]: {
        [asyncId]: {
          asyncId,
          start: _start,
          updated: _start,
          query,
          error: false,
          values,
          isLoading: true,
          meta
        }
      }
    })

    const response = yield call(
      callApi,
      {
        apiInfo,
        asyncId,
        query,
        values
      }
    ) || emptyObject
    const {
      error,
      status,
      entities,
      result
    } = response
    const end = Date.now()

    if (!error) {
      yield put({
        type: `${type}_SUCCESS`,
        error,
        status,
        entities,
        result,
        response,
        ...(cacheId
          ? {
              [cacheKey]: {
                [cacheId]:
                  result || response
              }
            }
          : {}),
        [reducerKey]: {
          [asyncId]: {
            asyncId,
            updated: end,
            result,
            end,
            isLoading: false,
            success: true,
            response
          }
        }
      })
    } else {
      yield put({
        type: `${type}_FAILURE`,
        response,
        [reducerKey]: {
          [asyncId]: {
            asyncId,
            end,
            updated: end,
            isLoading: false,
            error: true,
            errorMessages:
              response.errorMessages,
            response,
            status
          }
        }
      })
    }
  } catch (error) {
    console.error(error)
    const end = Date.now()
    yield put({
      type: `${type}_FAILURE`,
      [reducerKey]: {
        [asyncId]: {
          asyncId,
          end,
          updated: end,
          isLoading: false,
          error: true,
          errorMessages: error
        }
      }
    })
  }
}

export function* watchAsyncData() {
  yield takeEvery('*', function*(
    action
  ) {
    if (action.apiInfo) {
      yield call(doAsync, action)
    }
  })
}
