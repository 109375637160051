import { Button } from 'antd'
import logParams from 'helpers/logParams'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { UseFollow } from 'views/Server/ServerContainer'

export const FollowButton = ({
  item,
  size,
  children
}) => {
  const t = useTranslate()
  return (
    <UseFollow
      follow_status={item.follow_status}
      owner_id={
        item.id || item.username
      }
      owner_type={item._type}>
      {handleFollow => {
        if (children) {
          return logParams(children)(
            item.follow_status,
            handleFollow,
            item.follow_status
              ? t('following')
              : t('follow')
          )
        }
        return item.follow_status ? (
          <Button
            size={size}
            shape="round"
            onClick={() =>
              handleFollow()
            }
            className="background-200 text-gray-600 font-bold"
            type={'default'}>
            {t('following')}
          </Button>
        ) : (
          <Button
            size={size}
            shape="round"
            className=" font-bold"
            onClick={() =>
              handleFollow()
            }
            type={'primary'}>
            {t('follow')}
          </Button>
        )
      }}
    </UseFollow>
  )
}
