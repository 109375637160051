import classnames from 'classnames'
import Null from 'components/Null'
import React from 'react'
import './Count.css'
const Count = ({
  defaultNumber,
  type,
  component: Icon = Null,
  render,
  active,
  color,
  onClick,
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={classnames(
        'FeedCount  cursor-pointer flex items-center px-2',
        className,
        color,
        { active }
      )}
      {...props}>
      <button
        onClick={onClick}
        className="FeedCountButton">
        {Icon && (
          <Icon
            role="img"
            className="FeedCountIcon"
          />
        )}
        {render && render()}
        {children}
      </button>
      {!!defaultNumber && (
        <span
          className={classnames(
            'AnimateNumber overflow-hidden relative  leading-none',
            className
          )}>
          <span className="ml-1">{`${
            defaultNumber === null
              ? 0
              : defaultNumber
          }`}</span>
        </span>
      )}
    </div>
  )
}
export default Count
