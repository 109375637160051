import EllipsisOutlined from "@ant-design/icons/EllipsisOutlined";
import { Dropdown } from 'antd';
import React from 'react';
import { useToggle } from "react-use";

export const DropDownMenu = ({
  placement = "bottomRight",
  component = <EllipsisOutlined />,
  container,
  children,
  ...props
}) => {
  const [visible, setVisible] = useToggle(false);

  const toggle = () =>
    setVisible(!visible);

  return (
    <Dropdown
      getPopupContainer={() =>
        document.getElementById(
          container
        )
      }
      placement="bottomRight"
      overlay={
        children(visible, toggle)
      }
      trigger={['click']}
    >
      {component}
    </Dropdown>
  )

}

export default DropDownMenu