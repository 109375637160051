import {create_getSuggestedKeywords_Api, search_getTags_Api} from 'apis'
import classnames from 'classnames'
import Pure from 'components/Pure'
import {convertFromRaw, DefaultDraftBlockRenderMap, EditorState, Modifier, RichUtils} from 'draft-js'
import Editor from 'draft-js-plugins-editor'
import 'draft-js/dist/Draft.css'
import isSoftNewlineEvent from 'draft-js/lib/isSoftNewlineEvent'
import {emptyArray} from 'helpers/emptyObjects'
import Immutable from 'immutable'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React, {cloneElement, useCallback, useEffect, useRef, useState} from 'react'
import ScrollToViewByHash from '../ScrollToViewByHash'
import StatusBar from './components/StatusBar'
import plugins, {alignmentPlugin, dividerPlugin, mentionPlugin, tagPlugin} from './plugins'
import {InlineToolBar} from './plugins/inlineToolbar'
import EmbedButton from './plugins/side-toolbar/EmbedButton'
import ImageButton from './plugins/side-toolbar/ImageButton'
import sideToolbarPlugin from './plugins/side-toolbar/sideToolbarPlugin'
import './RichEditor.scss'
import sampleContent from './sampleContent'

const {
  AlignmentTool
} = alignmentPlugin

const { DividerButton } = dividerPlugin
const {
  MentionSuggestions: TagMentionSuggestions
} = tagPlugin

const {
  MentionSuggestions
} = mentionPlugin

const {
  SideToolbar
} = sideToolbarPlugin

const Header = props => {
  const text = _.get(
    props,
    'children.0.props.children.props.block'
  )
    .getText()
    .trim()
  const e = props.children[0]
  return cloneElement(e, {
    ...e.props,
    id: text
  })
}

const blockRenderMap = Immutable.Map({
  'header-one': {
    element: 'h2',
    wrapper: <Header />
  },
  'header-two': {
    element: 'h3',
    wrapper: <Header />
  },
  'header-three': {
    element: 'h4',
    wrapper: <Header />
  }
})

// keep support for other draft default block types and add our myCustomBlock type
const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(
  blockRenderMap
)
const Mentions = () => {
  const {
    response,
    handleAsyncAction
  } = useAsyncAction({
    apiInfo: create_getSuggestedKeywords_Api()
  })
  const onSearchChange = ({
    value
  }) => {
    handleAsyncAction({
      keyword: value
    })
  }
  const suggestions = _.get(
    response,
    'data.data',
    emptyArray
  )
    .map(item => ({
      name: _.get(item, 'name'),
      link: `/${_.get(
        item,
        'owner_type'
      )}/${_.get(item, 'idname') ||
      _.get(item, 'owner.username')}`,
      avatar: _.get(item, 'avatar')
    }))
    .filter((item, i) => i < 7)
  const onAddMention = () => {
    // get the mention object selected
  }
  return (
    <MentionSuggestions
      onSearchChange={onSearchChange}
      suggestions={suggestions}
      onAddMention={onAddMention}
    />
  )
}

const TagMentions = () => {
  const [
    keyword,
    setkeyword
  ] = useState()
  const {
    response,
    handleAsyncAction
  } = useAsyncAction({
    apiInfo: search_getTags_Api,
    query: { ':keyword': ' ' }
  })
  const onSearchChange = ({
    value
  }) => {
    setkeyword(value)
  }
  useEffect(() => {
    handleAsyncAction(
      {},
      keyword && keyword.length
        ? {
          ':keyword': keyword
        }
        : {}
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword])
  const suggestions = [
    {
      name: '#' + keyword,
      link: `/tag/${keyword}`
    },
    ..._.get(
      response,
      'data.data',
      emptyArray
    )
      .map(item => ({
        item,
        name: '#' + _.get(item, 'tags'),
        link: `/discovery?type=channel&keyword=${_.get(
          item,
          'tags'
        )}`
      }))
      .filter((item, i) => i < 7)
  ]
  const onAddMention = () => {
    // get the mention object selected
  }
  return (
    <TagMentionSuggestions
      onSearchChange={onSearchChange}
      suggestions={suggestions}
      onAddMention={onAddMention}
    />
  )
}
const handleReturn = (
  event,
  editorState,
  { setEditorState }
) => {
  if (isSoftNewlineEvent(event)) {
    const selection = editorState.getSelection()

    if (selection.isCollapsed()) {
      setEditorState(
        RichUtils.insertSoftNewline(
          editorState
        )
      )
    } else {
      const content = editorState.getCurrentContent()
      let newContent = Modifier.removeRange(
        content,
        selection,
        'forward'
      )
      const newSelection = newContent.getSelectionAfter()
      const block = newContent.getBlockForKey(
        newSelection.getStartKey()
      )

      newContent = Modifier.insertText(
        newContent,
        newSelection,
        '\n',
        block.getInlineStyleAt(
          newSelection.getStartOffset()
        ),
        null
      )

      setEditorState(
        EditorState.push(
          editorState,
          newContent,
          'insert-fragment'
        )
      )
    }

    return 'handled'
  }

  return 'not-handled'
}
const handleKey = (
  command,
  editorState,
  __,
  { setEditorState }
) => {
  const newState = RichUtils.handleKeyCommand(
    editorState,
    command
  )

  if (newState) {
    setEditorState(newState)
    return 'handled'
  }

  return 'not-handled'
}
const RichEditor = React.memo(
  ({
    readOnly,
    editorState,
    onChange
  }) => {
    const t = useTranslate()
    const [e, setE] = useState(
      editorState ||
      EditorState.createWithContent(
        convertFromRaw(
          JSON.parse(sampleContent)
        )
      )
    )
    window.editorState = e
    const editorRef = useRef()
    const onChangeRef = useRef(onChange)
    const onWrapClick = useCallback(
      () => editorRef.current.focus(),
      []
    )
    useEffect(() => {
      onChangeRef.current &&
        onChangeRef.current(e)
    }, [e])

    function renderPlaceholder(
      placeholder,
      editorState
    ) {
      const contentState = editorState.getCurrentContent()
      const shouldHide =
        contentState.hasText() ||
        contentState
          .getBlockMap()
          .first()
          .getType() !== 'unstyled'
      return shouldHide
        ? ''
        : placeholder
    }

    return (
      <div
        className={classnames(
          'prose RichEditor max-w-6xl dont-break-out mx-auto mb-4 ',
          {
            readOnly
          }
        )}>
        {!readOnly && (
          <StatusBar editorState={e} />
        )}
        <ScrollToViewByHash />
        <div onClick={onWrapClick}>
          <Editor
            placeholder={
              !readOnly &&
              renderPlaceholder(
                t('tell a story'),
                e
              )
            }
            blockRenderMap={
              extendedBlockRenderMap
            }
            readOnly={readOnly}
            ref={editorRef}
            editorState={e}
            onChange={setE}
            plugins={plugins}
            handleKeyCommand={handleKey}
            handleReturn={handleReturn}
          />
          {!readOnly && (
            <Pure>
              <AlignmentTool />
              <InlineToolBar />
              <Mentions />
              <TagMentions />
              <SideToolbar>
                {// may be use React.Fragment instead of div to improve perfomance after React 16
                  externalProps => [
                    <ImageButton
                      {...externalProps}
                    />,
                    <EmbedButton
                      {...externalProps}
                    />,
                    <DividerButton
                      {...externalProps}
                    />
                  ]}
              </SideToolbar>
            </Pure>
          )}
          <div className="clearfix" />
        </div>
      </div>
    )
  }
)
export const defaultRawContent = {
  blocks: [
    {
      key: '51v21',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    }
  ],
  entityMap: {}
}

export default RichEditor
