import DoubleRightOutlined from '@ant-design/icons/DoubleRightOutlined'
import {Spin} from "antd"
import Pure from 'components/Pure'
import {SelectEntityItem} from 'components/SelectEntityItem'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import {feed_fetchFeedsByOwner_Api} from "../../../apis"
import {channelModel} from "../../../apis/model"
import {feedSchema} from '../../../apis/schema'
import {FeedContext} from '../../../components/Feed/FeedCard'
import {createTimeLineFeed} from '../../../components/Feed/TimeLineFeed'
import {ChannelIcon} from "../../../components/icons/ChannelIcon"
import VisibleWhenHaveDataPagination from "../../../modules/asyncCache/components/VisibleWhenHaveDataPagination"
import {OrganizationContext} from '../OrganizationContext'

const Feed = createTimeLineFeed({
  ContentLayout: props => {
    return (
      <FeedContext.Consumer>
        {feedContent => {
          const title = _.get(
            feedContent,
            'embed_data.title',
            _.get(
              feedContent,
              'title',
              ''
            )
          )
          const description = _.get(
            feedContent,
            'embed_data.description',
            _.get(
              feedContent,
              'description',
              ''
            )
          )
          const linkToUrl =
            '/news/' +
            _.get(
              feedContent,
              'item._type'
            ) +
            '/' +
            _.get(
              feedContent,
              'item.id'
            )
          return (
            <div className="border-t border-gray-300 cursor-pointer">
              <div className="flex overflow-hidden p-2">
                <div className="break-words flex-1 mb-1 flex-col">
                  <h3 className="mt-0">
                    <Link
                      to={linkToUrl}
                      className="font-medium text-lg hover:underline leading-6">
                      {title}
                    </Link>
                  </h3>

                  <div className="leading-snug text-color-200">
                    {_.truncate(
                      description,
                      {
                        length: 160,
                        separator: ' '
                      }
                    )}
                  </div>

                  <div className="flex flex-1 text-sm pt-2">
                    <ChannelIcon
                      premium={_.get(feedContent, 'item.container.premium')}
                      className="mr-2 text-color-100 text-xl w-6 h-6"
                    />
                    <Link
                      to={channelModel.getLinkToDetail(
                        _.get(feedContent, 'item.container')
                      )}
                      className="">
                      {_.get(feedContent, 'item.container.title')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )
        }}
      </FeedContext.Consumer>
    )
  }
})
export const renderItem = (item) => {
  return (
    (item || null) && (
      <Pure
        key={item.id}
        input={[
          item &&
          item.content &&
          item.content.updated,
          _.get(
            item,
            'content.pin_status'
          )
        ]}>

        <Feed item={item.content} />

      </Pure>
    )
  )
}

export const OurNews = () => {
  const t = useTranslate()
  const {
    organization: item,
    url
  } = useContext(OrganizationContext)
  return (
    <VisibleWhenHaveDataPagination
      sample={3}
      id={
        feed_fetchFeedsByOwner_Api.path + '_type_organization_id_' + item.idname
      }
      apiInfo={
        feed_fetchFeedsByOwner_Api
      }
      query={{
        ':prop': 'organization',
        ':id': item.idname
      }}
      renderLoading={() => (
        <div className="w-full flex justify-center">
          <Spin className="m-auto" />
        </div>
      )}
      renderItem={
        (feedEl, i) => (
          <SelectEntityItem
            key={feedEl.id}
            item={feedEl.id}
            schema={feedSchema}>
            {
              item => renderItem(item, i)
            }
          </SelectEntityItem>
        )
      }
      renderWrap={children => (
        <div className="max-w-6xl mx-auto flex flex-col space-y-3">
          <h2 className="flex justify-center items-center font-bold text-xl uppercase">
            {t('news')}
          </h2>
          <div className="flex flex-col space-y-3">
            {children}
          </div>
          <div
            className="border-gray-300 font-bold border-gray-300 cursor-pointer p-3 flex-wrap flex justify-center color-primary">
            <div className="flex-1" />
            <Link to={url + '/news'}>
              <span>{t('see more')}</span>
              <DoubleRightOutlined />
            </Link>
          </div>
        </div>
      )}
    />
  )
}
